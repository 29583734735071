import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../consts/api";
import { http } from "../../../../services/HttpService";

export const useAvailableJobs = (options = {}) => {
  return useQuery(
    ["availableJobs"],
    () => http.get(HTTP_ENDPOINTS.getAvailableJobs()),
    { select: (data) => data.data, ...options },
  );
};
