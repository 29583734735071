import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../../consts/api";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { dispatch } from "../../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";
import { useCloseSmartReader } from "./useCloseSmartReader";
import { useSmartReaderState } from "./useSmartReaderState";

export const useCardReaderCharge = (selectedReader) => {
  const { refetchInvoice } = useInvoice();
  const { tSales } = useAppTranslation.Sales();
  const [isPolling, setIsPolling] = useState(false);

  const { mutate: closeSmartReader } = useCloseSmartReader();
  const { mutateAsync: getSmartReaderState } = useSmartReaderState(
    selectedReader?.readerIdentifier,
  );

  const req = useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.postCheckoutPayment(), dto),
    {
      onSuccess: () => {
        uiNotification.success(
          tSales("checkoutInvoice.success.transferPaymentToReader"),
        );

        let unchangedIterations = 0;
        const maxUnchangedIterations = 60;

        const pollInvoiceDetails = async () => {
          try {
            const response = await getSmartReaderState();
            const wait = response?.data?.data?.wait;

            if (!wait) {
              clearPolling();
            } else {
              unchangedIterations++;
              if (unchangedIterations >= maxUnchangedIterations) {
                closeSmartReader(selectedReader.readerIdentifier);
                clearPolling(tSales("checkoutInvoice.failed.paymentFailed"));
                return;
              }
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        };

        const pollingInterval = setInterval(pollInvoiceDetails, 3000);

        const clearPolling = (errorMessage = null) => {
          clearInterval(pollingInterval);
          setIsPolling(false);
          refetchInvoice();
          if (errorMessage) uiNotification.error(errorMessage);
          dispatch(checkoutInvoice.actions.paymentViewClose());
        };

        setIsPolling(true);
      },
      onError: (error) => {
        uiNotification.error(error.response?.data?.message);
      },
    },
  );

  return {
    chargePayment: req.mutate,
    isLoading: req.isLoading,
    isPolling,
  };
};
