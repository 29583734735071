import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { uiNotification } from "../../../services/UINotificationService";

const StripeCheckoutForm = (props) => {
  const {
    type,
    onSubmit,
    validation,
    onCancel,
    onSubmitText,
    onCancelText,
    prePayment,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (validation && (await validation())) {
      return;
    }

    setIsLoading(true);

    if (type === "setupIntent") {
      try {
        const result = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {},
        });

        if (result.error) {
          uiNotification.error(result.error.message);
        } else {
          onSubmit(event);
        }
      } catch (e) {
        /**/
      }
    }
    setIsLoading(false);
  };
  return (
    <form>
      <PaymentElement />
      {!!prePayment && (
        <button
          type="button"
          className="new-blue-btn pull-right m-t-20"
          onClick={prePayment.onClick}
        >
          {prePayment.label}
        </button>
      )}
      {!prePayment && (
        <button
          type="button"
          className="new-blue-btn pull-right m-t-20"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? "Processing..." : onSubmitText || "Charge"}
        </button>
      )}
      {onCancel && (
        <button
          type="button"
          className="new-blue-btn pull-right m-t-20"
          onClick={onCancel}
        >
          {onCancelText || "Cancel"}
        </button>
      )}
    </form>
  );
};

export default StripeCheckoutForm;
