import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  clinicIds: yup.array().of(yup.number()).nullable(),
  fromDate: yup.string().required(),
  toDate: yup.string().required(),
});

const responseSchema = yup.object({
  totalSales: yup
    .object({
      grossSales: yup.string().nullable(),
      membershipRevenue: yup.string().nullable(),
      prePaidProducts: yup.string().nullable(),
      giftCardPurchases: yup.string().nullable(),
      cancellationFeeCollected: yup.string().nullable(),
      walletDebits: yup.string().nullable(),
      giftCardRedemptions: yup.string().nullable(),
      refunds: yup.string().nullable(),
      tips: yup.string().nullable(),
      tax: yup.string().nullable(),
      processingAndBusinessFees: yup.string().nullable(),
      discountComplementary: yup.string().nullable(),
      membershipCoupons: yup.string().nullable(),
      totalNetSales: yup.string().nullable(),
      repeatMdRedemptions: yup.string().nullable(),
    })
    .required(),

  procedureTotalSales: yup
    .object({
      grossSales: yup.string().nullable(),
      walletDebits: yup.string().nullable(),
      giftCardRedemptions: yup.string().nullable(),
      refunds: yup.string().nullable(),
      tips: yup.string().nullable(),
      tax: yup.string().nullable(),
      processingAndBusinessFees: yup.string().nullable(),
      discountComplementary: yup.string().nullable(),
      membershipCoupons: yup.string().nullable(),
      totalNetSales: yup.string().nullable(),
    })
    .required(),

  depositSlip: yup
    .object({
      grossSales: yup.string().nullable(),
      membershipRevenue: yup.string().nullable(),
      prePaidProducts: yup.string().nullable(),
      giftCardPurchases: yup.string().nullable(),
      cancellationFeeCollected: yup.string().nullable(),
      walletDebits: yup.string().nullable(),
      discountComplementary: yup.string().nullable(),
      membershipCoupons: yup.string().nullable(),
      aspireCoupons: yup.string().nullable(),
      bdCoupons: yup.string().nullable(),
      evolusCoupons: yup.string().nullable(),
      giftCardDeposits: yup.string().nullable(),
      careCreditDeposits: yup.string().nullable(),
      greenskyDeposits: yup.string().nullable(),
      refunds: yup.string().nullable(),
      processingAndBusinessFees: yup.string().nullable(),
      totalNetSales: yup.string().nullable(),
    })
    .required(),

  paymentMethod: yup
    .object({
      cash: yup.string().nullable(),
      check: yup.string().nullable(),
      credit: yup.string().nullable(),
      cherry: yup.string().nullable(),
      stripe_link: yup.string().nullable(),
      klarna: yup.string().nullable(),
      greenSky: yup.string().nullable(),
      careCredit: yup.string().nullable(),
      aspireCoupons: yup.string().nullable(),
      bdCoupons: yup.string().nullable(),
      totalNetSales: yup.string().nullable(),
      giftCard: yup.string().nullable(),
      repeatMdRedemptions: yup.string().nullable(),
    })
    .required(),
});

const composeResponse = (res) => {
  const totalSales = res?.data?.data?.daily_total_sales || {};

  const procedureTotalSales =
    res?.data?.data?.daily_procedure_total_sales || {};

  const depositSlip = res?.data?.data?.daily_deposit_slip || {};

  const paymentMethod = res?.data?.data?.daily_payment_method || {};

  const clinics = res?.data?.data?.clinics || null;

  return {
    totalSales: {
      grossSales: totalSales.daily_gross_sales || null,
      membershipRevenue: totalSales.daily_membership_revenue || null,
      prePaidProducts: totalSales.pre_paid_products || null,
      giftCardPurchases: totalSales.gift_card_purchase || null,
      cancellationFeeCollected: totalSales.cancellation_feecollected || null,
      walletDebits: totalSales.wallet_debits || null,
      giftCardRedemptions: totalSales.gift_card_redemptions || null,
      refunds: totalSales.refunds || null,
      tips: totalSales.tips || null,
      tax: totalSales.tax || null,
      processingAndBusinessFees:
        totalSales.processing_and_bussiness_fees || null,
      discountComplementary: totalSales.discount_complementry || null,
      membershipCoupons: totalSales.membership_coupons || null,
      totalNetSales: totalSales.daily_total_net_Sales || null,
    },
    procedureTotalSales: {
      grossSales: procedureTotalSales.procedure_daily_gross_sales || null,
      walletDebits: procedureTotalSales.procedure_wallet_debits || null,
      giftCardRedemptions: procedureTotalSales.gift_card_redemptions || null,
      refunds: procedureTotalSales.procedure_refunds || null,
      tips: procedureTotalSales.procedure_tips || null,
      tax: procedureTotalSales.procedure_tax || null,
      processingAndBusinessFees:
        procedureTotalSales.procedure_processing_and_bussiness_fees || null,
      discountComplementary:
        procedureTotalSales.procedure_discount_complementry || null,
      membershipCoupons:
        procedureTotalSales.procedure_membership_coupons || null,
      totalNetSales:
        procedureTotalSales.procedure_daily_total_net_Sales || null,
    },
    depositSlip: {
      grossSales: depositSlip.deposit_daily_gross_sales || null,
      membershipRevenue: depositSlip.deposit_daily_membership_revenue || null,
      prePaidProducts: depositSlip.deposit_pre_paid_products || null,
      giftCardPurchases: depositSlip.gift_card_purchase || null,
      cancellationFeeCollected: depositSlip.cancellation_feecollected || null,
      walletDebits: depositSlip.deposit_wallet_debits || null,
      discountComplementary: depositSlip.deposit_discount_complementry || null,
      membershipCoupons: depositSlip.deposit_membership_coupons || null,
      aspireCoupons: depositSlip.deposit_aspire_coupons || null,
      bdCoupons: depositSlip.deposit_bd_coupons || null,
      evolusCoupons: depositSlip.deposit_evolus_coupons || null,
      xperienceCoupons: depositSlip.deposit_xperience_coupons || null,
      giftCardDeposits: depositSlip.deposit_gift_card || null,
      careCreditDeposits: depositSlip.deposit_deposit_care_credit_total || null,
      greenskyDeposits: depositSlip.deposit_greensky_total || null,
      refunds: depositSlip.deposit_refunds || null,
      processingAndBusinessFees:
        depositSlip.deposit_processing_and_bussiness_fees || null,
      totalNetSales: depositSlip.daily_deposit_total_net_Sales || null,
      repeatMdRedemptions: depositSlip.deposit_repeat_md_redemptions || null,
      joyaRedemptions: depositSlip.deposit_joya_redemptions || null,
    },
    paymentMethod: {
      cash: paymentMethod.daily_payment_cash || null,
      check: paymentMethod.daily_payment_check || null,
      credit: paymentMethod.daily_payment_credit || null,
      cherry: paymentMethod.daily_payment_cherry || null,
      stripe_link: paymentMethod.daily_payment_link || null,
      klarna: paymentMethod.daily_payment_klarna || null,
      greenSky: paymentMethod.daily_payment_greensky || null,
      careCredit: paymentMethod.daily_payment_care_credit || null,
      aspireCoupons: paymentMethod.daily_payment_aspire_coupons || null,
      bdCoupons: paymentMethod.daily_payment_bd_coupons || null,
      xperienceCoupons: paymentMethod.daily_payment_xperience_coupons || null,
      totalNetSales: paymentMethod.daily_payment_total_net_Sales || null,
      giftCard: paymentMethod.daily_payment_gift_card || null,
      repeatMdRedemptions:
        paymentMethod.daily_payment_repeat_md_redemptions || null,
      joyaRedemptions: paymentMethod.daily_payment_joya_redemptions || null,
    },
    clinics,
  };
};

function createDailySalesReportDetailsQueryKey({
  clinicIds,
  fromDate,
  toDate,
}) {
  return [
    QUERY_KEYS.dailySalesReportDetails,
    clinicIds.join("-"),
    fromDate,
    toDate,
  ];
}

export function useDailySalesReportDetailsQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createDailySalesReportDetailsQueryKey({
      clinicIds: req.clinicIds || [],
      fromDate: req.fromDate,
      toDate: req.toDate,
    }),
    async () => {
      const res = await http.post(
        HTTP_ENDPOINTS.createDailySalesReport(),
        removeNullishFromShape({
          clinic_id: req.clinicIds.length > 0 ? req.clinicIds : null,
          fromDate: req.fromDate,
          toDate: req.toDate,
        }),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
