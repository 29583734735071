import React from "react";
import * as yup from "yup";
import { withRouter } from "react-router";
import { Modal } from "../../../../../shared/Modal/Modal";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../../shared/Input/Input";
import { Button } from "../../../../../shared/Button/Button";
import { useConnectJoya } from "../hooks/useConnectJoya";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useJoyaWallet } from "../hooks/useJoyaWallet";
import { useFormik } from "formik";
import { InputError } from "../../../../../shared/InputError/InputError";
import { tClients } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

const schema = yup.object({
  joyaId: yup
    .string()
    .required(tClients("joyaWallet.connectModal.errors.joyaId")),
});

const ConnectJoyaModal = ({ children, match, onClose }) => {
  const patientId = match.params.clientID;

  const { refetch: fetchWallet } = useJoyaWallet(patientId);

  const { isLoading, mutate: connectJoya } = useConnectJoya({
    onSuccess: () => {
      fetchWallet();
      onClose();
      uiNotification.success(tClients("joyaWallet.connectModal.api.success"));
    },
    onError: (error) => uiNotification.error(error.response.data.message),
  });

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    validationSchema: schema,
    onSubmit: ({ joyaId }) => connectJoya({ patientId, joyaId }),
    initialValues: {
      joyaId: "",
    },
  });

  const handleInput = (event) => {
    setFieldValue("joyaId", event.target.value);
  };

  return (
    <Modal
      footerNoBorder
      isOpen
      onClose={onClose}
      header={
        <Modal.Title>{tClients("joyaWallet.connectModal.title")}</Modal.Title>
      }
      footer={
        <Button
          onClick={handleSubmit}
          leftAdornment={
            isLoading ? <CircularProgress color="white" size="small" /> : null
          }
          isDisabled={isLoading}
        >
          {tClients("joyaWallet.connectModal.connect")}
        </Button>
      }
    >
      <div>
        <InputLabel>{tClients("joyaWallet.connectModal.joyaId")}</InputLabel>
        <Input
          type="text"
          name="joyaId"
          value={values.joyaId}
          onChange={handleInput}
        ></Input>
        {errors.joyaId && <InputError>{errors.joyaId}</InputError>}
      </div>
      {children}
    </Modal>
  );
};

export default withRouter(ConnectJoyaModal);
