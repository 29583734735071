import React, { useEffect, useRef, useState } from "react";
import { useProcedureLockMutation } from "../../../../../api/procedure/useProcedureLockMutation";
import { extractApiError } from "../../../../../utilities/api";
import { uiNotification } from "../../../../../services/UINotificationService";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { useProcedureUnlockMutation } from "../../../../../api/procedure/useProcedureUnlockMutation";
import { BeforeUnload } from "../../../../../shared/BeforeUnload";

export function Lock({ procedureId, children }) {
  var intervalRef = useRef(null);
  var unlock = useProcedureUnlockMutation();

  var { 0: alreadyLockedMessage, 1: setAlreadyLockedMessage } =
    useState(undefined);

  var lock = useProcedureLockMutation({
    onMutate: () => {
      setAlreadyLockedMessage(undefined);
    },
    onError: (e) => {
      var message = extractApiError(e);
      uiNotification.error(message);

      if (message?.toLowerCase()?.includes("procedure is already locked")) {
        setAlreadyLockedMessage(message);
      }
    },
  });

  useEffect(() => {
    if (!intervalRef.current && procedureId) {
      lock.mutateAsync({ procedureId }).then(() => {
        intervalRef.current = setInterval(() => {
          lock.mutate({ procedureId });
        }, 1000 * 59);
      });
    }
  }, [intervalRef.current, procedureId, lock.mutate]);

  useEffect(() => {
    return () => {
      unlock.mutate({ procedureId });
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <>
      <BeforeUnload
        onBeforeunload={() => {
          unlock.mutate({ procedureId });
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }}
      />
      {alreadyLockedMessage ? (
        <AlertBox>{alreadyLockedMessage}</AlertBox>
      ) : (
        children
      )}
    </>
  );
}
