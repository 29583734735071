import { useQueryParamsState } from "../../../utilities/hooks/useQueryParamsState";

var IDLE_MODAL_KEY = "idle";

export function useModalState() {
  var { 0: state, 1: updateState } = useQueryParamsState({
    [IDLE_MODAL_KEY]: undefined,
  });

  var open = () => {
    updateState({
      [IDLE_MODAL_KEY]: true,
    });
  };

  var close = () => {
    updateState({
      [IDLE_MODAL_KEY]: undefined,
    });
  };

  return {
    isOpen: state[IDLE_MODAL_KEY] === "true",
    open,
    close,
  };
}
