import React from "react";
import cx from "clsx";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { withRouter } from "react-router-dom";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../shared/Select/Select";
import classes from "../scss/Joya.module.scss";
import { useSetJoyaMapping } from "../hooks/useSetJoyaMapping";
import { uiNotification } from "../../../../../services/UINotificationService";
import { INVENTORY_ROUTES } from "../../../../../consts/routes";
import { Button } from "../../../../../shared/Button/Button";

const AnnualSkinCheckMapping = ({ benefit, history }) => {
  const { mutate } = useSetJoyaMapping();
  const queryClient = useQueryClient();

  const selectValues = benefit.options
    .map((option) => {
      if (benefit.mapping?.includes(option.value)) {
        return option;
      }
      return null;
    })
    .filter(Boolean);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      services: selectValues,
    },
    onSubmit: (values) => {
      const dto = {
        id: benefit.benefitId,
        mapping: values.services.map((service) => service.value),
      };
      mutate(dto, {
        onSuccess: () => {
          uiNotification.success("Services saved successfully!");
          queryClient.invalidateQueries(["joyaMapping"]);
        },
      });
    },
  });

  const redirectEditProduct = (id) => {
    history.push(INVENTORY_ROUTES.editProduct(id));
  };

  return (
    <div className={cx(classes.mappingContainer, classes.font12)}>
      <p className={classes.mappingParagraph}>
        Please configure the annual skin cancer screening service that will be
        redeemed as the Joya Annual Skin Check Benefit. As long as the
        integration is active, this service will remain in your inventory.
      </p>
      <div className={cx(classes.inputContainer)}>
        <InputLabel className={classes.font14}>
          Service: <span className="required">*</span>
        </InputLabel>
        <Select
          size="small"
          options={benefit.options}
          value={values.services}
          placeholder="Select..."
          isSearchable
          isMulti={benefit.multipleSelect}
          isDisabled
          onChange={(option) =>
            setFieldValue(
              "services",
              benefit.multipleSelect ? option : [option],
            )
          }
        />
      </div>
      <div>
        {Boolean(values.services[0]?.value) && (
          <Button
            size="small"
            onClick={() => redirectEditProduct(values.services[0]?.value)}
          >
            Edit Service
          </Button>
        )}
      </div>
    </div>
  );
};

export default withRouter(AnnualSkinCheckMapping);
