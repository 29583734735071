import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classes from "../sass/InputName.module.scss";
import { Input } from "../../../../../shared/Input/Input";
import { svg } from "../../../../../assets/svg";
import { InputError } from "../../../../../shared/InputError/InputError";

export function InputName({
  isError,
  error,
  value,
  onChange,
  clientsList,
  onSelect,
  onSetCreateMode,
  isSearching,
  withSearch,
  isDisabled,
}) {
  const [isClientNameFocused, setIsClientNameFocused] = useState(false);
  const clientInputRef = useRef();
  const autoSuggestionClientsRef = useRef();

  useEffect(() => {
    const refHandler = (e) => {
      clientInputRef.current &&
        !clientInputRef.current.contains(e.target) &&
        autoSuggestionClientsRef.current &&
        !autoSuggestionClientsRef.current.contains(e.target) &&
        setIsClientNameFocused(false);
    };

    document.addEventListener("mousedown", refHandler);
    return () => document.removeEventListener("mousedown", refHandler);
  }, [clientInputRef.current, autoSuggestionClientsRef.current]);

  const handleSelectClient = (client) => {
    setIsClientNameFocused(false);
    onSelect(client);
  };

  const isNotFound = clientsList.length === 0 && !isSearching;

  return (
    <div className={classes.root}>
      <Input
        isError={Boolean(error) || isError}
        name="client"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter Full Name"
        type="text"
        autoComplete="client"
        onFocus={() => setIsClientNameFocused(true)}
        ref={clientInputRef}
        isDisabled={isDisabled}
      />
      {Boolean(error) && <InputError>{error}</InputError>}
      {!isDisabled && withSearch && Boolean(value) && isClientNameFocused && (
        <div className={classes.dropdown} ref={autoSuggestionClientsRef}>
          <button onClick={onSetCreateMode} className={classes.createBtn}>
            <img alt="+" src={svg.plusPrimary} />
            {`Create New Client “${value}”`}
          </button>
          {isNotFound && (
            <div className={classes.status}>Patient Not Found</div>
          )}
          {isSearching && <div className={classes.status}>Searching..</div>}
          {clientsList.map((client) => (
            <button
              key={client.id}
              onClick={() => handleSelectClient(client)}
              className={classes.patient}
            >
              {client.fullName}
              {(client.email || client.phone) && (
                <div className={classes.clientInfo}>
                  {client.phone && (
                    <div className={classes.clientPhone}>{client.phone}</div>
                  )}
                  {client.email && <div>{client.email}</div>}
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

InputName.propTypes = {
  isError: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clientsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      pinCode: PropTypes.string.isRequired,
      dateOfBirth: PropTypes.string.isRequired,
    }),
  ),
  onSelect: PropTypes.func,
  onSetCreateMode: PropTypes.func,
  isSearching: PropTypes.bool,
  withSearch: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

InputName.defaultProps = {
  isError: false,
  error: null,
  value: "",
  clientsList: [],
  isSearching: false,
  withSearch: false,
  onSelect: () => {},
  onSetCreateMode: () => {},
  isDisabled: false,
};
