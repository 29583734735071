import React from "react";

const RepeatMdIcon = ({ width, height, color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "36px"}
      height={height || "36px"}
      viewBox="0 0 225.000000 224.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,224.000000) scale(0.100000,-0.100000)"
        fill={color || "#000000"}
        stroke="none"
      >
        <path
          d="M968 2214 c-229 -35 -447 -146 -612 -310 -210 -211 -326 -489 -326
-784 0 -421 237 -802 615 -990 267 -133 582 -150 856 -47 304 115 547 357 659
659 73 196 84 446 30 660 -28 112 -113 285 -188 384 -182 241 -450 397 -747
433 -113 14 -177 13 -287 -5z m287 -524 c215 -30 383 -192 415 -402 24 -158
-29 -296 -169 -441 l-90 -93 84 -84 c47 -47 85 -88 85 -92 0 -5 -112 -8 -250
-8 l-250 0 0 257 0 258 93 -93 93 -93 85 88 c105 107 124 150 117 253 -4 55
-12 81 -34 117 -35 56 -99 108 -152 123 -20 5 -135 10 -254 10 l-218 0 0 -460
0 -460 -100 0 -100 0 0 558 c0 307 3 561 6 565 11 11 556 8 639 -3z"
        />
      </g>
    </svg>
  );
};

export default RepeatMdIcon;
