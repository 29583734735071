import React, { useState } from "react";
import cx from "clsx";
import { useFormik } from "formik";
import * as yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../shared/Select/Select";
import { Input } from "../../../../../shared/Input/Input";
import classes from "../scss/Joya.module.scss";
import { useAppointmentConfigurationService } from "../hooks/useAppointmentConfigurationService";
import { Button } from "../../../../../shared/Button/Button";
import { useAddAppointmentConfigurationService } from "../hooks/useAddAppointmentConfigurationService";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import AccordionButton from "./AccordionButton";
import { uiNotification } from "../../../../../services/UINotificationService";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { useEditAppointmentConfigurationService } from "../hooks/useEditAppointmentConfigurationService";
import { InputError } from "../../../../../shared/InputError/InputError";

const AppointmentConfiguration = ({ mapping }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const selectedServiceId = mapping?.service?.id || 0;
  const queryClient = useQueryClient();

  const {
    categoriesOptions,
    clinicsOptions,
    consentsOptions,
    providersOptions,
    questionnairesOptions,
    serviceData,
    isFetching,
  } = useAppointmentConfigurationService(selectedServiceId || 0, {
    enabled: isExpanded,
  });

  const { mutate: saveService, isLoading } =
    useAddAppointmentConfigurationService(selectedServiceId || 0);

  const { mutate: editService, isLoading: isEditing } =
    useEditAppointmentConfigurationService(selectedServiceId || 0);

  const validationSchema = yup.object().shape({
    serviceDuration: yup
      .number()
      .moreThan(9, "Service duration must at least 10 minutes")
      .required("Service duration must at least 10 minutes"),
    serviceCategories: yup.array().min(1).required(),
    clinics: yup.array().min(1).required(),
    providers: yup.array().min(1).required(),
    consents: yup.array().min(0).required(),
    questionnaires: yup.array().min(0).required(),
  });

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      serviceDuration: serviceData.duration,
      serviceCategories: serviceData.categories,
      clinics: serviceData.clinics,
      providers: serviceData.providers,
      consents: serviceData.consents,
      questionnaires: serviceData.questionnaires,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const dto = {
        categories: values.serviceCategories.map((category) => category.value),
        clinics: values.clinics.map((clinic) => clinic.value),
        duration: values.serviceDuration,
        providers: values.providers.map((provider) => provider.value),
        service_consents: values.consents.map((consent) => consent.value),
        questionnaires: values.questionnaires.map(
          (questionnaire) => questionnaire.value,
        ),
        name: "Joya Annual Skin Cancer Screening",
        step: 3,
        service_type: "in_person",
        is_joya_service: true,
        mode: mapping.service ? "edit" : "add",
      };

      const mutation = mapping.service ? editService : saveService;

      mutation(dto, {
        onSuccess: () => {
          uiNotification.success("Service Saved Successfully!");
          queryClient.invalidateQueries(["joyaMapping"]);
        },
      });
    },
  });

  const loading = isLoading || isEditing;

  return (
    <>
      <AccordionButton
        label="Joya Appointment Configuration"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      {isExpanded && (
        <div className={cx(classes.mappingContainer, classes.font12)}>
          <p className={classes.mappingParagraph}>
            Please configure your appointment settings for the annual Joya Skin
            Cancer Screening service. If Online Booking is available for a
            designated clinic below, a vertical link is automatically generated
            and shared with the Joya Health team so they can assist patients
            when booking with your Practice. This service will not display on
            your normal Online Booking URL unless you configure it within the
            Smart Configuration settings under the Appointments tab.
          </p>
          {isFetching ? (
            <Skeleton count={6} height={40} />
          ) : (
            <>
              <div className={classes.skinCheckMappingLine}>
                <div className={classes.appointmentConfigurationInput}>
                  <InputLabel className={classes.font14}>
                    Service Category: <span className="required">*</span>
                  </InputLabel>
                  <Select
                    size="small"
                    options={categoriesOptions}
                    value={values.serviceCategories}
                    placeholder="Select..."
                    isMulti
                    isSearchable
                    onChange={(option) =>
                      setFieldValue("serviceCategories", option)
                    }
                    isError={errors.serviceCategories}
                  />
                </div>
                <div className="flex gap-8 items-center text-no-wrap">
                  <span className={classes.bold}>Service Name:</span>
                  <span>Joya Annual Skin Cancer Screening</span>
                </div>
              </div>
              <div>
                <div className="flex gap-8 items-center text-no-wrap">
                  <span className={classes.bold}>Service Duration</span>
                  <Input
                    size="small"
                    type="text"
                    className={classes.minutesInput}
                    onChange={(event) =>
                      setFieldValue("serviceDuration", event.target.value)
                    }
                    value={values.serviceDuration}
                    isError={errors.serviceDuration}
                  />
                  <span>Minutes</span>
                </div>

                {errors.serviceDuration && (
                  <InputError>{errors.serviceDuration}</InputError>
                )}
              </div>
              <div className={classes.appointmentConfigurationLine}>
                <div className={classes.appointmentConfigurationInput}>
                  <InputLabel className={classes.font14}>
                    Clinic(s): <span className="required">*</span>
                  </InputLabel>
                  <Select
                    size="small"
                    options={clinicsOptions}
                    placeholder="Select..."
                    value={values.clinics}
                    isMulti
                    isSearchable
                    onChange={(option) => setFieldValue("clinics", option)}
                    isError={errors.clinics}
                  />
                </div>
                <div className={classes.appointmentConfigurationInput}>
                  <InputLabel className={classes.font14}>
                    Provider(s): <span className="required">*</span>
                  </InputLabel>
                  <Select
                    size="small"
                    options={providersOptions}
                    placeholder="Select..."
                    value={values.providers}
                    isMulti
                    isSearchable
                    onChange={(option) => setFieldValue("providers", option)}
                    isError={errors.providers}
                  />
                </div>
              </div>
              <div className={classes.appointmentConfigurationLine}>
                <div className={classes.appointmentConfigurationInput}>
                  <InputLabel className={classes.font14}>
                    Consent(s):
                  </InputLabel>
                  <Select
                    size="small"
                    options={consentsOptions}
                    placeholder="Select..."
                    value={values.consents}
                    isMulti
                    isSearchable
                    onChange={(option) => setFieldValue("consents", option)}
                    isError={errors.consents}
                  />
                </div>
                <div className={classes.appointmentConfigurationInput}>
                  <InputLabel className={classes.font14}>
                    Questionnaire(s):
                  </InputLabel>
                  <Select
                    size="small"
                    options={questionnairesOptions}
                    placeholder="Select..."
                    value={values.questionnaires}
                    isMulti
                    isSearchable
                    onChange={(option) =>
                      setFieldValue("questionnaires", option)
                    }
                    isError={errors.questionnaires}
                  />
                </div>
              </div>
              {mapping.vertical && (
                <div
                  className={cx(
                    classes.appointmentConfigurationLine,
                    classes.verticalBookingLine,
                  )}
                >
                  <span
                    className={cx(
                      classes.appointmentConfigurationInput,
                      classes.bold,
                    )}
                  >
                    Vertical Booking Link
                  </span>
                  <Input
                    className={classes.appointmentConfigurationInput}
                    size="small"
                    type="text"
                    value={mapping.vertical?.link || ""}
                    isReadonly
                  />
                </div>
              )}
              <div>
                <Button
                  size="small"
                  onClick={handleSubmit}
                  leftAdornment={
                    loading ? (
                      <CircularProgress size="tiny" color="white" />
                    ) : null
                  }
                  isDisabled={loading}
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AppointmentConfiguration;
