import React, { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router";
import Header from "../../../_legacy/Containers/Guest/Header.js";
import Footer from "../../../_legacy/Containers/Guest/Footer.js";
import "react-datepicker/dist/react-datepicker.css";
import { useAvailableJobs } from "./hooks/useAvailableJobs.js";
import { useRunJob } from "./hooks/useRunJob.js";
import { Select } from "../../../shared/Select/Select.js";
import { Input } from "../../../shared/Input/Input.js";
import { InputLabel } from "../../../shared/InputLabel/InputLabel.js";
import styles from "./scss/JobSimulator.module.scss";
import { Button } from "../../../shared/Button/Button.js";
import { InputError } from "../../../shared/InputError/InputError.js";
import { apiDateFormat } from "../../../Utils/services.js";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress.js";
import { uiNotification } from "../../../services/UINotificationService.js";

const JobSimulator = () => {
  const { data: jobSimulatorData } = useAvailableJobs();
  const { mutate, isLoading: isRunning } = useRunJob();

  const options = useMemo(
    () =>
      jobSimulatorData
        ?.map((job) => {
          if (!job.disabled) {
            return {
              label: job.command,
              value: job.command,
            };
          }
        })
        .filter(Boolean) || [],
    [jobSimulatorData],
  );

  const [jobs, setJobs] = useState({});
  const [shownData, setShownData] = useState({});

  const params =
    jobSimulatorData?.find((job) => job.command === jobs.value)?.params || [];

  const jobDescription =
    jobSimulatorData?.find((job) => job.command === jobs.value)?.description ||
    "";

  const validationSchema = (params) => {
    const shape = params.reduce((acc, param) => {
      let validator = yup.string();

      if (param.required) {
        validator = validator.required(
          `${param.label || param.value} is required`,
        );
      }

      acc[param.value] = validator;
      return acc;
    }, {});

    return yup.object().shape(shape);
  };

  const { values, setValues, setFieldValue, handleSubmit, errors } = useFormik({
    onSubmit: (values) => {
      const formData = {
        ...values,
        command: jobs.value,
        testDate: apiDateFormat(values.testDate),
      };
      mutate(formData, {
        onSuccess: (res) => {
          setShownData({
            cron: jobs.value,
            date: apiDateFormat(values.testDate),
            account: values.accounts,
            patient: values.patients,
            timestamp: format(new Date(), "YYYY-MM-DD h:mm:ss"),
            response: res.data,
          });
        },
        onError: () =>
          uiNotification.error("Job execution failed. Please try again."),
      });
    },
    initialValues: {},
    validationSchema: () => validationSchema(params),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (params.length > 0) {
      setValues(
        params.reduce((acc, param) => ({ ...acc, [param.value]: "" }), {}),
      );
    }
  }, [params, setValues]);

  return (
    <div className="guest">
      <Header />
      <div className="wrapper">
        <div className="card-block">
          <div className="login-main m-t-60">
            <h1>Commands Simulator</h1>
            <div className={styles.simulatorContainer}>
              <div>
                <InputLabel>
                  Job<span className="setting-require">*</span>
                </InputLabel>
                <Select
                  options={options}
                  value={jobs}
                  onChange={setJobs}
                  labelledBy="Select"
                  displayValue="Name"
                  hasSelectAll={false}
                  form="external-form"
                  disableSearch
                  shouldToggleOnHover={false}
                  required
                />
                {jobDescription && (
                  <p style={{ whiteSpace: "pre-line" }}>{jobDescription}</p>
                )}
              </div>
              {params.map((param) => {
                if (param.type === "date") {
                  return (
                    <div key={param.value}>
                      <InputLabel>
                        {param.label}
                        {param.required && (
                          <span className="setting-require">*</span>
                        )}
                      </InputLabel>
                      <DatePicker
                        dateFormat="yyyy/MM/dd"
                        selected={values[param.value]}
                        onChange={(date) => setFieldValue(param.value, date)}
                        required={param.required}
                        minDate={new Date()}
                        className="form-control"
                        form="external-form"
                      />
                      {errors[param.value] && (
                        <InputError>{errors[param.value]}</InputError>
                      )}
                    </div>
                  );
                }
                return (
                  <div key={param.value}>
                    <InputLabel>
                      {param.label}{" "}
                      {param.required && (
                        <span className="setting-require">*</span>
                      )}
                    </InputLabel>
                    <Input
                      className={styles.paramInput}
                      inputClassName={styles.input}
                      type="text"
                      value={values[param.value]}
                      onChange={(event) =>
                        setFieldValue(param.value, event.target.value)
                      }
                    />
                    {errors[param.value] && (
                      <InputError>{errors[param.value]}</InputError>
                    )}
                  </div>
                );
              })}
              <Button
                onClick={handleSubmit}
                isDisabled={!jobs.value || isRunning}
                rightAdornment={
                  isRunning ? (
                    <CircularProgress size="small" color="white" />
                  ) : null
                }
              >
                Run Job
              </Button>
              {shownData.response && (
                <div className="col-sm-12 m-b-10">
                  <p>Completed at {shownData.timestamp}</p>
                  <ul>
                    <li> cron: {shownData.cron} </li>
                    <li> account: {shownData.account}; </li>
                    <li> patient: {shownData.patient}; </li>
                    <li> date: {shownData.date} </li>
                  </ul>
                  <p>Response: {shownData.response}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(JobSimulator);
