import React from "react";

export class BeforeUnload extends React.Component {
  componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeunload);
  }

  handleBeforeunload = (event) => {
    var { onBeforeunload } = this.props;
    var returnValue;

    if (onBeforeunload) {
      returnValue = onBeforeunload(event);
    }

    if (typeof returnValue === "string") {
      event.returnValue = returnValue;
      return returnValue;
    }
  };

  render() {
    var { children = null } = this.props;
    return children;
  }
}
