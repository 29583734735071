import React from "react";
import cx from "clsx";
import PropTypes from "prop-types";
import classes from "./Tabs.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { MESSAGES_TYPE } from "../../hooks/useMessages";

export function Tabs({ type, changeType, unreadCount, readCount }) {
  const { tInbox } = useAppTranslation.Inbox();

  return (
    <div className={classes.root}>
      <button
        className={cx(classes.tab, {
          [classes.tabActive]: type === MESSAGES_TYPE.unread,
        })}
        onClick={() => changeType(MESSAGES_TYPE.unread)}
      >
        {tInbox("leadAr.tabs.unread", {
          count: unreadCount,
        })}
      </button>
      <button
        className={cx(classes.tab, {
          [classes.tabActive]: type === MESSAGES_TYPE.read,
        })}
        onClick={() => changeType(MESSAGES_TYPE.read)}
      >
        {tInbox("leadAr.tabs.read", {
          count: readCount,
        })}
      </button>
    </div>
  );
}

Tabs.propTypes = {
  type: PropTypes.string.isRequired,
  changeType: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
  readCount: PropTypes.number.isRequired,
};
