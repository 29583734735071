import React, { useState } from "react";
import AccordionButton from "./AccordionButton";
import SunscreenBenefitMapping from "./SunscreenBenefitMapping";
import TreatmentBenefitMapping from "./TreatmentBenefitMapping";
import AnnualSkinCheckMapping from "./AnnualSkinCheckMapping";
import QuarterlyCreditConfiguration from "./QuarterlyCreditConfiguration";
import SkinCareProductDiscount from "./SkinCareProductDiscount";
import FreeAestheticConsultation from "./FreeAestheticConsultation";

const BenefitMap = ({ benefit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const benefitComponent = {
    SKIN_CANCER_CHECK: <AnnualSkinCheckMapping benefit={benefit} />,
    SKIN_CARE_PRODUCT_DISCOUNT: <SkinCareProductDiscount benefit={benefit} />,
    FREE_FACIAL_OR_PEEL: <TreatmentBenefitMapping benefit={benefit} />,
    FREE_AESTHETIC_CONSULTATION: (
      <FreeAestheticConsultation benefit={benefit} />
    ),
    FREE_SUNSCREEN: <SunscreenBenefitMapping benefit={benefit} />,
    WALLET_CREDIT: <QuarterlyCreditConfiguration benefit={benefit} />,
  };

  return (
    <>
      <AccordionButton
        label={benefit.benefitLabel}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      {isExpanded && benefitComponent[benefit.benefitId]}
    </>
  );
};

export default BenefitMap;
