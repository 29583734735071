import { useClientQuery } from "../../../../../../../api/queries/useClientQuery";
import { GENDERS, GENDER_DEFAULT } from "../../../../../../../consts/api";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";
import { fileUtil } from "../../../../../../../utilities/file";
import {
  IMAGE_COMPRESS_WIDTH,
  IMAGE_UPLOAD_TYPE,
} from "../../CosmeticCreateEdit.consts";
import { getCosmeticPathParams } from "../../CosmeticCreateEdit.utils";
import { PROCEDURE_AREA } from "../../shared/Info/Info.consts";
import {
  IS_NONE_IMAGES_REQUIRED,
  IS_ONE_IMAGE_REQUIRED,
  REQUIRED_FORM_IMAGE_FIELDS_TO_NAMES_MAP,
  TEMPLATE_IMAGES_URL,
  TEMPLATE_IMAGE_NAMES,
} from "./config";

export function useUploadTemplateImages() {
  const { clientId } = getCosmeticPathParams();
  const { data: client } = useClientQuery({ clientId });

  const uploader = useImageUploader({
    uploadType: IMAGE_UPLOAD_TYPE,
    compress: {
      width: IMAGE_COMPRESS_WIDTH,
    },
  });

  const gender = (() => {
    if (client?.gender === GENDERS.notSelected) {
      return GENDER_DEFAULT;
    }

    if (client?.gender === GENDERS.nonBinary) {
      return client?.nonBinaryBodyTemplate || GENDER_DEFAULT;
    }

    return client?.gender || GENDER_DEFAULT;
  })();

  const buildImageUrl = (procedureArea, imageName) => {
    if (
      client?.gender === GENDERS.nonBinary &&
      procedureArea === PROCEDURE_AREA.face
    ) {
      return `${TEMPLATE_IMAGES_URL}/${procedureArea}/${GENDERS.nonBinary}/${imageName}`;
    }

    return `${TEMPLATE_IMAGES_URL}/${procedureArea}/${gender}/${imageName}`;
  };

  const uploadOne = async (procedureArea, templateImageName) => {
    const dataUrl = await fileUtil.readFromResource(
      buildImageUrl(procedureArea, templateImageName),
      { method: "dataUrl" },
    );

    const file = fileUtil.dataUrlToFile(dataUrl, templateImageName);
    const uploadResponse = await uploader.initiate(file);

    return uploadResponse?.uploadedFileFullPath;
  };

  const initiate = async (formValues) => {
    const { procedureArea, frontImage, image45Left, image45Right } = formValues;

    if (gender) {
      if (IS_NONE_IMAGES_REQUIRED(procedureArea)) {
        return Promise.resolve({
          isCompleted: true,
        });
      }

      if (IS_ONE_IMAGE_REQUIRED(procedureArea)) {
        if (frontImage) {
          return Promise.resolve({
            front: frontImage,
            isCompleted: true,
          });
        }

        const url = await uploadOne(procedureArea, TEMPLATE_IMAGE_NAMES.front);

        return {
          front: url,
          isCompleted: !!url,
        };
      }

      if ([frontImage, image45Left, image45Right].every(Boolean)) {
        return Promise.resolve({
          front: frontImage,
          left: image45Left,
          right: image45Right,
          isCompleted: true,
        });
      }

      const urls = await Promise.all(
        Object.entries(REQUIRED_FORM_IMAGE_FIELDS_TO_NAMES_MAP).map(
          ([field, name]) =>
            formValues[field]
              ? new Promise((res) => res(formValues[field]))
              : uploadOne(procedureArea, name),
        ),
      );

      return {
        front: urls[0],
        left: urls[1],
        right: urls[2],
        isCompleted: urls.every(Boolean),
      };
    }

    throw new Error("Cannot upload template images");
  };

  return {
    initiate,
    buildImageUrl,
  };
}
