import { useState } from "react";
import { useAddInvoiceTipMutation } from "../../../../api/mutations/useAddInvoiceTipMutation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useChargeWithCashMutation } from "../../../../api/mutations/useChargeWithCashMutation";
import { useChargeWithWalletMutation } from "../../../../api/mutations/useChargeWithWalletMutation";
import { useChargeWithKlarnaMutation } from "../../../../api/mutations/useChargeWithKlarnaMutation";
import { useChargeWithCareCreditMutation } from "../../../../api/mutations/useChargeWithCareCreditMutation";
import { useChargeWithGreenSkyMutation } from "../../../../api/mutations/useChargeWithGreenSkyMutation";
import { useChargeWithChequeMutation } from "../../../../api/mutations/useChargeWithChequeMutation";
import { useSendFrontDeskInvoiceMutation } from "../../../../api/mutations/useSendFrontDeskInvoiceMutation";
import { useSignInvoiceMutation } from "../../../../api/mutations/useSignInvoiceMutation";
import { TRANSACTION_TYPES, UPLOAD_TYPES } from "../../../../consts/api";
import { useChargeWithCardMutation } from "../../../../api/mutations/useChargeWithCardMutation";
import { extractApiError } from "../../../../utilities/api";
import { squashSpaces } from "../../../../utilities/general";
import { useCheckoutPaymentMutation } from "../../../../api/mutations/useCheckoutPaymentMutation";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import {
  prepareAmount,
  tipOptionToAmount,
} from "../SalesCheckoutInvoice.utils";
import { useAddMergedInvoiceTipMutation } from "../../../../api/mutations/useAddMergedInvoiceTipMutation";
import { useInvoice } from "./invoice/useInvoice";
import { useChargeWithText2PayMutation } from "../../../../api/mutations/useChargeWithText2PayMutation";
import { dispatch } from "../../../../store/store";
import { checkoutInvoice } from "../../../../store/checkoutInvoice";

export function useInvoiceActions() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { data: user } = useCurrentUserQuery();
  const { invoice, refetchInvoice, isStartStage } = useInvoice();
  const [isTipApplying, setIsTipApplying] = useState(false);
  const [isMergedTipApplying, setIsMergedTipApplying] = useState(false);

  const subtotalAmount = invoice?.subtotalAmount;

  const onTransactionSuccess = () => {
    refetchInvoice();
    uiNotification.success(tCommon("success.transaction"));
  };

  const onTransactionError = (e) => {
    const apiError = extractApiError(e);
    const errorKey = squashSpaces(apiError)?.includes(" ") ? null : apiError;
    if (errorKey) {
      uiNotification.error(
        tSales(
          `checkoutInvoice.apiError.${errorKey}`,
          tCommon("error.transaction"),
        ),
      );
    } else {
      uiNotification.error(apiError);
    }
  };

  const { data: addTipData, mutateAsync: applyTip } = useAddInvoiceTipMutation({
    onMutate: () => {
      setIsTipApplying(true);
      dispatch(checkoutInvoice.actions.changeIsTipsApplying(true));
    },
    onSuccess: refetchInvoice,
    onError: () => {
      uiNotification.error(tSales("checkoutInvoice.error.addTip"));
    },
    onSettled: () => {
      setIsTipApplying(false);
      dispatch(checkoutInvoice.actions.changeIsTipsApplying(false));
    },
  });

  const { mutateAsync: applyMergedTip } = useAddMergedInvoiceTipMutation({
    onMutate: () => {
      setIsMergedTipApplying(true);
      dispatch(checkoutInvoice.actions.changeIsTipsApplying(true));
    },
    onSuccess: refetchInvoice,
    onError: () => {
      uiNotification.error(tSales("checkoutInvoice.error.addTip"));
    },
    onSettled: () => {
      setIsMergedTipApplying(false);
      dispatch(checkoutInvoice.actions.changeIsTipsApplying(false));
    },
  });

  const { mutateAsync: chargeWithCash, isLoading: isChargingWithCash } =
    useChargeWithCashMutation({
      onSuccess: onTransactionSuccess,
      onError: onTransactionError,
    });

  const { mutateAsync: chargeWithWallet, isLoading: isChargingWithWallet } =
    useChargeWithWalletMutation({
      onSuccess: onTransactionSuccess,
      onError: onTransactionError,
    });

  const { mutateAsync: chargeWithKlarna, isLoading: isChargingWithKlarna } =
    useChargeWithKlarnaMutation({
      onSuccess: onTransactionSuccess,
      onError: onTransactionError,
    });

  const {
    mutateAsync: chargeWithCareCredit,
    isLoading: isChargingWithCareCredit,
  } = useChargeWithCareCreditMutation({
    onSuccess: onTransactionSuccess,
    onError: onTransactionError,
  });

  const { mutateAsync: chargeWithGreenSky, isLoading: isChargingWithGreenSky } =
    useChargeWithGreenSkyMutation({
      onSuccess: onTransactionSuccess,
      onError: onTransactionError,
    });

  const { mutateAsync: chargeWithCheque, isLoading: isChargingWithCheque } =
    useChargeWithChequeMutation({
      onSuccess: onTransactionSuccess,
      onError: onTransactionError,
    });

  const { mutateAsync: chargeWithCard, isLoading: isChargingWithCard } =
    useChargeWithCardMutation({
      onSuccess: onTransactionSuccess,
      onError: onTransactionError,
    });

  const { mutateAsync: chargeWithText2Pay, isLoading: isChargingWithText2Pay } =
    useChargeWithText2PayMutation();

  const { mutateAsync: checkoutPayment, isLoading: isCheckoutPaymentLoading } =
    useCheckoutPaymentMutation({
      onError: onTransactionError,
    });

  const { mutateAsync: sendReceipt, isLoading: isReceiptSending } =
    useSendFrontDeskInvoiceMutation({
      onSuccess: () => {
        uiNotification.success(tSales("checkoutInvoice.success.sendReceipt"));
      },
      onError: () => {
        uiNotification.error(tSales("checkoutInvoice.error.sendReceipt"));
      },
    });

  const { mutateAsync: sign, isLoading: isSigning } = useSignInvoiceMutation({
    onSuccess: () => {
      refetchInvoice();
      uiNotification.success(tCommon("success.sign"));
    },
    onError: () => {
      uiNotification.error(tCommon("error.sign"));
    },
  });

  const resetTip = () => {
    applyTip({
      invoiceId: invoice?.id,
      tipAmount: 0,
    });
  };

  const paymentViewClose = () => {
    if (isStartStage) {
      resetTip();
      dispatch(checkoutInvoice.actions.changeIsTipsOnlyFlow(false));
    }
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  return {
    applyTip: {
      isLoading: isTipApplying,
      initiate: ({ tipAmount, readerId }) =>
        applyTip({
          invoiceId: invoice?.id,
          tipAmount: prepareAmount(tipAmount),
          readerId,
        }),
      optionToAmount: (tipOptionValue) =>
        tipOptionToAmount({ tipOptionValue, subtotalAmount }),
    },

    resetTip,

    paymentViewClose,

    applyMergedTip: {
      isLoading: isMergedTipApplying,
      initiate: ({ tipAmount, mergedInvoiceId }) =>
        applyMergedTip({
          mergedInvoiceId,
          tipAmount: prepareAmount(tipAmount),
        }),
    },

    chargeCash: {
      isLoading: isChargingWithCash,
      initiate: ({ amount }) =>
        chargeWithCash({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
        }).then(() => {
          dispatch(checkoutInvoice.actions.paymentViewClose());
        }),
    },

    chargeWallet: {
      isLoading: isChargingWithWallet,
      initiate: ({ amount }) =>
        chargeWithWallet({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
        }).then(() => dispatch(checkoutInvoice.actions.paymentViewClose())),
    },

    chargeKlarna: {
      isLoading: isChargingWithKlarna,
      initiate: ({ amount, phoneNumber, email }) =>
        chargeWithKlarna({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
          phoneNumber,
          email,
        }),
    },

    chargeCareCredit: {
      isLoading: isChargingWithCareCredit,
      initiate: ({ amount, note }) =>
        chargeWithCareCredit({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
          note,
        }).then(() => dispatch(checkoutInvoice.actions.paymentViewClose())),
    },

    chargeGreenSky: {
      isLoading: isChargingWithGreenSky,
      initiate: ({ amount, note }) =>
        chargeWithGreenSky({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
          note,
        }).then(() => dispatch(checkoutInvoice.actions.paymentViewClose())),
    },

    chargeCheque: {
      isLoading: isChargingWithCheque,
      initiate: ({ amount, chequeNumber }) =>
        chargeWithCheque({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
          chequeNumber,
        }).then(() => dispatch(checkoutInvoice.actions.paymentViewClose())),
    },

    chargeCard: {
      isLoading: isChargingWithCard,
      initiate: ({
        clearentEmail,
        clearentZip,
        isSavedCard,
        saveCard,
        amount,
        stripeToken,
        clearentToken,
        clearentCardToken,
      }) =>
        chargeWithCard({
          invoiceId: invoice?.id,
          clearentEmail,
          clearentZip,
          isSavedCard,
          saveCard,
          amount: prepareAmount(amount),
          stripeToken,
          clearentToken,
          paymentSystem: user?.account?.paymentSystem,
          clearentCardToken,
        }).then(() => dispatch(checkoutInvoice.actions.paymentViewClose())),
    },

    chargeText2Pay: {
      isLoading: isChargingWithText2Pay,
      initiate: ({ amount, phoneNumber, email }) =>
        chargeWithText2Pay({
          invoiceId: invoice?.id,
          amount: prepareAmount(amount),
          phoneNumber,
          email,
        }),
    },

    transferPaymentToReader: {
      isLoading: isCheckoutPaymentLoading,
      initiate: ({ amount, cardReaderId }) =>
        checkoutPayment({
          accountId: user?.account?.id,
          amount: prepareAmount(amount),
          invoiceId: invoice?.id,
          deviceId: cardReaderId,
          paymentSystem: user?.account?.paymentSystem,
          transactionType: TRANSACTION_TYPES.terminalEmv,
        }),
    },

    sendReceipt: {
      isLoading: isReceiptSending,
      initiate: ({ patientEmail }) =>
        sendReceipt({ invoiceId: invoice?.id, patientEmail }),
    },

    sign: {
      isLoading: isSigning,
      initiate: ({ imageData }) =>
        sign({
          imageData,
          invoiceId: invoice?.id,
          uploadType: UPLOAD_TYPES.signature,
        }),
    },
    takeTipsOnReader: addTipData?.data?.data?.take_tips_on_reader || false,
  };
}
