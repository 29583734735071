import { ACTION_TYPES } from "./consts";

const initialState = {
  inboxActiveTab: "unread",
  leadArInboxActiveTab: "unread",
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.setInboxTabType: {
      return {
        ...state,
        inboxActiveTab: action.payload?.tab || initialState.inboxActiveTab,
      };
    }
    case ACTION_TYPES.setArInboxTabType: {
      return {
        ...state,
        leadArInboxActiveTab:
          action.payload?.arTab || initialState.leadArInboxActiveTab,
      };
    }
    default: {
      return state;
    }
  }
}
