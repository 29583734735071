import React from "react";
import cx from "clsx";
import moment from "moment";
import classes from "../../Clients.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Table } from "../../../../shared/Table/Table";

const RepeatMdMembershipBenefits = ({ memberships }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();

  const formatDate = (date) => {
    if (moment(date).isValid()) {
      return moment(date).format("MMMM D, YYYY");
    }
    return "";
  };

  const tableData = memberships.map((membership) => {
    return membership.rewardGroups.map((group) => ({
      item: (
        <div>
          <div className={classes.groupNameContainer}>
            <p className={classes.groupName}>{group.name}</p>
            <p className={classes.rewardDate}>
              {formatDate(group.refreshRewardDate)}
            </p>
          </div>
          {group.options.map((option) => (
            <p key={option.id}>{option.name}</p>
          ))}
        </div>
      ),
      clinic: membership.clinicName,
      balanceUnits: (
        <div>
          <div className={classes.groupNameContainer}>
            <p className={classes.bold}>
              {group.available} of {group.total} Remaining
            </p>
          </div>
          {group.options.map((option) => (
            <p key={option.id}>{option.quantity} Units</p>
          ))}
        </div>
      ),
    }));
  });

  return (
    <>
      <div className="juvly-container flex justify-between">
        <h1 className={classes.repeatMdTitle}>
          {tClients("repeatMd.benefits.title")}
        </h1>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          bgColor="white"
          keyExtractor={(row) => row?.id}
          colClassName={classes.clientWalletTableRow}
          headColClassName={cx(classes.clientWalletTableHead)}
          bodyClassName={classes.clientWalletTableBody}
          data={tableData.flat()}
          cols={[
            {
              data: tClients("repeatMd.benefits.table.packageProduct"),
              accessor: "item",
            },
            {
              data: tClients("repeatMd.benefits.table.clinic"),
              accessor: "clinic",
              className: cx(classes.verticalBaseline),
            },
            {
              data: tClients("repeatMd.benefits.table.balanceUnits"),
              accessor: "balanceUnits",
            },
          ]}
        />
        {!tableData?.length && (
          <p className={classes.noRecord}>{tCommon("label.noRecordFound")}</p>
        )}
      </div>
    </>
  );
};

export default RepeatMdMembershipBenefits;
