import React, { useMemo } from "react";
import classes from "../../Clients.module.scss";
import { Table } from "../../../../shared/Table/Table";
import { useRepeatMdWallet } from "../../hooks/useRepeatMdWallet";
import { withRouter } from "react-router";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { getCurrencySymbol } from "../../../../Utils";
import { formatCurrency } from "../../../../utilities/general";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { useSendRepeatMdIntegration } from "./hooks/useSendRepeatMdInvitation";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../services/UINotificationService";
import RepeatMdMembershipBenefits from "./RepeatMdMembershipBenefits";

const RepeatMdWallet = ({ match }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();
  const { data: user } = useCurrentUserQuery();

  const patientId = match.params.clientID;
  const { data: mdWallet } = useRepeatMdWallet(patientId);
  const { mutate, isLoading } = useSendRepeatMdIntegration();

  const tableData = useMemo(
    () =>
      mdWallet?.items?.map((item) => ({
        item: item.name,
        clinic: item.clinicName,
        balanceUnits: item.available,
        balance: formatCurrency(
          item.originalCost * item.available,
          user?.currency,
        ),
      })),
    [mdWallet],
  );

  const sendInvitation = () => {
    mutate(
      { patientId },
      {
        onSuccess: (response) => {
          uiNotification.success(response.data.message);
        },
      },
    );
  };

  return (
    <>
      <div className="juvly-container flex justify-between">
        <h1 className={classes.repeatMdTitle}>{tClients("repeatMd.title")}</h1>
        {!mdWallet?.isEnrolled && (
          <Button
            size="tiny"
            className="new-blue-btn"
            rightAdornment={
              isLoading ? <CircularProgress size="tiny" color="white" /> : null
            }
            isDisabled={isLoading}
            onClick={sendInvitation}
          >
            {tClients("repeatMd.sendInvite")}
          </Button>
        )}
      </div>
      <div className={classes.tableWrapper}>
        <Table
          bgColor="white"
          keyExtractor={(row) => row?.id}
          colClassName={classes.clientWalletTableRow}
          headColClassName={classes.clientWalletTableHead}
          bodyClassName={classes.clientWalletTableBody}
          data={tableData}
          cols={[
            {
              data: tClients("repeatMd.table.item"),
              accessor: "item",
            },
            {
              data: tClients("repeatMd.table.clinic"),
              accessor: "clinic",
            },
            {
              data: tClients("repeatMd.table.balanceUnits"),
              accessor: "balanceUnits",
            },
            {
              data: `${getCurrencySymbol()} ${tClients(
                "repeatMd.table.balance",
              )}`,
              accessor: "balance",
            },
          ]}
        />
        {!tableData?.length && (
          <p className={classes.noRecord}>{tCommon("label.noRecordFound")}</p>
        )}
      </div>
      <RepeatMdMembershipBenefits memberships={mdWallet?.memberships || []} />
    </>
  );
};

export default withRouter(RepeatMdWallet);
