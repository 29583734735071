import * as yup from "yup";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  DATE_FORMATS_MAP,
  HTTP_ENDPOINTS,
  PREVIEW_DATE_FORMATS_MAP,
  QUERY_KEYS,
  USER_ROLE_NAMES_BY_ROLE_ID,
  TIME_FORMATS_MAP,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import { passOr, unwrapOr } from "../../utilities/general";
import {
  CURRENCY_DEFAULT,
  CURRENCY_SYMBOL_DEFAULT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  PREVIEW_DATE_FORMAT,
} from "../../consts/general";
import { getUser, getUserId } from "../../utilities/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../consts/localStorage";
import { logout } from "../../helpers/auth";

const requestSchema = yup.object({
  userId: yup.number().nullable(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  isMainAdmin: yup.bool().required(),
  isSuperUser: yup.bool().required(),
  fullName: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().nullable(),
  role: yup.string().nullable(),
  roleId: yup.number().nullable(),
  privileges: yup.array().of(yup.string()).required(),
  account: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
      status: yup.string().required(),
      imageUrl: yup.string().nullable(),
      isPosEnabled: yup.bool().required(),
      paymentSystem: yup.string().nullable(),
      accountType: yup.string().nullable(),
      baa: yup.object({
        document: yup.string().nullable(),
        signedAt: yup.string().nullable(),
      }),
      termsOfService: yup.object({
        document: yup.string().nullable(),
        signedAt: yup.string().nullable(),
      }),
      integrations: yup
        .object({
          isQualiphyEnabled: yup.bool().required(),
          isScriptsureEnabled: yup.bool().required(),
        })
        .required(),
      globalSettings: yup
        .object({
          isPosEnabled: yup.bool().required(),
        })
        .required(),
      patientPortalSubdomain: yup.string().nullable(),
    })
    .nullable(),
  isMdConsentRequired: yup.bool().required(),
  mdId: yup.number().nullable(),
  signatureUrl: yup.string().nullable(),
  requireSignature: yup.bool().required(),
  dateFormat: yup.string().required(),
  previewDateFormat: yup.string().required(),
  timeFormat: yup.string().required(),
  currency: yup.string().required(),
  currencySymbol: yup.string().required(),
  clinicId: yup.number().nullable(),
  phone1: yup.string().nullable(),
  phone2: yup.string().nullable(),
  phone3: yup.string().nullable(),
  phone4: yup.string().nullable(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  const globalSettings = unwrapOr(() => res.data.global_settings, {});
  const accountAdminId = unwrapOr(() => data.account.admin_id, null);
  const userId = unwrapOr(() => data.id, null);

  return {
    id: userId,
    isMainAdmin: userId && accountAdminId && userId === accountAdminId,
    isSuperUser: Boolean(data.super_user || data.super_admin_id > 0),
    fullName: data.full_name,
    firstName: data.firstname,
    lastName: data.lastname || null,
    role: USER_ROLE_NAMES_BY_ROLE_ID[data.user_role_id] || null,
    roleId: data.user_role_id || null,
    privileges: data.privileges || [],
    account: passOr(data.account, null, () => ({
      id: data.account.id,
      name: data.account.name,
      status: data.account.status,
      accountType: data.account.account_type || null,
      imageUrl: data.account.logo_url || null,
      isPosEnabled: Boolean(data.account.pos_enabled),
      paymentSystem: data.account.pos_gateway || null,
      baa: {
        document: data?.account?.baa_document || null,
        signedAt: data?.account?.baa_document_sign_date || null,
      },
      termsOfService: {
        document: data?.account?.terms_of_service_document || null,
        signedAt: data?.account?.terms_of_service_document_sign_date || null,
      },
      integrations: {
        isQualiphyEnabled: Boolean(globalSettings.qualiphy),
        isScriptsureEnabled: Boolean(globalSettings?.script_sure?.is_enrolled),
      },
      globalSettings: {
        isPosEnabled: Boolean(globalSettings?.pos_data?.is_pos_enabled),
      },
      patientPortalSubdomain: data.account?.pportal_subdomain,
    })),
    isMdConsentRequired: Boolean(data.is_md_consent_required),
    mdId: data.md_user_id || null,
    signatureUrl: data.signature_url || null,
    requireSignature: Boolean(data.show_signature_popup),
    dateFormat:
      DATE_FORMATS_MAP?.[globalSettings.date_format] || DEFAULT_DATE_FORMAT,
    previewDateFormat:
      PREVIEW_DATE_FORMATS_MAP?.[globalSettings.date_format] ||
      PREVIEW_DATE_FORMAT,
    timeFormat:
      TIME_FORMATS_MAP?.[globalSettings.time_format] || DEFAULT_TIME_FORMAT,

    currency: globalSettings?.pos_data?.currency_code || CURRENCY_DEFAULT,

    currencySymbol:
      globalSettings?.pos_data?.currency_symbol || CURRENCY_SYMBOL_DEFAULT,

    clinicId: data?.clinic_id || null,
    phone1: data.contact_number_1,
    phone2: data.contact_number_2,
    phone3: data.contact_number_3,
    phone4: data.contact_number_4,
  };
};

function createUserQueryKey({ userId }) {
  return [QUERY_KEYS.user, userId];
}

function useUserQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createUserQueryKey({ userId: req.userId }),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getUser(req.userId));
      const validResponse = responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });

      if (!validResponse.account?.id) {
        logout();
        return;
      }

      const currentLsUserData = getUser();
      const nextLsUserData = {
        ...currentLsUserData,
        permissions: validResponse.privileges,
      };
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.userData,
        JSON.stringify(nextLsUserData),
      );

      return validResponse;
    },
    options,
  );
}

export const useCurrentUserQuery = (options = {}) => {
  const userId = getUserId();
  return useUserQuery({
    payload: {
      userId,
    },
    options: {
      enabled: Boolean(userId),
      ...options,
    },
  });
};

export const withCurrentUserQuery = (Component) => {
  return function Wrapper(props) {
    const currentUserQuery = useCurrentUserQuery();
    return <Component {...props} currentUserQuery={currentUserQuery} />;
  };
};
