import { useMemo } from "react";
import { useProcedureQuery } from "../../../../../../../api/procedure/useProcedureQuery";
import { useChartingFilterCategoriesQuery } from "../../../../../../../api/queries/useChartingFilterCategoriesQuery";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { getCosmeticPathParams } from "../../CosmeticCreateEdit.utils";
import { prepareProcedureDocumentIds } from "../../../utilities";
import { PROCEDURE_AREA } from "../../shared/Info/Info.consts";
import { useCurrentUserQuery } from "../../../../../../../api/queries/useUserQuery";
import { useClientQuery } from "../../../../../../../api/queries/useClientQuery";
import { useProviderOptions } from "../../../../hooks/useProviderOptions";

export function useInitialValues() {
  const { tCommon } = useAppTranslation.Common();
  const { clientId, procedureId, isEditMode } = getCosmeticPathParams();

  const { data: user } = useCurrentUserQuery();
  const { data: client } = useClientQuery({ clientId });
  const providerOptions = useProviderOptions();

  const { data, isFetching } = useProcedureQuery({
    payload: {
      procedureId,
    },
    options: {
      enabled: isEditMode,
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.fetchProcedure"));
      },
    },
  });

  const chartingFilters = useChartingFilterCategoriesQuery();

  const selectedFilter = chartingFilters?.data?.find(
    (x) => x.id === data?.chartingCategoryId,
  );

  const {
    questionnaireIds,
    consentIds,
    nonDeletableConsentIds,
    nonDeletableQuestionnaireIds,
  } = useMemo(
    () =>
      prepareProcedureDocumentIds(
        data?.patientConsents,
        data?.patientQuestionnaires,
      ),
    [data?.patientConsents, data?.patientQuestionnaires],
  );

  return useMemo(
    () => ({
      data: {
        appointmentId: data?.appointment?.id || null,

        serviceId: data?.serviceId || null,

        providerId:
          data?.providerId ||
          user?.id ||
          providerOptions.data?.[0]?.value ||
          null,

        clinicId: data?.clinicId || user?.clinicId || client?.clinicId || null,

        procedureArea: data?.area || PROCEDURE_AREA.face,

        procedureName: data?.name || "",

        procedureDate: data?.date ? new Date(data.date) : new Date(),

        frontImage: data?.images?.front || null,

        image45: data?.images?.image45 || null,

        image45Left: data?.images?.image45Left || null,

        image45Right: data?.images?.image45Right || null,

        image90: data?.images?.image90 || null,

        image90Left: data?.images?.image90Left || null,

        image90Right: data?.images?.image90Right || null,

        afterFrontImage: data?.afterImages?.front || null,

        afterImage45Left: data?.afterImages?.image45Left || null,

        afterImage45Right: data?.afterImages?.image45Right || null,

        afterImage90Left: data?.afterImages?.image90Left || null,

        afterImage90Right: data?.afterImages?.image90Right || null,

        backImage: data?.images?.back || null,

        back45LeftImage: data?.images?.back45Left || null,

        back45RightImage: data?.images?.back45Right || null,

        afterBackImage: data?.afterImages?.back || null,

        afterBack45LeftImage: data?.afterImages?.back45Left || null,

        afterBack45RightImage: data?.afterImages?.back45Right || null,

        questionnaireIds,

        consentIds,

        type: selectedFilter
          ? {
              label: selectedFilter.name,
              value: selectedFilter.id,
            }
          : null,

        nonDeletableConsentIds,

        nonDeletableQuestionnaireIds,

        skinCareCheck: data?.skinCareCheck || false,
      },
      isLoading: isFetching,
    }),
    [
      data,
      isFetching,
      user?.clinicId,
      client?.clinicId,
      providerOptions.data?.[0]?.value,
    ],
  );
}
