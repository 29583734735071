import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import ViewTipsModal from "../../../../ViewTipsModal/ViewTipsModal";
import { svg } from "../../../../../../../../assets/svg";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../../shared/InputError/InputError";
import RemainingBalanceDue from "../../../../../shared/RemainingBalanceDue/RemainingBalanceDue";
import { LoadingAlert } from "../../../../../../../../boxes/LoadingAlert/LoadingAlert";
import { CircularProgress } from "../../../../../../../../shared/CircularProgress/CircularProgress";

const PaymentTipsModal = ({
  isLoading,
  isPolling,
  submit,
  form,
  showTipsModal,
  isTipsApplying,
  errors,
  changeFormValue,
  isValid,
  takeTipsOnReader,
}) => {
  const { invoice, amountToPay } = useInvoice();

  const onClose = () => {
    showTipsModal.update(false);
  };

  useEffect(() => {
    if (!isPolling) {
      changeFormValue("amount", amountToPay);
    }
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.card")}
      imageSrc={svg.ccPrimary}
      onCharge={submit}
      isCharging={isLoading}
      chargeLabel={tCommon("label.charge")}
      amount={form.amount}
      isChargeDisabled={isTipsApplying || !isValid || isPolling}
      isCancelDisabled={isPolling}
    >
      {isTipsApplying ? (
        <div className="m-h-100 flex justify-center items-center">
          <CircularProgress size="medium" />
        </div>
      ) : (
        <>
          <ViewTipsModal
            amountToPay={form.amount}
            tipsDisabled={isPolling}
            takeTipsOnReader={takeTipsOnReader}
          />
          <div className="p-b-10">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.amount")}
            </InputLabel>
            <InputCurrency
              size="medium"
              prefix={invoice?.currencySymbol}
              value={form.amount}
              isError={errors.amount}
              onChange={(value) => changeFormValue("amount", value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.amount",
              )}
            />
            {errors.amount && <InputError>{errors.amount}</InputError>}
          </div>
          <div className="p-b-10">
            <RemainingBalanceDue amount={form.amount} />
          </div>
        </>
      )}
      {isPolling && (
        <LoadingAlert>
          <Trans
            i18nKey={tSales("checkoutInvoice.warn.awaitingForReaderPayment")}
            components={{
              b: <b />,
            }}
          />
        </LoadingAlert>
      )}
    </ModalCharge>
  );
};

export default PaymentTipsModal;
