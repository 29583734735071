import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import classes from "./scss/Joya.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { Box } from "../../../../shared/Box/Box";
import { useJoyaIntegration } from "./hooks/useJoyaIntegration";
import { Button } from "../../../../shared/Button/Button";
import { useJoyaAuthorization } from "./hooks/useJoyaAuthorization";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { showFormattedDate } from "../../../../Utils";
import { SETTINGS_ROUTES } from "../../../../consts/routes";
import AppointmentConfiguration from "./components/AppointmentConfiguration";
import { useJoyaMapping } from "./hooks/useJoyaMapping";
import BenefitMap from "./components/BenefitMap";
import { Skeleton } from "../../../../shared/Skeleton/Skeleton";

const Joya = ({ history, location }) => {
  const urlParams = new URLSearchParams(location.search);
  const { tSettings } = useAppTranslation.Settings();
  const [isActive, setIsActive] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  const { data, isLoading } = useJoyaIntegration({
    onSuccess: (data) => {
      setIsActive(data.isActive);
      setIsConnected(data.isConnected);
    },
  });
  const { mutate, isLoading: isMutating } = useJoyaAuthorization();
  const {
    benefitsMapping,
    onlineBooking,
    isGettingData: isGettingMappingData,
  } = useJoyaMapping({
    enabled: isActive,
  });

  const isGettingData = isLoading || isMutating;

  const handleActivationToggle = () => {
    mutate(
      {
        isActive: !isActive,
        code: null,
      },
      {
        onSuccess: (data) => {
          const res = data.data.data;
          setIsActive(res.isActive);
          setIsConnected(res.isConnected);
        },
      },
    );
  };

  const handleLoginButton = () => {
    window.location.href = data.loginUrl;
  };

  useEffect(() => {
    const code = urlParams.get("code");
    if (code) {
      mutate(
        {
          isActive: true,
          code,
        },
        {
          onSuccess: () => {
            history.push(SETTINGS_ROUTES.integrations.joya());
          },
        },
      );
    }
  }, []);

  return (
    <LayoutSettings contentClassName={classes.root}>
      <EntityHeader
        title={tSettings("joyaIntegration.title")}
        right={
          <div>
            {isGettingData ? (
              <CircularProgress size="small" color="primary" />
            ) : (
              <Toggle
                isEnabled={isActive}
                onChange={handleActivationToggle}
                isDisabled={isLoading}
              />
            )}
          </div>
        }
      />
      {isActive && (
        <div className={classes.root}>
          <Box>
            {!isConnected ? (
              <Button size="small" onClick={handleLoginButton}>
                {tSettings("joyaIntegration.login")}
              </Button>
            ) : (
              <>
                <p className={classes.authorizedText}>
                  <i className="fa fa-check-circle"></i>
                  Authorized on {showFormattedDate(data.updatedAt, true)}
                </p>
                <p className={classes.paragraph}>
                  If you would like to change the settings or find out more
                  about the benefits listed below, please click the login button
                  bellow.
                </p>
                <Button size="small" onClick={handleLoginButton}>
                  {tSettings("joyaIntegration.login")}
                </Button>
              </>
            )}
          </Box>
          <Box>
            <h4 className={classes.benefitsTitle}>
              Joya Benefits Configuration
            </h4>
            {isGettingMappingData ? (
              <Skeleton count={7} height={40} />
            ) : (
              <>
                {benefitsMapping?.map((benefit) => {
                  return (
                    <BenefitMap key={benefit.benefitId} benefit={benefit} />
                  );
                })}
                <AppointmentConfiguration mapping={onlineBooking} />
              </>
            )}
          </Box>
        </div>
      )}
    </LayoutSettings>
  );
};

export default withRouter(Joya);
