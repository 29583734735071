import React from "react";
import cx from "clsx";
import classes from "../scss/Joya.module.scss";

const QuarterlyCreditConfiguration = () => {
  return (
    <div className={cx(classes.mappingContainer, classes.font12)}>
      <p className={classes.mappingParagraph}>
        Joya patients receive $125 of credit toward a treatment or service each
        quarter, and this credit will expire if not used within that timeframe.
      </p>
      <div className={classes.noteContainer}>
        <h2>Note:</h2>
        <p>
          Credit must be used in full in a single visit. Remaining credit will
          be forfeited if not used.
        </p>
      </div>
    </div>
  );
};

export default QuarterlyCreditConfiguration;
