import React, { Component } from "react";
import $ from "jquery";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import {
  exportEmptyData,
  getSubscriptionAcceleratorEstimate,
  upgradeSubscriptionToAccelerator,
  subscriptionUpgradeAccount,
} from "../../../../Actions/Settings/settingsActions.js";
import { getSetupIntentForAccount } from "../../../../Actions/Stripe/StripeActions";
import {
  showFormattedDate,
  getIsAcceleratorPlan,
  numberFormat,
} from "../../../../Utils/services.js";
import Loader from "../../../Common/Loader.js";
import { formatBillingCurrency } from "../YourBilling.utils.js";
import StripePaymentForm from "../../../Sales/FrontdeskCheckout/StripePaymentForm";
import { UpgradeAcknowledgement } from "./shared/UpgradeAcknowledgement/UpgradeAcknowledgement.js";
import { uiNotification } from "../../../../services/UINotificationService.js";
import { withSignAgreementMutation } from "../../../../api/mutations/useSignAgreementMutation.js";
import { AgreementModal } from "../../../../widgets/AgreementModal/AgreementModal.js";
import { AGREEMENT_TEXTS, AGREEMENT_TYPES } from "../../../../consts/agreements.js";

class SubscriptionAccelerator extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      feature_is_not_available_upgrade_account: languageData.global
        .feature_is_not_available_upgrade_account
        ? languageData.global.feature_is_not_available_upgrade_account
        : "",
      subscription_you_are_upgrading_users_to_the_accelerator_plan: languageData
        .global.subscription_you_are_upgrading_users_to_the_accelerator_plan
        ? languageData.global
          .subscription_you_are_upgrading_users_to_the_accelerator_plan
        : "",
      subscription_essential_to_accelerator_note: "",

      showLoader: true,
      message: "",
      isShowAcceleratorUpgradeModal: false,
      estimatatedData: {},
      practice_accelerator: null,
      practice_essentials: null,
      feature_list_accelerator: [],
      feature_list_essentials: [],
      user_type: userData && userData.user_type ? userData.user_type : "",
      account_status: "Cancelled",
      account_type: "trial",
      subscription_type: "monthly",
      price_per_user: 12,
      last_four_digit: "0000",
      collapseShowIndex: null,
      discount_percentage_value: 0,
      subscription_type_essentials: "monthly",
      subscription_type_accelerator: "monthly",
      upgrade_subscription_plan: "practice_essentials",
      upgrade_subscription_type: "monthly",
      isShowPaidUpgradeModal: false,
      card_number: "",
      card_cvv: "",
      card_month: "",
      card_year: "",
      frequency_type: {},
      subscription_plans: {},
      one_time_setup_fees: 0,
      upgradeAcknowledgementModal: {
        isOpen: false,
        extra: null,
      },
      isAgreementModalOpen: false,
      isAgreementsNotSigned: true,
      agreements: [
              {
                text: AGREEMENT_TEXTS.baa,
                type: AGREEMENT_TYPES.baa,
                id: 1,
              },
              {
                text: AGREEMENT_TEXTS.termsOfService,
                type: AGREEMENT_TYPES.termsOfService,
                id: 2,
              },
            ],
    };

    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    this.state["showLoader"] = true;
    this.props.exportEmptyData();
    this.props.getSubscriptionAcceleratorEstimate();

    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        });
      });
    }

    const _self = this;

    $("#accordionAccelerator").on(
      "hidden.bs.collapse, hide.bs.collapse",
      function() {
        _self.setState({ collapseShowIndex: null });
      },
    );

    $("#accordionAccelerator").on("shown.bs.collapse", function() {
      _self.setState({ collapseShowIndex: null });
      $("ul.collapse").each(function(_, element) {
        if ($(element).hasClass("show")) {
          _self.setState({
            collapseShowIndex: $(element).data("collapse-index"),
          });
        }
      });
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.estimatatedData != undefined &&
      nextProps.estimatatedData !== prevState.estimatatedData
    ) {
      returnState.showLoader = false;
      returnState.estimatatedData = nextProps.estimatatedData;
      returnState.practice_accelerator =
        returnState.estimatatedData.practice_accelerator;
      returnState.practice_essentials =
        returnState.estimatatedData.practice_essentials;
      returnState.feature_list_accelerator = returnState.practice_accelerator
        .plan_feature_list
        ? returnState.practice_accelerator.plan_feature_list
        : [];
      returnState.feature_list_essentials = returnState.practice_essentials
        .plan_feature_list
        ? returnState.practice_essentials.plan_feature_list
        : [];
      returnState.discount_percentage_value = nextProps.estimatatedData
        .discount_percentage_value
        ? nextProps.estimatatedData.discount_percentage_value
        : 0;
      returnState.account_status = returnState.estimatatedData.account_status;
      returnState.account_type = returnState.estimatatedData.account_type;
      returnState.subscription_type =
        returnState.estimatatedData.subscription_type;
      returnState.last_four_digit = returnState.estimatatedData.last_four_digit
        ? returnState.estimatatedData.last_four_digit
        : "0000";
      returnState.price_per_user = returnState.estimatatedData.price_per_user
        ? returnState.estimatatedData.price_per_user
        : 12;
      returnState.frequency_type = returnState.estimatatedData.frequency_type
        ? returnState.estimatatedData.frequency_type
        : {};
      returnState.subscription_plans = returnState.estimatatedData
        .subscription_plans
        ? returnState.estimatatedData.subscription_plans
        : {};
      returnState.one_time_setup_fees = returnState.estimatatedData
        .one_time_setup_fees
        ? returnState.estimatatedData.one_time_setup_fees
        : 0;

      returnState.subscription_you_are_upgrading_users_to_the_accelerator_plan =
        prevState.subscription_you_are_upgrading_users_to_the_accelerator_plan.replace(
          /{ACTIVE_USER_COUNT}/g,
          `${returnState.estimatatedData.active_user_count
            ? returnState.estimatatedData.active_user_count
            : 0
          }`,
        );

      let subscription_essential_to_accelerator_note = "";
      let perUserCharged = 16;

      if (returnState.subscription_type === "yearly") {
        subscription_essential_to_accelerator_note = prevState.globalLang
          .subscription_essential_to_accelerator_note_yearly
          ? prevState.globalLang
            .subscription_essential_to_accelerator_note_yearly
          : "";
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /{FROM_DATE}/g,
            `${returnState.estimatatedData.from_date
              ? showFormattedDate(returnState.estimatatedData.from_date)
              : ""
            }`,
          );
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /{CURRENT_DATE}/g,
            `${returnState.estimatatedData.current_date
              ? showFormattedDate(returnState.estimatatedData.current_date)
              : ""
            }`,
          );
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /{TO_DATE}/g,
            `${returnState.estimatatedData.to_date
              ? showFormattedDate(returnState.estimatatedData.to_date)
              : ""
            }`,
          );
        perUserCharged = returnState.practice_accelerator
          .price_per_user_discounted
          ? returnState.practice_accelerator.price_per_user_discounted
          : 14.4;
      } else if (returnState.subscription_type === "monthly") {
        subscription_essential_to_accelerator_note = prevState.globalLang
          .subscription_essential_to_accelerator_note_monthly
          ? prevState.globalLang
            .subscription_essential_to_accelerator_note_monthly
          : "";
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /XXXX/g,
            `${returnState.estimatatedData.next_day_date
              ? showFormattedDate(returnState.estimatatedData.next_day_date)
              : ""
            }`,
          );
        perUserCharged = returnState.practice_accelerator.price_per_user
          ? returnState.practice_accelerator.price_per_user
          : 14.4;
      }
      returnState.subscription_essential_to_accelerator_note =
        subscription_essential_to_accelerator_note.replace(
          /{PER_USER_CHARGE}/g,
          `${numberFormat(perUserCharged, "decimal", 2)}`,
        );
    } else if (
      nextProps.showLoader !== undefined &&
      nextProps.showLoader === false
    ) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
    } else if (
      nextProps.isUpgraded !== undefined &&
      nextProps.isUpgraded === true
    ) {
      nextProps.exportEmptyData();
      returnState.message = nextProps.message;
      uiNotification.clear();
      uiNotification.success(nextProps.message, {
        onClose: () => {
          nextProps.history.push("/settings/manage-subscription");
        },
      });
    }
    return returnState;
  }

  toggleAcceleratorUpgradeModal = () => {
    if (this.state.user_type == "superadmin") {
      if (this.state.account_type == "Paid") {
        this.setState({
          isShowAcceleratorUpgradeModal:
            !this.state.isShowAcceleratorUpgradeModal,
        });
      } else {
        uiNotification.clear();
        uiNotification.error(this.state.globalLang.trial_account_upgrade_warning);
      }
    } else {
      uiNotification.clear();
      uiNotification.error(this.state.globalLang.unauthorized_access_upgrade_plan);
    }
  };

  upgradeSubscriptionToAccelerator = () => {
    this.setState({ showLoader: true, isShowAcceleratorUpgradeModal: false });

    this.props.upgradeSubscriptionToAccelerator({
      is_switch_to_yearly: 0,
    });
  };
  
  togglePaidUpgradeModal = (planType) => {
    if (this.state.user_type === "superadmin") {
      if (this.state.account_type === "Trial") {
        this.props.getSetupIntentForAccount();

        let upgrade_subscription_plan = planType
          ? planType
          : "practice_essentials";

        let upgrade_subscription_type = "monthly";

        this.setState({
          isShowPaidUpgradeModal: !this.state.isShowPaidUpgradeModal,
          upgrade_subscription_plan: upgrade_subscription_plan,
          upgrade_subscription_type: upgrade_subscription_type,
        });
      } else {
        uiNotification.clear();
        uiNotification.error(this.state.globalLang.trial_account_upgrade_warning);
      }
    } else {
      uiNotification.clear();
      uiNotification.error(this.state.globalLang.unauthorized_access_upgrade_plan);
    }
  };

  subscriptionUpgradeAccount = () => {
    this.setState({ showLoader: true });
    if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
      let formData = {
        setup_intent_id: this.props.stripeIntent.setupIntentId,
      };
      this.setState({ showLoader: true });
      formData.subscription_type = this.state.upgrade_subscription_type;
      formData.subscription_plan = this.state.upgrade_subscription_plan;
      this.setState({ isShowPaidUpgradeModal: false });
      this.props.subscriptionUpgradeAccount(formData);
    }
  };

  handleInputChange = (event) => {
    this.setState({ userChanged: true });
    const target = event.target;
    const name = event.target.name;
    let value = target.value;
    this.setState({
      [event.target.name]: value,
    });
    if (
      this.state.account_type === "Paid" &&
      name === "subscription_type_accelerator"
    ) {
      let subscription_essential_to_accelerator_note = "";
      let perUserCharged = 16;
      if (value === "yearly") {
        subscription_essential_to_accelerator_note = this.state.globalLang
          .subscription_essential_to_accelerator_note_yearly
          ? this.state.globalLang
            .subscription_essential_to_accelerator_note_yearly
          : "";
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /{FROM_DATE}/g,
            `${this.state.estimatatedData.from_date
              ? showFormattedDate(this.state.estimatatedData.from_date)
              : ""
            }`,
          );
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /{CURRENT_DATE}/g,
            `${this.state.estimatatedData.current_date
              ? showFormattedDate(this.state.estimatatedData.current_date)
              : ""
            }`,
          );
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /{TO_DATE}/g,
            `${this.state.estimatatedData.to_date
              ? showFormattedDate(this.state.estimatatedData.to_date)
              : ""
            }`,
          );
        perUserCharged = this.state.practice_accelerator
          .price_per_user_discounted
          ? this.state.practice_accelerator.price_per_user_discounted
          : 14.4;
      } else if (value === "monthly") {
        subscription_essential_to_accelerator_note = this.state.globalLang
          .subscription_essential_to_accelerator_note_monthly
          ? this.state.globalLang
            .subscription_essential_to_accelerator_note_monthly
          : "";
        subscription_essential_to_accelerator_note =
          subscription_essential_to_accelerator_note.replace(
            /XXXX/g,
            `${this.state.estimatatedData.next_day_date
              ? showFormattedDate(this.state.estimatatedData.next_day_date)
              : ""
            }`,
          );
        perUserCharged = this.state.practice_accelerator.price_per_user
          ? this.state.practice_accelerator.price_per_user
          : 16;
      }
      subscription_essential_to_accelerator_note =
        subscription_essential_to_accelerator_note.replace(
          /{PER_USER_CHARGE}/g,
          `${numberFormat(perUserCharged, "decimal", 2)}`,
        );
      this.setState({
        subscription_essential_to_accelerator_note:
          subscription_essential_to_accelerator_note,
      });
    }
  };

  renderSubcriptionPlan = () => {
    let htmlList = [];

    if (this.state.subscription_plans) {
      let subscription_plans =
        this.state.upgrade_subscription_type === "monthly"
          ? this.state.subscription_plans.monthly
          : this.state.subscription_plans.yearly;
      if (subscription_plans) {
        Object.keys(subscription_plans).forEach((idx) => {
          htmlList.push(
            <option key={"subscription_plans-" + idx} value={idx}>
              {subscription_plans[idx]}
            </option>,
          );
        });
      }
    }
    return htmlList;
  };

  onSignAgreements = async (agreements) => {
    this.setState({isAgreementModalOpen: false});

    try {
       await Promise.all(
        agreements.map(item => {
          this.props.signAgreement.mutateAsync(item)
        })
       )

      this.setState({ isAgreementsNotSigned: false });

    } catch {
      uiNotification.error("Unable to send agreement. Try again later.");
    }
  }

  onCloseAgreementModal = () => {
    this.setState({isAgreementModalOpen: false});
  }

  render() {
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter relative">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  {this.state.globalLang.essentials_to_accelerator_subscription}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 subscriptionPlanDevider">
                  <div className="setting-setion m-b-15">
                    {this.state.practice_essentials && (
                      <div className="planPriceSection">
                        <h2>{this.state.globalLang.label_essentials}</h2>
                        {this.state.account_type == "Trial" && (
                          <div className="monthlyYearlySubscription m-b-5 m-t-5">
                            <div className="business-row">
                              <div className="col new-stats-outer singleSubscription">
                                <label
                                  className="planTypeOuter"
                                  htmlFor="subscription_type_essentials_monthly"
                                >
                                  <h6>Month-to-Month Subscription</h6>
                                  <div className="dollarSubscription m-t-20">
                                    <div
                                      className="radio-outer m-b-0 no-display"
                                      htmlFor="subscription_type_essentials_monthly"
                                    >
                                      <input
                                        type="radio"
                                        name="subscription_type_essentials"
                                        id="subscription_type_essentials_monthly"
                                        value="monthly"
                                        checked={
                                          this.state
                                            .subscription_type_essentials ===
                                            "monthly"
                                            ? "checked"
                                            : false
                                        }
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                    <div className="yearPrice monthPrice">
                                      <span>$</span>
                                      {numberFormat(
                                        this.state.practice_essentials
                                          .price_per_user,
                                        "decimal",
                                        2,
                                      )}
                                      <label>
                                        {
                                          this.state.globalLang
                                            .lebel_per_user_month
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </label>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-6 new-stats-outer no-display">
                                <label
                                  className="planTypeOuter"
                                  htmlFor="subscription_type_essentials_yearly"
                                >
                                  <h6>Save with Yearly Subscription</h6>
                                  <div className="dollarSubscription m-t-20">
                                    <div
                                      className="radio-outer m-b-0"
                                      htmlFor="subscription_type_essentials_yearly"
                                    >
                                      <input
                                        type="radio"
                                        name="subscription_type_essentials"
                                        id="subscription_type_essentials_yearly"
                                        value="yearly"
                                        checked={
                                          this.state
                                            .subscription_type_essentials ===
                                            "yearly"
                                            ? "checked"
                                            : false
                                        }
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                    <div className="yearPrice monthPrice">
                                      <span>$</span>
                                      {numberFormat(
                                        this.state.practice_essentials
                                          .price_per_user_discounted,
                                        "decimal",
                                        2,
                                      )}
                                      <label>
                                        {
                                          this.state.globalLang
                                            .lebel_per_user_month
                                        }
                                      </label>
                                    </div>
                                    <div className="paidAnnually">
                                      Paid Annually
                                    </div>
                                    {this.state.discount_percentage_value >
                                      0 && (
                                        <div className="discountBadget">
                                          <div>
                                            {this.state.globalLang.label_save}
                                          </div>
                                          <div>
                                            {this.state.discount_percentage_value}
                                            %
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <p
                          className={
                            this.state.account_type == "Paid" ? "" : "m-b-0"
                          }
                        >
                          {this.state.settingsLang.essentials_info}
                        </p>
                        {this.state.account_type == "Paid" && (
                          <div className="priceLabel priceLabel-lowMargin">
                            <h2>
                              <span>$</span>
                              {numberFormat(
                                this.state.price_per_user,
                                "decimal",
                                2,
                              )}
                            </h2>
                            <span>
                              {this.state.globalLang.lebel_per_user_month}
                            </span>
                            {this.state.subscription_type === "yearly" && (
                              <div className="paidAnnually">&nbsp;</div>
                            )}
                          </div>
                        )}
                        {this.state.account_type == "Trial" && (
                          <a
                            className="new-blue-btn m-t-30"
                            onClick={() =>
                              this.setState({
                                upgradeAcknowledgementModal: {
                                  isOpen: true,
                                  extra: {
                                    type: "practice_essentials",
                                  },
                                },
                              })
                            }
                          >
                            Upgrade
                          </a>
                        )}
                        {this.state.account_type === "Paid" &&
                          getIsAcceleratorPlan() === false && (
                            <a className="new-blue-btn m-t-30 cursor-default">
                              <i className=" fa fa-check"></i>{" "}
                              {this.state.settingsLang.your_current_plan}
                            </a>
                          )}
                      </div>
                    )}
                    <div className="featuresSection">
                      <h4>{this.state.settingsLang.included_with_plan}:</h4>
                      <ul className="planFeatures">
                        {this.state.feature_list_essentials.map((obj, idx) => {
                          return (
                            <li key={"feature_list-essentials" + idx}>
                              <i className=" fa fa-check"></i>
                              {obj.label}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="setting-setion m-b-15 Accelerator">
                    {this.state.practice_accelerator && (
                      <div className="planPriceSection">
                        <h2>{this.state.globalLang.label_accelerator}</h2>
                        {(this.state.account_type == "Trial" ||
                          this.state.subscription_type === "monthly") && (
                            <div className="monthlyYearlySubscription m-b-5 m-t-5">
                              <div className="business-row">
                                <div className="col new-stats-outer singleSubscription">
                                  <label
                                    className="planTypeOuter"
                                    htmlFor="subscription_type_accelerator_monthly"
                                  >
                                    <h6>Month-to-Month Subscription</h6>
                                    <div className="dollarSubscription m-t-20">
                                      <div
                                        className="radio-outer m-b-0 no-display"
                                        htmlFor="subscription_type_accelerator_monthly"
                                      >
                                        <input
                                          type="radio"
                                          name="subscription_type_accelerator"
                                          id="subscription_type_accelerator_monthly"
                                          value="monthly"
                                          checked={
                                            this.state
                                              .subscription_type_accelerator ===
                                              "monthly"
                                              ? "checked"
                                              : false
                                          }
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                      <div className="yearPrice monthPrice">
                                        <span>$</span>
                                        {numberFormat(
                                          this.state.practice_accelerator
                                            .price_per_user,
                                          "decimal",
                                          2,
                                        )}
                                        <label>
                                          {
                                            this.state.globalLang
                                              .lebel_per_user_month
                                          }
                                        </label>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-6 new-stats-outer no-display">
                                  <label
                                    className="planTypeOuter"
                                    htmlFor="subscription_type_accelerator_yearly"
                                  >
                                    <h6>Save with Yearly Subscription</h6>
                                    <div className="dollarSubscription m-t-20">
                                      <div
                                        className="radio-outer m-b-0"
                                        htmlFor="subscription_type_accelerator_yearly"
                                      >
                                        <input
                                          type="radio"
                                          name="subscription_type_accelerator"
                                          id="subscription_type_accelerator_yearly"
                                          value="yearly"
                                          checked={
                                            this.state
                                              .subscription_type_accelerator ===
                                              "yearly"
                                              ? "checked"
                                              : false
                                          }
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                      <div className="yearPrice monthPrice">
                                        <span>$</span>
                                        {numberFormat(
                                          this.state.practice_accelerator
                                            .price_per_user_discounted,
                                          "decimal",
                                          2,
                                        )}
                                        <label>
                                          {
                                            this.state.globalLang
                                              .lebel_per_user_month
                                          }
                                        </label>
                                      </div>
                                      <div className="paidAnnually">
                                        Paid Annually
                                      </div>
                                      {this.state.discount_percentage_value >
                                        0 && (
                                          <div className="discountBadget">
                                            <div>
                                              {this.state.globalLang.label_save}
                                            </div>
                                            <div>
                                              {this.state.discount_percentage_value}
                                              %
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        <p
                          className={
                            this.state.account_type == "Paid" &&
                              this.state.subscription_type === "yearly"
                              ? ""
                              : "m-b-0"
                          }
                        >
                          {this.state.settingsLang.accelerator_info}
                        </p>
                        {this.state.account_type == "Paid" &&
                          this.state.subscription_type === "yearly" && (
                            <div className="priceLabel priceLabel-lowMargin">
                              <h2>
                                <span>$</span>
                                {numberFormat(
                                  this.state.practice_accelerator
                                    .price_per_user_discounted,
                                  "decimal",
                                  2,
                                )}
                              </h2>
                              <span>
                                {this.state.globalLang.lebel_per_user_month}
                              </span>
                              <div className="paidAnnually">Paid Annually</div>
                            </div>
                          )}
                        {this.state.account_type == "Trial" ? (
                          <a
                            className="new-line-btn m-t-30 upgradePlanBtn"
                            onClick={() =>
                              this.setState({
                                upgradeAcknowledgementModal: {
                                  isOpen: true,
                                  extra: {
                                    type: "practice_accelerator",
                                  },
                                },
                              })
                            }
                          >
                            Upgrade
                          </a>
                        ) : this.state.account_type === "Paid" &&
                          getIsAcceleratorPlan() === true ? (
                          <a className="new-blue-btn m-t-30 cursor-default">
                            <i className=" fa fa-check"></i>{" "}
                            {this.state.settingsLang.your_current_plan}
                          </a>
                        ) : (
                          <a
                            className="new-line-btn m-t-30 upgradePlanBtn"
                            onClick={this.toggleAcceleratorUpgradeModal}
                          >
                            Upgrade
                          </a>
                        )}
                      </div>
                    )}
                    <div className="featuresSection">
                      <h4>{this.state.settingsLang.included_with_plan}:</h4>
                      <ul
                        className="planFeatures accordion"
                        id="accordionAccelerator"
                      >
                        <li
                          data-toggle="collapse"
                          href={"#collapse" + 0}
                          aria-expanded="false"
                          aria-controls={"#collapse" + 0}
                        >
                          <span>
                            <i className=" fa fa-check"></i>
                          </span>
                          {this.state.collapseShowIndex == 0 && (
                            <span>
                              <i className=" fa fa-caret-down"></i>
                            </span>
                          )}
                          {this.state.collapseShowIndex != 0 && (
                            <span>
                              <i className=" fa fa-caret-right"></i>
                            </span>
                          )}
                          <span>
                            {this.state.settingsLang.everything_in_essential}
                          </span>
                          <ul
                            className="collapse"
                            id={"collapse" + 0}
                            data-parent="#accordionAccelerator"
                            data-collapse-index="0"
                          >
                            {this.state.feature_list_essentials.map(
                              (obj, idx) => {
                                if (obj.is_addon_service != 1)
                                  return (
                                    <li
                                      key={
                                        "plan_feature_list-accelerator0-" + idx
                                      }
                                    >
                                      - {obj.label}
                                    </li>
                                  );
                              },
                            )}
                          </ul>
                        </li>
                        {this.state.feature_list_accelerator.map((obj, idx) => {
                          const counter = idx + 1;
                          return (
                            <React.Fragment
                              key={"plan_feature_list-accelerator" + idx}
                            >
                              <li
                                key={"plan_feature_list-accelerator" + counter}
                                data-toggle={
                                  obj.sub_feature_lisit.length > 0
                                    ? "collapse"
                                    : ""
                                }
                                href={"#collapse" + counter}
                                aria-expanded="false"
                                aria-controls={"#collapse" + counter}
                                className={
                                  obj.sub_feature_lisit.length <= 0
                                    ? "cursor-auto"
                                    : ""
                                }
                              >
                                <span>
                                  <i className=" fa fa-check"></i>
                                </span>
                                {obj.sub_feature_lisit.length > 0 &&
                                  this.state.collapseShowIndex == counter && (
                                    <span>
                                      <i className=" fa fa-caret-down"></i>
                                    </span>
                                  )}
                                {obj.sub_feature_lisit.length > 0 &&
                                  this.state.collapseShowIndex != counter && (
                                    <span>
                                      <i className=" fa fa-caret-right"></i>
                                    </span>
                                  )}
                                <span>{obj.label}</span>
                                {obj.sub_feature_lisit.length > 0 && (
                                  <ul
                                    className="collapse"
                                    id={"collapse" + counter}
                                    data-parent="#accordionAccelerator"
                                    data-collapse-index={counter}
                                  >
                                    {obj.sub_feature_lisit.map((obj1, idx1) => {
                                      return (
                                        <li
                                          key={
                                            "plan_feature_list-essentials" +
                                            counter +
                                            "-" +
                                            idx1
                                          }
                                        >
                                          - {obj1.label}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Upgrade Account Modal -Essentials to Accelerator - START */}
          {this.state.user_type == "superadmin" &&
            this.state.account_type == "Paid" && (
              <div
                className={
                  this.state.isShowAcceleratorUpgradeModal
                    ? "modalOverlay"
                    : "no-display"
                }
              >
                <div className="small-popup-outer subscription-popup">
                  <div className="small-popup-header">
                    <div className="popup-name">
                      {
                        this.state.globalLang
                          .essentials_to_accelerator_subscription
                      }
                    </div>
                    <a
                      className="small-cross"
                      onClick={this.toggleAcceleratorUpgradeModal}
                    >
                      ×
                    </a>
                  </div>
                  <div className="small-popup-content">
                    <div className="juvly-container">
                      <div className="monthly-estimate-block">
                        <div className="">
                          <div className="accordian-section text-left">
                            <div className="featureNoteTitle">
                              {
                                this.state
                                  .subscription_you_are_upgrading_users_to_the_accelerator_plan
                              }
                            </div>
                            <div className="row p-t-20">
                              <div className="col-lg-12 col-xs-12">
                                <div className="feature-not-available featureNote">
                                  <p>
                                    {
                                      this.state
                                        .subscription_essential_to_accelerator_note
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="featureNoteTitle featureNoteFooter">
                              <p>
                                {
                                  this.state.globalLang
                                    .subscription_we_will_be_in_touch_with_you_shortly
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer-static">
                      <a
                        className="new-blue-btn pull-right"
                        name="btn_create_schedule"
                        onClick={this.upgradeSubscriptionToAccelerator}
                      >
                        {
                          this.state.globalLang
                            .subscription_cta_accelerate_my_practice
                        }
                      </a>
                      <a
                        className="new-white-btn pull-right"
                        onClick={this.toggleAcceleratorUpgradeModal}
                      >
                        {this.state.globalLang.label_cancel}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {/* Upgrade Account Modal -Essentials to Accelerator - END */}

          {/* Upgrade Account Modal - Trial to Paid - START */}
          <div
            className={
              this.state.isShowPaidUpgradeModal ? "modalOverlay" : "no-display"
            }
          >
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">
                  {this.state.settingsLang.subscription_upgrade_account}
                </div>
                <a
                  className="small-cross"
                  onClick={this.togglePaidUpgradeModal}
                >
                  ×
                </a>
              </div>
              <div className="small-popup-content maxHeight-550">
                <div className="juvly-container no-padding-bottom">
                  <div className="row">
                    {this.state.subscription_plans && (
                      <>
                        <div className="col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {this.state.settingsLang.subscription_plan_label}
                              <span className="setting-require">*</span>
                            </div>
                            <select
                              className="newSelectField"
                              name="upgrade_subscription_plan"
                              value={this.state.upgrade_subscription_plan}
                              onChange={this.handleInputChange}
                            >
                              {this.renderSubcriptionPlan()}
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {this.state.settingsLang.subscription_type}
                              <span className="setting-require">*</span>
                            </div>
                            <select
                              className="newSelectField"
                              name="upgrade_subscription_type"
                              value={this.state.upgrade_subscription_type}
                              onChange={this.handleInputChange}
                            >
                              <option value="monthly">
                                {this.state.frequency_type.monthly}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {this.state.globalLang.one_time_setup_fees}
                              <span className="setting-require">*</span>
                            </div>
                            <input
                              name="one_time_setup_fees"
                              value={formatBillingCurrency(
                                this.state.one_time_setup_fees,
                              )}
                              onChange={this.handleInputChange}
                              className="newInputField setting-input-box-disabled"
                              disabled={"dissabled"}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-xs-12">
                      <StripePaymentForm
                        type={"setupIntent"}
                        validation={""}
                        onSubmit={() => this.subscriptionUpgradeAccount()}
                        onCancel={this.togglePaidUpgradeModal}
                        prePayment={this.state.isAgreementsNotSigned ?{
                          label: "Sign Agreements",
                          onClick: () => this.setState({ isAgreementModalOpen: true }),
                        } : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static"></div>
            </div>
          </div>

          {this.state.upgradeAcknowledgementModal?.isOpen && (
            <UpgradeAcknowledgement
              isOpen
              onClose={() =>
                this.setState({
                  upgradeAcknowledgementModal: {
                    isOpen: false,
                    extra: null,
                  },
                })
              }
              onConfirm={() => {
                this.togglePaidUpgradeModal(
                  this.state.upgradeAcknowledgementModal.extra.type,
                );
                this.setState({
                  upgradeAcknowledgementModal: {
                    isOpen: false,
                    extra: null,
                  },
                });
              }}
            />
          )}

          {/* Upgrade Account Modal - Trial to Paid - END */}
          
          {this.state.isAgreementModalOpen && (
            <AgreementModal
              agreements={this.state.agreements}
              type={this.state.currentAgreement}
              isOpen={this.state.isAgreementModalOpen}
              onClose={() => this.setState({isAgreementModalOpen: false})}
              onSign={this.onSignAgreements}
            />
          )}
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.SettingReducer.action === "SUBSCRIPTION_ACCELERATOR_ESTIMATE") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear();
      if (state.SettingReducer.data.message == "third_party_error") {
        uiNotification.error(state.SettingReducer.data.data);
      } else {
        uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.estimatatedData = state.SettingReducer.data.data;
      returnState.estimatedTimestamp = new Date();
    }
  } else if (
    state.SettingReducer.action === "SUBSCRIPTION_ACCELERATOR_UPGRADE"
  ) {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear();
      if (state.SettingReducer.data.message == "third_party_error") {
        uiNotification.error(state.SettingReducer.data.data);
      } else {
        uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.timestamp = new Date();
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
      returnState.isUpgraded = true;
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_UPGRADE_ACCOUNT") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.clear();
      if (state.SettingReducer.data.message == "third_party_error") {
        uiNotification.error(state.SettingReducer.data.data);
      } else {
        uiNotification.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.timestamp = new Date();
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
      returnState.isUpgraded = true;
    }
  }

  returnState.stripeIntent = state.StripeReducer.stripeIntent;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      exportEmptyData,
      getSubscriptionAcceleratorEstimate,
      upgradeSubscriptionToAccelerator,
      subscriptionUpgradeAccount,
      getSetupIntentForAccount,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSignAgreementMutation(SubscriptionAccelerator));
