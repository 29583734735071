import { REQ_HEADERS } from "../consts/api";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";

export function withAccessTokenHeaders(config) {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.accessToken);

  return {
    ...config,
    headers: token
      ? {
          [REQ_HEADERS.accessToken]: token,
        }
      : {},
  };
}
