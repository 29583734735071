import axios from "axios";
import { withAccessTokenHeaders } from "../../utilities/auth";

var i18nHttp = (() => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  instance.interceptors.request.use(withAccessTokenHeaders);

  return instance;
})();

export { i18nHttp };
