import React from "react";
import PropTypes from "prop-types";
import { InputName } from "../components/InputName";
import { InputPhone } from "../components/InputPhone";
import classes from "../sass/ClientHandler.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { MODE } from "../ClientHandler.consts";
import { useUpdateForm } from "../hooks/useUpdateForm";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useCreatePatientProfileMutation } from "../../../../../api/mutations/useCreatePatientProfileMutation";
import { splitFullName, unwrapOr } from "../../../../../utilities/general";
import { InputEmail } from "../components/InputEmail";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { phoneUtil } from "../../../../../utilities/phone";
import { isDobForBookingAvailable } from "../../../../../helpers/featureFlags";
import { InputDob } from "../../../../../shared/InputDob";
import { dobToApiDate } from "../ClientHandler.utils";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";

export function FormCreate({ name, onSuccess, setMode, isPhoneRequired }) {
  const { tCommon } = useAppTranslation.Common();
  const { mutateAsync, isLoading } = useCreatePatientProfileMutation();
  const { data: user } = useCurrentUserQuery();

  const onSubmit = async (values) => {
    try {
      const { firstName, lastName } = splitFullName(values.name);
      const res = isDobForBookingAvailable()
        ? await mutateAsync({
            firstName,
            lastName,
            email: values.email,
            phone: phoneUtil.compose(values.phone),
            gender: values?.gender || "2",
            dob: dobToApiDate(values?.dob, user?.dateFormat),
          })
        : await mutateAsync({
            firstName,
            lastName,
            email: values.email,
            phone: phoneUtil.compose(values.phone),
            gender: values?.gender || "2",
          });
      const userId = unwrapOr(() => res.data.data.id, null);
      if (!userId) {
        uiNotification.error(tCommon("error.missingPatientId"));
      }
      onSuccess(userId, values);
    } catch {
      uiNotification.error(tCommon("error.createPatientProfile"));
    }
  };

  const { form, setFormValue, getError, submit } = useUpdateForm({
    onSubmit,
    isPhoneRequired,
    initialValues: isDobForBookingAvailable()
      ? {
          name,
          email: "",
          phone: "",
          dob: "",
        }
      : {
          name,
          email: "",
          phone: "",
        },
  });

  return (
    <div>
      <div className={classes.formGrid}>
        <InputName
          value={form.name}
          error={getError("name")}
          onChange={(nextName) => setFormValue("name", nextName)}
        />
        <InputEmail
          value={form.email}
          error={getError("email")}
          onChange={(nextEmail) => setFormValue("email", nextEmail)}
        />
        <InputPhone
          value={form.phone}
          error={getError("phone")}
          onChange={(nextPhone) => setFormValue("phone", nextPhone)}
        />
        {isDobForBookingAvailable() && (
          <InputDob
            value={form.dob}
            isError={!!getError("dob")}
            onChange={({ masked }) => setFormValue("dob", masked)}
          />
        )}
      </div>
      <div className={classes.formControls}>
        <Button
          size="small"
          variant="outlined"
          fontFamily="secondary"
          onClick={() => setMode(MODE.default)}
          className={classes.formBtn}
          isDisabled={isLoading}
        >
          {tCommon("label.cancel")}
        </Button>
        <Button
          size="small"
          fontFamily="secondary"
          onClick={submit}
          className={classes.formBtn}
          isDisabled={isLoading}
          leftAdornment={
            isLoading ? (
              <CircularProgress size="tiny" color="white" />
            ) : undefined
          }
        >
          {tCommon("label.create")}
        </Button>
      </div>
    </div>
  );
}

FormCreate.propTypes = {
  name: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  isPhoneRequired: PropTypes.bool.isRequired,
};
