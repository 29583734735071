import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  API_DATE_FORMAT,
  HTTP_ENDPOINTS,
  ORDER_TYPES,
  QUERY_KEYS,
  RECONCILIATION_STATUS,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import {
  packedListOr,
  removeNullishFromShape,
  unwrapOr,
} from "../../utilities/general";
import { createQueryKeyFromShape } from "../../utilities/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

export const RECONCILIATION_REPORTS_ORDER_BY = {
  invoice_number: "invoice_number",
  patient_name: "patient_name",
  payment_date: "payment_date",
  transaction_type: "transaction_type",
  cc_collected_amount: "cc_collected_amount",
  payout_amount: "payout_amount",
  payout_arrival_date: "payout_arrival_date",
  payout_id: "payout_id",
  transaction_payment_type: "transaction_payment_type",
};

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),

    status: yup
      .string()
      .test({
        test: (v) => Object.values(RECONCILIATION_STATUS).includes(v) || !v,
      })
      .nullable(),
  }),

  order: yup
    .object({
      by: yup
        .string()
        .test({
          test: (v) =>
            Object.values(RECONCILIATION_REPORTS_ORDER_BY).includes(v),
        })
        .required(),
      direction: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) => Object.values(ORDER_TYPES).includes(value),
        })
        .required(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    total_cc_collected: yup.number().required(),
    total_payouts: yup.number().required(),

    reports: yup
      .array()
      .of(
        yup.object({
          invoice_number: yup.string().nullable(),
          patient_name: yup.string().nullable(),
          payment_date: yup.string().nullable(),
          transaction_type: yup.string().nullable(),
          cc_collected_amount: yup.number().nullable(),
          payout_amount: yup.number().nullable(),
          payout_arrival_date: yup.string().nullable(),
          payout_id: yup.string().nullable(),
          transaction_payment_type: yup.string().nullable(),
        }),
      )
      .required(),
  }),
);

/*
  COMPOSERS
*/

const composeResponseSchema = (res, req) => {
  const data = unwrapOr(() => res.data.data, {});
  const reports = unwrapOr(() => res.data.data.report.data, []);

  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data.report, {}),
      pageSize: req.pageSize,
    }),
    total_cc_collected: data.total_cc_collected || 0,
    total_payouts: data.total_payouts || 0,
    status: data.status || null,

    reports: reports.map((r) => ({
      invoice_number: r.invoice_number || null,
      patient_name: r.patient_name || null,
      payment_date: r.payment_date || null,
      transaction_type: r.transaction_type || null,
      cc_collected_amount: r.cc_collected_amount || null,
      payout_amount: r.payout_amount || null,
      payout_arrival_date: r.payout_arrival_date || null,
      payout_id: r.payout_id || null,
      transaction_payment_type: r.transaction_payment_type || null,
    })),
  };
};

// ---------

function createReconciliationReportsQueryKey({
  order,
  filter,
  fromDate,
  toDate,
}) {
  return [QUERY_KEYS.salesMembershipReports, order, filter, fromDate, toDate];
}

const createKeyFromShape = (shape) =>
  unwrapOr(() => createQueryKeyFromShape(removeNullishFromShape(shape)), null);

export function useReconciliationReportsQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createReconciliationReportsQueryKey({
      order: createKeyFromShape(req.order),
      filter: createKeyFromShape(req.filter),
      fromDate,
      toDate,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.post(
        HTTP_ENDPOINTS.getReconciliationReports(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          start_date: moment(req.period.from).format(API_DATE_FORMAT),
          end_date: moment(req.period.to).format(API_DATE_FORMAT),
          clinic_ids: packedListOr(
            unwrapOr(() => req.filter.clinicIds, null),
            null,
          ),
          status: unwrapOr(() => req.filter.status, null),
          sortBy: unwrapOr(() => req.order.by, null),
          order: unwrapOr(() => req.order.direction, null),
        }),
      );
      return responseSchema.validateSync(composeResponseSchema(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
