import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useDownloadAllDocuments = (options = {}) => {
  return useMutation(
    async (dto) => http.post(HTTP_ENDPOINTS.postDownloadPatientDocs(), dto),
    { ...options },
  );
};
