import React from "react";
import cx from "clsx";
import classes from "../scss/AccordionButton.module.scss";
import { AngleDownIcon } from "../../../../../assets/Icons/AngleDownIcon";

const AccordionButton = ({ label, isExpanded, setIsExpanded }) => {
  return (
    <button
      className={classes.expandListButton}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <span>{label}</span>
      <span className={cx(classes.expandIcon, isExpanded && classes.expanded)}>
        <AngleDownIcon width={14} height={14} fill={"#667680"} />
      </span>
    </button>
  );
};

export default AccordionButton;
