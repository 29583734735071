import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import classes from "./scss/repeatMd.module.scss";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { Toggle } from "../../../../shared/Toggle/Toggle";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Box } from "../../../../shared/Box/Box";
import { useRepeatMdIntegration } from "./hooks/useRepeatMdIntegration";
import { useSaveRepeatMdIntegration } from "./hooks/useSaveRepeatMdIntegration";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import AuthorizationBox from "./components/AuthorizationBox";
import RepeatMdClinicSync from "./components/RepeatMdClinicSync";
import RepeatMdMembershipConfiguration from "./components/RepeatMdMembershipConfiguration";
import RepeatMdEnrollmentConfiguration from "./components/RepeatMdEnrollmentConfiguration";
import { integrationTypeOptions } from "./RepeatMd.consts";
import { InputLabel } from "../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../shared/Select/Select";

const RepeatMd = () => {
  const { tSettings } = useAppTranslation.Settings();
  const [isEnabled, setIsEnabled] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [integrationType, setIntegrationType] = useState(null);

  const isLocationIntegration = integrationType === "location";

  const [expanded, setExpanded] = useState({
    clinicSync: false,
    membershipConfiguration: false,
    enrollmentConfiguration: false,
  });

  const { data, isLoading } = useRepeatMdIntegration({
    onSuccess: (data) => {
      setIsEnabled(data.isActive);
      setIsConnected(data.isConnected);
      setIntegrationType(data.integrationType);
    },
  });

  const { mutate, isLoading: isSaving } = useSaveRepeatMdIntegration({
    onSuccess: (data) => setIntegrationType(data.data.data.integrationType),
  });

  return (
    <LayoutSettings contentClassName={classes.root}>
      <EntityHeader
        title={tSettings("repeatMdIntegration.title")}
        right={
          <div>
            {isSaving ? (
              <CircularProgress size="small" color="primary" />
            ) : (
              <Toggle
                isEnabled={isEnabled}
                onChange={() =>
                  mutate(
                    {
                      isActive: !isEnabled,
                    },
                    { onSuccess: () => setIsEnabled(!isEnabled) },
                  )
                }
                isDisabled={false}
              />
            )}
          </div>
        }
      />
      {isEnabled && (
        <Box className={classes.clinicsBox}>
          {isLoading ? (
            <Skeleton count={5} height={"35px"} />
          ) : (
            <>
              <div className={classes.authorizationContainer}>
                <AuthorizationBox
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  updatedAt={data?.updatedAt}
                  updateApiKey={mutate}
                  isUpdating={isSaving}
                />
                <div className={classes.integrationType}>
                  <InputLabel>
                    {tSettings("repeatMdIntegration.integrationType")}
                  </InputLabel>
                  <Select
                    size="small"
                    options={integrationTypeOptions}
                    value={integrationTypeOptions.find(
                      (integration) => integration.value === integrationType,
                    )}
                    isLoading={isSaving}
                    onChange={(option) => {
                      mutate(
                        {
                          isActive: true,
                          integrationType: option.value,
                          clinics: data?.clinics,
                        },
                        {
                          onSuccess: () => {
                            setIntegrationType(option.value);
                          },
                        },
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                {Boolean(data?.clinics.length) && isLocationIntegration && (
                  <>
                    <RepeatMdClinicSync
                      expanded={expanded}
                      setExpanded={setExpanded}
                      data={data}
                      mutate={mutate}
                      isSaving={isSaving}
                    />
                  </>
                )}
                <RepeatMdMembershipConfiguration
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
                <RepeatMdEnrollmentConfiguration
                  data={data}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </div>
            </>
          )}
        </Box>
      )}
    </LayoutSettings>
  );
};

export default RepeatMd;
