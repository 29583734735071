import React from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import { ROUTES } from "../consts/routes";
import PrivateRoutes from "./PrivateRoutes";
import { GuestRoute } from "./shared/GuestRoute.js";
import AuthorizeToLogin from "../Components/Login/AuthorizeToLogin/AuthorizeToLogin.js";
import RecurlyToStripe from "../Components/Login/RecurlyToStripe.js";
import TrailToPaid from "../Components/Login/TrailToPaid.js";
import Logout from "../Components/Login/Logout.js";
import AccountSetup from "../Components/SignUp/AccountSetup.js";
import TouchMDSignup from "../Components/SignUp/TouchMD-Signup.js";
import CandelaSignup from "../Components/SignUp/CandelaSignup/CandelaSignup.js";
import CandeleAccountSetup from "../Components/SignUp/CandelaSignup/CandelaAccountSetup.js";
import CandelaAccountSuccess from "../Components/SignUp/CandelaSignup/CandelaAccountSuccess.js";
import AutoLogin from "../Components/Login/AutoLogin.js";
import AccountSuccess from "../Components/SignUp/AccountSuccess.js";
import VerifyTouchMDAccount from "../Components/SignUp/VerifyTouchMD-Account.js";
import { Workspaces } from "../pages/Workspaces/Workspaces";
import KlarnaCompletedPayment from "../Components/Sales/FrontdeskCheckout/KlarnaCompletedPayment";
import KlarnaCancelPayment from "../Components/Sales/FrontdeskCheckout/KlarnaCancelPayment";
import MaintenancePage from "../Components/MentenanceRedirect/MaintenancePage.js";
import StripeCompletedPayment from "../Components/Sales/FrontdeskCheckout/StripeCompletedPayment";
import { MAINTENANCE_MODE } from "../consts/general.js";
import Login from "../pages/Login/Login";
import ForgotPassword from "../Components/ForgotPassword/ForgotPassword.js";
import BlockIP from "../Components/BlockIP/BlockIP.js";
import SignUpBasic from "../Components/SignUp/SignUpBasic.js";
import SignUpPremium from "../Components/SignUp/SignUpPremium.js";
import LoginPolicy from "../Components/Login/LoginPolicy.js";
import TwoFactorLogin from "../pages/Login/TwoFactorLogin";
import VerifyAccount from "../Components/SignUp/VerifyAccount.js";
import SignUpNationalMedicalDirectors from "../Components/SignUp/SignUpNationalMedicalDirectors";
import { useAppLockUntilAccountAddressUpdated } from "../hooks/useAppLockUntilAccountAddressUpdated.js";
import { ChangePassword } from "../pages/ChangePassword/ChangePassword.js";
import JobSimulator from "../Components/Login/JobSimulator/JobSimulator";

export const Router = () => {
  useAppLockUntilAccountAddressUpdated();

  return (
    <Switch>
      {/* Common Routes - Routes that are available for both logged in and logged out users */}

      <Route path={ROUTES.jobSimulator()} component={JobSimulator} />
      <Route
        path={ROUTES.authorizeAccess(":id")}
        component={AuthorizeToLogin}
      />
      <Route
        path="/stripe-completed-payment/:invoiceId/:type"
        component={StripeCompletedPayment}
      />
      <Route
        exact
        path={ROUTES.autoLogin(":cipherKey?")}
        component={AutoLogin}
      />
      <Route exact path={ROUTES.touchMdSignUp()} component={TouchMDSignup} />
      <Route
        path={ROUTES.klarnaCompletedPayment()}
        component={KlarnaCompletedPayment}
      />
      <Route
        path={ROUTES.klarnaCancelPayment()}
        component={KlarnaCancelPayment}
      />
      <Route
        exact
        path={ROUTES.touchMdSignUp(":type")}
        component={TouchMDSignup}
      />
      <Route exact path={ROUTES.candelaSignup()} component={CandelaSignup} />
      <Route
        exact
        path={ROUTES.touchMdAccountSetup()}
        component={AccountSetup}
      />
      <Route
        exact
        path={ROUTES.candelaAccountSetup()}
        component={CandeleAccountSetup}
      />
      <Route exact path={ROUTES.logout()} component={Logout} />
      <Route path={ROUTES.accountSetup(":type")} component={AccountSetup} />
      <Route
        exact
        path={ROUTES.upgradeAccountToStripe()}
        component={RecurlyToStripe}
      />
      <Route
        exact
        path={ROUTES.upgradeSubscriptionPlan()}
        component={TrailToPaid}
      />
      <Route exact path={ROUTES.accountSuccess()} component={AccountSuccess} />
      <Route
        exact
        path={ROUTES.touchMdAccountSuccess()}
        component={AccountSuccess}
      />
      <Route
        exact
        path={ROUTES.candelaAccountSuccess()}
        component={CandelaAccountSuccess}
      />
      <Route
        path={ROUTES.touchMdAccountVerify(":verificationCode?")}
        component={VerifyTouchMDAccount}
      />
      <Route exact path={ROUTES.workspaces()} component={Workspaces} />
      {MAINTENANCE_MODE && (
        <Route path={ROUTES.maintenance()} component={MaintenancePage} />
      )}

      {/* Guest Routes - Routes that are available only if user is logged out */}

      <GuestRoute path={ROUTES.login()} component={Login} />
      <GuestRoute path={ROUTES.forgotPasswd()} component={ForgotPassword} />
      <GuestRoute path={ROUTES.resetPasswd()} component={Login} />
      <GuestRoute path={ROUTES.blockIp()} component={BlockIP} />
      <GuestRoute
        path={ROUTES.signUpBasic(":invite_key?")}
        component={SignUpBasic}
      />
      <GuestRoute
        path={ROUTES.signUpPremium(":invite_key?")}
        component={SignUpPremium}
      />
      <GuestRoute
        exact
        path={ROUTES.acceptAgreement()}
        component={LoginPolicy}
      />
      <GuestRoute
        exact
        path={ROUTES.twoFactorLogin()}
        component={TwoFactorLogin}
      />
      <GuestRoute
        exact
        path={ROUTES.verifyAccount(":verificationCode")}
        component={VerifyAccount}
      />
      <GuestRoute
        path={ROUTES.nationalMedDirectors()}
        component={SignUpNationalMedicalDirectors}
      />
      <GuestRoute
        path={ROUTES.changePassword({ wpuId: ":wpuId" })}
        component={ChangePassword}
      />

      <Redirect exact path="/" to="login" />

      {/* Private Routes - Routes that are available only if user is logged in */}

      {PrivateRoutes}
    </Switch>
  );
};
