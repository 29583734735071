import React, { useMemo, useState } from "react";
import classes from "../../Clients.module.scss";
import { Table } from "../../../../shared/Table/Table";
import { withRouter } from "react-router";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { showFormattedDate } from "../../../../Utils";
import { Button } from "../../../../shared/Button/Button";
import ConnectJoyaModal from "./components/ConnectJoyaModal";
import { useJoyaWallet } from "./hooks/useJoyaWallet";

const JoyaWallet = ({ match }) => {
  const { tCommon } = useAppTranslation.Common();
  const { tClients } = useAppTranslation.Clients();
  const patientId = match.params.clientID;

  const [showConnectModal, setShowConnectModal] = useState(false);
  const { data: joya } = useJoyaWallet(patientId);

  const joyaPatientId = joya?.joyaPatientId;

  const tableData = useMemo(
    () =>
      joya?.items?.map((item) => ({
        item: item.name,
        availability: item.available ? "Available" : "Claimed",
        claimedOn: item.claimedOn
          ? showFormattedDate(item.claimedOn)
          : tCommon("symbol.longDash"),
      })),
    [joya],
  );

  return (
    <>
      <div className="juvly-container flex justify-between">
        <h1 className={classes.repeatMdTitle}>
          {tClients("joyaWallet.title")}
        </h1>
        {!joyaPatientId && (
          <Button
            size="tiny"
            className="new-blue-btn p-l-15 p-r-15"
            onClick={() => setShowConnectModal(true)}
          >
            {tClients("joyaWallet.connect")}
          </Button>
        )}
      </div>
      <div className={classes.tableWrapper}>
        <Table
          bgColor="white"
          keyExtractor={(row) => row?.id}
          colClassName={classes.clientWalletTableRow}
          headColClassName={classes.clientWalletTableHead}
          bodyClassName={classes.clientWalletTableBody}
          data={tableData || []}
          cols={[
            {
              data: tClients("joyaWallet.table.item"),
              accessor: "item",
            },
            {
              data: tClients("joyaWallet.table.availability"),
              accessor: "availability",
            },
            {
              data: tClients("joyaWallet.table.claimedOn"),
              accessor: "claimedOn",
            },
          ]}
        />
        {!tableData?.length && (
          <p className={classes.noRecord}>{tCommon("label.noRecordFound")}</p>
        )}
      </div>
      {showConnectModal && (
        <ConnectJoyaModal onClose={() => setShowConnectModal(false)} />
      )}
    </>
  );
};

export default withRouter(JoyaWallet);
