import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useAppointmentConfigurationService = (serviceId, options = {}) => {
  const composeResponse = (res) => {
    const categoriesOptions = useMemo(() => {
      return res?.data?.list_data?.categories?.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    }, [res]);

    const clinicsOptions = useMemo(() => {
      return res?.data?.list_data?.clinics?.map((clinic) => ({
        label: clinic.clinic_name,
        value: clinic.id,
      }));
    }, [res]);

    const providersOptions = useMemo(() => {
      return res?.data?.list_data?.providers?.map((provider) => ({
        label: provider.full_name,
        value: provider.id,
      }));
    }, [res]);

    const consentsOptions = useMemo(() => {
      return res?.data?.list_data?.consent_list?.map((provider) => ({
        label: provider.consent_name,
        value: provider.id,
      }));
    }, [res]);

    const questionnairesOptions = useMemo(() => {
      return res?.data?.list_data?.questionnaires?.map((provider) => ({
        label: provider.title,
        value: provider.id,
      }));
    }, [res]);

    const serviceDataCategory = categoriesOptions?.filter((category) => {
      const seviceCategories =
        res?.data?.service_data?.service_category_assoc || [];

      return seviceCategories.some((cat) => category.value === cat.category_id);
    });

    const serviceDataClinics = clinicsOptions?.filter((clinic) => {
      const serviceClinics = res?.data?.service_data?.service_clinics || [];

      return serviceClinics.some((cat) => clinic.value === cat.clinic_id);
    });

    const serviceDataProviders = providersOptions?.filter((provider) => {
      const serviceProviders = res?.data?.service_data?.service_providers || [];

      return serviceProviders.some((p) => provider.value === p.user_id);
    });

    const serviceDataConsents = consentsOptions?.filter((consent) => {
      const serviceConsents = res?.data?.service_data?.service_consents || [];

      return serviceConsents.some((c) => consent.value === c.consent_id);
    });

    const serviceDataQuestionnaires = questionnairesOptions?.filter(
      (questionnaire) => {
        const serviceQuestionnaires =
          res?.data?.service_data?.service_questionnaires || [];

        return serviceQuestionnaires.some(
          (c) => questionnaire.value === c.questionnaire_id,
        );
      },
    );

    const serviceData = {
      categories: serviceDataCategory || [],
      clinics: serviceDataClinics || [],
      providers: serviceDataProviders || [],
      consents: serviceDataConsents || [],
      questionnaires: serviceDataQuestionnaires || [],
      duration: res?.data?.service_data?.duration || 0,
    };

    return {
      categoriesOptions: categoriesOptions || [],
      clinicsOptions: clinicsOptions || [],
      providersOptions: providersOptions || [],
      consentsOptions: consentsOptions || [],
      questionnairesOptions: questionnairesOptions || [],
      serviceData,
      isFetching: res.isLoading,
    };
  };

  const res = useQuery(
    ["appointmentConfigurationService"],
    async () => {
      return http.get(HTTP_ENDPOINTS.getServiceClone(serviceId));
    },
    {
      cacheTime: 0,
      select: (data) => data.data.data,
      ...options,
    },
  );

  return composeResponse(res);
};
