import {
  positionFooterCorrectly,
  getIsPosEnabled,
} from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";
import { uiNotification } from "../../services/UINotificationService.js";

positionFooterCorrectly();

export function getSetupIntent(formData, cancel, showError = true) {
  return async (dispatch) => {
    if (cancel) {
      await dispatch(cancelSetupIntent());
    }

    dispatch({ type: "GET_STRIPE_INTENT_RESET" });
    if (getIsPosEnabled()) {
      await http
        .post("stripe/setup-intent", formData)
        .then((response) => {
          dispatch({
            type: "GET_STRIPE_INTENT_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "GET_STRIPE_INTENT_ERROR",
            payload: error?.response?.data,
          });
          if (showError) {
            uiNotification.error(error.response.data.message);
          }
        });
    }
  };
}

export function clearSetupIntent() {
  return async (dispatch) => {
    await dispatch({ type: "GET_STRIPE_INTENT_RESET" });
  };
}

function cancelSetupIntent() {
  return (dispatch) => dispatch({ type: "CANCEL_STRIPE_SETUP_INTENT" });
}

export const getSetupIntentForSignUp = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_STRIPE_INTENT_RESET", payload: {} });
    http
      .post("register-account-setup-intent", formData)
      .then((response) => {
        dispatch({ type: "GET_STRIPE_INTENT_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_STRIPE_INTENT_ERROR",
          payload: error.response.data,
        });
      });
  };
};

export const getSetupIntentForAccount = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_STRIPE_INTENT_RESET", payload: {} });
    http
      .post("stripe/account-setup-intent", formData)
      .then((response) => {
        dispatch({ type: "GET_STRIPE_INTENT_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_STRIPE_INTENT_ERROR",
          payload: error.response.data,
        });
      });
  };
};

export const getStripePaymentIntent = (formData) => {
  return async (dispatch) => {
    if (getIsPosEnabled()) {
      dispatch({ type: "GET_STRIPE_INTENT_RESET", payload: {} });
      await http
        .post("stripe/payment-intent", formData)
        .then((response) => {
          dispatch({
            type: "GET_STRIPE_INTENT_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          return Promise.reject(error.response.data);
        });
    }
  };
};

export const getClientCardDataStripe = (form) => {
  return async (dispatch) => {
    if (getIsPosEnabled()) {
      await http
        .post("stripe/list-payment-methods", form)
        .then((response) => {
          dispatch({
            type: "GET_CLIENT_STRIPE_CARDS",
            payload: response.data.data.paymentMethods,
          });
        })
        .catch(() => {
          dispatch({ type: "GET_CLIENT_STRIPE_CARDS", payload: [] });
        });
    }
  };
};
