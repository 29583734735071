import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useJoyaIntegration = (options = {}) => {
  const res = useQuery(
    ["joyaIntegration"],
    async () => {
      return http.get(HTTP_ENDPOINTS.joyaIntegration.getJoyaIntegration());
    },
    {
      cacheTime: 0,
      staleTime: 0,
      select: (data) => data.data.data,
      ...options,
    },
  );
  return res;
};
