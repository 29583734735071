import React, { useState } from "react";
import { Box } from "../../../../../shared/Box/Box";
import classes from "./CosmeticCreateEdit.module.scss";
import { Info } from "./shared/Info/Info";
import { FrontImage } from "./shared/FrontImage/FrontImage";
import { Images45 } from "./shared/Images45/Images45";
import { Images90 } from "./shared/Images90/Images90";
import { AfterFrontImage } from "./shared/AfterFrontImage/AfterFrontImage";
import { AfterImages45 } from "./shared/AfterImages45/AfterImages45";
import { AfterImages90 } from "./shared/AfterImages90/AfterImages90";
import { Documents } from "../../shared/Documents/Documents";
import Footer from "../../shared/Footer/Footer";
import { useForm } from "./hooks/form/useForm";
import { useSubmit } from "./hooks/useSubmit";
import { getCosmeticPathParams } from "./CosmeticCreateEdit.utils";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Header } from "../../shared/Header/Header";
import { PROCEDURE_AREA } from "./shared/Info/Info.consts";
import { BackImages } from "./shared/BackImages/BackImages";
import { AfterBackImages } from "./shared/AfterBackImages/AfterBackImages";
import { useDeleteProcedure } from "../../hooks/useDeleteProcedure";
import { LayoutMain } from "../../../../../boxes/LayoutMain/LayoutMain";
import { uiNotification } from "../../../../../services/UINotificationService";
import { Lock } from "../../shared/Lock";

export function CosmeticCreateEdit() {
  const { tClients } = useAppTranslation.Clients();
  const { isEditMode, clientId, procedureId } = getCosmeticPathParams();
  const [isImagesUploading, setIsImagesUploading] = useState(false);

  const submit = useSubmit();
  const del = useDeleteProcedure(procedureId);
  const form = useForm(submit.initiate);

  const [isSubmittingDefault, setIsSubmittingDefault] = useState(false);
  const [isSubmittingWithFill, setIsSubmittingWithFill] = useState(false);
  const [isSubmittingWithSign, setIsSubmittingWithSign] = useState(false);

  const submitWrapper = (fn) => () => {
    if (isImagesUploading) {
      uiNotification.warn(
        tClients("createEditCosmeticProcedure.warn.uploadingImages"),
      );
    } else {
      fn();
    }
  };

  const onSubmitDefault = submitWrapper(() => {
    setIsSubmittingDefault(true);
    form.handleSubmit().finally(() => {
      setIsSubmittingDefault(false);
    });
  });

  const onSubmitWithFill = submitWrapper(() => {
    setIsSubmittingWithFill(true);
    form.handleSubmit({ redirectToFillQuestionnaires: true }).finally(() => {
      setIsSubmittingWithFill(false);
    });
  });

  const onSubmitWithSign = submitWrapper(() => {
    setIsSubmittingWithSign(true);
    form.handleSubmit({ redirectToSignConsents: true }).finally(() => {
      setIsSubmittingWithSign(false);
    });
  });

  const additionFooterButtons = [
    {
      label: tClients("createEditCosmeticProcedure.submitWithQuestionnaires"),
      onClick: onSubmitWithFill,
      isLoading: isSubmittingWithFill,
      isDisabled: submit.isLoading,
      isVisible: form.questionnaireIds.value.length > 0,
    },
    {
      label: tClients("createEditCosmeticProcedure.submitWithConsents"),
      onClick: onSubmitWithSign,
      isLoading: isSubmittingWithSign,
      isDisabled: submit.isLoading,
      isVisible: form.consentIds.value.length > 0,
    },
  ];

  return (
    <Lock procedureId={procedureId}>
      <LayoutMain>
        <Box className={classes.root}>
          <Header clientId={clientId}>
            {isEditMode
              ? tClients("createEditCosmeticProcedure.editLabel")
              : tClients("createEditCosmeticProcedure.createLabel")}
          </Header>
          <Info
            appointmentId={form.appointmentId}
            serviceId={form.serviceId}
            providerId={form.providerId}
            clinicId={form.clinicId}
            procedureArea={form.procedureArea}
            procedureName={form.procedureName}
            procedureDate={form.procedureDate}
            isLoading={form.isLoading}
            type={form.type}
          />
          <FrontImage
            image={form.frontImage}
            isLoading={form.isLoading}
            setProfileImage={form.patientImage.onChange}
            procedureArea={form.procedureArea.value}
            onUploadingChange={setIsImagesUploading}
          />
          <Images45
            procedureImage={form.image45}
            leftImage={form.image45Left}
            rightImage={form.image45Right}
            isLoading={form.isLoading}
            procedureArea={form.procedureArea.value}
            onUploadingChange={setIsImagesUploading}
          />
          {form.procedureArea.value !== PROCEDURE_AREA.laser && (
            <Images90
              procedureImage={form.image90}
              leftImage={form.image90Left}
              rightImage={form.image90Right}
              isLoading={form.isLoading}
              procedureArea={form.procedureArea.value}
              onUploadingChange={setIsImagesUploading}
            />
          )}
          {form.procedureArea.value === PROCEDURE_AREA.coolSculpting && (
            <BackImages
              backImage={form.backImage}
              leftImage={form.back45LeftImage}
              rightImage={form.back45RightImage}
              onUploadingChange={setIsImagesUploading}
            />
          )}
          <AfterFrontImage
            image={form.afterFrontImage}
            isLoading={form.isLoading}
            onUploadingChange={setIsImagesUploading}
          />
          <AfterImages45
            leftImage={form.afterImage45Left}
            rightImage={form.afterImage45Right}
            isLoading={form.isLoading}
            onUploadingChange={setIsImagesUploading}
          />
          {form.procedureArea.value !== PROCEDURE_AREA.laser && (
            <AfterImages90
              leftImage={form.afterImage90Left}
              rightImage={form.afterImage90Right}
              isLoading={form.isLoading}
              onUploadingChange={setIsImagesUploading}
            />
          )}
          {form.procedureArea.value === PROCEDURE_AREA.coolSculpting && (
            <AfterBackImages
              backImage={form.afterBackImage}
              leftImage={form.afterBack45LeftImage}
              rightImage={form.afterBack45RightImage}
              onUploadingChange={setIsImagesUploading}
            />
          )}
          <Documents
            label={tClients("createEditCosmeticProcedure.gallery.label")}
            appointmentId={form.appointmentId.value}
            procedureId={procedureId}
            serviceId={form.serviceId.value}
            questionnaireIds={form.questionnaireIds}
            consentIds={form.consentIds}
            isLoading={form.isLoading}
          />
          <Footer
            onSubmit={onSubmitDefault}
            onDelete={del.initiate}
            isSubmitting={isSubmittingDefault}
            isDeleting={del.isLoading}
            isFormError={form.isError}
            isLoading={form.isLoading}
            isEditMode={isEditMode}
            submitLabel={tClients("createEditCosmeticProcedure.submit")}
            additionButtons={additionFooterButtons}
          />
        </Box>
      </LayoutMain>
    </Lock>
  );
}
