import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useJoyaRedemptionReport = (params, options = {}) => {
  const res = useQuery(
    ["joyaRedemptionReport", params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getJoyaRedemptionReport(), {
        params,
      });
      const composeResponse = {
        clinics: res.data.data.clinics,
        reportData: res.data.data.report_data,
      };
      return composeResponse;
    },
    { ...options, cacheTime: 0 },
  );

  return res;
};
