import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import cx from "clsx";
import VIEW_ICON from "../../../Clients/Documents/icons/view.svg";
import TIMER_ICON from "../../../Clients/Documents/icons/TimerIcon";
import { checkIfPermissionAllowed } from "../../../../Utils";
import classes from "../scss/expirationActionButtons.module.scss";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useDeleteClientDocuments } from "./hooks/useDeleteClientDocuments";
import ExpirationModal from "../../../Clients/Documents/components/ExpirationModal";
import LockProcedure from "../../../Clients/Timeline/LockProcedure";

const ExpirationActionButtons = ({
  document,
  history,
  refetchData,
  setSelectedDocument,
}) => {
  const canEdit = checkIfPermissionAllowed("add-update-documents");
  const canView = checkIfPermissionAllowed("view-documents");
  const canBeEdited = canEdit && document.type === "file";
  const canBeLocked = document.is_lock && document.type === "procedure";

  const [deleteDocument, setDeteleDocument] = useState(null);
  const [expirationDocument, setExpirationDocument] = useState(null);

  const { mutate: submitDeleteDocument, isLoading: isDeleting } =
    useDeleteClientDocuments(deleteDocument, {
      onSuccess: () => {
        refetchData();
        setDeteleDocument(null);
      },
    });

  const redirectTo = (path) => {
    history.push(path);
  };

  const handleView = () => {
    if (!document.is_lock) {
      setSelectedDocument(document);
    }
  };

  const getDaysUntilExpiration = (expirationDate) => {
    return moment(expirationDate).diff(moment(), "days");
  };

  const getTimerColor = (document) => {
    const daysUntilExpiration = getDaysUntilExpiration(
      document.doc_expire_date,
    );
    const isAcknowledged = !!document.acknowledged_at;

    if (daysUntilExpiration <= 30 && !isAcknowledged) {
      return "red";
    }

    return "#217acd";
  };

  return (
    <div className="flex justify-center gap-8">
      {canView &&
        (!canBeLocked ? (
          <>
            <button
              className={cx(
                "header-unselect-btn",
                "modal-link",
                classes.viewIcon,
              )}
              onClick={handleView}
            >
              <img
                src={VIEW_ICON}
                className="cursor-pointer min-w-16"
                title="View"
                width={16}
                height={16}
                alt=""
              />
            </button>
            <button
              className="min-w-16 m-t-5"
              onClick={() => setExpirationDocument(document)}
              title="Expiration Timer"
            >
              <TIMER_ICON
                width="16px"
                height="22px"
                color={getTimerColor(document)}
              />
            </button>
          </>
        ) : (
          <LockProcedure
            isdocuments
            isLocked={document.is_lock}
            procedureId={document.parent_id}
            refetchProcedures={refetchData}
          />
        ))}
      {canBeEdited && (
        <>
          <button
            className="header-unselect-btn setting modal-link color-dark-blue min-w-16"
            onClick={() =>
              redirectTo(
                `/clients/documents/edit/${document.object_id}/${document.patient_id}/profile`,
              )
            }
            title="Edit"
          >
            <i className="fa fa-pencil-alt m-r-5" />
          </button>
          <button
            className="header-unselect-btn setting modal-link min-w-16"
            onClick={() => setDeteleDocument(document.object_id)}
            title="Delete"
          >
            <i className="fa fa-trash-alt m-r-5 m-t-2 color-dark-blue" />
          </button>
        </>
      )}
      {deleteDocument && (
        <ConfirmModal
          isOpen
          onConfirm={submitDeleteDocument}
          onCancel={() => setDeteleDocument(null)}
          isConfirming={isDeleting}
        >
          Are you sure you want to delete this document?
        </ConfirmModal>
      )}
      {expirationDocument && (
        <ExpirationModal
          isOpen
          document={{
            date: document.date,
            name: document.doc_expire_name,
            type: document.type,
            documentId: document.object_id,
            expirationDate: document.doc_expire_date,
            label: document.doc_expire_type,
            parentID: document.parent_id,
            subType: document.sub_type,
          }}
          onClose={() => setExpirationDocument(null)}
          patientId={parseInt(document.patient_id)}
          patientName={document.doc_expire_patient}
          refetchDocuments={refetchData}
        />
      )}
    </div>
  );
};

export default withRouter(ExpirationActionButtons);
