import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { QUERY_KEYS, HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const useMembershipSales = (params, options = {}) => {
  return useQuery(
    [QUERY_KEYS.membershipSales, params],
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getBiMembershipSales(), {
        params,
      });

      const composeResponse = {
        clinics: res.data.data.clinics,
        clinicMembershipSales: res.data.data.clinic_membership_sales,
        providerMembershipSales: res.data.data.provider_membership_sales,
      };
      return composeResponse;
    },
    options,
  );
};
