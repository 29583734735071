import axios from "axios";
import { SERVER_MESSAGE_KEYS } from "../consts/api";
import { handleInvalidToken, setConfigData } from "../Utils/services";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";
import { ROUTES } from "../consts/routes";
import { history } from "../history";

// Due to legacy requirements we need to dispatch actions from here
// eslint-disable-next-line
import { dispatch } from "../store/store";
import { uiNotification } from "./UINotificationService";
import { withAccessTokenHeaders } from "../utilities/auth";

export const DO_NOT_REDIRECT_TO_LOGIN_FROM = ["/change-password"];

export class HttpService {
  constructor(axiosInstance) {
    this.repository = axiosInstance;
  }

  async call({ url, method, data, params }) {
    return this.repository({
      url,
      method,
      data,
      params,
    });
  }

  async get(url, config) {
    return this.repository.get(url, config);
  }

  async post(url, data, config) {
    return this.repository.post(url, data, config);
  }

  async put(url, data, config) {
    return this.repository.put(url, data, config);
  }

  async patch(url, data, config) {
    return this.repository.patch(url, data, config);
  }

  async delete(url, config) {
    return this.repository.delete(url, config);
  }
}

/**
 * ======================================
 */

export const requestInterceptors = {
  success: withAccessTokenHeaders,
  error: function (error) {
    return Promise.reject(error);
  },
};

export const responseInterceptors = {
  success: function (response) {
    uiNotification.setBlockErrors(false);
    const globalSettings = response?.data?.global_settings;

    if (globalSettings) {
      setConfigData(globalSettings);
    }

    // TODO: Needs to be removed
    if (globalSettings?.pos_data?.account_status) {
      dispatch({
        type: "SET_ACCOUNT_STATUS",
        payload: globalSettings.pos_data.account_status,
      });
    }

    // TODO: Needs to be removed. BE should not send access_token in headers
    if (response?.headers?.access_token) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.accessToken,
        response.headers.access_token,
      );
    }

    return response;
  },
  error: function (error) {
    if (error.response) {
      const data = error.response.data?.data || {};
      const msg = error.response.data?.message || "";

      if (msg === SERVER_MESSAGE_KEYS.passwordExpired) {
        uiNotification.setBlockErrors(true);
        const wpuId = data.wpuId;

        if (wpuId) {
          handleInvalidToken(false);
          history.replace(
            ROUTES.changePassword({
              wpuId,
              message: true,
              require_passcode: true,
            }),
          );
        } else {
          handleInvalidToken();
        }
      } else if (
        [
          SERVER_MESSAGE_KEYS.invalidToken,
          SERVER_MESSAGE_KEYS.sessionTimeout,
          SERVER_MESSAGE_KEYS.tokenNotFound,
        ].includes(msg) &&
        !DO_NOT_REDIRECT_TO_LOGIN_FROM.find(
          (x) =>
            window.location.pathname.startsWith(x) ||
            history.location.pathname.startsWith(x),
        )
      ) {
        handleInvalidToken();
      }
    }

    return Promise.reject(error);
  },
};

/**
 * ======================================
 */

export const http = (() => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(
    requestInterceptors.success,
    requestInterceptors.error,
  );

  axiosInstance.interceptors.response.use(
    responseInterceptors.success,
    responseInterceptors.error,
  );

  return new HttpService(axiosInstance);
})();
