import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";
import { benefitsLabels } from "../Joya.consts";

export const useJoyaMapping = (options = {}) => {
  const res = useQuery(
    ["joyaMapping"],
    async () => {
      return http.get(HTTP_ENDPOINTS.joyaIntegration.getJoyaMapping());
    },
    {
      cacheTime: 0,
      select: (data) => data.data.data,
      ...options,
    },
  );

  const onlineBooking = res?.data?.onlineBooking;

  const benefitsMapping = useMemo(
    () =>
      res?.data?.benefitsMapping?.map((benefit) => ({
        benefitId: benefit.benefitId,
        benefitLabel: benefitsLabels[benefit.benefitId],
        mappingType: benefit.mappingType,
        config: benefit.config,
        mapping: benefit.mapping,
        multipleSelect: benefit.selectMany,
        options:
          res?.data?.options?.[benefit.mappingType]?.map((options) => ({
            label: options.product_name,
            value: options.id,
          })) || null,
      })),
    [res],
  );

  return { benefitsMapping, onlineBooking, isGettingData: res.isLoading };
};
