import { http } from "../services/HttpService";

export function doAutoLogin(cipeher) {
  return (dispatch) => {
    http
      .get("switch-to-new/" + cipeher)
      .then((response) => {
        dispatch({ type: "DO_AUTO_LOGIN", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "DO_AUTO_LOGIN", payload: error.response.data });
        }
      });
  };
}
