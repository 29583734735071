import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useSetJoyaMapping = (options = {}) => {
  const res = useMutation(
    (dto) => http.patch(HTTP_ENDPOINTS.joyaIntegration.setJoyaMapping(), dto),
    { ...options },
  );
  return res;
};
