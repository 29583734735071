import { getAccountId } from "../utilities/localStorage";

export const isShopifyAvailable = () => {
  const accountId = Number(getAccountId());
  return [
    1300, 1650, 4041, 2703, 10299, 4328, 8062, 8941, 3580, 8941, 7473, 3191,
    7530, 10984,
  ].includes(accountId);
};

export const isDobForBookingAvailable = () => {
  const accountId = Number(getAccountId());
  return [1300, 9374, 16997, 16998].includes(accountId);
};

export const isAutoLogoutAvailable = () => {
  const accountId = Number(getAccountId());
  return [1300, 9374, 16997, 16998, 20709, 23421, 13462].includes(accountId);
};
