import * as yup from "yup";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { getCosmeticPathParams } from "../../CosmeticCreateEdit.utils";
import { useExtendedFormik } from "../../../../../../../hooks/useExtendedFormik";
import {
  extractDocumentIdsFromOptions,
  getNextServiceOptionOnApptChange,
  prepareSubmitValues,
  getNextDocumentsFromServiceOption,
  initDedupForProcedureDocuments,
  filterNextRequiredDocuments,
} from "../../../utilities";
import { DOC_DEDUP_TYPE } from "../../../../../../../store/patients/mutateProcedure/config";
import { validateImages } from "./utilities";
import { useInitialValues } from "./useInitialValues";
import { useUploadTemplateImages } from "./useUploadTemplateImages";
import { useClientQuery } from "../../../../../../../api/queries/useClientQuery";
import { PROCEDURE_AREA } from "../../shared/Info/Info.consts";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";
import { UPLOAD_MEDIA_TYPE } from "../../../../../../../api/mutations/useUploadMediaMutation";
import { fileUtil } from "../../../../../../../utilities/file";
import { TEMPLATE_IMAGE_NAMES } from "./config";
import { IMAGE_COMPRESS_WIDTH } from "../../CosmeticCreateEdit.consts";

export function useForm(onSubmit) {
  const { tClients } = useAppTranslation.Clients();
  const { isEditMode, clientId } = getCosmeticPathParams();
  const { data: client } = useClientQuery({ clientId });

  const profileImageUploader = useImageUploader({
    uploadType: UPLOAD_MEDIA_TYPE.patientProfilePhoto,
    compress: {
      width: IMAGE_COMPRESS_WIDTH,
    },
  });

  const initialValues = useInitialValues();
  const uploadTemplateImages = useUploadTemplateImages();

  const requiredFields = [
    "serviceId",
    "providerId",
    "clinicId",
    "procedureArea",
    "procedureName",
  ];

  const schema = yup.object({
    appointmentId: yup.number().nullable(),

    serviceId: yup
      .number()
      .when("appointmentId", {
        is: Boolean,
        then: (schema) =>
          schema.required(
            tClients("createEditCosmeticProcedure.formError.serviceRequired"),
          ),
      })
      .nullable(),

    providerId: yup
      .number()
      .nullable()
      .required(
        tClients("createEditCosmeticProcedure.formError.providerRequired"),
      ),

    clinicId: yup
      .number()
      .nullable()
      .required(
        tClients("createEditCosmeticProcedure.formError.clinicRequired"),
      ),

    procedureArea: yup
      .string()
      .nullable()
      .required(
        tClients("createEditCosmeticProcedure.formError.procedureAreaRequired"),
      ),

    procedureName: yup.lazy(() => {
      const schema = yup.string();

      if (isEditMode) {
        return schema.required(
          tClients(
            "createEditCosmeticProcedure.formError.procedureNameRequired",
          ),
        );
      }

      return schema.nullable();
    }),

    procedureDate: yup.date().nullable(),

    frontImage: yup.string().nullable(),

    image45: yup.string().nullable(),

    image45Left: yup.string().nullable(),

    image45Right: yup.string().nullable(),

    image90: yup.string().nullable(),

    image90Left: yup.string().nullable(),

    image90Right: yup.string().nullable(),

    afterFrontImage: yup.string().nullable(),

    afterImage45Left: yup.string().nullable(),

    afterImage45Right: yup.string().nullable(),

    afterImage90Left: yup.string().nullable(),

    afterImage90Right: yup.string().nullable(),

    backImage: yup.string().nullable(),

    back45LeftImage: yup.string().nullable(),

    back45RightImage: yup.string().nullable(),

    afterBackImage: yup.string().nullable(),

    afterBack45LeftImage: yup.string().nullable(),

    afterBack45RightImage: yup.string().nullable(),

    questionnaireIds: yup.array().of(yup.string()),

    consentIds: yup.array().of(yup.string()),

    type: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),

    nonDeletableConsentIds: yup.array().of(yup.string()),

    nonDeletableQuestionnaireIds: yup.array().of(yup.string()),

    patientImage: yup.string().nullable(),

    skinCareCheck: yup.boolean().nullable(),
  });

  const form = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: initialValues.data,
    onSubmit,
  });

  const initDedup = (ids, type) => {
    if (isEditMode) {
      initDedupForProcedureDocuments(ids, type);
    }
  };

  const setServiceId = (option) => {
    const res = getNextDocumentsFromServiceOption({
      option,
      selectedConsentIds: form.values.consentIds,
      selectedQuestionnaireIds: form.values.questionnaireIds,
      nonDeletableConsentIds: form.values.nonDeletableConsentIds,
      nonDeletableQuestionnaireIds: form.values.nonDeletableQuestionnaireIds,
      appendMode: isEditMode,
    });

    form.setFieldValue("serviceId", res.nextServiceId);
    form.setFieldValue("consentIds", res.nextConsentIds);
    form.setFieldValue("questionnaireIds", res.nextQuestionnaireIds);
    form.setFieldValue(
      "nonDeletableQuestionnaireIds",
      res.nextNonDeletableQuestionnaireIds,
    );
    form.setFieldValue(
      "nonDeletableConsentIds",
      res.nextNonDeletableConsentIds,
    );

    initDedup(res.nextQuestionnaireIds, DOC_DEDUP_TYPE.questionnaire);
    initDedup(res.nextConsentIds, DOC_DEDUP_TYPE.consent);
  };

  const submit = async ({
    redirectToFillQuestionnaires,
    redirectToSignConsents,
  } = {}) => {
    form.setFormTouched();
    const errors = await form.validateForm(form.values);

    if (Object.values(errors).length === 0) {
      let patientImage = form.values.patientImage;
      const templateImages = await uploadTemplateImages.initiate(form.values);

      if (!templateImages.isCompleted) {
        const imageError = validateImages(form.values);

        if (imageError) {
          return uiNotification.error(tClients(imageError));
        }
      }

      if (
        form.values.procedureArea === PROCEDURE_AREA.face &&
        !client.profileImageUrl &&
        !patientImage &&
        !isEditMode
      ) {
        const dataUrl = await fileUtil.readFromResource(
          uploadTemplateImages.buildImageUrl(
            PROCEDURE_AREA.face,
            TEMPLATE_IMAGE_NAMES.front,
          ),
          { method: "dataUrl" },
        );

        const file = fileUtil.dataUrlToFile(dataUrl, "profileImage.jpg");

        const res = await profileImageUploader.initiate(file);
        patientImage = res?.uploadedFileName;
      }

      return onSubmit({
        form: prepareSubmitValues({
          ...form.values,
          frontImage: form.values.frontImage || templateImages.front,
          image45Right: form.values.image45Right || templateImages.right,
          image45Left: form.values.image45Left || templateImages.left,
          patientImage,
        }),
        redirectToFillQuestionnaires,
        redirectToSignConsents,
      });
    }
  };

  return useMemo(
    () => ({
      handleSubmit: submit,

      isError: requiredFields.some((f) => Boolean(form.getError(f))),

      isLoading: initialValues.isLoading,

      appointmentId: {
        value: form.values.appointmentId,
        onChange: (option, serviceOptions = []) => {
          const nextServiceOption = getNextServiceOptionOnApptChange(
            option,
            serviceOptions,
          );

          form.setFieldValue("appointmentId", option?.value || null);
          setServiceId(nextServiceOption);
          if (option) {
            form.setFieldValue("providerId", option.providerId);
            form.setFieldValue("clinicId", option.clinicId);
          }
        },
      },

      serviceId: {
        value: form.values.serviceId,
        onChange: setServiceId,
        getError: () => form.getError("serviceId"),
      },

      providerId: {
        value: form.values.providerId,
        onChange: (option) => {
          form.setFieldValue("providerId", option?.value || null);
        },
        getError: () => form.getError("providerId"),
      },

      clinicId: {
        value: form.values.clinicId,
        onChange: (option) => {
          form.setFieldValue("clinicId", option?.value || null);
        },
        validateWithProviderClinics: (providerClinicOptions = []) => {
          if (
            !providerClinicOptions.find((o) => o.value === form.values.clinicId)
          ) {
            form.setFieldValue("clinicId", null);
          }
        },
        getError: () => form.getError("clinicId"),
      },

      procedureArea: {
        value: form.values.procedureArea,
        onChange: (option) => {
          form.setFieldValue("procedureArea", option?.value || null);
        },
        getError: () => form.getError("procedureArea"),
      },

      procedureName: {
        value: form.values.procedureName,
        onChange: (next) => form.setFieldValue("procedureName", next),
        getError: () => form.getError("procedureName"),
      },

      procedureDate: {
        value: form.values.procedureDate,
        onChange: (next) => form.setFieldValue("procedureDate", next),
      },

      frontImage: {
        value: form.values.frontImage,
        onChange: (next) => form.setFieldValue("frontImage", next),
      },

      image45: {
        value: form.values.image45,
        onChange: (next) => form.setFieldValue("image45", next),
      },

      image45Left: {
        value: form.values.image45Left,
        onChange: (next) => form.setFieldValue("image45Left", next),
      },

      image45Right: {
        value: form.values.image45Right,
        onChange: (next) => form.setFieldValue("image45Right", next),
      },

      image90: {
        value: form.values.image90,
        onChange: (next) => form.setFieldValue("image90", next),
      },

      image90Left: {
        value: form.values.image90Left,
        onChange: (next) => form.setFieldValue("image90Left", next),
      },

      image90Right: {
        value: form.values.image90Right,
        onChange: (next) => form.setFieldValue("image90Right", next),
      },

      afterFrontImage: {
        value: form.values.afterFrontImage,
        onChange: (next) => form.setFieldValue("afterFrontImage", next),
      },

      afterImage45Left: {
        value: form.values.afterImage45Left,
        onChange: (next) => form.setFieldValue("afterImage45Left", next),
      },

      afterImage45Right: {
        value: form.values.afterImage45Right,
        onChange: (next) => form.setFieldValue("afterImage45Right", next),
      },

      afterImage90Left: {
        value: form.values.afterImage90Left,
        onChange: (next) => form.setFieldValue("afterImage90Left", next),
      },

      afterImage90Right: {
        value: form.values.afterImage90Right,
        onChange: (next) => form.setFieldValue("afterImage90Right", next),
      },

      backImage: {
        value: form.values.backImage,
        onChange: (next) => form.setFieldValue("backImage", next),
      },

      back45LeftImage: {
        value: form.values.back45LeftImage,
        onChange: (next) => form.setFieldValue("back45LeftImage", next),
      },

      back45RightImage: {
        value: form.values.back45RightImage,
        onChange: (next) => form.setFieldValue("back45RightImage", next),
      },

      afterBackImage: {
        value: form.values.afterBackImage,
        onChange: (next) => form.setFieldValue("afterBackImage", next),
      },

      afterBack45LeftImage: {
        value: form.values.afterBack45LeftImage,
        onChange: (next) => form.setFieldValue("afterBack45LeftImage", next),
      },

      afterBack45RightImage: {
        value: form.values.afterBack45RightImage,
        onChange: (next) => form.setFieldValue("afterBack45RightImage", next),
      },

      questionnaireIds: {
        value: form.values.questionnaireIds,
        onChange: (options) => {
          form.setFieldValue(
            "questionnaireIds",
            extractDocumentIdsFromOptions(options),
          );
        },
        appendAsNonDeletable: (values) => {
          const filteredValues = filterNextRequiredDocuments(
            values,
            form.values.questionnaireIds,
          );

          const res = getNextDocumentsFromServiceOption({
            option: {
              value: form.values.serviceId,
              questionnaireIds: filteredValues.map((v) => v.templateId),
              consentIds: [],
            },
            selectedConsentIds: [],
            selectedQuestionnaireIds: form.values.questionnaireIds,
            nonDeletableConsentIds: [],
            nonDeletableQuestionnaireIds:
              form.values.nonDeletableQuestionnaireIds,
            appendMode: isEditMode,
          });

          form.setFieldValue("questionnaireIds", res.nextQuestionnaireIds);
          form.setFieldValue(
            "nonDeletableQuestionnaireIds",
            res.nextNonDeletableQuestionnaireIds,
          );

          initDedup(res.nextQuestionnaireIds, DOC_DEDUP_TYPE.questionnaire);
        },
        replaceRawIds: (ids) => {
          form.setFieldValue("questionnaireIds", ids);
        },
        isDeletable: (id) =>
          !form.values.nonDeletableQuestionnaireIds.includes(String(id)),
      },

      consentIds: {
        value: form.values.consentIds,
        onChange: (options) => {
          form.setFieldValue(
            "consentIds",
            extractDocumentIdsFromOptions(options),
          );
        },
        appendAsNonDeletable: (values) => {
          const filteredValues = filterNextRequiredDocuments(
            values,
            form.values.consentIds,
          );

          const res = getNextDocumentsFromServiceOption({
            option: {
              value: form.values.serviceId,
              questionnaireIds: [],
              consentIds: filteredValues.map((v) => v.templateId),
            },
            selectedConsentIds: form.values.consentIds,
            selectedQuestionnaireIds: [],
            nonDeletableConsentIds: form.values.nonDeletableConsentIds,
            nonDeletableQuestionnaireIds: [],
            appendMode: isEditMode,
          });

          form.setFieldValue("consentIds", res.nextConsentIds);
          form.setFieldValue(
            "nonDeletableConsentIds",
            res.nextNonDeletableConsentIds,
          );

          initDedup(res.nextConsentIds, DOC_DEDUP_TYPE.consent);
        },
        replaceRawIds: (ids) => {
          form.setFieldValue("consentIds", ids);
        },
        isDeletable: (id) =>
          !form.values.nonDeletableConsentIds.includes(String(id)),
      },

      type: {
        value: form.values.type,
        onChange: (next) => form.setFieldValue("type", next),
      },

      patientImage: {
        onChange: (next) => form.setFieldValue("patientImage", next),
      },

      skinCareCheck: {
        value: form.values.skinCareCheck,
        onChange: (next) => form.setFieldValue("skinCareCheck", next),
      },
    }),
    [
      form.handleSubmit,
      form.values,
      initialValues.isLoading,
      form.errors,
      form.touched,
    ],
  );
}
