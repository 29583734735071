import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import cx from "clsx";
import { AGREEMENT_TEXTS } from "../../consts/agreements";

export default class TermsOfService extends React.Component {
  handleAccept = () => {
    if (this.props.value && !this.props.processingLoader) {
      this.props.handleChildChange({ isHppaPolicyAccepted: true });
      this.props.toggleSignUpForm(this.props.nextStep, true);
    }
  };

  render() {
    return (
      <div className="login-main-policy">
        <div className="policy-info">
          {this.props.signUpLabel != undefined ? (
            <div className="col-sm-12">
              <div className="login-title">{this.props.signUpLabel}</div>
            </div>
          ) : null}
          <div className="col-sm-12 hippa-section">
            <div className="member-section">
              <div className="member-section-title no-margin">
                {this.props.globalLang &&
                  this.props.globalLang
                    .signup_aesthetic_record_terms_of_service}
              </div>
              <div className="term-policy-div">
                <Scrollbars style={{ height: 370 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: AGREEMENT_TEXTS.termsOfService,
                    }}
                  ></div>
                </Scrollbars>
              </div>
              <div className="member-section-footer">
                <label htmlFor="agree_checkbox">
                  <input
                    type="checkbox"
                    name={this.props.name}
                    checked={this.props.value ? "checked" : false}
                    onChange={this.props.handleInputChange}
                  />{" "}
                  {this.props.globalLang &&
                    this.props.globalLang
                      .signup_accept_aesthetic_record_terms_of_service}
                </label>
                <button
                  disabled={this.props.processingLoader || !this.props.value}
                  className={cx("agree-btn", {
                    ["agree-btn-loader"]: this.props.processingLoader,
                    ["disable"]: !this.props.value,
                  })}
                  onClick={this.handleAccept}
                >
                  {this.props.processingLoader
                    ? this.props?.globalLang?.signup_please_wait
                    : this.props?.globalLang?.signup_button_i_agree}{" "}
                  <img
                    alt=""
                    className={this.props.processingLoader ? "" : "no-display"}
                    src="/images/btn-load.gif"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
