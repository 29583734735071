import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { AGREEMENT_TEXTS } from "../../consts/agreements";
import { AppTranslation } from "../../i18n/useAppTranslation";

export default class BbaPolicy extends React.Component {
  handleAccept = () => {
    this.props.handleChildChange({ isBbaPolicyAccepted: true });
    this.props.toggleSignUpForm(this.props.nextStep);
  };

  render() {
    return (
      <div className="login-main-policy">
        <div className="policy-info">
          {this.props.signUpLabel != undefined ? (
            <div className="col-sm-12">
              <div className="login-title">{this.props.signUpLabel}</div>
            </div>
          ) : null}
          <div className="col-sm-12 hippa-section">
            <div className="member-section">
              <div className="member-section-title no-margin">
                <AppTranslation.Common path="label.baaAgreementTitile" />
              </div>
              <div className="term-policy-div">
                <Scrollbars style={{ height: 370 }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: AGREEMENT_TEXTS.baa }}
                  ></div>
                </Scrollbars>
              </div>
              <div className="member-section-footer">
                <button
                  type="button"
                  className="agree-btn"
                  onClick={this.handleAccept}
                >
                  {this.props.globalLang &&
                    this.props.globalLang.signup_button_accept}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
