import * as yup from "yup";
import { useEffect, useMemo } from "react";
import { usePatientByPatternQuery } from "../../../api/queries/usePatientByPatternQuery";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import { getPathParams } from "../Event.utils";

const schema = yup.array().of(
  yup.object({
    id: yup.number(),
    fullName: yup.string(),
    email: yup.string(),
    phone: yup.string(),
    pinCode: yup.string(),
    doNotEmail: yup.boolean(),
    doNotSms: yup.boolean(),
    dateOfBirth: yup.string(),
  }),
);

const compose = (response) => {
  let result = [];
  if (response && Array.isArray(response.data.data)) {
    result = response.data.data.map((client) => ({
      id: client.id,
      fullName: client.full_name,
      email:
        [client.email, client.email_2, client.email_3, client.email_4]
          .filter(Boolean)
          .find(Boolean) || "",
      phone:
        [
          client.phoneNumber,
          client.phoneNumber_2,
          client.phoneNumber_3,
          client.phoneNumber_4,
        ]
          .filter(Boolean)
          .find(Boolean) || "",
      pinCode: client.pincode || "",
      doNotEmail: Boolean(client.do_not_email),
      doNotSms: Boolean(client.do_not_sms),
      dateOfBirth: client.date_of_birth || "",
    }));
  }
  return result;
};

export function useApiFindClient({ name }) {
  const { leadId } = getPathParams();

  const [searchName, debouncedSearchName, setSearchName] = useDebouncedState(
    name || "",
  );

  const { data, isFetching } = usePatientByPatternQuery(
    { pattern: debouncedSearchName },
    {
      staleTime: 0,
      enabled: Boolean(debouncedSearchName) && !leadId,
    },
  );

  const findClientList = useMemo(
    () =>
      schema.validateSync(compose(data), {
        strict: true,
      }),
    [data],
  );

  useEffect(() => {
    if (searchName !== name) {
      setSearchName(name);
    }
  }, [name, searchName]);

  return {
    findClientList,
    isClientsSearching: isFetching,
  };
}
