const BAA = `<p><b>Aesthetic Record Business Associate Agreement</b></p>
<p>This Business Associate Agreement ("Agreement") is made and entered into by and between Aesthetic Record LLC ("Aesthetic Record," "we," "us," or "our"), a provider of software and technology solutions, and an Aesthetic Record Subscribed Client ("Covered Entity," "you," or "your").</p>
<p>This Agreement outlines our obligations concerning the privacy and security of Protected Health Information ("PHI") in accordance with the Health Insurance Portability and Accountability Act of 1996 ("HIPAA") and its implementing regulations, including 45 CFR Parts 160 and 164, as amended from time to time ("Privacy Rules and Security Rules"), and certain provisions of the Health Information Technology for Economic and Clinical Health Act ("HITECH") and its implementing regulations. This Agreement is required by 45 CFR 164.504 and shall be construed in compliance with HIPAA, HITECH, and their related regulations.</p>
<p><b>Definitions</b></p>
<p>For the purposes of this Agreement:</p>
  <b>•	Protected Health Information (PHI):</b> Information in any format created, received, maintained, or transmitted by us on your behalf that relates to an individual's past, present, or future physical or mental health condition, the provision of healthcare, or payment for healthcare and that identifies or can reasonably be used to identify an individual.<br />
  <b>•	Electronic PHI (ePHI):</b> PHI that is transmitted or maintained in electronic media.<br />
  <b>•	Breach:</b> The acquisition, access, use, or disclosure of PHI in a manner not permitted under HIPAA Privacy Rules that compromises the security or privacy of such information, potentially causing financial, reputational, or other harm to an individual.<br />
<p><b>Obligations of Aesthetic Record</b></p>
1.	<b>Use and Disclosure of PHI:</b> We will not use or disclose PHI except as permitted by this Agreement, required by law, or necessary for our management and administration or legal responsibilities.<br />
2.	<b>Safeguards:</b> We will implement administrative, physical, and technical safeguards in compliance with 45 CFR 164.308, 164.310, 164.312, and 164.316 to prevent unauthorized use or disclosure of PHI.<br />
3.	<b>Subcontractors and Agents:</b> Any subcontractor or agent to whom we provide PHI must agree to the same restrictions and conditions that apply to us under this Agreement.<br />
4.	<b>Reporting of Unauthorized Use or Disclosure:</b> We will report any unauthorized use or disclosure of PHI, including security incidents, to you.<br />
5.	<b>Breach Notification:</b> Upon discovering a potential Breach of PHI, we will:<br />
  o	Investigate and provide a written report within 48 hours detailing the nature and extent of the Breach.<br />
  o	Take necessary actions to mitigate harm and prevent further unauthorized disclosures.<br />
  o	Cooperate with you to facilitate investigations and fulfill notification obligations.<br />
6.	<b>Access and Amendment of PHI:</b> Upon your request, we will provide access to PHI and incorporate any requested amendments as required by HIPAA.<br />
7.	<b>Accounting of Disclosures:</b> We will maintain records and provide an accounting of disclosures of PHI upon request as long as the account is in active standing Within 30 days of termination, any client records are permanently deleted. It is the client’s responsibility to execute a data export either by contracting with Aesthetic Record or through manual means.<br />
8.	<b>HHS Compliance:</b> We will make our internal practices, books, and records relating to the use and disclosure of PHI available to the Secretary of Health and Human Services to determine compliance.<br />
9.	<b>Return or Destruction of PHI:</b> Upon termination of this Agreement, if feasible, we will return or destroy all PHI. If infeasible, we will extend the protections of this Agreement to the retained PHI and limit further use or disclosure.<br />
10.	<b>Indemnification:</b><br />
  o	We agree to indemnify, defend, and hold you harmless from any claims, liabilities, or damages resulting from a Breach caused by us, our agents, or subcontractors.<br />
  o	You agree to indemnify, defend, and hold us harmless from any claims, liabilities, or damages resulting from a Breach caused by you, your agents, or subcontractors.<br />
<br />
<p><b>Termination and Amendment</b></p>
  <b>•	Termination for Cause:</b> If we determine that you have violated a material term of this Agreement, we may terminate our service agreements with you.<br />
  <b>•	Amendments:</b> Given that HIPAA and related regulations may change, we agree to negotiate necessary amendments to this Agreement as required by law. If an amendment cannot be agreed upon within 30 days of notice, either party may terminate this Agreement.
<p></p>
<p>IN WITNESS WHEREOF, the parties have executed this Agreement as of the date of the subscription to Aesthetic Record’s services.</p>
`;

const termsOfService = `<p><b>Aesthetic Record Terms of Service</b></p>

<p><b>Introduction</b></p>
<p>Thank you for choosing Aesthetic Record (“we”, “us”, or “our”) for your business. Aesthetic Record provides online business management software and HIPAA-compliant EMR services for the aesthetics and beauty industry (“Software Service”). You can access our Software Service via the login page on our website and through our application.
<p>As used in this Aesthetic Record Terms of Service (“Agreement”), “you”, “your”, or “users” means your company (together with its agents, assigns, representatives, Affiliates, officers, directors, members, managers, principals, and employees) that access or use our Services. “Affiliate” means any entity that directly or indirectly controls, is controlled by, or is under common control with you, and that has been designated to receive Services under this Agreement. “Affiliate” also includes any of your business locations and any franchisees that have been designated to receive Services under this Agreement. For purposes of the definition of the term “Affiliate”, “control” means the power to direct or cause the direction of the management and policies of the subject entity, whether through equity ownership, a credit arrangement, franchise agreement or other contractual arrangement.</p>
<p>This Agreement applies to any use of and access to our Software Service, websites, tools, or applications (“Services”) by you or any of your Affiliates. These terms and the order confirmation that you received when you signed up for our Software Service (“Order Confirmation”), along with all policies and additional terms posted on and in our Services contains the terms and conditions that govern your access to and use of the Services and is an agreement between you and Aesthetic Record. By accessing or using our Services, you agree to be bound by this Agreement, including all policies referenced herein including but not limited to the <b>Acceptable Use Policy</b> and the <b>Privacy Policy</b>. If you do not wish to be bound by any of the terms of this Agreement, you may not use or access any Services. This Agreement is effective (“Effective Date”) on the earlier of (a) the date you accept this Agreement by clicking an “Accept” or similar button or otherwise indicate that you accept this Agreement (including through an Order Confirmation), or (b) the date you first access or use any Services.</p>
<p><b>Your Account</b></p>
<p>To access and use the Services, you must register for an Aesthetic Record account (“Account”) by providing your full legal name, job title, business or employer name, current business or employer address, current business or employer phone number, a valid business or employer- issued email address, and any other information as required by Aesthetic Record. We may reject your application for an Account for any reason in our sole discretion. If any of the above- listed registration information changes, you must notify Aesthetic Record within 48 hours of any change via electronic mail with return receipt requested to <a href="mailto:info@aestheticrecord.com">info@aestheticrecord.com</a>. Any personal data you provide to us will be handled in accordance with Aesthetic Record’s Privacy Policy.</p>
<p>You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.</p>
<p>You acknowledge that the person signing up for the Service by opening an Account will be the contracting party (“Account Owner”) for the purposes of this Agreement and will be the person who is authorized to use any corresponding Account we may provide to the Account Owner in connection with the Services.</p>
<p>If you are signing up for the Services on behalf of your business or employer, your business or employer shall be the Account Owner and you must use your business or employer-issued email address. Once an Account Owner is established, it is his or her responsibility to reassign ownership in the event it become necessary. In the event the designated Account Owner leaves the business, Aesthetic Record will not reassign ownership to another user with valid proof of business ownership.</p> 
<p>You acknowledge that Aesthetic Record will use the approved business and/or employer-issued email address you provided when registering an Account or as updated by you from time to time as the primary method for communication with you. In the event you unsubscribe from our emails, you will be unable to receive important notifications regarding your account. We will make an attempt to contact you via SMS or phone if needed, but it is the Account Owners responsibility to ensure he or she can receive our communications.</p> 
<p>Any username or password that we provide to you or that you may create may only be used in accordance with this Agreement. You are solely responsible for maintaining the security of your username and password. You may not disclose your username or password to any third party, including your own Authorized Employees as defined further below in the Confidential Information section of this Agreement. You agree to operate in accordance with HIPAA by designating a unique login for each employee. You are solely responsible for any use of or action taken under your user name or password. Aesthetic Record will not be liable for any loss or damage from your failure to maintain the security of your Account, username, or password or for any loss caused by multiple employees using one login. If your username or password is compromised, you must immediately change your username or password. If you provide a password to Aesthetic Record in the course of resolving a technical support issue, you must change your password immediately once testing is complete.</p> 
<p><b>Grant of Rights in Your Data</b></p>
<p>“Your Data” means any data, information or material provided or submitted or made available by you or your representative to the Services. Your Data may include Personal Information as defined further below in the Data Security and Privacy section of this Agreement but excludes Anonymized Data as defined further below in this section.</p>
<p>As between you and Aesthetic Record, you own all right, title and interest in Your Data. You hereby grant Aesthetic Record a non-exclusive, worldwide, assignable, sublicensable, fully paid-up and royalty-free license and right to copy, distribute, display and perform, publish, prepare derivative works of and otherwise use Your Data for the purposes of providing, improving and developing Aesthetic Record’s products and services and/or complimentary products and services of our partners.</p>
<p>You represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions, insurance, power and/or authority necessary to conduct your business and grant the rights granted herein and that the provision of Your Data through and in connection with the Services does not violate any applicable laws or rights of any third parties.</p>
<p>Anonymization is a data processing technique that removes or modifies personally identifiable information; it results in anonymized data that cannot be associated with any one individual. By analyzing Anonymized Data, we are able to improve our Services and safely share anonymized data externally, making it useful for others without putting your privacy at risk. You authorize Aesthetic Record to anonymize and aggregate Your Data or other data in connection with this Agreement (“Anonymized Data”), and Aesthetic Record  will own all Anonymized Data. You agree that nothing in this Agreement will prohibit Aesthetic Record from utilizing Anonymized Data for any purpose, provided such Anonymized Data does not reveal any personally identifying information about you or any persons that use or access the Services.</p>
<p>Notwithstanding any other sections of this Agreement, all right, title and interest in any data or information collected by Aesthetic Record independently and without access to, reference to or use of any of Your Data, including, without limitation, any data or information Aesthetic Record obtains through the Aesthetic Record website or application (whether the same as Your Data or otherwise), will be solely owned by Aesthetic Record.</p>
<p>You are responsible for complying with all state and federal laws related to retention of medical data and records, patient access and amendment to information, and patient authorization to release data.</p>
<p><b>Our Services</b></p>
<p>Aesthetic Record will make the Services to which you have subscribed available to you, subject to the terms and conditions of this Agreement. Aesthetic Record reserves the right at any time, and from time to time, to modify, suspend, or discontinue, the Services (or any part thereof) with or without notice. Aesthetic Record shall not be liable to you or to any third party for any modifications, fee changes, price changes, suspensions, or the discontinuance of any Service. Your continued use of the Services after any such revisions are posted to Aesthetic Record’s website constitutes your agreement to, and acceptance of any such revisions. Not all Services and features are available in every jurisdiction, and we are under no obligation to make all Services or features available in any jurisdiction.</p>
<p>From time to time, Aesthetic Record may, in its sole discretion, invite you to use, on a trial basis, pre-release or beta features that are in development and not yet available to all users (“Beta Services”). In addition to the terms of this Agreement, Beta Services will also be subject to the Beta Services User Agreement, which will be presented to you for your acceptance prior to the provision of the Beta Services. If this Agreement is inconsistent with the Beta Services User Agreement, the Beta Services User Agreement will control with respect to the service with which it applies.</p>
<p>Aesthetic Record offers the ability to process payments through the Services (“Payment Processing Services”). Payment Processing Services are provided by our third-party payment processing partners that may change from time to time and any Payment Processing Services conducted between you and our third-party payment processing partners will be subject to a separate agreement which will be solely between you and the third party processor.</p>
<p>At Aesthetic Record’s sole discretion, you may also be offered Payment Processing Services provided by third-party partners through our Services (“AR Payments”). AR Payments are subject to certain fees that are discussed further below in the “Our Fees” section of this Agreement (“AR Payments Fees”). By enrolling in AR Payments, you agree to be bound by the third-party agreements which may be modified by Aesthetic Record or our third-party partners from time to time.</p>
<p>As a condition of AR Payments, you agree to provide Aesthetic Record with any account information that we so require in our sole discretion via the Administrative Settings in your AR account. You agree to update all account information to ensure that the information at all times remains accurate, complete, and valid. You authorize Aesthetic Record to share any information related to your use of AR Payments pursuant to our Privacy Policy.</p>
<p>At Aesthetic Record’s sole discretion, we may offer you discounts on or pricing options for our Services. In this case, such promotions will have separate purchasing terms that identify amount, usage requirements and termination of any discount. Aesthetic Record is not required to extend a discount or pricing option beyond the terms provided at the time of purchase.</p> 
<p>The Services provided by Aesthetic Record may have third party products integrated with or used in connection with the Services and moreover, the Services may contain links to other websites, mobile applications, and other online services operated by third parties, and other websites, mobile applications, and online services operated by third parties may contain links to our Services (“Third Party Offerings”). The integration with or use of Third-Party Offerings in connection with the Service is not an endorsement of, or representation that we are affiliated with, any third party and shall not constitute a “Service” under this Agreement.</p>
<p>We do not control third parties, and we are not responsible for their Third Party Offerings that may be used in connection with the Services. Your use of such Third-Party Offerings may require that you enter into separate subscription, licensing, and/or business associate agreements with such third-party vendors and suppliers. You agree: (i) to comply with and, upon request, execute such agreements as may be required for the use of such software or services; (ii) that Aesthetic Record may disclose information to these third parties on your behalf (iii) to work directly with your third-party provider for technical or account support specific to your account on their platform. We encourage you to read the terms or service and/or user agreements for these third-party providers you use.</p>
<p><b>Our Fees</b></p>
<p>To use the Services, you must provide us with valid credit or debit card information from a credit or debit card(s) is acceptable to Aesthetic Record. Upon activating your Aesthetic Record Point-of-Sale, you must provide a valid bank account acceptable by Aesthetic Record and the third-party POS underwriters in order to receive payouts. You agree to update allcredit or debit card(s) and bank account(s) information to ensure that the information at all times remains accurate, complete, and valid. If failure to do this results in unpaid fees, your account will be suspended until the balance is paid. In the event valid banking information is not available to our third-party POS platforms, they may choose to hold your payouts. In the event a payout is held, Aesthetic Record has no ability to override their decision without supplying the necessary information on your behalf.  You authorize us (and will provide us documentation evidencing your authorization upon our request) to verify your information (including any updated information), to obtain credit reports about you from time to time, to obtain credit authorizations from the issuer of your credit or debit card(s), and to charge your credit or debit card or debit your bank account for any sums payable by you to us (in reimbursement or otherwise).</p>
<p>You are required to pay any and all applicable fees, including but not limited to, your subscription to our Software Service, which includes our standard onboarding fee (“Setup Fee”) and our recurring subscription service (“Subscription Fee”). Current Setup and Subscription Fees can be located on our website at <a href="www.aestheticrecord.com/plans-and-pricing" target="_blank">www.aestheticrecord.com/plans-and-pricing</a>. You may also purchase supplementary products or services for an additional fee (“Additional Fees”) or be charged AR Promos Fees and AR Payments Fees as discussed above in the Our Services section of this Agreement. Together, the Setup Fees, Subscription Fees, Additional Fees, AR Promos Fees, and AR Payments Fees are referred to as the “Fees”. Unless otherwise indicated, all Fees and other charges are in U.S. dollars, and all payments shall be in U.S. currency. All payment obligations under this Agreement are non-cancelable and all Fees paid are non- refundable.</p>
<p>Setup and Subscription Fees are paid in advance by charging your approved credit or debit card(s) or bank account(s), and user fees will be billed in either 30-day or annual intervals depending on the Subscription Term that you select (each such date known as a “Billing Date”). Additional Fees and AR Promos Fees are paid immediately by charging your approved credit or debit card(s) or bank account(s). Such fees include, SMS and Email overages, 2-way inboxes, POS connection fee, ERX, eFax connection and usage fees. </p>
<p>AR Payments Fees will be assessed at the time a transaction is processed and will be first deducted from the funds received for such transactions. AR Payments Fees are calculated as a percentage of the total amount of the sale. The total amount of the sale is the amount the buyer pays, including any shipping and handling charges. Sales tax is not included in the calculation. The current AR Payments Fee for transactions utilizing a card reader and those using manual card entry vary based on the annual processing volume of your account. In order to provide the appropriate processing payment information, we may ask for proof of prior processing history with your current merchant. However, we do not make any guarantees that we can match or beat current rates.</p>
<p>If a chargeback occurs with respect to any Payment Processing Services (including AR Payments) performed on your behalf (a “Chargeback”), then you agree that, as between you and Aesthetic Record, you will be solely responsible for the amount of the disputed payment and any additional fees or penalties which are charged by the payment processor (collectively, the “Chargeback Amount”). Aesthetic Record will use reasonable efforts to notify you if a payment processor notifies Aesthetic Record that a Chargeback has occurred, and you agree to assist to provide evidence as necessary to investigate and resolve the Chargeback. In the event that a Chargeback occurs and Aesthetic Record pays any Chargeback Amounts to the payment processor that is attributable to payments processed on your behalf, you further agree to pay to Aesthetic Record any Chargeback Amount associated with such Chargeback, and hereby authorize Aesthetic Record to obtain the Chargeback Amount in any manner discussed further below in this section, plus a service fee equal to the greater of $15 or such amount that is charged by any third party and/or any other financial institution(s) (“Service Fee(s)”). If a Chargeback is resolved in your favor and the payment processor gives Aesthetic Record a credit for some or all of the Chargeback Amount, then Aesthetic Record shall refund to you such credited amounts. The Service Fee is non-refundable. In the event that you request a refund of any payments, you will be responsible for any and all credit card or third-party processing fees. In the event that a chargeback dispute is resolved in your favor, Aesthetic Record will transfer the appropriate funds back into your account.</p>
<p>You will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees will appear on an invoice that you can retrieve under the settings tab inside your AR administrative settings area. In the event Aesthetic Record is unable to charge your approved credit or debit card(s) or bank account(s) for any Fees, Aesthetic Record, in its sole discretion, may suspend your access to the Services until your payment information is updated and Aesthetic Record has been paid in full.</p>
<p>For any amounts that we determine you owe us, we may (a) charge your credit or debit card(s) or any other payment instrument you provide to us; (b) offset any amounts that are payable by you to us (in reimbursement or otherwise) against any payments we may make to you or amounts we may owe you; (c) invoice you for amounts due to us, in which case you will pay the invoiced amounts upon receipt; (d) reverse any credits to your bank account(s); (e) deduct the corresponding amounts from funds payable to you arising from the settlement of card transactions through AR Payments; or (f) collect payment or reimbursement from you by any other lawful means. If we determine that you have utilized the Services to engage in deceptive, fraudulent, or illegal activity, or to repeatedly violate our policies, then we may in our sole discretion permanently withhold any payments to you.</p> 
<p>In the event you fail to meet the necessary underwriting requirements or document requests set forth by our third-party processing partners, it is solely their decision as to whether to disable your payouts. Aesthetic Record is unable to interfere in the legal requirements set forth by the United State Treasury or other federal regulations and imposed by the processing partner. Additionally, should your practice be subject to a UCC lien filed by a debtor of your Practice, third-party processors are required by law to withhold funds in satisfaction of that UCC lien. That withholding is strictly managed by the third-party processor and cannot be released by Aesthetic Record.</p> 
<p>Your failure to fully pay amounts that you owe us on demand may be considered a breach of this Agreement. You will be liable for our costs associated with collection in addition to the amount owed, including without limitation attorneys’ fees and expenses, costs of any arbitration or court proceeding, collection agency fees, and any applicable interest. Additionally, we may require a personal guaranty from a principal of a business for funds owed under this Agreement. If we require a personal guarantee we will specifically inform you electronically to the email address registered with your Account. In addition to the amount due, delinquent accounts may be charged with fees that are incidental to the collection of delinquent accounts and chargebacks including, but not limited to, collection fees and convenience fees and other third-party charges. You hereby explicitly agree that communication from Aesthetic Record in relation to delinquent accounts may made by electronic mail or by phone or by other communication methods. In the event your POS account balance become negative for an extended period of time, Aesthetic Record reserves the right to debit your card on file to cover the associated balance. We will make every attempt to contact you before we do so. In the event you terminate your Aesthetic Record account, and your processing balance goes to $0, and disputes or refunds requested after that point will be withdrawn from the payment method you keep on file with the third-party processor. In the event we are unable to collect outstanding fees from the on-file payment and you do not pay the outstanding balance within 30 days, the balance will be subjected to a 10% monthly penalty. We reserve the right to pursue legal action against the practice and any named partners to recuperate these funds in addition to any associated attorney or court costs.</p> 
<p><b>Modifications to Fees, Prices, and Services</b></p>
<p>We may modify our Fees or prices for using Services from time to time by posting the modifications 14 days in advance on the Aesthetic Record website located at  <a href="https://aestheticrecord.com" target="_blank">https://aestheticrecord.com</a> and on our live chat platform. If the Fee or price modification involves a Subscription Term for which you have already agreed to, such modification will not become effective until the expiration of the current Subscription Term. For example, Aesthetic Record posts notice of aFee change on January 14th. If you have a month-to-month subscription that would expire on January 31st, the Fee change would be applicable to you on February 1st. Any other Fee or price modifications are effective immediately. Aesthetic Record may also convert any free, trial, or Beta Service into a Service subject to a subscription Fee upon notice to you to via email sent to the email address registered with your Account and your rights to such Service will be suspended if you do not pay the Subscription Fee.</p>
<p>Aesthetic Record agrees to honor the published monthly price per user as of the execution ofthis contract for a term of 1 year ($12/ Essentials Plan & $16/Accelerator Plan). This is a month- to-month contract and can be cancelled at any time.</p>
<p>Aesthetic Record reserves the right at any time, and from time to time, to modify, suspend, or discontinue, the Services (or any part thereof) with or without notice.</p>
<p>Aesthetic Record shall not be liable to you or to any third party for any modifications, Fee changes, price changes, suspensions, or the discontinuance of any Service. Your continued use of the Services after any such revisions are posted to Aesthetic Record’s website constitutes your agreement to, and acceptance of any such revisions.</p>
<p><b>Tax Matters</b></p>
<p>All Fees are exclusive of applicable federal, provincial, state, local or other governmental sales, goods and services, harmonized or other taxes, fees or charges now in force or enacted in the future (“Taxes”).</p>
<p>As between the parties, you will be responsible for the collection, reporting, and payment of any and all Taxes. To the extent that Aesthetic Record charges Taxes, such Taxes are calculated using the tax rates that apply based on the current business or employer billing address that corresponds to your Account. Taxes will be billed to your credit or debit card(s) or bank account(s) that corresponds to your Account. If you are exempt from payment of such Taxes, you must provide us with evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after the date we receive evidence satisfactory to Aesthetic Record in our sole discretion of your exemption. If you are not charged Taxes by Aesthetic Record, you are responsible for determining if Taxes are payable, and if so, self- remitting Taxes to the appropriate tax authorities in your jurisdiction. You acknowledge that Aesthetic Record may make certain reports to taxation authorities (for example, IRS Form1099-MISC) regarding transaction that we process to which we provide payment processing services.</p>
<p>All sums payable by you to Aesthetic Record under this Agreement shall be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes charged by Aesthetic Record to you and remitted to the appropriate tax authorities on your behalf, any deductions or withholdings that are required by law shall be borne by you and paid separately to the relevant taxation authority. Aesthetic Record shall be entitled to charge the full amount of Fees stipulated under this Agreement to your credit or debit card(s) or bank account(s) ignoring any such deduction or withholding that may be required.</p>
<p><b>Content</b></p>
<p>Our Services may allow you to access blogs, message boards, chat services, surveys and other forums that allow patients or clients to share information, opinions, chats and other text, data, graphics, images, video or other Content (“Content”). Aesthetic Record is under no duty to regularly inspect Content and such Content may simply represent a patient’s or client’s opinion. Our Services may also include survey results, ratings or testimonials (“Evaluations”) from patients, clients or other customers of healthcare professionals (“Professionals”) that may endorse, recommend, critique, analyze, evaluate or otherwise describe the Professionals and the nature or quality of the services received by such Patients. Evaluations are typically anecdotal first-hand accounts and are neither professional judgments nor the product of medical science. Evaluations do not in any way constitute or imply our endorsement or recommendation of any Professional. Furthermore, Evaluations are subject to errors and biases that are common in anecdotal first-hand accounts, and should not be presumed to be reliable or error-free.</p>
<p><b>Directories</b></P>
<p>Our Services may include listings and directories (“Directories”) that are provided for your convenience. The Directories are not comprehensive, but rather generally represent Professionals who use our Services and who have chosen to participate in the Directories.</p>
<p>Further, we do not evaluate any Professional and the listing of a Professional does not in any way constitute a recommendation of any Professional. Before obtaining services or treatment from any Professional listed in a Directory, you should take the same care you would under any other circumstance, including by confirming licensure and specialty certifications.</p>
<p>Professionals are solely responsible for the appropriateness and quality of the services they provide. Additionally, the Directories rely on information submitted by Professionals themselves. Unless Professionals provide us with current information, the Directory information may not be timely or accurate. As a convenience, the Services may permit you to request an appointment with a Professional. However, Professionals are responsible for maintaining their own schedules, and we cannot ensure that any given Professional will be available, nor that such Professional will not cancel his or her appointment.No Medical Advice</p>
<p>Any information provided by Aesthetic Record or in connection with any Services is for informational purposes only and is in no way intended to create a physician-patient relationship as defined by state and federal law. Our Services are not a substitute for any professional diagnosis and treatment. Reliance on any information provided by Aesthetic Record or in connection with any services, is solely at your own risk. All Professionals identified or accessible via the Services are independent providers and are not employed or affiliated with Aesthetic Record. If you think there is a medical emergency, you should call your local emergency number (usually 911) immediately.</p>
<p><b>Clinical Decision Support</b></p>
<p>Clinical decision support (“CDS”) provides Professionals with knowledge and person-specific information, intelligently filtered or presented at appropriate times, to enhance health and health care. CDS encompasses a variety of tools to enhance decision-making, which can include information and reminders concerning drug interactions, dosages, clinical guidelines, and contextually relevant reference information. While CDS identifies and describes generally recommended courses of intervention, it is not intended to substitute for the advice of a physician or other knowledgeable health care professional or provider. The CDS information and materials available through our Services are for informational purposes only and are not to be considered as professional advice, diagnosis, or treatment, or to substitute for anyone’s professional judgment.</P>
<p>Individual patients may require different treatments from those specified in CDS. CDS is not entirely inclusive or exclusive of all methods of reasonable care that can obtain/produce the same results. Although CDS can be developed to take into account variations in clinical settings, resources, or common patient characteristics, it cannot address the unique needs of each patient nor the combination of resources available to a particular community or health care professional or provider. Deviations from CDS may be justified by individual circumstances. Thus, CDS must be applied based on individual patient needs using professional judgment.</p>
<p><b>No Legal or Regulatory Advice</b></p>
<p>Some Content or Materials found in the Services may reference information related to relevant health regulations. Such references are intended to convey general information only and not to provide legal advice or opinions. The contents of the Services, and the posting and viewing of the information on any Aesthetic Record on any website or application, should not be construed as, and should not be relied upon for, legal advice in any particular circumstance or fact situation. No action should be taken in reliance on the information contained in the Contents or Materials found in the Services and we disclaim all liability in respect to actions taken or not taken based on any or all of the contents of the Services to the fullest extent permitted by law. An attorney should be contacted for advice on specific legal issues.</p>
<p><b>Subscription Term and Termination of Agreement</b></p>
<p>The term of this Agreement will be either month-to-month or annual (“Subscription Term”), depending on which subscription you signed up for in your subscription details tab within your Aesthetic Record account settings. The Subscription Term commences on the Effective Date discussed above in the Introduction section of this Agreement and will automatically renew on a monthly basis for month-to-monthly contracts and on an annual basis for annual contracts until either Party terminates in accordance with this Agreement. Unless otherwise specified in an Order Form, Subscription Fees during any automatic renewal term will revert to the current pricing in effect at the time such renewal term commences.</p>
<p>Aesthetic Record provides an onboarding specialist, virtual training via Core4 as well as live virtual workshops, and two check-in calls during the first 90 days of the subscription as part of our one-time setup fee on the Essentials & Accelerator plans. All other training will be billed at $99 per hour and will be charged immediately to the approved credit or debit card(s) or bank account(s) associated with your Account.</p>
<p>Either Party may terminate the Agreement and/or any Services at any time for any reason by providing notice to the other Party at least 30 days before the end of the relevant Subscription Term. Your notice of termination can be made via the administrative settings area of your account. To avoid your monthly fee, this termination must be made prior to the billing date. If a user is deactivated prior to the billing date, no prorated refunds will be provided. Billing will cease for that user on the upcoming billing date.</p>  
<p>We may suspend or terminate this Agreement or your use of any Services immediately if we determine that (a) you have materially breached this Agreement and failed to cure within 7 days of a cure notice unless your breach exposes us to liability toward a third party, in which case we are entitled to reduce, or waive, the aforementioned cure period at our reasonable discretion;
(b) your account has been, or our controls identify that it may be used for deceptive or fraudulent, or illegal activity; (c) where you and Aesthetic Record do not agree on the use of a payment processor; (d) you become insolvent, file a bankruptcy petition or commence or have commenced against you proceedings related to bankruptcy, receivership, reorganization, or assignment for the benefit of creditors; or (e) your use of the Services has harmed, or may reasonably cause harm to other sellers, customers, or Aesthetic Record’s legitimate interests. We will promptly notify you of any such termination or suspension via electronic mail via to the email address registered with your Account, except where we have reason to believe that providing this information will hinder the investigation or prevention of deceptive, fraudulent, or illegal activity, or will enable you to circumvent our safeguards.</p>
<p>On expiration or termination of this Agreement by either party for any reason, all related rights and obligations under this Agreement immediately terminate, except that you will remain responsible for performing all of your obligations in connection with transactions entered into before termination and for any liabilities that accrued before or as a result of termination, and sections titled Grant of Rights in Your Data; Our Fees; Modifications to Fees, Prices; and Services, Subscription Term and Termination of Agreement; Materials and License; Confidential Information; Data Security and Privacy; Protected Health Information (“PHI”); Representations; Indemnity; Disclaimer of Warranties and General Release; Limitation of Liability; Insurance; Legal Disputes; and General Conditions of this Agreement survive. In addition, you will not be entitled to any refunds of any Fees, pro rata or otherwise, and any liabilities will become immediately due and payable in full. Further, (a) all Order Forms will automatically terminate and be of no force or effect; (b) you will have no rights to continue use of the Services and will cease accessing and/or using the Services; and (c) for a period of no greater than thirty (30) days following termination, Aesthetic Record will make Your Data available to you through Aesthetic Record; however, Aesthetic Record will have no obligation to maintain Your Data and/or account or to retain or forward any Your Data to you or any third party, except as required by applicable law, beyond this 30-day time period.</p>
<p><b>Materials and License</b></p>
<p>In accordance with Aesthetic Record’s Acceptable Use Policy, you further agree that you are responsible for all activity and content such as photos, images, videos, graphics, written content, audio files, code, information, or data uploaded, collected, generated, stored, displayed, distributed, transmitted or exhibited on or in connection with your Account (“Materials”). You are responsible for the compliance of Materials with any applicable laws and regulations and for ensuring that all Materials are up-to-date, accurate, complete, and not misleading.</p>
<p>Materials that violate any of Aesthetic Record’s policies may be modified, obfuscated, or deleted at any time in Aesthetic Record’s discretion. We may revise your Materials to supplement, remove, or correct information.
<p>When you provide Materials using the Services (directly or indirectly), you grant us a non- exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers) right to exercise any and all Intellectual Property Rights you have in that content in connection with our provision, expansion, and promotion of the Services. “Intellectual Property Rights” means any patent, copyright, trademark, domain name, moral right, trade secret right, or any other intellectual property right arising under any laws and all ancillary and related rights, including all rights of registration and renewal and causes of action for violation, misappropriation, or infringement of any of the foregoing. To the fullest extent permitted under applicable law, you waive your right to enforce your Intellectual Property Rights in that content against Aesthetic Record, our assignees, our sublicensees, and their assignees in connection with our, those assignees', and those sublicensees' use of that content in connection with our provision, expansion, and promotion of the Services.<p>
<p>You represent and warrant that, for all Materials you provide in using or accessing the Services, you own or otherwise control all necessary rights to do so and to meet your obligations under this Agreement. You represent and warrant that such Materials are accurate. You represent and warrant that use of any such Materials (including derivative works) by us, our users, or others in contract with us, and in compliance with this Agreement, does not and will not infringe any Intellectual Property Rights of any third party. Aesthetic Record takes no responsibility and assumes no liability for any Materials provided by you or any third party.</p>
<p><b>Confidential Information</b></p>
<p>During the course of your access and use of the Services, you may receive Confidential Information. Confidential Information shall include, but shall not be limited to, any and all information associated with a Aesthetic Record’s business and not publicly known, including specific business information, technical processes and formulas, security programs and practices, software, customer lists, prospective customer lists, names, addresses and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans and marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary.</p>
<p>All Confidential Information will remain Aesthetic Record’s exclusive property. You agree to use Confidential Information solely as reasonably necessary for performing your obligations under this Agreement. You also agree to take all reasonable measures to protect Confidential Information against any use or disclosure that is not expressly permitted by this Agreement. You agree to not, directly or indirectly, disclose Confidential Information to any person other than your Authorized Employees, including any subcontractors, agents, or outsourcers without express written consent from Aesthetic Record, unless and to the extent expressly required by applicable law, in which case you shall notify Aesthetic Record before such disclosure or within twenty-four hours thereafter via email with return receipt requested to Tiphany Lopez, Chief Growth Officer, at <a href="mailto:tiphany@aestheticrecord.com">tiphany@aestheticrecord.com</a> Re: Confidential Information Disclosure. Any such disclosure required by applicable law shall be limited to the minimum necessary disclosure and you must explicitly indicate the confidential nature of the shared information. You shall be responsible for and be liable to Aesthetic Record for the actions and omissions of such unauthorized third parties concerning the treatment of Confidential Information. “Authorized Employees” means your employees or agents who have a need to know or otherwise access Confidential Information to enable you to perform your obligations under this Agreement.</p>
<p>You may not issue any press release or make any public statement related to the Marketplace, or use our name, trademarks, or logo, in any way (including in promotional material) without our advance written permission, or misrepresent or embellish the relationship between us in any way. You must send this request to Aesthetic Record by electronic mail with return receipt requested to Tiphany Lopez, Chief Growth Officer, at <a href="mailto:tiphany@aestheticrecord.com">tiphany@aestheticrecord.com</a>, Re: Trademark Request.</p>
<p><b>Data Security and Privacy</b></p>
<p>During the course of your access and use of the Services, you may also receive or store Personal Information. You agree to comply with the terms and conditions set forth in this Agreement in your collection, receipt, transmission, storage, disposal, use, and disclosure of such Personal Information and be responsible for the unauthorized collection, receipt, transmission, storage, disposal, use, and disclosure of Personal Information under your control or in your possession by all your Authorized Employees, including any subcontractors, agents, or outsourcers. Personal information means (i) an individual’s government-issued identification number (including but not limited to social security number, driver’s license number, or state-issued identification number); or (ii) “Cardholder Data” as defined on the PCI Security Standards Council (“PCI SSC”) website which can be found at <a href="https://www.pcisecuritystandards.org/" target="_blank">https://www.pcisecuritystandards.org/</a>.  Personal Information does not
include “Protected Health Information” that is discussed further below in the Protected Health Information (“PHI”) section of this Agreement.</p>
<p>You agree to keep and maintain all Personal Information in strict confidence, using such reasonable care as is appropriate to avoid unauthorized collection, receipt, transmission, storage, disposal, use, and disclosure. You agree to not, directly or indirectly, disclose Personal Information to any person other than your Authorized Employees, including any subcontractors, agents, or outsourcers without express written consent from Aesthetic Record, unless and to the extent expressly required by applicable law, in which case you shall notify Aesthetic Record before such disclosure or as soon as reasonably possible thereafter. You shall be responsible for and be liable to Aesthetic Record for the actions and omissions of such unauthorized third parties concerning the treatment of Personal Information.</p>
<p>You agree that your collection, access, use, storage, processing, disposal, and disclosure of Personal Information does and will comply with all applicable federal and state privacy and data protection laws, as well as all other applicable regulations and directives, including but not limited to, the Payment Card Industry Data Security Standard (“PCI DSS”) promulgated by the PCI SSC as may be amended from time to time, which can be found at <a href="https://www.pcisecuritystandards.org/" target="_blank">https://www.pcisecuritystandards.org/</a>.</p>
<p>Aesthetic Record does not store any credit card information within its system and does not authorize you or any user within your organization to store personal payment information within the system. Any credit card information stored within Aesthetic Record that is not in accordance with PCI DSS is solely your responsibility in the case of unauthorized access of your records.</p>
<p>You agree to implement reasonable administrative, technical, and physical safeguards to protect Personal Information that are no less rigorous than accepted industry practices and shall ensure that such safeguards, including the manner in which Personal Information is collected, accessed, used, stored, processed, disposed, and disclosed, comply with applicable privacy and data protection laws, as well as the terms and conditions of this Agreement.</p>
<p>You agree to provide Aesthetic Record with the name and contact information for an individual who will serve as your primary security contact and shall be available to assist Aesthetic Record twenty-four (24) hours per day, seven (7) days per week as a contact in resolving obligations associated with a Security Breach. Security Breach means (i) any act or omission that compromises the security, confidentiality, or integrity of Personal Information or the physical, technical, or administrative safeguards you have put in place that relate to the security, confidentiality, or integrity of Personal Information; or (ii) receipt of a complaint or a breach or an alleged breach of this Agreement in relation to your privacy practices. In the event a breach is caused by direct actions taken by you or your authorized employees, you are fully responsible for any financial obligations that may arise as a result of required credit monitoring, legal fees, business interruption, legal cases, etc. We require your practice to carry appropriate cyber liability insurance to ensure you can fulfill this obligation.</p>  
<p><b>Representations</b></p>
<p>Each party to this Agreement represents and warrants that: (a) if it is a business, it is duly organized, validly existing and in good standing under the laws of the country in which the business is registered and that you are registering for an Account within such country; (b) it has all requisite right, power, and authority to enter into this Agreement, perform its obligations, and grant the rights, licenses, and authorizations in this Agreement; (c) any information provided or made available by one party to the other party is at all times accurate and complete; (d) it is not subject to sanctions or otherwise designated on any list of prohibited or restricted parties or owned or controlled by such a party, including but not limited to the lists maintained by the United Nations Security Council, the US Government (e.g., the US Department of Treasury’s Specially Designated Nationals list and Foreign Sanctions Evaders list and the US Department of Commerce’s Entity List), the European Union or its member states, or other applicable government authority; and (e) it will comply with all applicable laws in performance of its obligations and exercise of its rights under this Agreement.</p>
<p><b>Indemnity</b></p>
<p>You will defend, indemnify, and hold harmless Aesthetic Record (and its agents, officers, directors, and employees) against any third-party claim, loss, damage, settlement, cost, expense, or other liability (including, without limitation, attorneys’ fees) (each, a “Claim”) arising from or related to (a) your non-compliance with applicable laws; (b) any actual or alleged infringement of any Intellectual Property Rights by any of the foregoing, and any personal injury, death (to the extent the injury or death is not caused by Aesthetic Record), or property damage related thereto;
(c) your taxes and duties or the collection, payment, or failure to collect or pay your taxes or duties, or the failure to meet tax registration obligations or duties; (d) any actual or alleged breach by you of this Agreement.
<p>Aesthetic Record agrees to indemnify, defend, and hold harmless you and your associates from and against any and all claims, losses, liabilities, costs, and expenses (including reasonable attorney’s fees) resulting from, or incurred in connection with, any conduct by Aesthetic Record or its associates in violation of this Agreement.</p>
<p>As of the execution of this contract, there have been no security risks or breaches made known to Aesthetic Record since its inception. Aesthetic Record partners with Amazon Web Services to manage, protect and store your data. Should you need more information regarding the security parameters of the platform, please send a notification via email to Tiphany Hall, CGO at <a href="mailto:tiphany@aestheticrecord.com">Tiphany@aestheticrecord.com</a></p>
<p><b>Electronic Signatures, Communications, and Notices</b></p>
<p>You acknowledge and agree that this Agreement and documents related hereto, including but not limited to as Order Forms and the Business Associate Addendum, may be executed by electronic signature, which shall be considereds an original signature for all purposes and shall have the same force and effect as an original signature. Without limitation, “electronic signature” shall include faxed versions of an original signature or electronically scanned and transmitted versions (e.g., via pdf) of an original signature. You agree to waive any rights under any laws or regulations that require an original, non-electronic signature or delivery or retention of non-electronic records.</p>
<p>Any communications and notices provided by Aesthetic Record under this Agreement may be delivered to you within the Services or to the email address registered with your Account. You hereby consent to receive notice from Aesthetic Record through the foregoing means, and such notices will be deemed effective when sent if on a business day, and if not sent on a business day then on the next business day.</p>
<p><b>Disclaimer of Warranties and General Release</b></p>
<p>We work diligently to keep the Aesthetic Record safe, secure, and functioning properly, but we cannot guarantee the continuous operation of or access to our Services. You agree that you are making use of our Services at your own risk, and that they are being provided to you on an "AS IS" and "AS AVAILABLE" basis. Accordingly, to the extent permitted by applicable law, we exclude all express or implied warranties, terms and conditions including, but not limited to, implied warranties of merchantability; fitness for a particular purpose; non-infringement; implied warranties arising out of course of dealing, course of performance, or usage of trade; and any obligation, liability, right, claim, or remedy in tort, whether or not arising from our negligence.</p>
<p>We do not warrant that your use of the Services will meet your requirements or be available, timely, accurate, reliable, secure, uninterrupted, or error free, and Aesthetic Record will not be liable for any service interruptions, including but not limited to system failures or other interruptions that may affect the receipt, processing, acceptance, completion, or settlement of any transactions. In the event a disruption in services should occur, Aesthetic Record will make every attempt to resolve any issues and restore functionality as soon as possible. In the event a service outage does occur, please refer to the live support widget found inside of your Aesthetic Record portal for real-time updates and relevant information regarding the status of our services. In the event you experience an issue with or disruption in your service, you should refer to the live support widget for ny posted announcements, and if none are available, report your issue to our live support agents for immediate review.</p>
<p>We are not responsible for any disputes or disagreements between you and any third party with whom you interact using the Services. We are not responsible for mediating legal disputes between business partners in the event of an account ownership reassignment request. In the event a party contacts AR for reassignment of ownership and is not the current Account Owner, any communications regarding this process may also include the current Account Owner.  We expect that interested parties should resolve their legal disputes without our involvement and provide us with a mutual decision at that point. We are also not responsible for disputes or disagreements between you and any patient or client with or for whom you or the patient or client have used the Services. You assume all risk associated with dealing with third parties. You agree to resolve disputes directly with the other party. You release Aesthetic of all claims, demands, and damages in disputes among users of the Aesthetic Record Service. You also agree not to involve us in such disputes.</p>
<p><b>Limitation of Liability</b></p>
<p>To the extent permitted by applicable law, Aesthetic Record (and its agents, officers, directors, and employees) will not be liable (whether in contract, warranty, tort (including negligence, product liability, or other theory) or otherwise) to you or any other person for cost of cover, recovery, or recoupment of any investment made by you in connection with this Agreement, or for any loss of profit, revenue, business or goodwill, use, data, or punitive or consequential damages arising out of or relating to this Agreement, even if Aesthetic Record has been advised of the possibility of those costs or damages. Further, Aesthetic Record’s aggregate liability to you or any third party arising out of or in connection with this Agreement or the transactions contemplated by the Agreement will not exceed at any time the total amounts during the prior six month period paid by you to Aesthetic Record in connection with the particular Service giving rise to the claim.</p>
<p><b>Insurance</b></p>
<p>Without limiting or diminishing your obligation to indemnify or hold Aesthetic Record harmless as stated in this Agreement, you agree to procure and maintain or cause to be maintained, at your sole cost and expense, commercial cyber liability insurance coverage covering claims which may arise from or out of your performance of your obligations under this Agreement. Your policy’s limit of liability shall not be less than $1,000,000 per occurrence combined single limit. If such insurance contains a general aggregate limit, it shall apply separately to this agreement or be no less than two (2) times the occurrence limit. Upon Aesthetic Record’s request, you shall provide Aesthetic Record with a certificate of insurance from your insurer evidencing such insurance coverage. This certificate may be stored in your POS document repository in your Administrative Settings. In the event you do not have cyber liability insurance, you may reach out to our carrier, PCF Insurance, for a cyber audit and quote on a policy that complies with our requirements at <a href="https://referral.aestheticrecord.com/cyber" target="_blank">https://referral.aestheticrecord.com/cyber</a></p>
<p><b>Export</b></p>
<p>You will not directly or indirectly export, re-export, transmit, or cause to be exported, re-exported or transmitted, any commodities, software or technology to any country, individual, corporation, organization, or entity to which such export, re-export, or transmission is restricted or prohibited, including any country, individual, corporation, organization, or entity under sanctions or embargoes administered by the United Nations, US Departments of State, Treasury or Commerce, the European Union, or any other applicable government authority.</p>
<p><b>SMS Marketing Campaign Feature</b></p>
<p>Your Account may be enabled with our Standard Rate Messaging Service Feature (“SMS Feature”) that allows you to send and receive SMS messages with patients or clients that opt-in to the SMS Feature. By enabling the SMS Feature, you agree to comply any and all mandates found in the CTIA Short Code Monitoring Handbook (“CTIA Handbook”) and the Telephone Consumer Protection Act of 1991 (“TCPA”) and any associated regulations. Copies of the CTIA Handbook and the TCPA can be found here:
 <a href="https://www.wmcglobal.com/storage/us_resources/ctia-short-code-monitoring-handbook-
current-Short-Code-Monitoring-Handbook-v1.7.pdf" target="_blank">https://www.wmcglobal.com/storage/us_resources/ctia-short-code-monitoring-handbook-
current-Short-Code-Monitoring-Handbook-v1.7.pdf</a>	and <a href="https://www.fcc.gov/sites/default/files/tcpa-rules.pdf" target="_blank">https://www.fcc.gov/sites/default/files/tcpa-rules.pdf</a>.</p>
  <p>1.	You agree that you will not send Unsolicited Messages via the SMS Feature. Unsolicited Messages are defined as either a) messages delivered without prior express consent from the patient or client or b) messages sent after a patient or client has opted out except for a single opt- out message acknowledging the patient’s or client’s opt-out.</p>
  <p>2.	You agree that all messages you send to patients and clients will conform to Aesthetic Record’s Acceptable Use Policy. You also agree the messages will not be deceptive and will remain clear and consistent throughout the user experience.</p>
  <p>3.	All messages sent by utilizing the SMS Feature must be delivered to a patient’s or client’s mobile device only after the patient or client has affirmatively opted-in to receive such messages through a clear and conspicuous call-to-action. Requiring a patient or client to enter a mobile phone number does not constitute a compliant opt-in. Instead, patients and clients must understand they will receive messages and expressly consent to receive such messages.</p>
  <p>4.	If the patient or client books through the Aesthetic Record online booking portal, a compliant opt-in is provided. If the clinic books the patient or clinic, you will need to ensure the patient is presented with an opportunity to opt-in or opt-out of receiving SMS from your clinic.</p>
  <p>5. At any point, a client or patient may ask to opt-out of receiving SMS messages, in the event they use the universal key words “STOP, END, CANCEL, UNSUBSCRIBE or QUIT” they will be automatically opted-out. If they communicate their desire to opt out to your clinic, you must change their DND settings in their Patient Profile inside of Aesthetic Record.</p>
  <p>6. In the event that Aesthetic Record, in its sole discretion, determines that you have failed to comply with any of the terms and conditions in the SMS Marketing Campaign Feature Section of this Agreement, you agree to the following course of action:</p>
    <p>i.	For a first violation, Aesthetic Record will notify you of the violation by sending an email to the email address registered with your Account. We will also immediately suspend your ability to participate in the SMS Feature for up to 30 days from the day we provided notice to you (“Notice Date”). You will also have 30 days from the Notice Date to provide Aesthetic Record with proof of correction, which can, in our sole discretion, include but not be limited to items such as screenshots, documents, and process improvement plans. Upon receipt of all required proof of correction documentation, Aesthetic Record will then render a decision within 14 days regarding the reinstatement of the SMS Feature to your Account. If you cannot demonstrate the required corrective action was completed within the 30-day period after your Notice Date, Aesthetic Record, in its sole discretion, may permanently exclude you from using the SMS Feature.</p>
    <p>ii.	For a second violation, Aesthetic Record will lock your Aesthetic Record user inbox and immediately suspend your ability to participate in the SMS Feature. We will notify you of such actions by providing you with a dialogue box upon your attempt to access your user inbox. The dialogue box will indicate the SMS Feature has been immediately suspended and that you can contact <a href="mailto:support@aestheticrecord.com">support@aestheticrecord.com</a> for more information. Additionally, we will notify you by sending an email to the email address registered with your Account. We may also permanently exclude you from using the SMS Feature.</p>
<p><b>Legal Disputes</b></p>
<p><b>PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL HAVE A SUBSTANTIAL IMPACT ON HOW CLAIMS YOU AND AESTHETIC RECORD HAVE AGAINST EACH OTHER ARE RESOLVED.</b></p>
<p>You and Aesthetic Record agree that any claim or dispute at law or equity that has arisen, or may arise, between you and Aesthetic Record (including any claim or dispute between you and a third-party agent of Aesthetic Record) that relates in any way to or arises out of this or previous versions of this Agreement, your use of or access to the Services, the actions of</p>
<p>Aesthetic Record (and its agents, officers, directors, and employees) or any products or services sold or purchased through the Services, will be resolved in accordance with the provisions set forth in this Legal Disputes Section.</p>
<p>1.	<b>Applicable law</b>. You agree that, except to the extent inconsistent with or preempted by federal law, the laws of the State of Ohio, without regard to principles of conflict of laws, will govern this Agreement and any claim or dispute that has arisen or may arise between you and Aesthetic Record, except as otherwise stated in this Agreement.</p>
<p><b>2.	Agreement to Arbitrate. You and Aesthetic Record each agree that any and all disputes or claims that have arisen, or may arise, between you and Aesthetic Record (including any disputes or claims between you and a third-party agent of Aesthetic Record) that relate in any way to or arise out of this or previous versions of this Agreement, your use of or access to the Services, the actions of Aesthetic Record (and its agents, officers, directors, and employees), or any products or services sold, offered, or purchased through the Services shall be resolved exclusively through final and binding arbitration, rather than in court. The Federal Arbitration Act governs the interpretation and enforcement of this Agreement to Arbitrate.</b></p>
  <p><b>i.	Prohibition of Class and Representative Actions and Non-individualized Relief.</b></p>
<p><b>YOU AND AESTHETIC RECORD AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING. UNLESS BOTH YOU AND AESTHETIC AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, CLASS, OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED
CANNOT AFFECT OTHER USERS</b>. If a court decides that applicable law precludes enforcement of any of this paragraph's limitations as to a particular claim for relief, then that claim (and only that claim) must be severed from the arbitration and may be brought in court, subject to your and Aesthetic Record's right to appeal the court's decision. All other claims will be arbitrated.</p>
<p><b>ii.	Arbitration Procedures</b></p>
<p>Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, and court review of an arbitration award is very limited. However, an arbitrator can award the same damages and relief on an individual basis that a court can award to an individual. An arbitrator should apply the terms of the Agreement as a court would. All issues are for the arbitrator to decide, except those issues relating to arbitrability, the scope or enforceability of this Agreement to Arbitrate, or the interpretation of Section (i) of this Agreement to Arbitrate ("Prohibition of Class and Representative Actions and Non- Individualized Relief"), shall be for a court of competent jurisdiction to decide.</p>
<p>The arbitration will be conducted by the American Arbitration Association ("AAA") under its rules and procedures, including the AAA's Consumer Arbitration Rules (as applicable), as modified by this Agreement to Arbitrate. The AAA's rules are available at <a href="https://www.adr.org/" target="_blank">www.adr.org</a> or by calling the AAA at 1-800-778- 7879. The use of the word "arbitrator" in this provision shall not be construed to prohibit more than one arbitrator from presiding over an arbitration; rather, the AAA's rules will govern the number of arbitrators that may preside over an arbitration conducted under this Agreement to Arbitrate.</p>
<p>A party who intends to seek arbitration must first send to the other, by certified mail, a letter requesting arbitration, describing the nature and basis of your claim, and the relief sought. The Notice to Aesthetic Record should be sent via certified mail to Aesthetic Record, Attn: Tiphany Lopez, Re: Arbitration Request, 40 West Gay Street, Columbus, OH 43215. Aesthetic Record will send any letter requesting arbitration to your business or employer address we have on file associated with your Account; it is your responsibility to keep your address up to date.</p>
<p>If you and Aesthetic Record are unable to resolve the claims described in the letter requesting arbitration within 30 days after the letter is sent, you or Aesthetic Record may initiate arbitration proceedings. A form for initiating arbitration proceedings is available on the AAA's site at <a href="https://www.adr.org" target="_blank">www.adr.org</a>. In addition to filing this form with the AAA in accordance with its rules and procedures, the party initiating the arbitration must mail a copy of the completed form to the opposing party. You shall also send a copy to Aesthetic Record via certified mail to the following address: Aesthetic Record, Attn: Tiphany Lopez, Re: Arbitration Request, 40 West Gay Street, Columbus, OH 43215. In the event Aesthetic Record initiates arbitration proceedings against you, it will send a copy of the completed form to the physical address we have on file associated with your Account. Any settlement offer made by you or Aesthetic Record shall not be disclosed to the arbitrator.</p>
<p>The arbitration hearing shall be held in the county in which you reside or at another mutually agreed location. If the value of the relief sought is $10,000 or less, you or Aesthetic Record may elect to have the arbitration conducted by telephone or based solely on written submissions, which election shall be binding on you and Aesthetic Record subject to the arbitrator's discretion to require an in-person hearing, if the circumstances warrant. In cases where an in-person hearing is held, you and/or Aesthetic record may attend by telephone, unless the arbitrator requires otherwise. The arbitrator will decide the substance of all claims in accordance with applicable law, including recognized principles of equity, and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different users, but is bound by rulings in prior arbitrations involving the same Aesthetic Record user to the extent required by applicable law. The arbitrator's award shall be final and binding and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.</p>
<p><b>iii.	Costs of Arbitration</b></p>
<p>Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules, unless otherwise stated in this Agreement to Arbitrate. If the value of the relief sought is $10,000 or less, at your request, Aesthetic Record will pay all filing, administration, and arbitrator fees associated with the arbitration. Any request for payment of fees by Aesthetic Record should be submitted by mail to the AAA along with your Demand for Arbitration and Aesthetic Record will make arrangements to pay all necessary fees directly to the AAA. In the event the arbitrator determines the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse Aesthetic Record for all fees associated with the arbitration paid by Aesthetic Record on your behalf that you otherwise would be obligated to pay under the AAA's rules.</p>
<p><b>iv.	Severability</b></p>
<p>With the exception of any of the provisions in Section (i) of this Agreement to Arbitrate ("Prohibition of Class and Representative Actions and Non- Individualized Relief"), if an arbitrator or court decides that any part of this Agreement to Arbitrate is invalid or unenforceable, the other parts of this Agreement to Arbitrate shall still apply.</p>
<p><b>v.	Future Amendments to the Agreement to Arbitrate</b></p>
<p>Notwithstanding any provision in this Agreement to the contrary, you and Aesthetic Record agree that if we make any amendment to this Agreement to Arbitrate (other than an amendment to any notice address) in the future, that amendment shall not apply to any claim that was filed in a legal proceeding against Aesthetic Record prior to the effective date of the amendment. The amendment shall apply to all other disputes or claims governed by the Agreement to Arbitrate that have arisen or may arise between you and Aesthetic Record. We will notify you of amendments to this Agreement to Arbitrate by posting the amended terms on the AR Website located at www.AestheticRecord.com If you do not agree to these amended terms, you may close your Account within the 30-day period and you will not be bound by the amended terms.</p>
<p>3.	<b>Judicial Forum for Legal Disputes</b>. Unless you and Aesthetic Record agree otherwise, in the event that the Agreement to Arbitrate above is found not to apply to you or to a
particular claim or dispute, you agree that any claim or dispute that has arisen or may arise between you and Aesthetic Record must be resolved exclusively by a state or federal court located in Franklin County, Ohio. You and Aesthetic Record agree to submit to the personal jurisdiction of the courts located within Franklin County, Ohio for the purpose of litigating all such claims or disputes.</p>
<p><b>General Conditions</b></p>
<p>To the extent permitted by applicable law, we reserve the right to refuse to provide any Service to anyone for any reason at any time.</p>
<p>You agree to respond promptly to any Aesthetic Record request to provide direction, information, approvals, authorizations or decisions that are reasonably necessary for Aesthetic Record to perform Services in accordance with the requirements of this Agreement.</p>
<p>You acknowledge and agree that the relationship with Aesthetic Record is non-exclusive and Aesthetic Record may perform services for and contract with as many additional customers, persons, or entities in any market segment in Aesthetic Record’s sole discretion as it sees fit.</p>
<p>You agree to not purchase search engine or other pay-per-click keywords (such as Google AdWords), or domain names that use Aesthetic Record or Aesthetic Record trademarks and/or variations and misspellings thereof.</p>
<p>This Agreement may be available in languages other than English. To the extent any inconsistencies or conflicts arise between the English version of this Agreement and another version of Agreement available in a different language, the most current English version of the Agreement at <a href="https://www.AestheticRecord.com/legal/terms" target="_blank">https://www.AestheticRecord.com/legal/terms</a> will prevail.</p>
<p>In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business registration, government-issued photo ID, the last four digits of the credit or debit card(s) on file, the last four digits of the bank account number(s) on file, paperwork confirming your status as an authorized employee of an entity, etc. Aesthetic Record retains the right, in our sole discretion, to determine rightful Account ownership and the ability transfer an Account to the rightful owner. If we are unable to reasonably determine the rightful owner, without prejudice to our other rights and remedies, Aesthetic Record reserves the right to temporarily disable an Account until resolution has been determined between the disputing parties.</p>
<p>Except as otherwise provided in this Agreement, if any provision of this Agreement is held to be invalid, void or for any reason unenforceable, such provision shall be struck out and shall not affect the validity and enforceability of the remaining provisions. All the terms and provisions of this Agreement shall be binding upon and inure to the benefit of the parties to the Agreement and to their respective heirs, successors, permitted assigns and legal representatives. In our sole discretion, we may assign this Agreement, and in such an event, we will send notice to the email address registered with your Account. You shall have no right to assign or otherwise transfer the Agreement, or any of your rights or obligations hereunder, including but not limited to the transfer of any Account, to any third party without Aesthetic Record’s prior written consent which is to be given or withheld in Aesthetic Record’s sole discretion. You must send any request to assign to Aesthetic Record by certified mail to the following address: Aesthetic Record, Attn: Tiphany Hall, Re: Assignment Request, 1303 West Walnut Hill Lane #200, Irving, TX.</p>
<p>You and Aesthetic Record are independent contractors, and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between us. You will have no authority to make or accept any offers or representations on Aesthetic Record’s behalf. Nothing expressed or mentioned in or implied from this Agreement is intended or will be construed to give to any person other than the parties to this Agreement any legal or equitable right, remedy, or claim under or in respect to this Agreement.</p>
<p>Headings are for reference purposes only and do not limit the scope or extent of each Section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this Agreement.</p>
<p>You acknowledge and agree that Aesthetic Record may change, add, or delete portions of this Agreement at any time by posting the relevant amended and restated Agreement to our website(s) or application(s), effective at the time of posting unless otherwise stated. Continued use of the Services following notice of any such changes will indicate your acknowledgement of such changes and agreement to be bound by the revised Agreement, inclusive of such change.</p>
<p>Your use of and access to certain Services may be subject to additional terms (“Supplemental Terms”) and such Supplemental Terms will be presented to you for your acceptance when you sign up to use the supplemental Service. If this Agreement is inconsistent with the Supplemental Terms, the Supplemental Terms will control with respect to the service with which it applies.</p>
<p>We will not be liable for any delay or failure to perform any of our obligations under this Agreement by reasons, events or other matters beyond our reasonable control.</p>
<p>This Agreement represents the entire agreement between the parties with respect to the Services and related subject matter and supersedes any previous or contemporaneous oral or written agreements and understandings.</p>
<p>If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.</p> 
`;

const termsOfServiceLink =
  "https://api.dev.arinternal.xyz/legal/aesthetic_record_terms_of_service_20250311153251.pdf";
const baaLink =
  "https://api.dev.arinternal.xyz/legal/aesthetic_record_baa_20250320133227.pdf";

export const AGREEMENT_TEXTS = {
  baa: BAA,
  termsOfService: termsOfService,
};

export const AGREEMENT_TYPES = {
  baa: "baa",
  termsOfService: "terms_of_service",
};

export const AGREEMENT_LINK = {
  termsOfService: termsOfServiceLink,
  baa: baaLink,
};
