import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  userProfile,
  createUser,
  getUser,
  fetchSelectMD,
  fetchClinics,
  deleteUser,
  getDefaultUserData,
  checkFutureAppointment,
  exportEmptyData,
  checkEmail,
} from '../../../../Actions/Settings/settingsActions.js';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import validator from 'validator';
import { Link } from 'react-router-dom';
import FileUploader from '../../../FileUploader/FileUploader.js';
import defLogo from '../../../../_legacy/images/appmale.png';
import {
  isPositiveNumber,
  getDegreeDesignation,
  getCurrencySymbol,
} from '../../../../Utils/services.js';
import popupClose from '../../../../_legacy/images/popupClose.png';
import ReactSelect from '../../../../Components/Common/ReactSelect.js';
import Loader from '../../../Common/Loader.js'
import {fetchCountrySuggestions, resetCountrySuggestions} from "../../../../Actions/countrySuggestionsActions";
import * as _ from "lodash";
import { withInputConfirm } from './withInputConfirm.js';
import { InputPhone } from '../../../../shared/InputPhone/InputPhone.js';
import { phoneUtil } from '../../../../utilities/phone';
import { uiNotification } from '../../../../services/UINotificationService.js';
import { tCommon } from '../../../../i18n/useAppTranslation.js';
import { http } from '../../../../services/HttpService.js';
import { withAppTranslation } from '../../../../i18n/useAppTranslation.js';

const EmailInputWithConfirm = withInputConfirm(
  (p) => <input {...p} />,
  {
    confirmMsg: `Please confirm that :value is a legitimate email address.
    Please note, if this is a false email, the user will not be able to login.`,
    valueAccessor: "value",
    onBlurAccessor: "onBlur",
  },
);

const PhoneInputWithConfirm = withInputConfirm(
  (p) => <InputPhone fullWidth variant="legacy-outlined" {...p} />,
  {
    confirmMsg: `Please confirm that :value is an accurate
    cellphone number for this user. If this is not a legitimate
    cell number, the user will not be able to receive OTP codes to login.`,
    valueAccessor: "value",
    onBlurAccessor: "onBlur",
    valueWrapper: phoneUtil.compose,
  },
);

class CreateEditUser extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    let user = JSON.parse(localStorage.getItem('userData'))
    this.state = {
      accountType : (userData.account != undefined && userData.account.account_type != undefined) ? userData.account.account_type : 'trial',
      other_user_image: '',
      other_user_image_url: '',
      userData: {},
      firstnameMD: '',
      lastnameMD: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      address_line_4: '',
      bio_description: '',
      bio_name: '',
      bio_title: '',
      contact_number_1: '',
      contact_number_2: '',
      contact_number_3: '',
      contact_number_4: '',
      commission_percentage: '',
      city: '',
      country: '',
      email_id: '',
      email_id_2: '',
      email_id_3: '',
      firstname: '',
      lastname: '',
      startFresh: true,
      clinic_array_error: false,
      showModal: false,
      showPriceModal: false,
      user_changed: false,
      monthly_procedure_goal: '0',
      monthly_sales_goal: '0',
      pincode: '',
      weekly_sales_goal: '0',
      weekly_procedure_goal: '0',
      website: '',
      is_dashboard_enabled: true,
      supplementary_title: '',
      user_role_id: userData.account.account_preference.is_touch_md == 1 && userData.account.account_subscription.plan_code == 'practice_booking' ? 1 : '',
      clinic_list: [],
      state: '',
      cash_drawer_notification: true,
      is_md_consent_required: false,
      md_user_id: '',
      is_available_online: true,
      userId: '',
      userChanged: false,
      firstnameClass: 'newInputField',
      lastnameClass: 'newInputField',
      email_idClass: 'newInputField',
      passcodeClass: 'newInputField',
      passwordClass: 'newInputField',
      bio_nameClass: 'newInputField',
      bio_descriptionClass: 'newInputField',
      stateClass: 'newInputField',
      cityClass: 'newInputField',
      countryClass: 'newInputField',
      commission_percentageClass: 'newInputField',
      clinic_name: '',
      clinicList: [],
      select_Default_Clinic: [],
      clinic_id: '',
      select_Role: '',
      select_clinics: '',
      clinics_array: [],
      password: '',
      pass_code: '',
      is_provider: false,
      rowDisplayEnable: 'row',
      rowDisplayDisable: 'row no-display',
      mdConsetDisplayEnable: 'setting-container work_as_provider_container border-top',
      mdConsetDisplayDisable: 'setting-container work_as_provider_container no-display',
      selectedOption: null,
      defaultOptions: [],
      select_RoleClass: 'newSelectField',
      inputType: 'password',
      contactClass1: 'newInputField',
      contactClass2: 'newInputField',
      contactClass3: 'newInputField',
      contactClass4: 'newInputField',
      select_DefClinicClass: 'newSelectField',
      dbfirstname: '',
      uploadedFile: '',
      dzImgObj: {},
      showLoader: false,
      loggedInUserId: user.user.id,
      loggedInAdminId: user.account.admin_id,
      loggedInUserType: (user.user_type) ? user.user_type : '',
      loggedInRoleName: (user && user.user && user.user.role_name) ? user.user.role_name : '',
      loggedInUserPermissions: (user.permissions) ? user.permissions : [],
      loggedInUserIsDemo: user.user.is_demo,
      settingsLang: languageData.settings,
      receive_checkin_sms: false,
      mdUserIdError: false,
      price_per_user: '',
      isShowDeleteButton: false,
      isShowPrivilegesButton: false,
      defaultCountry: (localStorage.getItem('cCode')) ? localStorage.getItem('cCode') : 'us',
      isRender: false,
      futureAppoinmentData : {},
      providerList : [],
      showFutureAppoinmentModal : false,
      assign_to_provider_id : 0,
      assign_to_provider_idClass : 'simpleSelect',
      provider_delete_appointment_msg : '',
      clinic_listObject:[],
      clinic_listError:false,
      select_RoleError:false,
      appointment_color:'',
      provider_colors:[],
      appointment_colorError:false,
      hourly_rate: '',
      hourly_rateClass : 'newInputField',
      degree_designation:'',
      degree_designationClass : 'newSelectField',
      degree_designationList:getDegreeDesignation(),
      globalLang : languageData.global,
      state_license: '',
      dea: '',
      npi: '',
      medicaid_upin:'',
      state_licenseClass: 'newInputField',
      deaClass: 'newInputField',
      npiClass: 'newInputField',
      medicaid_upinClass:'newInputField',
      show_password : this.props.match.params.userId ? false : true,
      user_id : this.props.match.params.userId ?this.props.match.params.userId : '',
      isTouchMd : (userData && userData.account) ? userData.account.account_preference.is_touch_md : 0,
      plan_selected: userData.account.account_subscription.plan_code,
      is_user_has_password: false,
      is_tips_enabled: true,
    };

    window.onscroll = () => {
      return false;
    }
    localStorage.setItem("showLoader", false);
  }
  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", true);
  }

  handleDocumentClick = () => {
    this.props.resetCountrySuggestions();
  }

  handleCountrySelect = (country) => {
    this.setState({ country });
    this.props.resetCountrySuggestions();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    let isShowDeleteButton = true;
    let isShowPrivilegesButton = false;
    if (this.state.loggedInUserId !== undefined) {
      if (this.state.loggedInUserId == this.props.match.params.userId) {
        isShowDeleteButton = false;
      } else if (this.state.loggedInAdminId == this.props.match.params.userId) {
        isShowDeleteButton = false;
      }
      if ((this.state.loggedInUserType == 'superadmin' || this.state.loggedInUserPermissions.indexOf('manage-user-roles') != -1) && (this.props.match.params.userId) && this.state.loggedInUserId != this.props.match.params.userId) {
        if (this.state.loggedInUserIsDemo == 0) {
          isShowPrivilegesButton = true
        }
      }
    }
    this.setState({ isShowDeleteButton: isShowDeleteButton, isShowPrivilegesButton: isShowPrivilegesButton })
    window.onscroll = () => {
      return false;
    }
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    let userId = (this.props.match.params.userId) ? this.props.match.params.userId : 0;
    let isRender = false;
    if (!userId) {
      isRender = true;
    }
    this.setState({
      validation_clinics_array_required: languageData.global['validation_clinics_array_required'],
      createUsers_Work_As_Provider: languageData.settings['createUsers_Work_As_Provider'],
      profile_first_name: languageData.settings['profile_first_name'],
      editUsers_Drop_Files_To_Upload: languageData.settings['editUsers_Drop_Files_To_Upload'],
      profile_last_name: languageData.settings['profile_last_name'],
      saveBtn: languageData.global['saveBtn'],
      editUsers_Edit_User: languageData.settings['editUsers_Edit_User'],
      editUsers_Clinic_Information: languageData.settings['editUsers_Clinic_Information'],
      editUsers_Dashboard_Access: languageData.settings['editUsers_Dashboard_Access'],
      editUsers_Yes: languageData.settings['editUsers_Yes'],
      editUsers_No: languageData.settings['editUsers_No'],
      editUsers_Select_Role: languageData.settings['editUsers_Select_Role'],
      editUsers_Select_clinics: languageData.settings['editUsers_Select_clinics'],
      editUsers_Select_Default_Clinic: languageData.settings['editUsers_Select_Default_Clinic'],
      editUsers_Website: languageData.settings['editUsers_Website'],
      editUsers_User_Information: languageData.settings['editUsers_User_Information'],
      editUsers_Supplementary_Title: languageData.settings['editUsers_Supplementary_Title'],
      editUsers_Email: languageData.settings['editUsers_Email'],
      editUsers_Password: languageData.settings['editUsers_Password'],
      editUsers_Passcode: languageData.settings['editUsers_Passcode'],
      editUsers_Generate_Passcode: languageData.settings['editUsers_Generate_Passcode'],
      editUsers_Provider_Address_Information: languageData.settings['editUsers_Provider_Address_Information'],
      editUsers_Contact_No_1: "Contact No",
      editUsers_Contact_No_2: languageData.settings['editUsers_Contact_No_2'],
      editUsers_Contact_No_3: languageData.settings['editUsers_Contact_No_3'],
      editUsers_Contact_No_4: languageData.settings['editUsers_Contact_No_4'],
      editUsers_Email_2: languageData.settings['editUsers_Email_2'],
      editUsers_Email_3: languageData.settings['editUsers_Email_3'],
      editUsers_Address_Line_1: "Address Line",
      editUsers_Address_Line_2: languageData.settings['editUsers_Address_Line_2'],
      editUsers_Address_Line_3: languageData.settings['editUsers_Address_Line_3'],
      editUsers_Zip_Code: languageData.settings['editUsers_Zip_Code'],
      editUsers_City: languageData.settings['editUsers_City'],
      editUsers_State: languageData.settings['editUsers_State'],
      editUsers_Country: languageData.settings['editUsers_Country'],
      editUsers_MD_Consent_Required: languageData.settings['editUsers_MD_Consent_Required'],
      editUsers_Is_availble_Smart_Booking: languageData.settings['editUsers_Is_availble_Smart_Booking'],
      editUsers_Provider_Goals: languageData.settings['editUsers_Provider_Goals'],
      editUsers_Monthly_Procedure_Goals: languageData.settings['editUsers_Monthly_Procedure_Goals'],
      editUsers_Weekly_Procedure_Goals: languageData.settings['editUsers_Weekly_Procedure_Goals'],
      editUsers_Monthly_Sales_Goals: languageData.settings['editUsers_Monthly_Sales_Goals'],
      editUsers_Weekly_Sales_Goals: languageData.settings['editUsers_Weekly_Sales_Goals'],
      editUsers_Bio_For_Appointment_Booking: languageData.settings['editUsers_Bio_For_Appointment_Booking'],
      editUsers_Display_Name: languageData.settings['editUsers_Display_Name'],
      editUsers_Display_title: languageData.settings['editUsers_Display_title'],
      editUsers_Bio: languageData.settings['editUsers_Bio'],
      editUsers_CancelBtn: languageData.settings['editUsers_CancelBtn'],
      privilege_admin: languageData.settings['privilege_admin'],
      privilege_front_desk_user: languageData.settings['privilege_front_desk_user'],
      privilege_md: languageData.settings['privilege_md'],
      privilege_provider: languageData.settings['privilege_provider'],
      user_Create_UserBtn: languageData.settings['user_Create_UserBtn'],
      editUsers_Select_MD: languageData.settings['editUsers_Select_MD'],
      showLoader: true,
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],
      Appointment_Reminder_Delete: languageData.settings['Appointment_Reminder_Delete'],
      settings_commission_percentage: languageData.settings['settings_commission_percentage'],
      settings_create_text_msg1: languageData.settings['settings_create_text_msg1'],
      settings_create_text_msg2: languageData.settings['settings_create_text_msg2'],
      Appointment_Reminder_Inactive: languageData.settings['Appointment_Reminder_Inactive'],
      currentUserId: (this.props.match.params.userId) ? this.props.match.params.userId : undefined,
      isRender: isRender,
    })
    if (this.props.match.params.userId !== undefined) {
      this.props.getUser(this.props.match.params.userId, 'user');
    } else {
      this.props.getDefaultUserData();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== undefined && prevProps.user.status === 200 && this.props.user !== undefined) {
      if (prevProps.user.data.user_image_url !== this.props.user.data.user_image_url) {
        if (this.props.user.data.user_image !== "") {
          this.setState({ defImageCls: 'no-display', cameraInPreviewCls: 'camra-icon dz-clickable camera-in-preview', dzCSS: '', other_user_image: this.props.user.data.user_image, other_user_image_url: this.props.user.data.user_image_url });
        } else {
          this.setState({ uploadedFile: this.props.user.data.user_image, other_user_image: this.props.user.data.user_image });
        }
        let reInitData = {};
        reInitData.dzImgObj = this.state.dzImgObj;
        reInitData.mockFile = { name: this.props.user.data.user_image_url, accepted: true, size: 7627 };
        reInitData.other_user_image_url = this.props.user.data.user_image_url;
        reInitData.other_user_image = this.props.user.data.user_image;
        this.refs.child.reInit(reInitData);
      }
    }

    if (this.props.checkEmailData && prevProps.checkEmailData !== this.props.checkEmailData) {
      if (this.props.checkEmailData.email_exists) {
        uiNotification.error("E-mail already used.");
      }
    }

  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    switch (target.type) {
      case 'checkbox': {
        value = target.checked;
        break;
      }
      case 'radio': {
        value = (target.value == true || target.value == "true") ? true : false;
        break;
      }
      default: {}
    }
    if (target.name === "country" && value && value.length >= 2) {
      this.fetchCountrySuggestions(value);
    }
    this.setState({ [event.target.name]: value, userChanged: true });
  }

  fetchCountrySuggestions = _.debounce((value) => {
    this.props.fetchCountrySuggestions(value)
  }, 250);

  checkEmail = (event) => {
    const target = event.target;
    let value = target.value;
    let formData = {
      email_id : value
    }
    if(event.target.value.length > 0){
      this.setState({ showLoader : true, checkEmailID : value });
      this.props.checkEmail(formData);
    }
  }

  handleChildChange = (stateToUpdate) => {
    this.setState(stateToUpdate);
    this.setState({showLoader: true})
    if(stateToUpdate.showLoader == false){
      this.setState({showLoader: false})
    }
  }

  handleUserRole = (e) => {
    this.setState({
      select_Role: e.target.value
    });
  }

  changeInputType = () => {
    this.setState({
      inputType: (this.state.inputType == 'password') ? 'text' : 'password'
    });
  }

  getPassCode = () => {
    this.showLoaderFunc()
    http.get("user/check-passcode")
      .then(res => {
        this.setState({ pass_code: res.data.data.passcode, showLoader: false })
      })
      .catch(function (error) {
      });
  }

  phoneNumberChanged = (index, nextPhone) => {
    if (this.state.contactError && phoneUtil.isPossible(phoneUtil.compose(nextPhone))) {
      this.setState({
        contactError: false,
        userChanged: true
      });
    }
    this.setState({
      [`contact_number_${index}`]: nextPhone,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let error = false;
    this.setState({
      firstnameError: false,
      clinic_array_error: false,
      lastnameError: false,
      email_idError: false,
      passwordError: false,
      passcodeError: "",
      bio_nameError: false,
      bio_descriptionError: false,
      stateError: false,
      cityError: false,
      countryError: false,
      select_DefClinicError: false,
      commission_percentageError: false,
      select_Role: false,
      clinic_listError:false,
      select_RoleError:false,
      appointment_colorError:false,
      state_licenseClass:'newInputField',
      deaClass:'newInputField',
      npiClass:'newInputField',
    });

    if (typeof this.state.user_role_id === undefined || this.state.user_role_id === null || this.state.user_role_id === '') {
      this.setState({
        select_RoleError: true,
        select_RoleClassError: 'newInputField field_error'
      })
      error = true;
    } else if (this.state.user_role_id) {
      this.setState({
        select_RoleError: false,
        select_RoleClass: 'newInputField'
      })
    }
    if (typeof this.state.firstname === undefined || this.state.firstname === null || this.state.firstname.trim() === '') {
      this.setState({
        firstnameError: true,
        firstnameClassError: 'newInputField field_error'
      })
      error = true;
    } else if (this.state.firstname) {
      this.setState({
        firstnameError: false,
        firstnameClass: 'newInputField'
      })
    }
    if (typeof this.state.lastname === undefined || this.state.lastname === null || this.state.lastname.trim() === '') {
      this.setState({
        lastnameError: true,
        lastnameClassError: 'newInputField field_error'
      })
      error = true;
    } else if (this.state.lastname) {
      this.setState({
        lastnameError: false,
        lastnameClass: 'newInputField'
      })
    }
    if (typeof this.state.email_id === undefined || this.state.email_id === null || this.state.email_id.trim() === '') {
      this.setState({
        email_idError: true,
        email_idClassError: 'newInputField field_error'
      })
      error = true;
    } else if (!validator.isEmail(this.state.email_id)) {
      this.setState({
        email_idError: true,
        email_idClassError: 'newInputField field_error'
      })
      uiNotification.error("Incorrect email address");
      error = true;
    } else if (this.state.email_id) {
      this.setState({
        email_idError: false,
        email_idErrorClass: 'newInputField'
      })
    }
    if (this.state.is_provider === true || this.state.user_role_id == 2) {
      if (this.state.is_md_consent_required === true || this.state.is_md_consent_required === 'true') {
        if (!this.state.md_user_id) {
          this.setState({ mdUserIdError: true })
          error = true;
        }
      }
      if (typeof this.state.bio_name === undefined || this.state.bio_name === null || this.state.bio_name.trim() === '') {
        this.setState({
          bio_nameError: true,
          bio_nameClassError: 'newInputField field_error'
        })
        error = true;
      } else if (this.state.bio_name) {
        this.setState({
          bio_nameError: false,
          bio_nameErrorClass: 'newInputField'
        })
      }
      if (typeof this.state.bio_description === undefined || this.state.bio_description === null || this.state.bio_description.trim() === '') {
        this.setState({
          bio_descriptionError: true,
          bio_descriptionClassError: 'newInputField field_error'
        })
        error = true;
      } else if (this.state.bio_description) {
        this.setState({
          bio_descriptionError: false,
          bio_descriptionErrorClass: 'newInputField'
        })
      }
    }

    if (typeof this.state.user_role_id === undefined || this.state.user_role_id === null || this.state.user_role_id === '') {
      this.setState({
        select_RoleError: true,
        select_RoleClassError: 'newSelectField field_error'
      })
      error = true;
    } else if (this.state.select_Role) {
      this.setState({
        select_RoleError: false,
        select_RoleClass: 'newSelectField'
      })
    }

    if (typeof this.state.clinic_id == undefined || this.state.clinic_id == null || this.state.clinic_id == '' || this.state.clinic_id <=0) {
      this.setState({
        select_DefClinicError: true,
        select_DefClinicClassError: 'newSelectField field_error'
      })
      error = true;
    }  else if (this.state.clinic_id) {
      this.setState({
        select_DefClinicError: false,
        select_DefClinicClass: 'newInputField'
      })
    }

    if (this.state.is_provider == true || this.state.user_role_id == 2) {
      if (this.state.commission_percentage && !isPositiveNumber(this.state.commission_percentage)) {
        this.setState({
          commission_percentageError: true,
          commission_percentageClass: 'newInputField field_error'
        })
        error = true;
      }
      if (this.state.commission_percentage && isPositiveNumber(this.state.commission_percentage)) {
        this.setState({
          commission_percentageError: false,
          commission_percentageClass: 'newInputField'
        })
      }
    }


    if (typeof this.state.clinic_list == undefined
      || this.state.clinic_list == null
      || this.state.clinic_list == ''
      || (this.state.clinic_list && this.state.clinic_list.length <= 0)
    ) {
      this.setState({
        clinic_listError:true,
      })
      error = true;
    }

    if (typeof this.state.appointment_color == undefined || this.state.appointment_color == null || this.state.appointment_color == '') {
      this.setState({
        appointment_colorError: true,
      })
      error = true;
      uiNotification.error('Please choose appointment color')
    }

    if (this.state.loggedInUserType == 'superadmin' || this.state.loggedInRoleName == 'admin'){
      if(!isPositiveNumber(this.state.hourly_rate,0)){
        this.setState({hourly_rateClass: 'newInputField field_error'})
        error = true;
      } else if(this.state.hourly_rate){
        this.setState({hourly_rateClass: 'newInputField'})
      }

      if (typeof this.state.degree_designation == undefined || this.state.degree_designation == null || this.state.degree_designation == ''){
        this.setState({degree_designationClass: 'newSelectField field_error'})
        error = true;
      } else if(this.state.degree_designation){
        this.setState({degree_designationClass: 'newSelectField'})
      }
    }

    if (this.props.checkEmailData !== undefined && this.props.checkEmailData.need_phone_number) {
      if (this.state.contact_number_1.trim() === "" || !phoneUtil.isPossible(phoneUtil.compose(this.state.contact_number_1))) {
        this.setState({
          contactError: true,
        })
        error = true;
        uiNotification.error(tCommon('formError.phoneInvalid'));
      } else {
        this.setState({
          contactError: false,
        })
      }
    }

    if (error === true) {
      return;
    }

    let formData = {
      clinics_array: this.state.clinic_list,
      address_line_1: this.state.address_line_1,
      address_line_2: this.state.address_line_2,
      address_line_3: this.state.address_line_3,
      address_line_4: this.state.address_line_4,
      bio_description: this.state.bio_description,
      contact_number_1: phoneUtil.compose(this.state.contact_number_1),
      contact_number_2: phoneUtil.compose(this.state.contact_number_2),
      contact_number_3: phoneUtil.compose(this.state.contact_number_3),
      contact_number_4: phoneUtil.compose(this.state.contact_number_4),
      city: this.state.city,
      country: this.state.country,
      commission_percentage: this.state.commission_percentage,
      email_id: this.state.email_id,
      email_id_2: this.state.email_id_2,
      email_id_3: this.state.email_id_3,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      monthly_procedure_goal: this.state.monthly_procedure_goal,
      monthly_sales_goal: this.state.monthly_sales_goal,
      pincode: this.state.pincode,
      weekly_sales_goal: this.state.weekly_sales_goal,
      weekly_procedure_goal: this.state.weekly_procedure_goal,
      website: this.state.website,
      is_dashboard_enabled: (this.state.is_dashboard_enabled === true || this.state.is_dashboard_enabled === 'true') ? 0 : 1,
      supplementary_title: this.state.supplementary_title,
      user_role_id: this.state.user_role_id,
      state: this.state.state,
      is_md_consent_required: (this.state.is_md_consent_required) ? 1 : 0,
      md_user_id: (this.state.is_md_consent_required) ? this.state.md_user_id : 0,
      is_available_online: (this.state.is_available_online) ? 1 : 0,
      clinic_id: this.state.clinic_id,
      pass_code: this.state.pass_code ? this.state.pass_code: '',
      is_provider: (this.state.is_provider == true) ? 1 : 0,
      user_image: this.state.other_user_image,
      receive_checkin_sms: (this.state.receive_checkin_sms == true) ? 1 : 0,
      appointment_color:this.state.appointment_color,
      is_tips_enabled: Number(this.state.is_tips_enabled)
    }

    if (this.state.user_role_id == 1) {
      formData.cash_drawer_notification = (this.state.cash_drawer_notification == true) ? 1 : 0;
    }

    if (this.state.is_provider || this.state.user_role_id == 2) {
      formData.bio_name = this.state.bio_name;
      formData.bio_title = this.state.bio_title;
      formData.bio_description = this.state.bio_description;
      formData.state_license = this.state.state_license;
      formData.npi = this.state.npi;
      formData.dea = this.state.dea;
      formData.medicaid_upin = this.state.medicaid_upin;
    }
    if (this.state.password != '' && this.state.show_password == true && this.state.is_user_has_password) {
      formData.password = this.state.password
    }

    if (this.state.loggedInUserType == 'superadmin' || this.state.loggedInRoleName == 'admin'){
      formData.hourly_rate = this.state.hourly_rate;
      formData.degree_designation = this.state.degree_designation;
    }

    if (this.props.match.params.userId !== undefined) {
      this.showLoaderFunc();
      this.props.userProfile(formData, this.props.match.params.userId, 'user')
        .catch(e => {
          if (e.message === "user_has_schedule_in_clinic") {
            uiNotification.error("Cannot remove a clinic with either schedule or appointment");
          } else {
            uiNotification.error(this.state.globalLang[e.message]);
          }
        })
        .finally(() => {
          this.setState({
            showLoader: false, 
          });
        });
    } else {
      if(this.state.accountType === 'trial' || (this.state.isTouchMd == 1 )){
        this.showLoaderFunc();
        this.props.createUser(formData);
      } else {
        this.setState({ showPriceModal: true, userFormData: formData })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      if (localStorage.getItem("showLoader") == "false") {
        return { showLoader: false };
      }
    }
    if (props.userData != undefined && props.userData !== state.userData && props.userDataTime != state.userDataTime) {
      if (localStorage.getItem("showLoader") == "false") {
        let x = (state.userChanged) ? state.other_user_image : ((props.userData.user_image == null) ? '' : props.userData.user_image);
        let clinic_listObject = []
        if (!state.userChanged) {
          if (props.userData.clinics_array.length && state.clinicList) {
            props.userData.clinics_array.map((obj, idx) => {
              let c = props.userData.clinics.find(y => y.id === obj);
              if (c)
                clinic_listObject.push({ value: c.id, label: c.clinic_name })
            })
          }
        }
        let provider_colors = (state.provider_colors.length) ? state.provider_colors : (props.userData.provider_colors) ? props.userData.provider_colors : [];
        props.exportEmptyData()
        return {
          userDataTime: props.userDataTime,
          userData: props.userData,
          showLoader: false,
          dbfirstname: props.userData.firstname,
          firstname: (state.userChanged) ? state.firstname : props.userData.firstname,
          lastname: (state.userChanged) ? state.lastname : props.userData.lastname,
          address_line_1: (state.userChanged) ? state.address_line_1 : props.userData.address_line_1,
          address_line_2: (state.userChanged) ? state.address_line_2 : props.userData.address_line_2,
          address_line_3: (state.userChanged) ? state.address_line_3 : props.userData.address_line_3,
          address_line_4: (state.userChanged) ? state.address_line_4 : props.userData.address_line_4,
          bio_description: (state.userChanged) ? state.bio_description : props.userData.bio_description,
          bio_name: (state.userChanged) ? state.bio_name : props.userData.bio_name,
          bio_title: (state.userChanged) ? state.bio_title : (props.userData.bio_title) ? props.userData.bio_title : '',
          contact_number_1: phoneUtil.compose((state.userChanged) ? state.contact_number_1 : props.userData.contact_number_1),
          contact_number_2: phoneUtil.compose((state.userChanged) ? state.contact_number_2 : props.userData.contact_number_2),
          contact_number_3: phoneUtil.compose((state.userChanged) ? state.contact_number_3 : props.userData.contact_number_3),
          contact_number_4: phoneUtil.compose((state.userChanged) ? state.contact_number_4 : props.userData.contact_number_4),
          city: (state.userChanged) ? state.city : props.userData.city,
          country: (state.userChanged) ? state.country : props.userData.country,
          commission_percentage: (state.userChanged) ? state.commission_percentage : ((props.userData.commission_percentage) ? props.userData.commission_percentage : ""),
          email_id: (state.userChanged) ? state.email_id : props.userData.email_id,
          email_id_2: (state.userChanged) ? state.email_id_2 : (props.userData.email_id_2) ? props.userData.email_id_2 : '',
          email_id_3: (state.userChanged) ? state.email_id_3 : (props.userData.email_id_3) ? props.userData.email_id_3 : '',
          monthly_procedure_goal: (state.userChanged) ? state.monthly_procedure_goal : props.userData.monthly_procedure_goal,
          monthly_sales_goal: (state.userChanged) ? state.monthly_sales_goal : props.userData.monthly_sales_goal,
          pincode: (state.userChanged) ? state.pincode : props.userData.pincode,
          weekly_sales_goal: (state.userChanged) ? state.weekly_sales_goal : props.userData.weekly_sales_goal,
          weekly_procedure_goal: (state.userChanged) ? state.weekly_procedure_goal : props.userData.weekly_procedure_goal,
          website: (state.userChanged) ? state.website : props.userData.website,
          is_dashboard_enabled:  ((props.userData.is_dashboard_enabled) ? true : false),
          supplementary_title: (state.userChanged) ? state.supplementary_title : props.userData.supplementary_title,
          user_role_id: (state.userChanged) ? state.user_role_id : props.userData.user_role_id,
          state: (state.userChanged) ? state.state : props.userData.state,
          cash_drawer_notification: (state.userChanged) ? state.cash_drawer_notification : ((props.userData.cash_drawer_notification && props.userData.cash_drawer_notification != undefined ) ? true : false),
          is_md_consent_required: (state.is_md_consent_required) ? state.is_md_consent_required : ((props.userData.is_md_consent_required) ? true : false),
          md_user_id: (state.md_user_id) ? state.md_user_id : props.userData.md_user_id,
          is_available_online: ((props.userData.is_available_online) ? true : false),
          is_provider: props.userData.is_provider,
          clinics_array: (state.userChanged) ? state.clinics_array : props.userData.clinics_array,
          clinic_id: (state.userChanged) ? state.clinic_id : props.userData.clinic_id,
          pass_code: (state.userChanged) ? state.pass_code : props.userData.pass_code,
          defaultOptions: (state.userChanged) ? state.defaultOptions : props.userData.defaultOptions,
          receive_checkin_sms: (state.userChanged) ? state.receive_checkin_sms : props.userData.receive_checkin_sms,
          other_user_image: (state.userChanged) ? state.other_user_image : ((props.userData.user_image == null) ? '' : props.userData.user_image),
          other_user_image_url: props.userData.user_image_url,
          role_id: props.userData.user_role_id,
          MDListData: props.userData.md_list,
          clinicList: (props.userData.clinics) ? props.userData.clinics : [],
          isRender: true,
          clinic_list : (props.userData.clinics_array) ? props.userData.clinics_array : [],
          clinic_listObject :clinic_listObject,
          appointment_color: (state.userChanged) ? state.appointment_color : (props.userData.appointment_color) ? props.userData.appointment_color : '',
          provider_colors :provider_colors,
          hourly_rate: (props.userData.hourly_rate >= 0 ) ? props.userData.hourly_rate : '',
          degree_designation: (props.userData.degree_designation) ? props.userData.degree_designation : '',
          state_license: (props.userData.state_license) ? props.userData.state_license : '',
          dea: (props.userData.dea) ? props.userData.dea : '',
          npi: (props.userData.npi) ? props.userData.npi : '',
          medicaid_upin: (props.userData.medicaid_upin) ? props.userData.medicaid_upin : '',
          is_tips_enabled: state.userChanged ? state.is_tips_enabled : Boolean(props.userData.is_tips_enabled)
        };
      }
    }
    else if (
      props.defaultUserData != undefined &&
      props.defaultUserData !== state.defaultUserData
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        let returnState = {};
        returnState.provider_colors = (state.provider_colors.length)
          ? state.provider_colors
          : (props.defaultUserData.provider_colors)
            ? props.defaultUserData.provider_colors
            : [];
        returnState.clinicList = (state.clinicList.length)
          ? state.clinicList
          : (props.defaultUserData.clinics)
            ? props.defaultUserData.clinics
            : [];
        returnState.MDListData = (state.userChanged) ? state.MDListData : props.defaultUserData.md_list;
        returnState.price_per_user = (state.userChanged) ? state.MDListData : props.defaultUserData.price_per_user;
        returnState.defaultUserData = props.defaultUserData;
        returnState.userChanged = false;
        returnState.showLoader = false;
        if (!state.currentUserId) {
          returnState.dbfirstname = "asd";
        }
        return returnState;
      }
    }
    else if (props.MDListData != undefined && props.MDListData.data !== state.MDListData) {
      if (localStorage.getItem("showLoader") == "false") {
        return {
          MDListData: (state.userChanged) ? state.MDListData : props.MDListData.data,
          userChanged: false,
          showLoader: false
        };
      }
    }
    else if (props.redirect !== undefined && props.redirect === true) {
      if (localStorage.getItem("showLoader") == "false") {
        if (props.redirectMode) {
          uiNotification.error(props.message)
          props.history.push('/settings/users');
        } else {
          uiNotification.success(props.message)
          props.history.push('/settings/users');
        }
      }
    }
    else if (props.futureAppoinmentData !== undefined && props.futureAppoinmentData !== state.futureAppoinmentData) {
      let returnState = {}
      returnState.futureAppoinmentData = props.futureAppoinmentData
      returnState.providerList = (returnState.futureAppoinmentData.providers) ? returnState.futureAppoinmentData.providers : []
      returnState.upcoming_appointment_count = returnState.futureAppoinmentData.upcoming_appointment_count;
      returnState.showModal = (returnState.upcoming_appointment_count <= 0) ? true : false
      returnState.showFutureAppoinmentModal = (returnState.upcoming_appointment_count > 0) ? true : false
      returnState.assign_to_provider_id = 0;
      returnState.assign_to_provider_idClass = 'simpleSelect';
      returnState.showLoader = false
      returnState.provider_delete_appointment_msg = "Provider has "+returnState.upcoming_appointment_count+ ((returnState.upcoming_appointment_count > 1) ? " appointments" : " appointment") +" in future. You can inactivate provider only if he does not have any appointment or his appointments are reassigned to other provider."
      return returnState;
    }

    if(props.checkEmailData !== undefined && props.checkEmailDataTime != state.checkEmailDataTime){
      let returnState = {};
      returnState.checkEmailDataTime = props.checkEmailDataTime;
      returnState.showLoader = false;
      returnState.show_password = props.checkEmailData.email_exists == true ? false : true;
      returnState.is_user_has_password = !props.checkEmailData.is_user_has_password;
      return returnState;
    }
    return null;
  }

  emailAlreadyRegistered = () => {
    return this.props.checkEmailData && this.props.checkEmailData.email_exists;
  }

  userHasPassword = () => {
    return this.props.checkEmailData && this.props.checkEmailData.is_user_has_password;
  }

  showDeleteModal = () => {
    let id = this.props.match.params.userId;
    if(this.state.userData.user_role_id === 2 || this.state.userData.is_provider === true){
      this.showLoaderFunc();
      this.props.checkFutureAppointment(id);
    } else{
      this.setState({ showModal: true })
    }
  }

  dismissModal = () => {
    this.setState({ showModal: false })
  }

  showPriceModal = () => {
    this.setState({ showPriceModal: true })
  }

  dismissPriceModal = () => {
    this.setState({ showPriceModal: false })
  }

  deleteUserID = () => {
    let id = this.props.match.params.userId;
    let formData = {
      assign_to_provider_id :  0
    }
    if(this.state.showFutureAppoinmentModal && (this.state.userData.user_role_id === 2 || this.state.userData.is_provider === true)){
      if(this.state.assign_to_provider_id <= 0){
        this.setState({assign_to_provider_idClass:'simpleSelect field_error  field-error'})
        return false
      } else {
        formData.assign_to_provider_id = this.state.assign_to_provider_id
        this.setState({assign_to_provider_idClass:'simpleSelect'})
      }
    } else {
    }
    this.showLoaderFunc();
    this.dismissModal();
    this.dismissFutureAppoinmentModal();
    this.props.deleteUser(id,formData);
  }

  createUserNow = () => {
    this.setState({ showPriceModal: false })
    this.showLoaderFunc();
    this.props.checkEmail({ email_id : this.state.checkEmailID })
    this.props.createUser(this.state.userFormData);
  }

  dismissFutureAppoinmentModal = () => {
    this.setState({ showFutureAppoinmentModal: false })
  }

  handleReactSelect = (selectState) => {
    this.setState(selectState)
    let returnState = {}
    if(selectState['clinic_listObject']){
      if(selectState.clinic_list.length <= 0){
        returnState.clinic_id = ''
      } else if(this.state.clinic_id <= 0 && selectState.clinic_list.length == 1){
        returnState.clinic_id = selectState.clinic_list[0]
      } else if (selectState['clinic_listObject'].length > 0 && selectState.clinic_list.indexOf(this.state.clinic_id) <= -1){
        returnState.clinic_id = ''
      }
      returnState.userChanged = true;
      this.setState(
        returnState
      );
    }
  }

  isTipsEnabled = () => {
    const selectedClinic = this.state.clinicList.filter(clinic => {
      if (this.state.clinic_list.includes(clinic.id)) {
        return clinic
      }
      return null
    })

    return selectedClinic?.filter(clinic => clinic.is_tips_enabled).length > 0;
  }

  render() {    
    let provider_colors = []
    provider_colors = this.state.provider_colors
    provider_colors = [
      '#00008B', '#0000CD', '#8B0000', '#FF8C00',
      '#32CD32', '#808000', '#FF6F61', '#008080',
      '#8A2BE2', '#00BFFF', '#37C5AB', '#FF0000',
      '#1E90FF', '#FF00FF', '#E15D44', '#FC91AD',
      '#92A8D1', '#BDB76B', '#D2B48C', '#696969'
    ];

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="setting-setion">
              <form id="edit-user-form" name="login-form" className="nobottommargin" action="#" method="post" onSubmit={this.handleSubmit}>
                <div className="setting-container">
                  <div className="setting-title">
                    {this.props.match.params.userId ? this.state.editUsers_Edit_User : (this.state.accountType === 'trial' || (this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking')) ? this.state.user_Create_UserBtn : (this.state.settings_create_text_msg1 +  ' ' + this.state.price_per_user + ' ' + this.state.settings_create_text_msg2)}
                    <Link to="/settings/users" className="pull-right crossIcon cancelAction"><img src="/images/close.png" /></Link>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 profile-detail-left">
                      <div className="main-profile-picture">
                        {this.state.dbfirstname != '' && <FileUploader type='profile' uploadedFileName={'other_user_image'} fileUrl={'other_user_image_url'} other_user_image={this.state.other_user_image} other_user_image_url={this.state.other_user_image_url} defLogo={defLogo} handleChildChange={this.handleChildChange} ref="child" containerClass={'dropzone-holder'} />}
                      </div>
                      {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? <div className="settings-subtitle m-b-20 ">{this.state.editUsers_Clinic_Information}
                        <div className="pull-right dash-access-outer">
                          {(this.state.isShowPrivilegesButton) && <Link to={"/settings/user-privileges/" + this.state.currentUserId + '/role/' + this.state.role_id} className="new-blue-btn pull-right edit_setting" id="manage-privileges">Manage Privileges</Link>}
                        </div>
                      </div> : <div className="settings-subtitle m-b-20 ">{this.state.editUsers_Clinic_Information}
                        <div className="pull-right dash-access-outer">
                          <span className="setting-text dash-access">{this.state.editUsers_Dashboard_Access}</span>
                          <input type="radio" name="is_dashboard_enabled" id="UserIsDashboardEnabled0" checked={this.state.is_dashboard_enabled} className="basic-form-checkbox" onChange={this.handleInputChange} value={true} />
                          <label htmlFor="UserIsDashboardEnabled0" className="basic-form-text">{this.state.editUsers_Yes}</label>
                          <input type="radio" name="is_dashboard_enabled" id="UserIsDashboardEnabled1" checked={!this.state.is_dashboard_enabled} className="basic-form-checkbox" onChange={this.handleInputChange} value={false} />
                          <label htmlFor="UserIsDashboardEnabled1" className="basic-form-text">{this.state.editUsers_No}</label>
                          {(this.state.isShowPrivilegesButton) && <Link to={"/settings/user-privileges/" + this.state.currentUserId + '/role/' + this.state.role_id} className="new-blue-btn pull-right edit_setting" id="manage-privileges">Manage Privileges</Link>}
                        </div>
                      </div>}
                      <div className="row">
                      {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' :<div className="col-lg-4">
                         <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Select_Role} <span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <select name="user_role_id" className={(this.state.select_RoleError === true) ? this.state.select_RoleClassError : this.state.select_RoleClass} onChange={this.handleInputChange} value={this.state.user_role_id}>
                                <option value=''>Select</option>
                                <option value='1'>{this.state.privilege_admin}</option>
                                <option value='2'>{this.state.privilege_provider}</option>
                                <option value='3'>{this.state.privilege_front_desk_user}</option>
                                <option value='4'>{this.state.privilege_md}</option>
                              </select>
                            </div>
                          </div>
                        </div>}
                        <div className={this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? "col-lg-12" : 'col-lg-8'}>
                          <div className="simpleField">
                            <div className="simpleLabel">{this.state.editUsers_Select_clinics}<span className="setting-require">*</span></div>
                            <div className="addSaleSelect">
                            <ReactSelect name={'clinic_list'} selectedId={this.state.clinic_list} list={this.state.clinicList} value={'id'} label={'clinic_name'} handleReactSelect={this.handleReactSelect} isValid={!this.state.clinic_listError} placeholder={'Select Clinic'} isMulti={true} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Select_Default_Clinic}<span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <select name="clinic_id" className={(this.state.select_DefClinicError == true) ? this.state.select_DefClinicClassError : this.state.select_DefClinicClass} onChange={this.handleInputChange} value={this.state.clinic_id} >
                                <option value={-1}>Select</option>
                                {this.state.clinic_listObject.map((lobj, lidx) => {
                                  return (
                                    <option value={lobj.value} key={lobj.value} >{lobj.label}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Website}</div>
                            <div className="setting-input-outer">
                              <input name="website" id="website1" className="newInputField" maxLength={500} type="text" value={(this.state.website !== null) ? this.state.website : ""} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                      </div>
                      <div className="settings-subtitle m-b-20 m-t-20">{this.state.editUsers_User_Information}</div>
                      <div className="row">
                        {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? "" :
                          this.state.user_role_id == 1 &&  <div className="m-b-20 pull-left full-width">
                            <div className="col-lg-7 col-md-12">{this.state.settingsLang.editUsers_cash_drawler_notification}<span className="setting-require">*</span></div>
                            <div className="col-lg-5 col-md-12">
                              <div className="basic-checkbox-outer">
                                <input id="radio_button1" className="basic-form-checkbox md_consent_required" name="cash_drawer_notification" type="radio"
                                  checked={this.state.cash_drawer_notification}
                                  value={true} onChange={this.handleInputChange} />
                                <label className="basic-form-text" htmlFor="radio_button1">{this.state.editUsers_Yes}</label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input id="radio_button2" className="basic-form-checkbox md_consent_required" name="cash_drawer_notification" type="radio" checked={!this.state.cash_drawer_notification} value={false} onChange={this.handleInputChange} />
                                <label className="basic-form-text" htmlFor="radio_button2">{this.state.editUsers_No}</label>
                              </div>
                            </div>
                        </div>}
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Supplementary_Title}</div>
                            <div className="setting-input-outer">
                              <input name="supplementary_title" id="supplementary_title" className="newInputField" type="text" value={(this.state.supplementary_title) ? this.state.supplementary_title : ""} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.profile_first_name}<span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <input name="firstname" id="firstname" className={(this.state.firstnameError === true) ? this.state.firstnameClassError : this.state.firstnameClass} maxLength={255} type="text" value={this.state.firstname} onChange={this.handleInputChange} autoComplete="off" disabled={this.props.match.params.userId ? true : false}/>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.profile_last_name}<span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <input name="lastname" id="lastname" className={this.state.lastnameError === true ? this.state.lastnameClassError : this.state.lastnameClass} maxLength={255} type="text" value={this.state.lastname} onChange={this.handleInputChange} autoComplete="off" disabled={this.props.match.params.userId ? true : false}/></div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12'>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {this.state.editUsers_Email}
                              <span className="setting-require">*</span>
                            </div>
                            <div className="setting-input-outer">
                              <EmailInputWithConfirm
                                onBlur={this.checkEmail}
                                name="email_id"
                                id="user_email"
                                className={this.state.email_idError === true
                                  ? this.state.email_idClassError
                                  : this.state.email_idClass}
                                maxLength="500"
                                type="email"
                                value={this.state.email_id}
                                onChange={this.handleInputChange}
                                autoComplete="off"
                                disabled={this.props.match.params.userId ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                        {this.userHasPassword() && !this.emailAlreadyRegistered() && <div className='col-lg-12 colorAR'>
                          <h6>Note: {this.state.checkEmailID} is an existing user on Aesthetic Record. You do not need to set their password or passcode, they will receive an email invitation to join your workspace with instructions. </h6>
                        </div>}
                        {this.emailAlreadyRegistered() && <div className='col-lg-12 colorAR'>
                          <h6>Note: sorry, this email already exists in your account, please choose a unique email address for your user</h6>
                        </div>}
                        <div className="preferred-color col-lg-5 col-xs-12 position-relative dropdown m-t-20">
                          <label>Appointment color</label>
                          <a className="new-white-btn changeColor" id="dropdownMenu-preferredColor" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Change</a>
                          <span className="current-color"  style={{backgroundColor:(this.state.appointment_color) ? this.state.appointment_color : '#fff', borderColor:(this.state.appointment_color) ? this.state.appointment_color : (this.state.appointment_colorError) ? '#e74c3c' : '#667680' }}>
                                </span>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu-preferredColor">
                              {provider_colors.map((obj,idx) => {
                                return (
                                  <li key={"provider_colors-"+idx} data-color={obj}>
                                    <span className="color-palette" onClick={() => this.setState({appointment_color:obj,appointment_colorError:false})} style={{backgroundColor:obj, borderColor:obj }}>
                                      {(obj == this.state.appointment_color) &&
                                        <span className="color-palette-checked"><i className="fa fa-check" style={{color:'#fff'}}></i></span>
                                      }
                                    </span>
                                  </li>
                                )
                              })}
                            </ul>
                        </div>
                      </div>
                      {(this.state.loggedInUserType == 'superadmin' || this.state.loggedInRoleName == 'admin') &&
                        <div className="row d-flex items-center">
                          <div className="col-lg-4">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">{this.state.settingsLang.settings_degree_designation}<span className="setting-require">*</span></div>
                              <div className="setting-input-outer">
                                <select name="degree_designation" className={this.state.degree_designationClass} onChange={this.handleInputChange} value={this.state.degree_designation} >
                                  <option value=''>Select</option>
                                  {
                                    this.state.degree_designationList.map((dObj, dIdx) => {
                                      return (
                                        <option value={dObj} key={dObj} >{dObj}</option>
                                      )
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">{this.state.settingsLang.settings_hourly_rate}<span className="setting-require">*</span></div>
                              <div className="setting-input-outer hide-pwd-outer">
                                <input name="hourly_rate" id="hourly_rate" className={this.state.hourly_rateClass} maxLength={10} type="text" value={this.state.hourly_rate} onChange={this.handleInputChange} autoComplete="off" />
                                  <div className="hidePassword">{getCurrencySymbol()}</div>
                                </div>
                              </div>
                          </div>
                          {this.isTipsEnabled() && <div className="col-lg-4">
                            <div className="newInputFileldOuter m-b-0 d-flex">
                              <input type="checkbox" className="new-check m-t-3" id="enabled-tips" name="is_tips_enabled" checked={this.state.is_tips_enabled} onChange={this.handleInputChange}/>
                              <label htmlFor="enabled-tips">Is this user eligible to accept Tips?</label>
                            </div>
                          </div>}
                        </div>
                      }
                      <div className="settings-subtitle m-b-20 m-t-20">{this.state.editUsers_Provider_Address_Information}</div>
                      <div className="row">
                      {((this.props.checkEmailData !== undefined && !this.props.match.params.userId && this.props.checkEmailData.need_phone_number) || (this.props.match.params.userId)) && <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              {this.state.editUsers_Contact_No_1}
                              <span className="setting-require">*</span>
                            </div>
                            <div className="setting-input-outer flag-input">
                              <input type="hidden" name="full_number" />
                              {this.state.isRender && (
                                <PhoneInputWithConfirm
                                  value={this.state.contact_number_1 || ""}
                                  onChange={this.phoneNumberChanged.bind(this, 1)}
                                  isDisabled={Boolean(this.props.match.params.userId)}
                                  isError={this.state.contactError}
                                />
                              )}
                              <input type="hidden" name="data[User][full_number]" id="full_number" />
                            </div>
                          </div>
                        </div>}
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Address_Line_1}</div>
                            <div className="setting-input-outer">
                              <input name="address_line_1" className="newInputField" id="address_line_1" value={(this.state.address_line_1) ? this.state.address_line_1 : ""} onChange={this.handleInputChange} maxLength={500} type="text" autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_City} </div>
                            <div className="setting-input-outer">
                              <input name="city" id="city" className={this.state.cityError === true ? this.state.cityClassError : this.state.cityClass} maxLength={255} value={(this.state.city) ? this.state.city : ""} onChange={this.handleInputChange} type="text" autoComplete="off" />	</div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_State} </div>
                            <div className="setting-input-outer">
                              <input name="state" id="state" className={this.state.stateError === true ? this.state.stateClassError : this.state.stateClass} maxLength={255} type="text" value={this.state.state} onChange={this.handleInputChange} autoComplete="off" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Zip_Code}</div>
                            <div className="setting-input-outer">
                              <input name="pincode" className="newInputField" id="pincode" maxLength={255} value={(this.state.pincode) ? this.state.pincode : ""} onChange={this.handleInputChange} type="text" autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Country} </div>
                            <div className="setting-input-outer relative">
                              <input
                                name="country"
                                id="country"
                                className={this.state.countryClass}
                                maxLength={255}
                                value={this.state.country} onChange={this.handleInputChange} type="text" autoComplete="off" />
                              {this.props.countrySuggestions.hasData &&
                                <ul className="search-dropdown">
                                  {this.props.countrySuggestions.data.map(c => <li key={c}><a onClick={() => this.handleCountrySelect(c)}>{c}</a></li>)}
                                </ul>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className={(this.state.is_provider == true || this.state.user_role_id == 2) ? "newInputFileldOuter" : 'no-display'} >
                            <div className="newInputLabel">{this.state.settings_commission_percentage}</div>
                            <div className="setting-input-outer">
                              <input name="commission_percentage" id="commission_percentage" className={this.state.commission_percentageClass} maxLength={255} type="text" value={this.state.commission_percentage} onChange={this.handleInputChange} autoComplete="off" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={this.state.user_role_id == 2 ? 'no-display' : "setting-container border-top work_as_provider_container"}>
                  <div className="setting-title no-margin" id="work_as_provider_box">{this.state.createUsers_Work_As_Provider}
                    <label className="setting-switch pull-right" >
                      <input type="checkbox" name="is_provider" className="setting-custom-switch-input" checked={(this.state.is_provider) ? 'checked' : false} onChange={this.handleInputChange} />
                      <span className="setting-slider"></span>
                    </label>
                  </div>
                </div>
                <div className={((this.state.user_role_id == 2) || this.state.is_provider == true) ? this.state.mdConsetDisplayEnable : this.state.mdConsetDisplayDisable}>
                  <div className="row is-provider-form-title" >
                    <div className="col-xs-12 profile-detail-left">
                      {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' : <div className="row">
                        <div className="col-lg-7 col-md-12">{this.state.editUsers_MD_Consent_Required}<span className="setting-require">*</span></div>
                        <div className="col-lg-5 col-md-12">
                          <div className="basic-checkbox-outer">
                            <input id="radiobutton1" className="basic-form-checkbox md_consent_required" name="is_md_consent_required" type="radio"
                              checked={this.state.is_md_consent_required}
                              value={true} onChange={this.handleInputChange} />
                            <label className="basic-form-text" htmlFor="radiobutton1">{this.state.editUsers_Yes}</label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input id="radiobutton2" className="basic-form-checkbox md_consent_required" name="is_md_consent_required" type="radio" checked={!this.state.is_md_consent_required} value={false} onChange={this.handleInputChange} />
                            <label className="basic-form-text" htmlFor="radiobutton2">{this.state.editUsers_No}</label>
                          </div>
                        </div>
                      </div>}
                      <div className={(this.state.is_md_consent_required === 'true' || this.state.is_md_consent_required == true) ? this.state.rowDisplayEnable : this.state.rowDisplayDisable} id="select_md_consent">
                        <div className="col-lg-5 ">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Select_MD}<span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <select name="md_user_id" id="md_user_id" className={(this.state.mdUserIdError) ? "newSelectField field_error" : "newSelectField"} value={this.state.md_user_id} onChange={this.handleInputChange}>
                                <option value={0} onChange={this.handleInputChange}>Select</option>
                                {
                                  this.state.MDListData !== undefined && this.state.MDListData !== null && this.state.MDListData.map((obj, idx) => {
                                    return (
                                      <option key={idx} value={obj.id} onChange={this.handleInputChange}>{obj.firstname} {obj.lastname}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-7 col-md-12">
                          {this.state.editUsers_Is_availble_Smart_Booking}
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <div className="basic-checkbox-outer">
                            <input id="radiobutton3" className="basic-form-checkbox" name="is_available_online" type="radio" value='true' onChange={this.handleInputChange} checked={this.state.is_available_online} />
                            <label className="basic-form-text" htmlFor="radiobutton3">{this.state.editUsers_Yes}</label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input id="radiobutton4" className="basic-form-checkbox" name="is_available_online" type="radio" value='false' checked={!this.state.is_available_online} onChange={this.handleInputChange} />
                            <label className="basic-form-text" htmlFor="radiobutton4">{this.state.editUsers_No}</label>
                          </div>
                        </div>
                      </div>
                      <div className="settings-subtitle m-b-20 m-t-20">{this.state.editUsers_Provider_Goals}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Monthly_Procedure_Goals}</div>
                            <div className="setting-input-outer">
                              <input name="monthly_procedure_goal" id="monthly_goal" className="newInputField" maxLength={11} value={this.state.monthly_procedure_goal} onChange={this.handleInputChange} type="text" autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Weekly_Procedure_Goals}</div>
                            <div className="setting-input-outer">
                              <input name="weekly_procedure_goal" id="monthly_goal1" className="newInputField" maxLength={11} value={this.state.weekly_procedure_goal} onChange={this.handleInputChange} type="text" autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Monthly_Sales_Goals}</div>
                            <div className="setting-input-outer">
                              <input name="monthly_sales_goal" id="monthly_goal2" className="newInputField" type="text" value={this.state.monthly_sales_goal} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Weekly_Sales_Goals}</div>
                            <div className="setting-input-outer">
                              <input name="weekly_sales_goal" id="monthly_goal3" className="newInputField" type="text" value={this.state.weekly_sales_goal} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                      </div>
                      <div className="settings-subtitle m-b-20 m-t-20">{this.state.editUsers_Bio_For_Appointment_Booking}</div>
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Display_Name} <span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <input name="bio_name" id="bio_name" className={this.state.bio_nameError === true ? this.state.bio_nameClassError : this.state.bio_nameClass} maxLength={255} type="text" value={this.state.bio_name || ""} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Display_title}</div>
                            <div className="setting-input-outer">
                              <input name="bio_title" id="bio_title" className="newInputField" maxLength={255} type="text" value={this.state.bio_title} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.editUsers_Bio} <span className="setting-require">*</span></div>
                            <div className="setting-input-outer">
                              <input name="bio_description" id="bio_description" className={this.state.bio_descriptionError === true ? this.state.bio_descriptionClassError : this.state.bio_descriptionClass} value={this.state.bio_description || ""} onChange={this.handleInputChange} type="text" autoComplete="off" /></div>
                          </div>
                          <div className="m-b-30 pull-left">
                            <input type="checkbox"  className="new-check child_view-patients" id="recieveCheckInSms" name="receive_checkin_sms" checked={(this.state.receive_checkin_sms) ? 'checked' : false} onChange={this.handleInputChange} />
                            <label className="setting-text" htmlFor="recieveCheckInSms">{this.state.settingsLang.receive_checkin_sms_text}</label>
                          </div>
                        </div>
                      </div>
                      <div className="settings-subtitle m-b-20 m-t-20">{'Prescriber Information'}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{'State License #'} </div>
                            <div className="setting-input-outer">
                              <input name="state_license" id="state_license" className={this.state.state_licenseClass} value={this.state.state_license} onChange={this.handleInputChange} type="text" autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{'DEA #'} </div>
                            <div className="setting-input-outer">
                              <input name="dea" id="dea" className={this.state.deaClass} value={this.state.dea} onChange={this.handleInputChange} type="text" autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{'NPI #'} </div>
                            <div className="setting-input-outer">
                              <input name="npi" id="npi" className={this.state.npiClass} type="text" value={this.state.npi} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{'Medicaid UPIN #'}</div>
                            <div className="setting-input-outer">
                              <input name="medicaid_upin" id="medicaid_upin" className={this.state.medicaid_upinClass} type="text" value={this.state.medicaid_upin} onChange={this.handleInputChange} autoComplete="off" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer-static">
                  <input className="new-blue-btn pull-right" id="editsave" type="submit" value="Save" disabled={this.emailAlreadyRegistered() ? "disabled" : false} />
                  <Link to="/settings/users" className="new-white-btn pull-right cancelAction">
                    Cancel
                  </Link>
                  {(this.state.isShowDeleteButton) &&
                    <button type="button" className={this.props.match.params.userId ? "new-red-btn pull-left" : "no-display"} id="resetform" onClick={this.showDeleteModal} data-message={this.state.settingsLang.settings_are_you_sure_del_PTI} >{/*this.state.Appointment_Reminder_Inactive*/ "Inactivate"}</button>
                  }
                  {/*   DELETE MODAL - START  */}
                  <div className={(this.state.showModal ? 'overlay' : '')}></div>
                  <div id="filterModal" role="dialog" className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div className="modal-header-content">
                            <h4 className="modal-title" id="model_title">{this.state.settingsLang.settings_Confirmation_required}{this.state.showModal}</h4>
                            <div className="d-flex">
                              <button type="button" className="close" data-dismiss="modal" onClick={this.dismissModal}>×</button> 
                            </div>
                          </div>
                        </div>
                        <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                          {"Are you sure you want to Inactivate this user?"}
                        </div>
                        <div className="modal-footer" >
                          <div className="col-md-12 text-left modal-footer-buttons-wrapper" id="footer-btn">
                            <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.dismissModal}>{this.state.settingsLang.no_option}</button>
                            <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.deleteUserID}>{this.state.settingsLang.yes_option}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*   DELETE MODAL - END  */}
                  {/*   FUTURE APPOINTMENT MODAL - START  */}
                  <div className={(this.state.showFutureAppoinmentModal === true) ? "blackOverlay" : "blackOverlay no-display"}>
                    <div className="vert-middle">
                      <div className="white-popup">
                        <div className="white-popup-wrapper">
                          <div className="membershipTypeTitle">Assign Appointments To Provider<a onClick={() => this.dismissFutureAppoinmentModal()} className="popupClose"><img src={popupClose} /></a></div>
                          <div className="row">
                            <div className="col-xs-12 m-b-20">
                              {this.state.provider_delete_appointment_msg}
                            </div>
                            <div className="col-xs-12 m-b-20">
                              <div className="simpleField">
                                <div className="simpleLabel">{'Provider'}<span className="fieldRequired">*</span></div>
                                <select type="text" className={this.state.assign_to_provider_idClass} autoComplete="off" name="assign_to_provider_id" value={this.state.assign_to_provider_id} onChange={this.handleInputChange} >
                                  <option value="" >Select</option>
                                  {(this.state.providerList && this.state.providerList.length) &&
                                    this.state.providerList.map((obj,idx) => {
                                      return (<option key={'provider-'+idx} value={obj.id} >{obj.firstname+' '+obj.lastname}</option>)
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="footer-static p-r-24">
                          <a className="new-blue-btn pull-right" onClick={this.deleteUserID}>{'Assign & Inactivate'}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*   FUTURE APPOINTMENT MODAL - END  */}
                  {/*   AMOUNT CHARGE MODAL - START  */}
                  <div className={(this.state.showPriceModal ? 'overlay' : '')}></div>
                  <div id="filterModal" role="dialog" className={(this.state.showPriceModal ? 'modal fade in displayBlock' : 'modal fade')}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <div className="modal-header-content">
                            <h4 className="modal-title" id="model_title">Charges</h4>
                            <div className="d-flex">
                              <button type="button" className="close" data-dismiss="modal" onClick={this.dismissPriceModal}>×</button>
                            </div>
                          </div>
                        </div>
                        <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                          {this.props.tSettings("createUser", {prisePerUser: this.state.price_per_user})}
                      </div>
                        <div className="modal-footer">
                          <div className="col-md-12 text-left modal-footer-buttons-wrapper" id="footer-btn">
                            <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.dismissPriceModal}>{this.state.settingsLang.no_option}</button>
                            <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.createUserNow}>{this.state.settingsLang.yes_option}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*   AMOUNT CHARGE MODAL - END  */}
                </div>
              </form>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {};
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  localStorage.setItem("showLoader", false);
  if (state.SettingReducer.action === "USER_GET") {
    if (state.SettingReducer.data.status != 200) {
      returnState.redirect = true;
      returnState.redirectMode = 'error';
      returnState.message = languageData.global[state.SettingReducer.data.message];
    }
    else {
      returnState.userData = state.SettingReducer.data.data;
      returnState.userDataTime = new Date()
    }
  }

  if (state.SettingReducer.action === "MDS_LIST") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.MDListData = state.SettingReducer.data
    }
  }

  if (state.SettingReducer.action === "USER_CREATE") {
    if (state.SettingReducer.data.status != 201) {
      returnState.updateData = state.SettingReducer.data
      returnState.showLoader = false
    }
    else {
      returnState.redirect = true;
      returnState.message = "Email Invite Sent Successfully!";
    }
  }

  if (state.SettingReducer.action === "DEFAULT_USERADD_DATA") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.defaultUserData = state.SettingReducer.data.data
    };
  }
  if (state.SettingReducer.action === "USER_DELETE") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;

      const message = state.SettingReducer?.data?.message;
      
      uiNotification.clear();
      uiNotification.error(languageData.global[message] || tCommon(`apiError.${message}`, message));
    }
    else {
      returnState.redirect = true;
      returnState.message = languageData.global[state.SettingReducer.data.message]
    }
  }
  if (state.SettingReducer.action === "USER_UPDATE") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      // update localStorage data if logged-user update user-data (in case of self) - START
      if(state.SettingReducer.data.data !== undefined && state.SettingReducer.data.data.login_data !== undefined){
        let login_data = state.SettingReducer.data.data.login_data;
        if(login_data && login_data.user_role_id > 0){
          let userData = JSON.parse(localStorage.getItem('userData'));
          userData.user = login_data
          localStorage.setItem('currentUserRole', login_data.user_role_id);
          localStorage.setItem('userData', JSON.stringify(userData))
          returnState.status = 200;
        }
      }
      // update localStorage data if logged-user update user-data (in case of self) - END
      returnState.redirect = true;
      returnState.message = languageData.global[state.SettingReducer.data.message];
    }
  }

  if (state.SettingReducer.action === "CHECK_FUTURE_APPOINTMENT") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
      uiNotification.clear();
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
    }
    else {
      returnState.futureAppoinmentData = state.SettingReducer.data.data
    }
  }

  if(state.SettingReducer.action === 'CHECK_EMAIL'){
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
      uiNotification.clear();
      uiNotification.error(languageData.global[state.SettingReducer.data.message]);
    }
    else {
      returnState.checkEmailData = state.SettingReducer.data.data;
      returnState.checkEmailDataTime = new Date();
    }
  }

  returnState.countrySuggestions = state.CountrySuggestionsReducer.suggestions;
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCountrySuggestions,
    resetCountrySuggestions,
    getUser: getUser, fetchClinics: fetchClinics,
    userProfile: userProfile,
    createUser: createUser,
    fetchSelectMD: fetchSelectMD,
    deleteUser: deleteUser,
    getDefaultUserData: getDefaultUserData,
    checkFutureAppointment: checkFutureAppointment,
    exportEmptyData:exportEmptyData,
    checkEmail:checkEmail,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withAppTranslation("Settings")(CreateEditUser));
