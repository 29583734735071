import React from "react";
import styles from "./UpdateTermsInfo.module.scss";

export function UpdateTermsInfo() {
  return (
    <div className={styles.updateInfoWrapper}>
      <h3>{`We've Updated Our Terms of Service`}</h3>
      <div className={styles.info}>
        Please find updated information including HIPAA breach protocol updates,
        required cyber liability insurance, third-party integration information
        and a new Business Associate Addendum. Once reviewed, sign below to
        acknowledge and accept these important updates.
      </div>
    </div>
  );
}
