import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useEditAppointmentConfigurationService = (
  serviceId,
  options = {},
) => {
  const res = useMutation(
    async (dto) => http.put(HTTP_ENDPOINTS.editService(serviceId), dto),
    { ...options },
  );
  return res;
};
