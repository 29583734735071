import queryString from "query-string";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function useSessionExpireMessage() {
  var { tAuth: t } = useAppTranslation.Auth();

  var searchParams = queryString.parse(window.location.search);
  var withMessage = searchParams?.sessionExpired === "true";

  return withMessage ? t("error.sessionExpired") : "";
}
