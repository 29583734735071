import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useUpdateRepeatMdMembershipColor = (options = {}) => {
  const res = useMutation(
    (dto) =>
      http.put(
        HTTP_ENDPOINTS.repeatMdIntegration.putRepeatMdIntegrationMemberships(),
        dto,
      ),
    { ...options },
  );
  return res;
};
