import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useRepeatMdMemberships = (options = {}) => {
  const res = useQuery(
    ["repeatMdMemberships"],
    async () =>
      http.get(
        HTTP_ENDPOINTS.repeatMdIntegration.getRepeatMdIntegrationMemberships(),
      ),
    { select: (data) => data.data.data, ...options },
  );
  return res;
};
