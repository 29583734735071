import React from "react";
import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import { getAccountId } from "../../utilities/localStorage";
import {
  CURRENCY_DEFAULT,
  CURRENCY_SYMBOL_DEFAULT,
} from "../../consts/general";

const requestSchema = yup.object({
  accountId: yup.number().nullable(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  adminId: yup.number().required(),
  isPosEnabled: yup.bool().required(),
  paymentSystem: yup.string().nullable(),
  subscription: yup
    .object({
      planCode: yup.string().nullable(),
    })
    .required(),
  subscriptionPlan: yup.string().nullable(),
  preferences: yup.object({
    clientGlobalName: yup.string().nullable(),
    timeFormat: yup.string().nullable(),
    isTouchMd: yup.bool().required(),
    isWalletExpirationConfigured: yup.bool().required(),
    weightUnit: yup.string().nullable(),
    lengthUnit: yup.string().nullable(),
    temperatureUnit: yup.string().nullable(),
  }),
  businessInfo: yup
    .object({
      freeTaxableDocName: yup.string().nullable(),
      freeTaxableDocUrl: yup.string().nullable(),
    })
    .required(),
  integrations: yup
    .object({
      isQualiphyEnabled: yup.bool().required(),
      isShopifyEnabled: yup.bool().required(),
    })
    .required(),
  globalSettings: yup
    .object({
      isPosEnabled: yup.bool().required(),
      posType: yup.string().nullable(),
      currency: yup.string().required(),
      currencySymbol: yup.string().required(),
      featureFlag: yup.object({
        appointmentDowntime: yup.boolean(),
        betaFeatures: yup.boolean(),
        efax: yup.boolean(),
        inbox: yup.boolean(),
        newBi: yup.boolean(),
        quickbooks: yup.boolean(),
      }),
    })
    .required(),
  createdAt: yup.string().required(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  const globalSettings = unwrapOr(() => res.data.global_settings, {});
  const preferences = data?.account_preference || {};
  const businessInfo = data?.account_business_info || {};

  return {
    id: data.id,
    adminId: data.admin_id,
    isPosEnabled: Boolean(data.pos_enabled),
    paymentSystem: data.pos_gateway || null,
    subscription: {
      planCode: data?.account_subscription?.plan_code || null,
    },
    subscriptionPlan: data?.account_subscription?.plan_code,
    preferences: {
      isTouchMd: Boolean(preferences.is_touch_md),
      clientGlobalName: preferences.client_replacement_text || null,
      timeFormat: preferences.time_format_original || null,
      isWalletExpirationConfigured:
        (preferences.wallet_item_expiry > 0 &&
          preferences.wallet_item_expiry !== 50000) ||
        (preferences.wallet_dollar_expiry > 0 &&
          preferences.wallet_dollar_expiry !== 50000),
      weightUnit: preferences.weight_unit,
      lengthUnit: preferences.length_unit,
      temperatureUnit: preferences.temperature_unit,
    },
    businessInfo: {
      freeTaxableDocName: businessInfo.free_taxable_doc,
      freeTaxableDocUrl: businessInfo.free_taxable_doc_url,
    },
    integrations: {
      isQualiphyEnabled: Boolean(globalSettings.qualiphy),
      isShopifyEnabled: Boolean(globalSettings.shopify_enabled),
    },
    globalSettings: {
      isPosEnabled: Boolean(globalSettings?.pos_data?.is_pos_enabled),
      posType: globalSettings?.pos_data?.stripe_connection || null,
      currency: globalSettings?.pos_data?.currency_code || CURRENCY_DEFAULT,
      currencySymbol:
        globalSettings?.pos_data?.currency_symbol || CURRENCY_SYMBOL_DEFAULT,
      featureFlag: {
        appointmentDowntime: globalSettings?.feature_flag?.appointment_downtime,
        betaFeatures: globalSettings?.feature_flag?.beta_features,
        efax: globalSettings?.feature_flag?.efax,
        inbox: globalSettings?.feature_flag?.inbox,
        newBi: globalSettings?.feature_flag?.new_bi,
        quickbooks: globalSettings?.feature_flag?.quickbooks,
      },
    },
    createdAt: data.created,
  };
};

function createAccountQueryKey({ accountId }) {
  return [QUERY_KEYS.account, accountId];
}

function useAccountQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createAccountQueryKey({ accountId: req.accountId }),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getAccount(req.accountId), {
        params: {
          scopes:
            "accountPreference,activeUsers,accountSubscription,account_business_info",
        },
      });
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}

export const useCurrentAccountQuery = (options = {}) => {
  const accountId = Number(getAccountId());
  return useAccountQuery({
    payload: {
      accountId,
    },
    options: {
      enabled: Boolean(accountId),
      ...options,
    },
  });
};

export const withCurrentAccountQuery = (Component) => {
  return function Wrapper(props) {
    const currentAccountQuery = useCurrentAccountQuery();
    return <Component {...props} currentAccountQuery={currentAccountQuery} />;
  };
};
