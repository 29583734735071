import { useEffect } from "react";
import { authService } from "../../../services/AuthService";
import { logout } from "../../../helpers/auth";

export function useAutoLogout() {
  useEffect(() => {
    const wpuId = authService.getLoginData().wpuId;
    if (!authService.isLoggedIn() || !wpuId) {
      logout();
    }
  }, []);
}
