import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputPhone } from "../components/InputPhone";
import classes from "../sass/ClientHandler.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { MODE } from "../ClientHandler.consts";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { useUpdateForm } from "../hooks/useUpdateForm";
import { useUpdatePatientProfileMutation } from "../../../../../api/mutations/useUpdatePatientProfileMutation";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../services/UINotificationService";
import { splitFullName } from "../../../../../utilities/general";
import { InputEmail } from "../components/InputEmail";
import { InputName } from "../components/InputName";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { phoneUtil } from "../../../../../utilities/phone";
import { isDobForBookingAvailable } from "../../../../../helpers/featureFlags";
import { InputDob } from "../../../../../shared/InputDob";
import { dobToApiDate } from "../ClientHandler.utils";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";

export function FormEdit({
  id,
  name,
  email,
  phone,
  dob,
  onSuccess,
  setMode,
  isPhoneRequired,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { mutateAsync, isLoading } = useUpdatePatientProfileMutation();
  const { data: user } = useCurrentUserQuery();

  const onSubmit = async (values) => {
    try {
      setIsConfirmModalOpen(false);
      const { firstName, lastName } = splitFullName(values.name);
      if (isDobForBookingAvailable()) {
        await mutateAsync({
          id,
          firstName,
          lastName,
          email: values.email,
          phone: phoneUtil.compose(values.phone),
          dateOfBirth: dobToApiDate(values.dob, user?.dateFormat),
        });
      } else {
        await mutateAsync({
          id,
          firstName,
          lastName,
          email: values.email,
          phone: phoneUtil.compose(values.phone),
        });
      }
      onSuccess(id, values);
    } catch {
      uiNotification.error(tCommon("error.updatePatientProfile"));
    }
  };

  const { form, setFormValue, getError, submit, dirty, validate } =
    useUpdateForm({
      onSubmit,
      isPhoneRequired,
      initialValues: isDobForBookingAvailable()
        ? {
            name,
            email,
            phone,
            dob,
          }
        : {
            name,
            email,
            phone,
          },
    });

  const handleOpenConfirmModal = () => {
    validate().then((isValid) => {
      if (isValid) {
        setIsConfirmModalOpen(true);
      }
    });
  };

  return (
    <div>
      <div className={classes.formGrid}>
        <InputName
          value={form.name}
          error={getError("name")}
          onChange={(nextName) => setFormValue("name", nextName)}
        />
        <InputEmail
          value={form.email}
          error={getError("email")}
          onChange={(nextEmail) => setFormValue("email", nextEmail)}
        />
        <InputPhone
          value={form.phone}
          error={getError("phone")}
          onChange={(nextPhone) => setFormValue("phone", nextPhone)}
        />
        {isDobForBookingAvailable() && (
          <InputDob
            value={form.dob}
            isError={!!getError("dob")}
            onChange={({ masked }) => setFormValue("dob", masked)}
          />
        )}
      </div>
      <div className={classes.formControls}>
        <Button
          size="small"
          variant="outlined"
          fontFamily="secondary"
          onClick={() => setMode(MODE.default)}
          className={classes.formBtn}
          isDisabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          size="small"
          fontFamily="secondary"
          onClick={handleOpenConfirmModal}
          className={classes.formBtn}
          isDisabled={!dirty || isLoading}
          leftAdornment={
            isLoading ? (
              <CircularProgress size="tiny" color="white" />
            ) : undefined
          }
        >
          Save
        </Button>
      </div>
      {isConfirmModalOpen && (
        <ConfirmModal
          title="Confirm Patient Profile Updates"
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={submit}
        >
          {`You are about to permanently update this patient's information. Are
          you sure you want to continue?`}
        </ConfirmModal>
      )}
    </div>
  );
}

FormEdit.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  isPhoneRequired: PropTypes.bool.isRequired,
};

FormEdit.defaultProps = {
  phone: "",
};
