export const membershipColors = [
  "#00008B",
  "#0000CD",
  "#8B0000",
  "#FF8C00",
  "#32CD32",
  "#808000",
  "#FF6F61",
  "#008080",
  "#8A2BE2",
  "#00BFFF",
  "#37C5AB",
  "#FF0000",
  "#1E90FF",
  "#FF00FF",
  "#E15D44",
  "#FC91AD",
];
