import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { authService } from "../../services/AuthService";
import { Modal } from "../../shared/Modal/Modal";
import { Button } from "../../shared/Button/Button";
import styles from "./styles.module.scss";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { TimeoutService } from "../../services/TimeoutService";
import {
  ActivityTracking,
  IdleTabSignature,
  initTabVisibilityListener,
  logoutByIdle,
} from "./utilities";
import { useModalState } from "./hooks/useModalState";
import {
  ACTIVITY_LOG_INTERVAL,
  EVENTS,
  PROMPT_TIMEOUT,
  TIMEOUT,
} from "./config";

IdleTabSignature.sign();

export function Idle() {
  var { tCommon: t } = useAppTranslation.Common();
  var modal = useModalState();

  var idleRef = useRef(null);
  var promptRef = useRef(null);

  var trackActivity = _.debounce(() => {
    if (!ActivityTracking.isLocked()) {
      ActivityTracking.capture();
      idleRef.current?.reset();
      promptRef.current?.reset();
    }
  }, ACTIVITY_LOG_INTERVAL);

  var stay = () => {
    modal.close();
    ActivityTracking.unlock();
    trackActivity();
  };

  useEffect(() => {
    if (!idleRef.current && !promptRef.current) {
      idleRef.current = new TimeoutService({
        timeout: TIMEOUT,
        onTimeout: ({ reset }) => {
          if (IdleTabSignature.isActive()) {
            reset();
            logoutByIdle();
            ActivityTracking.unlock();
          }
        },
      });

      promptRef.current = new TimeoutService({
        timeout: PROMPT_TIMEOUT,
        onTimeout: ({ reset }) => {
          reset();
          if (authService.isLoggedIn() && IdleTabSignature.isActive()) {
            modal.open();
            ActivityTracking.lock();
          }
        },
      });

      EVENTS.forEach((event) => window.addEventListener(event, trackActivity));

      initTabVisibilityListener({
        onActive: () => {
          if (ActivityTracking.isExpired(TIMEOUT)) {
            logoutByIdle();
          }
          IdleTabSignature.activate();
          idleRef.current?.reset();
          promptRef.current?.reset();
        },
        onNotActive: () => {
          IdleTabSignature.deactivate();
          idleRef.current?.stop();
          promptRef.current?.stop();
        },
      });

      idleRef.current.start();
      promptRef.current.start();
    }
  });

  return (
    <>
      {modal.isOpen && (
        <Modal
          isOpen
          footerNoBorder
          onClose={stay}
          header={<Modal.Title>{t("idle.title")}</Modal.Title>}
          footer={
            <div className={styles.footer}>
              <Button onClick={stay}>{t("idle.stay")}</Button>
              <Button variant="outlined" onClick={() => logoutByIdle(false)}>
                {t("idle.logout")}
              </Button>
            </div>
          }
        >
          {t("idle.description")}
        </Modal>
      )}
    </>
  );
}
