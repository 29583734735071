/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "clsx";

export function MultiSelectFilter({
  btnClassName,
  title,
  data = [],
  getDisplayValue = () => "",
  selectedItems = new Set(),
  onChange = () => null,
  searchItems = () => true,
  className = "",
}) {
  const [lang] = useState(() =>
    JSON.parse(localStorage.getItem("languageData")),
  );
  const [isShown, setIsShown] = useState(false);
  const [search, setSeach] = useState("");
  const containerRef = useRef();
  const filteredData = search
    ? data.filter((item) => searchItems(search, item))
    : data;
  const hasData = !!filteredData.length;
  const areAllSelected = selectedItems.size === data.length;
  const handleToggle = (e) => {
    if (containerRef.current === e.target) {
      setIsShown(!isShown);
    }
  };
  const handleDocumentClick = useCallback((e) => {
    if (!containerRef.current.contains(e.target)) {
      setSeach("");
      setIsShown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  function handleToggleItem(item) {
    const updatedSelectedItems = new Set(selectedItems);
    if (selectedItems.has(item)) {
      updatedSelectedItems.delete(item);
    } else {
      updatedSelectedItems.add(item);
    }
    onChange(updatedSelectedItems);
    setSeach("");
  }

  function handleToggleAll() {
    if (areAllSelected) {
      onChange(new Set());
    } else {
      onChange(new Set([...data]));
    }
    setSeach("");
  }

  return (
    <div className={className}>
      <div
        ref={containerRef}
        onClick={handleToggle}
        className={cx("multi-sel-btn", btnClassName)}
      >
        {title} ({selectedItems.size})
        <ul
          ref={containerRef}
          style={{ zIndex: 10 }}
          className={`new-dropdown-menu ${!isShown && "no-display"}`}
        >
          <li id="search_select_btn_li">
            <input
              className="simpleInput"
              value={search}
              onChange={(e) => setSeach(e.target.value)}
              placeholder="Search"
              name="search"
            />
            <a
              className="line-btn text-center"
              name="eps"
              onClick={handleToggleAll}
            >
              {areAllSelected
                ? lang.sales.sales_unselect_all
                : lang.sales.sales_select_all}
            </a>
          </li>
          {hasData
            ? filteredData.map((item, index) => (
                <li key={`${item.value}${index}`}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedItems.has(item) ? "checked" : false}
                      name={"item-" + item.id}
                      onChange={() => handleToggleItem(item)}
                    />
                    {getDisplayValue(item)}
                  </label>
                </li>
              ))
            : "No data available"}
        </ul>
      </div>
    </div>
  );
}
