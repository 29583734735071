import { useMemo } from "react";
import moment from "moment";
import {
  LEADAR_CHAT_MESSAGE_TYPE,
  useLeadArInboxChatQuery,
} from "../../../../../api/queries/useLeadArInboxChatQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { CHAT_MESSAGE_TYPE } from "../../../../../shared/Chat/Chat.consts";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { CURRENCY, PREVIEW_DATE_FORMAT } from "../../../../../consts/general";
import { formatCurrency } from "../../../../../utilities/general";

const defaultImage = "/images/user.png";

const messageTypeMap = {
  [LEADAR_CHAT_MESSAGE_TYPE.message]: CHAT_MESSAGE_TYPE.received,
  [LEADAR_CHAT_MESSAGE_TYPE.reply]: CHAT_MESSAGE_TYPE.sent,
};

export function useChat(id) {
  const { tCommon } = useAppTranslation.Common();
  const { tInbox } = useAppTranslation.Inbox();
  const { data: user } = useCurrentUserQuery();

  const chat = useLeadArInboxChatQuery({
    payload: {
      id: Number(id),
    },
    options: {
      staleTime: 0,
      onError: () => {
        uiNotification.error(tInbox("leadAr.error.getChat"));
      },
    },
  });

  const chatPatient = chat.data?.patient || {};

  const preparedMessages = useMemo(() => {
    return (chat?.data?.messages || []).map((m) => ({
      id: m.id,
      type: messageTypeMap[m.type],
      date: m.date,
      author: {
        id: m.author.id,
        fullName: m.author?.fullName,
        imageUrl: m.author.imageUrl,
      },
      body: m.body,
    }));
  }, [chat?.data?.messages]);

  const preparedPatient = {
    fullName: chatPatient?.fullName,

    email: chatPatient.email,

    phone: chatPatient.phone,

    location: chatPatient.location,

    birthday: chatPatient.dateOfBirth,

    imageUrl: chatPatient.imageUrl || defaultImage,

    emergencyName: chatPatient.emergencyName,

    emergencyNumber: chatPatient.emergencyNumber,

    lastVisit: chatPatient.lastVisit
      ? moment(chatPatient.lastVisit).format(
          user?.previewDateFormat || PREVIEW_DATE_FORMAT,
        )
      : tCommon("label.never"),

    membershipProgram: chatPatient.isMonthlyMembership
      ? tCommon("label.registered")
      : tCommon("label.notRegistered"),

    totalSaleRelationship: formatCurrency(
      chatPatient.totalSaleRelationship,
      user?.currency || CURRENCY.usd,
    ),
  };

  return {
    messages: preparedMessages,
    patient: preparedPatient,
    isLoading: chat.isLoading,
  };
}
