const FILE_READER_METHODS_MAP = {
  text: "readAsText",
  arrayBuffer: "readAsArrayBuffer",
  dataUrl: "readAsDataURL",
};

class FileUtil {
  getExtensionFromName = (filename) => {
    if (filename.length === 0) return "";
    let dot = filename.lastIndexOf(".");
    if (dot === -1) return "";
    const extension = filename.substr(dot, filename.length);
    return extension;
  };

  shortenName = (filename, { start, end } = {}) => {
    if (typeof filename === "string") {
      const START = start || 10;
      const END = end || 7;
      if (filename.length <= START + END) {
        return filename;
      }
      return `${filename.slice(0, START)}...${filename.slice(
        filename.length - END,
        filename.length,
      )}`;
    } else {
      return filename;
    }
  };

  read = async (file, { method = "arrayBuffer" } = {}) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader[FILE_READER_METHODS_MAP[method]](file);
    });
  };

  readFromResource = async (url, { method = "arrayBuffer" } = {}) => {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader[FILE_READER_METHODS_MAP[method]](blob);
        });
      });
  };

  dataUrlToFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  download = (url, filename) => {
    Object.assign(document.createElement("a"), {
      href: url,
      download: filename,
    }).click();
  };

  compress = (file, width) => {
    return new Promise((res, rej) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (event) => {
        var img = new Image();
        img.src = event.target.result;

        (img.onload = () => {
          var elem = document.createElement("canvas");
          var scaleFactor = width / img.width;

          elem.width = width;
          elem.height = img.height * scaleFactor;

          var ctx = elem.getContext("2d");

          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);

          ctx.canvas.toBlob(
            (blob) => {
              const file = new File([blob], `${+new Date()}.jpg`, {
                type: "image/jpg",
                lastModified: Date.now(),
              });

              res(file);
            },
            "image/jpg",
            1,
          );
        }),
          (reader.onerror = rej);
      };
    });
  };
}

export const fileUtil = new FileUtil();
