import { useState } from "react";
import { useUploadMediaMutation } from "../api/mutations/useUploadMediaMutation";
import { useAppTranslation } from "../i18n/useAppTranslation";
import { fileUtil } from "../utilities/file";
import { uiNotification } from "../services/UINotificationService";

const ALLOWED_IMAGE_UPLOADER_TYPES = [
  "image/png",
  "image/PNG",
  "image/jpg",
  "image/JPG",
  "image/jpeg",
  "image/JPEG",
  "image/gif",
  "image/GIF",
];

/**
 * @param {{
 *  uploadType?: string,
 *  distinguishByName?: boolean,
 *  compress?: {
 *    width: number,
 *  },
 *  onLoadingChange?: (isLoading: boolean) => void,
 * }} param0
 */
export function useImageUploader({
  uploadType,
  distinguishByName,
  compress,
  onLoadingChange,
} = {}) {
  const { tCommon } = useAppTranslation.Common();
  const [isLoading, setIsLoading] = useState(false);
  const upload = useUploadMediaMutation();

  const [currentName, setCurrentName] = useState(null);
  const [queue, setQueue] = useState(new Set());

  const initiate = async (file, uploadType_, name, compress_) => {
    try {
      setIsLoading(true);
      onLoadingChange?.(true);

      if (distinguishByName) {
        setQueue((prev) => new Set([...prev, name]));
      }

      if (file && ALLOWED_IMAGE_UPLOADER_TYPES.indexOf(file.type) > -1) {
        var file_ = file;
        var compressWidth = compress_?.width || compress?.width;

        if (compressWidth) {
          file_ = await fileUtil.compress(file, compressWidth);
        }

        const fileAfterRead = await fileUtil.read(file_, {
          method: "dataUrl",
        });

        const response = await upload.mutateAsync({
          file: file_,
          type: uploadType_ || uploadType,
        });

        return {
          uploadedFileName: response.fileName,
          uploadedFileFullPath: response.fullPath,
          originalFile: file,
          compressedFile: compress ? file_ : null,
          fileAfterRead,
        };
      } else {
        uiNotification.error(tCommon("error.unsupportedFileType"));
      }
    } catch {
      uiNotification.error(tCommon("error.imageUpload"));
    } finally {
      setIsLoading(false);
      onLoadingChange?.(false);
      if (distinguishByName) {
        setQueue((prev) => {
          prev.delete(name);
          return new Set([...prev]);
        });
        setCurrentName(null);
      }
    }
  };

  const htmlOnChange = async (e, uploadType) => {
    const file = e.target?.files?.[0];
    const name = e.target?.name;

    if (distinguishByName) {
      setCurrentName(name);
    }

    return initiate(file, uploadType, name);
  };

  return {
    isLoading: distinguishByName
      ? isLoading && queue.has(currentName)
      : isLoading,
    initiate,
    htmlOnChange,
    uploadingName: currentName,
  };
}
