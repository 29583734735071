import React, { useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import styles from "../scss/RepeatMdMembershipConfiguration.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { membershipColors } from "./MembershipConfiguration.utils";
import { useUpdateRepeatMdMembershipColor } from "../hooks/useUpdateRepeatMdMembershipColor";
import { useClickOutside } from "../../../../../utilities/hooks/useClickOutside";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../services/UINotificationService";

const MembershipConfigurationInput = ({ membership, membershipsList }) => {
  const [showColorsList, setShowColorsList] = useState(false);
  const [selectedColor, setSelectedColor] = useState(
    membership.badge_color || "#00008B",
  );
  const colorContainerRef = useRef();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateRepeatMdMembershipColor();
  useClickOutside(colorContainerRef, () => setShowColorsList(false));

  const handleColorChange = (color) => {
    const updatedMemberships = membershipsList.map((m) =>
      m.id === membership.id ? { ...m, badge_color: color } : m,
    );

    mutate(
      {
        memberships: updatedMemberships,
      },
      {
        onSuccess: (data) => {
          setSelectedColor(color);
          queryClient.setQueryData(["repeatMdMemberships"], () => {
            return data;
          });
        },
        onError: (error) => uiNotification.error(error.response.data.message),
      },
    );
    setShowColorsList(false);
  };

  return (
    <div className={styles.inputFieldActions} ref={colorContainerRef}>
      <span
        className={styles.colorSquare}
        style={{ background: selectedColor }}
      ></span>
      <div className={styles.colorsContainer}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setShowColorsList(!showColorsList)}
          leftAdornment={isLoading ? <CircularProgress size="small" /> : null}
          isDisabled={isLoading}
        >
          Change
        </Button>
        {showColorsList && (
          <div className={styles.colorsList}>
            {membershipColors.map((color) => (
              <button
                key={color}
                className={styles.colorSquare}
                style={{ background: color }}
                onClick={() => handleColorChange(color)}
              ></button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MembershipConfigurationInput;
