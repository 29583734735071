import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape } from "../../utilities/general";

const dtoSchema = yup.object({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string().nullable(),
  email: yup.string().nullable(),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  gender: yup.string().nullable(),
  dateOfBirth: yup.string().nullable(),
});

export function useUpdatePatientProfileMutation(options = {}) {
  return useMutation((payload) => {
    const dto = dtoSchema.validateSync(payload, {
      strict: true,
    });
    return http.patch(
      HTTP_ENDPOINTS.patchPatientProfile(dto.id),
      removeNullishFromShape({
        firstname: dto.firstName,
        lastname: dto.lastName,
        phoneNumber: dto.phone,
        email: dto.email,
        city: dto.city,
        country: dto.country,
        gender: dto.gender,
        date_of_birth: dto.dateOfBirth,
      }),
    );
  }, options);
}
