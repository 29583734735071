import React from "react";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  agreement_type: yup.string().required(),
  date: yup.string().required(),
  signature: yup.string().required(),
  agreement_text: yup.string().required(),
});

export function useSignAgreementMutation({ onError, onSuccess } = {}) {
  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, { strict: true });

      return http.post(HTTP_ENDPOINTS.sendSignedAgreement(), {
        agreement_type: req.agreement_type,
        signature: req.signature,
        date: req.date,
        agreement_text: req.agreement_text,
        type: req.type,
      });
    },
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });
}

export const withSignAgreementMutation = (Component) => (props) => {
  const sign = useSignAgreementMutation();

  return (
    <Component {...props} signAgreement={sign} isLoading={sign.isLoading} />
  );
};
