import { useState } from "react";

export const useExpandReportList = (globalSettings) => {
  const [expandedList, setExpandedList] = useState({
    sales: false,
    payments: false,
    memberships: false,
  });

  const salesReports = [
    {
      label: "Sales Summary",
      href: "daily_sales_report",
    },
    {
      label: "Total Sales Report",
      href: "gross_sales",
    },
    {
      label: "Net Sales",
      href: "net_sales",
    },
    {
      label: "Membership Sales",
      href: "membership_sales",
    },
    {
      label: "eGift Sales",
      href: "egift_sales",
    },
    {
      label: "Product Sales",
      href: "item_sales",
    },
    {
      label: "Category Sales",
      href: "category_sales",
    },
    {
      label: "Package Sales",
      href: "package_sales",
    },
    {
      label: "Pricing Variations Sales",
      href: "iv_package_sales",
    },
    {
      label: "Discounts",
      href: "discounts",
    },
    {
      label: "Commissions",
      href: "commission_report",
    },
    {
      label: "Tips Per Provider",
      href: "tip_report",
    },
    {
      label: "Cancellation Fees",
      href: "cancellation_fees",
    },
    {
      label: "Retail Report",
      href: "retail_report",
    },
    {
      label: "Services Performed",
      href: "services_performed",
    },
  ];

  const paymentsReports = [
    {
      label: "Allē + Aspire + Evolus + Xperience",
      href: "bd_aspire_report",
    },
    {
      label: "Refunds Report",
      href: "refunds_report",
    },
    {
      label: "Cost to Company",
      href: "cost_to_company",
    },
    {
      label: "eGift Cards Sold vs. Redeemed",
      href: "egift_cards",
    },
    {
      label: "eGift Cards Revenue",
      href: "egift_cards_revenue",
    },
    {
      label: "Payment Methods",
      href: "payment_methods",
    },
    {
      label: "Taxes",
      href: "taxes",
    },
    {
      label: "Treatment Plans",
      href: "treatment_plans",
    },
    {
      label: "Short Term Liability",
      href: "short_term_liability",
    },
    {
      label: "Staff Treatment",
      href: "staff_treatments",
    },
    {
      label: "ASPIRE Redemption Report",
      href: "redeemed_aspire_certificates",
    },
    {
      label: "RepeatMD Redemption Report",
      href: "repeatmd_redemption",
      hide: !globalSettings?.["repeat-md"]?.isAvailable,
    },
    {
      label: "Joya Redemption Report",
      href: "joya_redemption",
      hide: !globalSettings?.["repeat-md"]?.isAvailable,
    },
  ];

  const membershipsReports = [
    {
      label: "Membership Dashboard",
      href: "membership_overview",
    },
    {
      label: "Membership Churn Report",
      href: "membership_churn_report",
    },
    {
      label: "Membership Decline Report",
      href: "membership_decline_report",
    },
  ];

  const updateExpandedList = (list) => {
    setExpandedList((prev) => {
      const newExpandedList = Object.keys(prev).reduce(
        (acc, key) => ({
          ...acc,
          [key]: false,
        }),
        {},
      );

      newExpandedList[list] = !prev[list];

      return newExpandedList;
    });
  };

  return {
    expandedList,
    updateExpandedList,
    salesReports,
    paymentsReports,
    membershipsReports,
  };
};
