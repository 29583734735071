import React from "react";
import cx from "clsx";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import classes from "../scss/Joya.module.scss";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../shared/Select/Select";
import { useSetJoyaMapping } from "../hooks/useSetJoyaMapping";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { uiNotification } from "../../../../../services/UINotificationService";

const TreatmentBenefitMapping = ({ benefit }) => {
  const { mutate, isLoading } = useSetJoyaMapping();
  const queryClient = useQueryClient();

  const selectValues = benefit.options
    .map((option) => {
      if (benefit.mapping?.includes(option.value)) {
        return option;
      }
      return null;
    })
    .filter(Boolean);

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      services: selectValues,
    },
    onSubmit: (values) => {
      const dto = {
        id: benefit.benefitId,
        mapping: values.services.map((service) => service.value),
      };
      mutate(dto, {
        onSuccess: () => {
          uiNotification.success("Services saved successfully!");
          queryClient.invalidateQueries(["joyaMapping"]);
        },
      });
    },
  });

  return (
    <div className={cx(classes.mappingContainer, classes.font12)}>
      <div className={cx(classes.inputContainer)}>
        <InputLabel className={classes.font14}>
          Service(s): <span className="required">*</span>
        </InputLabel>
        <Select
          size="small"
          options={benefit.options}
          value={values.services}
          placeholder="Select..."
          isSearchable
          isMulti={benefit.multipleSelect}
          onChange={(option) =>
            setFieldValue(
              "services",
              benefit.multipleSelect ? option : [option],
            )
          }
        />
      </div>
      <p className={classes.mappingParagraph}>
        Please select all services that can be used to fulfill the Joya
        Treatment Benefit. Selected services will be eligible for a 100%
        redemption when a Joya Treatment Benefit is applied at checkout. Please
        refer to your Joya Health portal for more information on the recommended
        service types.
      </p>
      <Button
        size="small"
        onClick={handleSubmit}
        leftAdornment={
          isLoading ? <CircularProgress size="tiny" color="white" /> : null
        }
        isDisabled={isLoading}
        widthFit
      >
        Save
      </Button>
    </div>
  );
};

export default TreatmentBenefitMapping;
