import axios from "axios";
import { authService } from "../services/AuthService";
import { intercomService } from "../services/IntercomService";
import { withAccessTokenHeaders } from "../utilities/auth";
import { HTTP_ENDPOINTS } from "../consts/api";
import { history } from "../history";
import { ROUTES } from "../consts/routes";
import { LOGOUT_REASONS } from "../consts/auth";

var logoutInstance = (() => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  instance.interceptors.request.use(withAccessTokenHeaders);

  return instance;
})();

export var getLogout =
  (http) =>
  async ({ reason } = {}) => {
    var initiate = () => {
      authService.logout();
      intercomService.shutdown();

      if (reason === LOGOUT_REASONS.sessionExpire) {
        history.replace(`${ROUTES.login()}?sessionExpired=true`);
      } else {
        history.replace(ROUTES.login());
      }
    };

    return http.get(HTTP_ENDPOINTS.logout()).finally(initiate);
  };

export var logout = getLogout(logoutInstance);
