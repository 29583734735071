import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { http } from "../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { removeNullishFromShape } from "../../utilities/general";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
  tipAmount: yup.number().required(),
});

export function useAddInvoiceTipMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.postInvoiceTip(),
      removeNullishFromShape({
        invoice_id: req.invoiceId,
        tip_amount: req.tipAmount,
        reader_id: req.readerId,
      }),
    );
  }, options);
}
