import React from "react";
import { withRouter } from "react-router";
import cx from "clsx";
import ReportsList from "./BusinessInsightsContent/ReportsList";
import SalesSummary from "./BusinessInsightsContent/Reports/SalesSummary/SalesSummary";
import TotalSales from "./BusinessInsightsContent/Reports/TotalSales/TotalSales";
import NetSales from "./BusinessInsightsContent/Reports/NetSales/NetSales";
import MembershipSales from "./BusinessInsightsContent/Reports/MembershipSales/MembershipSales";
import ProductSales from "./BusinessInsightsContent/Reports/ProductSales/ProductSales";
import CategorySales from "./BusinessInsightsContent/Reports/CategorySales/CategorySales";
import PackageSales from "./BusinessInsightsContent/Reports/PackageSales/PackageSales";
import PricingVariationsSales from "./BusinessInsightsContent/Reports/PricingVariationsSales/PricingVariationsSales";
import Discounts from "./BusinessInsightsContent/Reports/Discount/Discounts";
import Commissions from "./BusinessInsightsContent/Reports/Commissions/Commissions";
import TipsPerProvider from "./BusinessInsightsContent/Reports/TipsPerProvider/TipsPerProvider";
import CancellationFees from "./BusinessInsightsContent/Reports/CancellationFees/CancellationFees";
import RetailReport from "./BusinessInsightsContent/Reports/RetailReport/RetailReport";
import ServicesPerformed from "./BusinessInsightsContent/Reports/ServicesPerformed/ServicesPerformed";
import BdAspire from "./BusinessInsightsContent/Reports/BdAspire/BdAspire";
import Refunds from "./BusinessInsightsContent/Reports/Refunds/Refunds";
import EGiftCardRevenue from "./BusinessInsightsContent/Reports/EgiftCardRevenue/EGiftCardRevenue";
import EgiftSales from "./BusinessInsightsContent/Reports/EgiftSales/EgiftSales";
import PaymentMethods from "./BusinessInsightsContent/Reports/PaymentMethods/PaymentMethods";
import Taxes from "./BusinessInsightsContent/Reports/Taxes/Taxes";
import TreatmentPlans from "./BusinessInsightsContent/Reports/TreatmentPlans/TreatmentPlans";
import { ShortTermLiability } from "./BusinessInsightsContent/Reports/ShortTermLiability/ShortTermLiability";
import StaffTreatment from "./BusinessInsightsContent/Reports/StaffTreatment/StaffTreatment";
import CostToCompany from "./BusinessInsightsContent/Reports/CostToCompany/CostToCompany";
import { Box } from "../../shared/Box/Box";
import MembershipOverviewReport from "./BusinessInsightsContent/Reports/MembershipOverviewReport/MembershipOverviewReport";
import { MembershipChurnReport } from "./BusinessInsightsContent/Reports/MembershipChurnReport/MembershipChurnReport";
import { MembershipDeclineReport } from "./BusinessInsightsContent/Reports/MembershipDeclineReport/MembershipDeclineReport";
import EGiftCards from "./BusinessInsightsContent/Reports/eGiftcards/EGiftCards";
import AspireRedemptionReport from "./BusinessInsightsContent/Reports/AspireRedemptionReport/AspireRedemptionReport";
import RepeatMdRedemptionReport from "./BusinessInsightsContent/Reports/RepeatMdRedemptionReport/RepeatMdRedemptionReport";
import JoyaRedemptionReport from "./BusinessInsightsContent/Reports/JoyaRedemptionReport/JoyaRedemptionReport";

const NewBusinessInsightsContent = ({ match }) => {
  const reports = {
    daily_sales_report: { title: "Sales Summary", component: <SalesSummary /> },
    gross_sales: { title: "Total Sales", component: <TotalSales /> },
    net_sales: { title: "Net Sales", component: <NetSales /> },
    membership_sales: {
      title: "Membership Sales",
      component: <MembershipSales />,
    },
    item_sales: { title: "Product Sales", component: <ProductSales /> },
    category_sales: { title: "Category Sales", component: <CategorySales /> },
    package_sales: { title: "Package Sales", component: <PackageSales /> },
    iv_package_sales: {
      title: "Pricing Variations Sales",
      component: <PricingVariationsSales />,
    },
    discounts: { title: "Discounts", component: <Discounts /> },
    commission_report: { title: "Commissions", component: <Commissions /> },
    tip_report: { title: "Tips Per Provider", component: <TipsPerProvider /> },
    cancellation_fees: {
      title: "Cancellation Fees",
      component: <CancellationFees />,
    },
    retail_report: { title: "Retail Report", component: <RetailReport /> },
    services_performed: {
      title: "Services Performed",
      component: <ServicesPerformed />,
    },
    bd_aspire_report: { title: "Services Performed", component: <BdAspire /> },
    refunds_report: { title: "Refunds", component: <Refunds /> },
    egift_cards: {
      title: "eGift Cards",
      component: <EGiftCards />,
    },
    egift_cards_revenue: {
      title: "eGift Card Revenue",
      component: <EGiftCardRevenue />,
    },
    egift_sales: {
      title: "eGift Sales",
      component: <EgiftSales />,
    },
    payment_methods: {
      title: "Payment Methods",
      component: <PaymentMethods />,
    },
    taxes: { title: "Taxes", component: <Taxes /> },
    treatment_plans: {
      title: "Treatment Plans",
      component: <TreatmentPlans />,
    },
    short_term_liability: {
      title: "Short Term Liability",
      component: <ShortTermLiability />,
    },
    staff_treatments: {
      title: "Staff Treatment",
      component: <StaffTreatment />,
    },
    cost_to_company: { title: "Cost To Company", component: <CostToCompany /> },
    membership_overview: {
      title: "Membership Dashboard",
      component: <MembershipOverviewReport />,
    },
    membership_churn_report: {
      title: "Membership Churn Report",
      component: <MembershipChurnReport />,
    },
    membership_decline_report: {
      title: "Membership Decline Report",
      component: <MembershipDeclineReport />,
    },
    redeemed_aspire_certificates: {
      title: "Redeemed Aspire Certificates",
      component: <AspireRedemptionReport />,
    },
    repeatmd_redemption: {
      title: "RepeatMD Redemption",
      component: <RepeatMdRedemptionReport />,
    },
    joya_redemption: {
      title: "Joya Redemption",
      component: <JoyaRedemptionReport />,
    },
  };

  return (
    <div className="full-width membership-title flex-col">
      {!match.params.report ? (
        <>
          <Box
            className={cx(
              "m-b-20 membership-title flex justify-between items-center",
            )}
          >
            Sales
          </Box>
          <ReportsList />
        </>
      ) : (
        <div>{reports[match.params.report]["component"]}</div>
      )}
    </div>
  );
};

export default withRouter(NewBusinessInsightsContent);
