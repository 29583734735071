import React from "react";
import { connect } from "react-redux";
import { InputLabel } from "../../../../../../../../shared/InputLabel/InputLabel";
import {
  tCommon,
  tSales,
} from "../../../../../../../../i18n/useAppTranslation";
import { Select } from "../../../../../../../../shared/Select/Select";
import { ModalCharge } from "../../../../../shared/ModalCharge/ModalCharge";
import { useInvoiceActions } from "../../../../../hooks/useInvoiceActions";
import { useInvoice } from "../../../../../hooks/invoice/useInvoice";
import { checkoutInvoice } from "../../../../../../../../store/checkoutInvoice";

const PaymentFormModal = ({
  form,
  changeFormValue,
  cardOptions,
  isValid,
  showTipsModal,
  applyTip,
  isTipsOnlyFlow,
}) => {
  const { paymentViewClose } = useInvoiceActions();
  const { invoice } = useInvoice();

  const handleSubmit = () => {
    showTipsModal.update(true);
    if (invoice.status === "draft" && !isTipsOnlyFlow) {
      applyTip.initiate({
        tipAmount: 0,
        readerId: form.selectedCard.readerIdentifier,
      });
    }
  };

  return (
    <>
      <div className="col-sm-12 p-b-10">
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.cardReader")}
        </InputLabel>
        <Select
          size="medium"
          value={form.selectedCard}
          onChange={(option) => changeFormValue("selectedCard", option)}
          options={cardOptions || []}
        />
      </div>
      <ModalCharge.Footer
        onClose={paymentViewClose}
        onCharge={handleSubmit}
        isChargeDisabled={!isValid}
        chargeLabel={tCommon("label.continue")}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isTipsOnlyFlow: checkoutInvoice.selectors.selectIsTipsOnlyFlow(state),
});

export default connect(mapStateToProps, null)(PaymentFormModal);
