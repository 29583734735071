import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";

export const useJoyaWallet = (patientId, options = {}) => {
  const res = useQuery(
    ["joyaWallet"],
    async () => {
      return http.get(
        HTTP_ENDPOINTS.joyaIntegration.getJoyaBenefits(patientId),
      );
    },
    { cacheTime: 0, select: (data) => data.data.data, ...options },
  );
  return res;
};
