import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import styles from "./AgreementModal.module.scss";
import { AgreementContent } from "./shared/AgreementContent/AgreementContent";

export function AgreementModal({
  agreements,
  isOpen,
  onClose,
  onSign,
  additionalInfo,
  isLoading,
}) {
  const [current, setCurrent] = useState(0);
  const [signed, setSigned] = useState([]);

  const handleNext = (currentAgreement) => {
    const signData = {
      agreement_type: currentAgreement.type,
      agreement_text: currentAgreement.text,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      signature: currentAgreement.signature,
    };

    setSigned((prev) => [...prev, signData]);

    if (current !== agreements.length - 1) {
      return setCurrent(current + 1);
    }
    const data = [...signed, signData];
    onSign(data);
  };

  const currentAgr = agreements[current];

  return (
    <Modal isOpen={isOpen} onClose={onClose} shouldCloseOnOverlayClick={false}>
      <div className={styles.root}>
        {additionalInfo && (
          <div className={styles.additionalInfo}>{additionalInfo}</div>
        )}
        <AgreementContent
          agreement={currentAgr}
          key={current}
          onSubmit={(res) => handleNext(res)}
          isLast={current === agreements.length - 1}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
}

AgreementModal.PropTypes = {
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onSubmit: PropTypes.func,
      type: PropTypes.string,
      id: PropTypes.number.isRequired,
    }),
  ),
};
