import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback, useMemo } from "react";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { getHealthPathParams } from "../../../HealthCreateEdit.utils";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { useProcedureQuery } from "../../../../../../../../api/procedure/useProcedureQuery";

function useInitialValues() {
  const { tCommon } = useAppTranslation.Common();
  const { procedureId, isEditMode } = getHealthPathParams();

  const { data, isFetching } = useProcedureQuery({
    payload: {
      procedureId,
    },
    options: {
      enabled: isEditMode,
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.fetchProcedure"));
      },
    },
  });

  return useMemo(
    () => ({
      data: {
        chiefComplaint: data?.name || "",
        notes: data?.notes || "",
      },
      isLoading: isFetching,
    }),
    [data, isFetching],
  );
}

export function useForm(onSubmit) {
  const { isEditMode } = getHealthPathParams();

  const initialValues = useInitialValues();
  const requiredFields = ["chiefComplaint"];
  const { tClients } = useAppTranslation.Clients();

  const schema = yup.object({
    chiefComplaint: yup.lazy(() => {
      const schema = yup.string();

      if (!isEditMode) {
        return schema.required(
          tClients(
            "createEditHealthProcedure.formError.chiefComplaintRequired",
          ),
        );
      }

      return schema.nullable();
    }),

    notes: yup.string().nullable(),
  });

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: initialValues.data,
    onSubmit,
  });

  const getError = useCallback(
    (field) => {
      if (form.touched[field] && form.errors[field]) {
        return form.errors[field];
      }
      return null;
    },
    [form.touched, form.errors],
  );

  return useMemo(
    () => ({
      handleSubmit: form.handleSubmit,

      isError: requiredFields.some((f) => Boolean(getError(f))),

      isLoading: initialValues.isLoading,

      chiefComplaint: {
        value: form.values.chiefComplaint,
        onChange: (next) => form.setFieldValue("chiefComplaint", next),
        getError: () => getError("chiefComplaint"),
      },

      notes: {
        value: form.values.notes,
        onChange: (next) => form.setFieldValue("notes", next),
      },
    }),
    [
      form.handleSubmit,
      getError,
      form.values,
      initialValues.isLoading,
      form.errors,
      form.touched,
    ],
  );
}
