import React from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./BackImages.module.scss";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { ImageUploader } from "../../../../../../../boxes/ImageUploader/ImageUploader";
import {
  IMAGE_COMPRESS_WIDTH,
  IMAGE_UPLOAD_TYPE,
} from "../../CosmeticCreateEdit.consts";
import { useDefaultImage } from "../../hooks/useDefaultImage";
import { useImageUploader } from "../../../../../../../hooks/useImageUploader";

export function BackImages({
  backImage,
  leftImage,
  rightImage,
  onUploadingChange,
}) {
  const { tClients } = useAppTranslation.Clients();
  const defaultImage = useDefaultImage();

  const uploader = useImageUploader({
    distinguishByName: true,
    uploadType: IMAGE_UPLOAD_TYPE,
    onLoadingChange: onUploadingChange,
    compress: {
      width: IMAGE_COMPRESS_WIDTH,
    },
  });

  return (
    <div>
      <div className={classes.title}>
        {tClients("createEditCosmeticProcedure.images.backImages")}
      </div>
      <div className={classes.images}>
        <div>
          <InputLabel>
            {tClients("createEditCosmeticProcedure.images.backImage")}
          </InputLabel>
          <ImageUploader
            name="backImage"
            value={backImage.value}
            isLoading={
              uploader.isLoading && uploader.uploadingName === "backImage"
            }
            onChange={(e) =>
              uploader
                .htmlOnChange(e)
                .then(({ uploadedFileFullPath }) =>
                  backImage.onChange(uploadedFileFullPath),
                )
            }
            onRemove={() => backImage.onChange(null)}
            defaultImage={defaultImage}
          />
        </div>
        <div>
          <InputLabel>
            {tClients("createEditCosmeticProcedure.images.backLeft45deg")}
          </InputLabel>
          <ImageUploader
            name="backLeftImage"
            value={leftImage.value}
            isLoading={
              uploader.isLoading && uploader.uploadingName === "backLeftImage"
            }
            onChange={(e) =>
              uploader
                .htmlOnChange(e)
                .then(({ uploadedFileFullPath }) =>
                  leftImage.onChange(uploadedFileFullPath),
                )
            }
            onRemove={() => leftImage.onChange(null)}
            defaultImage={defaultImage}
          />
        </div>
        <div>
          <InputLabel>
            {tClients("createEditCosmeticProcedure.images.backRight45deg")}
          </InputLabel>
          <ImageUploader
            name="backRightImage"
            value={rightImage.value}
            isLoading={
              uploader.isLoading && uploader.uploadingName === "backRightImage"
            }
            onChange={(e) =>
              uploader
                .htmlOnChange(e)
                .then(({ uploadedFileFullPath }) =>
                  rightImage.onChange(uploadedFileFullPath),
                )
            }
            onRemove={() => rightImage.onChange(null)}
            defaultImage={defaultImage}
          />
        </div>
      </div>
    </div>
  );
}

BackImages.propTypes = {
  backImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  leftImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  rightImage: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
