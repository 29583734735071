import React, { useMemo } from "react";
import moment from "moment";
import cx from "clsx";
import ReactInputMask from "react-input-mask";
import styles from "./styles.module.scss";

// eslint-disable-next-line
import { useCurrentUserQuery } from "../../api/queries/useUserQuery";
import { DEFAULT_DATE_FORMAT } from "../../consts/general";
import { API_DATE_FORMAT } from "../../consts/api";

export var validateMaskedDob = (value, format = DEFAULT_DATE_FORMAT) => {
  var date = moment.utc(value, format, true);
  return date.isValid() && date.isBefore(moment());
};

var FORMAT_MASK_MAP = {
  "YYYY/MM/DD": "9999/99/99",
  "DD/MM/YYYY": "99/99/9999",
  "MM/DD/YYYY": "99/99/9999",
};

var SIZES = {
  medium: "medium",
  small: "small",
  default: "default",
};

export function InputDob({
  value,
  onChange,
  isError,
  isDisabled,
  isReadonly,
  size,
  className,
}) {
  var { data: user } = useCurrentUserQuery();
  var format = user?.dateFormat || DEFAULT_DATE_FORMAT;

  var isValid = useMemo(() => {
    if (!value || value.includes("_")) {
      return true;
    }
    return validateMaskedDob(value, format);
  }, [value, format]);

  var inputClasses = cx(styles.input, {
    [styles.error]: isError || !isValid,
    [styles.disabled]: isDisabled,
    [styles.readonly]: isReadonly,
    [styles.mediumInput]: size === SIZES.medium,
    [styles.smallInput]: size === SIZES.small,
  });

  var handleChange = (e) => {
    var value = e.target.value;
    var isValid = validateMaskedDob(value, format);

    var apiDate = isValid
      ? moment.utc(value, format).format(API_DATE_FORMAT)
      : undefined;

    onChange({ masked: value, isValid, apiDate });
  };

  return (
    <div className={cx(styles.root, className)}>
      <ReactInputMask
        mask={FORMAT_MASK_MAP[format]}
        maskChar="_"
        placeholder="Date of Birth"
        value={value}
        onChange={handleChange}
        className={inputClasses}
        disabled={isDisabled}
      />
      <span className={styles.maskTip}>{format}</span>
    </div>
  );
}
