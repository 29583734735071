export var TIMEOUT = 1000 * 60 * 60;
export var PROMPT_TIMEOUT = TIMEOUT - 1000 * 60 * 5;
export var ACTIVITY_LOG_INTERVAL = 400;

export var EVENTS = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "focus",
];
