import moment from "moment";
import { getLanguageData } from "../../../../utilities/localStorage";
import { DEFAULT_DATE_FORMAT } from "../../../../consts/general";
import { API_DATE_FORMAT } from "../../../../consts/api";

export const getLabel = () => {
  const languageData = getLanguageData("global");
  if (languageData && "header_clients" in languageData) {
    const label = languageData["header_clients"];
    if (
      typeof label === "string" &&
      label.length > 1 &&
      label[label.length - 1].toLowerCase() === "s"
    ) {
      return label.slice(0, -1);
    }
  }
  return "Client";
};

export const dobToApiDate = (value, format) => {
  return value
    ? moment.utc(value, format || DEFAULT_DATE_FORMAT).format(API_DATE_FORMAT)
    : "";
};

export const apiDateToDob = (value, format) => {
  return value
    ? moment.utc(value, API_DATE_FORMAT).format(format || DEFAULT_DATE_FORMAT)
    : "";
};
