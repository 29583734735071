/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import moment from "moment";
import Autocomplete from "react-google-autocomplete";
import validator from "validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import HeaderCandela from "./HeaderCandela";
import FooterTouchMD from "../../../_legacy/Containers/Guest/FooterTouchMD.js";
import {
  signUp,
  signUpPremium,
  checkUniqueEmail,
  getSignUpPlans,
  signUpTouchMD,
} from "../../../Actions/SignUp/signUpAction.js";
import TextBox from "../TextBox";
import ContactNumber from "../ContactNumber";
import TermsOfService from "../../Policies/TermsOfService.js";
import BbaPolicy from "../../../Components/Policies/BbaPolicy.js";
import Loader from "../../Common/Loader.js";
import { http } from "../../../services/HttpService.js";
import { uiNotification } from "../../../services/UINotificationService.js";

var cardNumber = "";
var cardExpiry = "";
var cardCvc = "";
var stripeToken = "";

class CandelaSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invite_key: this.props.match.params.invite_key
        ? this.props.match.params.invite_key
        : "",
      firstname: "",
      lastname: "",
      email: "",
      contactNumber: "",
      password: "",
      confirm_password: "",
      business_name: "",
      subscription_type: "monthly",
      subscription_plans: "practice_accelerator",
      term_condition: false,
      agree_checkbox: false,
      isHppaPolicyAccepted: false,
      isBbaPolicyAccepted: false,
      firstNameClass: "form-control",
      lastNameClass: "form-control",
      emailClass: "form-control",
      contactNumberClass: "form-control p-l-55",
      passwordClass: "form-control",
      confirmPasswordClass: "form-control",
      businessNameClass: "form-control",
      subscriptionPlanClass: "form-control",
      subscriptionTypeClass: "form-control",
      termconditionClass: "",
      agreecheckboxClass: "",
      defaultCountry: "us",
      contactNumberError: false,
      isRender: false,
      processingLoder: false,
      isShowInfo: true,
      isShowCardInfo: false,
      isShowTerms: false,
      isShowBba: false,
      signUpData: {},
      uniqueEmailData: {},
      isUniqueEmail: false,
      isInfoValid: false,
      isCardInfoValid: false,

      card_number: "",
      cvc: "",
      expiry: "",
      cardNumberClass: "form-control card-details",
      cardCvcClass: "form-control card-details",
      cardExpiryClass: "form-control card-details",

      address: "",
      city: "",
      state: "",
      country: "",
      pin_code: "",
      addressClass: "form-control",
      cityClass: "form-control",
      stateClass: "form-control",
      countryClass: "form-control",
      pinCodeClass: "form-control",

      planAndCountryData: {},
      countryList: [],
      subscriptionPlanList: {},
      subscriptionTypeList: {},
      stripeToken: "",
      globalLang: {},
      business_address: "",
      business_city: "",
      business_state: "",
      business_zip: "",
      businessAddClass: "form-control",
      businessCityClass: "form-control",
      businessStateClass: "form-control",
      businessZipClass: "form-control",
      subscriptionPlanListYearly: {},
      subscriptionPlanListMonthly: {},
      setup_fee: "0",
      removePassword: false,
      type: this.props.match.params.type ? this.props.match.params.type : "",
      year: moment().format("YYYY"),
      existsAsSignup: false,
    };
  }

  componentDidMount() {
    localStorage.removeItem("accountSetupFile");
    localStorage.removeItem("accountSetupStatus");
    this.props.getSignUpPlans();
    let languageData = {};
    if (localStorage.getItem("languageData")) {
      languageData = JSON.parse(localStorage.getItem("languageData"));
    }
    if (
      !languageData ||
      languageData.global === undefined ||
      !languageData.global
    ) {
      http
        .get("getLanguageText/1/global")
        .then((res) => {
          languageData = res.data.data;
          if (languageData.country_code) {
            localStorage.setItem("cCode", languageData.country_code);
          }
          this.setState({
            globalLang:
              languageData && languageData.global ? languageData.global : null,
          });
          localStorage.setItem("languageData", JSON.stringify(languageData));
        })
        .catch(function () {});
    } else {
      this.setState({
        globalLang:
          languageData && languageData.global ? languageData.global : null,
      });
    }

    if (window.Stripe) {
      this.setState(
        { stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) },
        () => {
          this.generateStripeInput();
        },
      );
    } else {
      document.querySelector("#stripe-js").addEventListener(
        "load",
        () => {
          this.setState({
            stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
          });
        },
        () => {
          this.generateStripeInput();
        },
      );
    }

    let accountSetupInterval = localStorage.getItem("accountSetupInterval");
    if (accountSetupInterval) {
      clearInterval(accountSetupInterval);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }
    if (
      nextProps.processingLoder != undefined &&
      nextProps.processingLoder == false
    ) {
      returnState.processingLoder = false;
    } else if (
      nextProps.signUpData != undefined &&
      nextProps.signUpData != prevState.signUpData
    ) {
      returnState.signUpData = nextProps.signUpData;
      return <div>{nextProps.history.push(`/candela-account-setup`)}</div>;
    } else if (
      nextProps.planAndCountryData != undefined &&
      nextProps.planAndCountryData != prevState.planAndCountryData
    ) {
      returnState.planAndCountryData = nextProps.planAndCountryData;
      returnState.countryList = nextProps.planAndCountryData.country_list;
      returnState.subscriptionPlanListMonthly = nextProps.planAndCountryData
        .subscription_plans
        ? nextProps.planAndCountryData.subscription_plans.monthly
        : {};
      returnState.subscriptionPlanListYearly = nextProps.planAndCountryData
        .subscription_plans
        ? nextProps.planAndCountryData.subscription_plans.yearly
        : {};
      returnState.subscriptionTypeList = nextProps.planAndCountryData
        .subscription_type
        ? nextProps.planAndCountryData.subscription_type
        : {};
      returnState.setup_fee = nextProps.planAndCountryData.one_time_setup_fees
        ? nextProps.planAndCountryData.one_time_setup_fees
        : "0";
      returnState.globalLang =
        nextProps.planAndCountryData.languageData &&
        nextProps.planAndCountryData.languageData.global
          ? nextProps.planAndCountryData.languageData.global
          : null;
      returnState.isRender = true;
      if (nextProps.planAndCountryData.country_code) {
        localStorage.setItem(
          "cCode",
          nextProps.planAndCountryData.country_code,
        );
      }
    } else if (nextProps.isUniqueEmail != undefined) {
      returnState.isUniqueEmail = nextProps.isUniqueEmail;
      returnState.showLoader = false;

      if (!nextProps.isUniqueEmail) {
        returnState.removePassword = true;
        returnState.existsAsSignup = nextProps.existsAsSignup
          ? nextProps.existsAsSignup
          : false;
      } else if (
        nextProps.isUniqueEmail == true &&
        nextProps.isUniqueEmail != prevState.isUniqueEmail
      ) {
        returnState.removePassword = false;
        returnState.existsAsSignup = false;
        if (prevState.isInfoValid) {
          returnState.isShowInfo = false;
          returnState.isShowBba = false;
          returnState.isShowTerms = false;
          returnState.isShowCardInfo = true;
        }
      }
      nextProps.signUp();
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = event.target.name;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (name == "password" || name == "confirm_password" || name == "email") {
      value = value.trim();
    }
    this.setState({
      [name]: value,
    });
  };

  handleChildChange = (value) => {
    this.setState(value);
  };

  handleInfoSubmit = (event) => {
    uiNotification.clear();
    if (typeof event === "object") {
      event.preventDefault();
    }
    this.setState({ isInfoValid: false });
    let error = false;
    let toastError = false;
    let regularExpression =
      /^(?=.{8,})(?=.*[A-Z])(?=.*[`~*-/[\]\\|{}().:;,''""!_<>-@#$%^&+=]).*$/;
    if (
      typeof this.state.firstname == undefined ||
      this.state.firstname == null ||
      this.state.firstname == ""
    ) {
      this.setState({ firstNameClass: "form-control field_error" });
      error = true;
    } else if (this.state.firstname) {
      this.setState({ firstNameClass: "form-control" });
    }

    if (
      typeof this.state.lastname == undefined ||
      this.state.lastname == null ||
      this.state.lastname == ""
    ) {
      this.setState({ lastNameClass: "form-control field_error" });
      error = true;
    } else if (this.state.lastname) {
      this.setState({ lastNameClass: "form-control" });
    }

    if (
      typeof this.state.email == undefined ||
      this.state.email == null ||
      this.state.email == ""
    ) {
      this.setState({ emailClass: "form-control field_error" });
      error = true;
    } else {
      if (!validator.isEmail(this.state.email)) {
        this.setState({ emailClass: "form-control field_error" });
        error = true;
        uiNotification.error(
          this.state.globalLang &&
            this.state.globalLang.signup_error_email_valid,
        );
        toastError = true;
      } else {
        this.setState({
          emailClass: "form-control",
          isUniqueEmail: false,
          uniqueEmailData: {},
        });
      }
    }

    if (
      typeof this.state.contactNumber == undefined ||
      this.state.contactNumber == null ||
      this.state.contactNumber == ""
    ) {
      this.setState({ contactNumberClass: "form-control  p-l-55 field_error" });
      error = true;
    } else {
      if (this.state.contactNumberError) {
        this.setState({
          contactNumberClass: "form-control  p-l-55 field_error",
        });
        error = true;
        if (!toastError) {
          uiNotification.error(
            this.state.globalLang &&
              this.state.globalLang.signup_error_phone_number_valid,
          );
        }
        toastError = true;
      } else if (this.state.contactNumber.length <= 9) {
        this.setState({
          contactNumberClass: "form-control  p-l-55 field_error",
        });
        error = true;
        if (!toastError) {
          uiNotification.error(
            this.state.globalLang &&
              this.state.globalLang.signup_error_phone_number_valid,
          );
        }
        toastError = true;
      } else {
        this.setState({ contactNumberClass: "form-control  p-l-55" });
      }
    }

    if (
      (typeof this.state.password === undefined ||
        this.state.password === null ||
        this.state.password === "" ||
        this.state.password.trim() == "") &&
      this.state.removePassword == false
    ) {
      this.setState({ passwordClass: "form-control field_error" });
      error = true;
    } else if (this.state.password && this.state.removePassword == false) {
      if (this.state.password.length <= 8) {
        this.setState({ passwordClass: "form-control field_error" });
        error = true;
        if (!toastError) {
          uiNotification.error(
            this.state.globalLang &&
              this.state.globalLang.signup_error_password_length,
          );
        }
        toastError = true;
      } else if (!regularExpression.test(this.state.password)) {
        this.setState({ passwordClass: "form-control field_error" });
        error = true;
        if (!toastError) {
          uiNotification.error(
            this.state.globalLang &&
              this.state.globalLang.signup_error_password_combination,
          );
        }
        toastError = true;
      } else {
        this.setState({ passwordClass: "form-control" });
      }
    }

    if (
      (typeof this.state.confirm_password === undefined ||
        this.state.confirm_password === null ||
        this.state.confirm_password === "") &&
      this.state.removePassword == false
    ) {
      this.setState({ confirmPasswordClass: "form-control field_error" });
      error = true;
      if (this.state.password.length > 0) {
        this.setState({ passwordClass: "form-control field_error" });
        error = true;
        if (!toastError) {
          uiNotification.error(
            this.state.globalLang &&
              this.state.globalLang.signup_error_password_match_with_confirm,
          );
        }
        toastError = true;
      }
    } else if (
      this.state.confirm_password &&
      this.state.removePassword == false
    ) {
      if (this.state.password !== this.state.confirm_password) {
        this.setState({
          passwordClass: "form-control field_error",
          confirmPasswordClass: "form-control field_error",
        });
        error = true;
        if (!toastError) {
          uiNotification.error(
            this.state.globalLang &&
              this.state.globalLang.signup_error_password_match_with_confirm,
          );
        }
        toastError = true;
      } else {
        this.setState({ confirmPasswordClass: "form-control" });
      }
    }

    if (!this.state.term_condition && !toastError) {
      uiNotification.error(
        this.state.globalLang &&
          this.state.globalLang.signup_error_accept_terms_of_services,
      );
      error = true;
      toastError = true;
    }

    if (error) {
      this.toggleSignUpForm("isShowInfo");
      return;
    } else {
      this.generateStripeInput();
      this.setState({ isInfoValid: true });
      this.toggleSignUpForm("isShowCardInfo");
    }
  };

  handleCardInfoSubmit = (event) => {
    uiNotification.clear();
    if (typeof event === "object") {
      event.preventDefault();
    }
    this.setState({ isCardInfoValid: false });
    let error = false;
    if (
      typeof this.state.address == undefined ||
      this.state.address == null ||
      this.state.address == ""
    ) {
      this.setState({ addressClass: "form-control field_error" });
      error = true;
    } else if (this.state.address) {
      this.setState({ addressClass: "form-control" });
    }

    if (
      typeof this.state.city == undefined ||
      this.state.city == null ||
      this.state.city == ""
    ) {
      this.setState({ cityClass: "form-control field_error" });
      error = true;
    } else if (this.state.city) {
      this.setState({ cityClass: "form-control" });
    }

    if (
      typeof this.state.state == undefined ||
      this.state.state == null ||
      this.state.state == ""
    ) {
      this.setState({ stateClass: "form-control field_error" });
      error = true;
    } else if (this.state.state) {
      this.setState({ stateClass: "form-control" });
    }

    if (
      typeof this.state.country == undefined ||
      this.state.country == null ||
      this.state.country == ""
    ) {
      this.setState({ countryClass: "form-control field_error" });
      error = true;
    } else if (this.state.country) {
      this.setState({ countryClass: "form-control" });
    }

    if (
      typeof this.state.pin_code == undefined ||
      this.state.pin_code == null ||
      this.state.pin_code == ""
    ) {
      this.setState({ pinCodeClass: "form-control field_error" });
      error = true;
    } else if (this.state.pin_code) {
      this.setState({ pinCodeClass: "form-control" });
    }

    if (error) {
      this.toggleSignUpForm("isShowCardInfo");
      return;
    } else {
      this.setState({ processingLoder: true });
      this.generateStripeCardToken();
    }
  };

  generateStripeInput = () => {
    var elements = this.state.stripe.elements();
    cardNumber = elements.create("cardNumber");
    cardExpiry = elements.create("cardExpiry");
    cardCvc = elements.create("cardCvc");
    cardNumber.mount("#stripe-card-number");
    cardExpiry.mount("#stripe-card-expiry");
    cardCvc.mount("#stripe-card-cvc");
  };

  generateStripeCardToken = () => {
    this.state.stripe.createToken(cardNumber).then((response) => {
      if (response.error) {
        this.setState({ stripeToken: "", processingLoder: false });
        uiNotification.error(response.error.message);
      } else {
        stripeToken = response.token.id;
        if (stripeToken) {
          this.setState({ stripeToken: stripeToken, processingLoder: false });
          this.setState({ isCardInfoValid: true });
          this.toggleSignUpForm("isShowTerms");
        }
      }
    });
  };

  toggleSignUpForm = (nextStep, isSubmit) => {
    isSubmit = isSubmit || false;
    this.setState({
      isShowInfo: false,
      isShowCardInfo: false,
      isShowBba: false,
      isShowTerms: nextStep == "isShowTerms" || isSubmit == true ? true : false,
      [nextStep]: true,
    });
    if (isSubmit) {
      this.setState({ processingLoder: true });
      let formData = {};
      formData.firstname = this.state.firstname;
      formData.lastname = this.state.lastname;
      formData.email_id = this.state.email;
      formData.contact_number_1 = this.state.contactNumber;
      formData.password =
        this.state.removePassword == false ? this.state.password : "";
      formData.confirm_password =
        this.state.removePassword == false ? this.state.confirm_password : "";
      formData.business_name = this.state.business_name;
      formData.term_condition = this.state.term_condition ? 1 : 0;
      formData.agree_checkbox = this.state.agree_checkbox ? 1 : 0;
      formData.subscription_type = "monthly";
      formData.subscription_plans = "practice_accelerator";
      formData.stripe_token = this.state.stripeToken;
      formData.address = this.state.address;
      formData.city = this.state.city;
      formData.state = this.state.state;
      formData.country = this.state.country;
      formData.pincode = this.state.pin_code;
      formData.business_address = this.state.business_address;
      formData.business_city = this.state.business_city;
      formData.business_state = this.state.business_state;
      formData.business_zip = this.state.business_zip;
      formData.invite_key = this.state.invite_key ? this.state.invite_key : "";
      formData.business_country = this.state.business_country;
      formData.type = "candela";
      this.props.signUpTouchMD(formData);
    }
  };

  renderSubscriptionPlan = () => {
    let htmlList = [];
    let subscriptionPlanList =
      this.state.subscription_type == "monthly"
        ? this.state.subscriptionPlanListMonthly
        : this.state.subscriptionPlanListYearly;
    Object.keys(subscriptionPlanList).forEach((idx) => {
      htmlList.push(
        <option key={"subscriptionPlanList-" + idx} value={idx}>
          {subscriptionPlanList[idx]}{" "}
        </option>,
      );
    });
    return htmlList;
  };

  renderSubscriptionType = () => {
    let htmlList = [];
    Object.keys(this.state.subscriptionTypeList).forEach((idx) => {
      htmlList.push(
        <option key={"subscriptionTypeList-" + idx} value={idx}>
          {this.state.subscriptionTypeList[idx]}{" "}
        </option>,
      );
    });
    return htmlList;
  };

  checkEmail = () => {
    if (!validator.isEmail(this.state.email)) {
      this.setState({ emailClass: "form-control field_error" });
      uiNotification.error(this.state.globalLang.signup_error_email_valid);
    } else {
      let formData = {
        email_id: this.state.email,
        type: "touchmd",
      };
      this.props.checkUniqueEmail(formData);
      this.setState({
        emailClass: "form-control",
        isUniqueEmail: false,
        uniqueEmailData: {},
        showLoader: true,
      });
    }
  };

  selectLocation2 = (place) => {
    let zipcode = "";
    let city = "";
    let state = "";
    let country = "";
    let address = "";
    let inputName = (document.getElementById("new-input").value = "");

    if (place.address_components && place.name) {
      place.address_components.map((data) => {
        if (
          data.types[0] == "route" ||
          data.types[0] == "street_number" ||
          data.types[0] == "neighborhood"
        ) {
          address = data.long_name ? address + data.long_name + " " : "";
        }
        //get city
        if (data.types[0] == "locality" || data.types[0] == "postal_town") {
          city = data.long_name ? data.long_name : "";
        }

        if (city == "") {
          if (data.types[0] == "administrative_area_level_2") {
            city = data.long_name ? data.long_name : "";
          }
        }
        //get state
        if (data.types[0] == "administrative_area_level_1") {
          state = data.short_name ? data.short_name : "";
        }
        //get country
        if (data.types[0] == "country") {
          country = data.short_name ? data.short_name : "";
        }
        //get zipcode
        if (data.types[0] == "postal_code") {
          zipcode = data.long_name ? data.long_name : "";
        }
        if (zipcode == "") {
          if (data.types[0] == "postal_code_prefix") {
            zipcode = data.long_name ? data.long_name : "";
          }
        }

        if (address) {
          inputName = address.trim();
        }
        document.getElementById("new-input").value = inputName;
      });

      this.setState({
        business_city: city,
        business_state: state,
        business_country: country,
        business_address: address.trim(),
        business_zip: zipcode,
      });
    }
  };

  render() {
    return (
      <div className="guest">
        <HeaderCandela />
        <div className="wrapper">
          {/*  Info Data Block START  */}
          <div
            className={this.state.isShowInfo ? "info-block" : "info-block hide"}
          >
            <div className="login-main sign-up-main">
              <form onSubmit={this.handleInfoSubmit}>
                <h1 className="login-title">
                  {this.state.type == "booking"
                    ? "Sign up with booking plan"
                    : "Sign up with accelerator plan"}
                </h1>
                <div className="form-group row">
                  <div className="col-sm-6 first-name">
                    <TextBox
                      name="firstname"
                      value={this.state.firstname}
                      handleInputChange={this.handleInputChange}
                      class={this.state.firstNameClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_placeholder_first_name
                      }
                      type="text"
                      label={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_your_name
                      }
                      isRequired={true}
                    />
                  </div>
                  <div className="col-sm-6 last-name">
                    <TextBox
                      name="lastname"
                      value={this.state.lastname}
                      handleInputChange={this.handleInputChange}
                      class={this.state.lastNameClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_placeholder_last_name
                      }
                      type="text"
                      label="&nbsp;"
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div className="field-group">
                      <label htmlFor="usr">
                        Email<span className="required">*</span>
                      </label>
                      <input
                        name="email"
                        onBlur={this.checkEmail}
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        className={this.state.emailClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang.signup_placeholder_email
                        }
                        type="text"
                        autoComplete="new-password"
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <ContactNumber
                      name="contactNumber"
                      value={this.state.contactNumber}
                      handleChildChange={this.handleChildChange}
                      class={this.state.contactNumberClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_placeholder_phone
                      }
                      type="text"
                      label={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_phone
                      }
                      isRequired={true}
                      isRender={this.state.isRender}
                    />
                  </div>
                </div>
                {this.state.removePassword == false && (
                  <div className="form-group row">
                    <div className="col-sm-6 first-name">
                      <TextBox
                        name="password"
                        value={this.state.password}
                        handleInputChange={this.handleInputChange}
                        class={this.state.passwordClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang.signup_placeholder_password
                        }
                        type="password"
                        label={
                          this.state.globalLang &&
                          this.state.globalLang.signup_label_password
                        }
                        isRequired={true}
                      />
                    </div>
                    <div className="col-sm-6 last-name">
                      <TextBox
                        name="confirm_password"
                        value={this.state.confirm_password}
                        handleInputChange={this.handleInputChange}
                        class={this.state.confirmPasswordClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang
                            .signup_placeholder_retype_password
                        }
                        type="password"
                        label="&nbsp;"
                        isRequired={false}
                      />
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-sm-12">
                    <TextBox
                      name="business_name"
                      value={this.state.business_name}
                      handleInputChange={this.handleInputChange}
                      class={this.state.businessNameClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_business_name
                      }
                      type="text"
                      label={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_business_name
                      }
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div className="field-group">
                      <label htmlFor="usr">
                        Business Address<span className="required">*</span>
                      </label>
                      <Autocomplete
                        id="new-input"
                        placeholder="Search Address"
                        className={this.state.businessAddClass}
                        onPlaceSelected={(place) => this.selectLocation2(place)}
                        types={[]}
                        fields={[
                          "address_component",
                          "formatted_address",
                          "geometry",
                          "name",
                          "place_id",
                          "plus_code",
                          "type",
                          "formatted_phone_number",
                          "international_phone_number",
                          "opening_hours",
                          "website",
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <TextBox
                      name="business_city"
                      value={this.state.business_city}
                      handleInputChange={this.handleInputChange}
                      class={this.state.businessCityClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_business_city
                      }
                      type="text"
                      label={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_city
                      }
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <TextBox
                      name="business_state"
                      value={this.state.business_state}
                      handleInputChange={this.handleInputChange}
                      class={this.state.businessStateClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_business_state
                      }
                      type="text"
                      label={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_state
                      }
                      isRequired={false}
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextBox
                      name="business_zip"
                      value={this.state.business_zip}
                      handleInputChange={this.handleInputChange}
                      class={this.state.businessZipClass}
                      placeholder={
                        this.state.globalLang &&
                        this.state.globalLang.signup_business_zip
                      }
                      type="text"
                      label={
                        this.state.globalLang &&
                        this.state.globalLang.signup_label_zip_code
                      }
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12 terms text-left">
                    <input
                      type="checkbox"
                      className={this.state.termConditionClass}
                      name="term_condition"
                      checked={this.state.term_condition ? "checked" : false}
                      onChange={this.handleInputChange}
                    />{" "}
                    {this.state.globalLang &&
                      this.state.globalLang.signup_i_agree_to_the}{" "}
                    <a
                      href="https://www.aestheticrecord.com/terms-of-service"
                      target="_blank"
                    >
                      {this.state.globalLang &&
                        this.state.globalLang
                          .signup_aesthetic_record_terms_of_service_link}
                    </a>
                  </div>
                  {this.state.removePassword == true &&
                    !this.state.existsAsSignup && (
                      <div className="col-sm-12 terms colorAR text-left m-t-20">
                        You already have account in our system. You can login
                        with same password to access this new account.
                      </div>
                    )}
                  {this.state.removePassword == true &&
                    this.state.existsAsSignup && (
                      <div className="col-sm-12 terms colorRed text-left m-t-20">
                        You already have an account on AR.
                      </div>
                    )}
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={
                      this.state.removePassword == true &&
                      this.state.existsAsSignup
                        ? true
                        : false
                    }
                    className={
                      this.state.removePassword == true &&
                      this.state.existsAsSignup
                        ? "subscriptionBtn disable"
                        : "subscriptionBtn"
                    }
                  >
                    {this.state.globalLang &&
                      this.state.globalLang.signup_button_continue}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/*  Info Data Block END  */}
          {/*  Card And Billing Block START  */}
          <div className="sign-up-main">
            <form onSubmit={this.handleCardInfoSubmit}>
              <div
                className={
                  this.state.isShowCardInfo ? "card-block" : "card-block hide"
                }
              >
                <div className="col-sm-12">
                  <div className="login-title">
                    {this.state.globalLang &&
                      this.state.globalLang
                        .signup_premium_plan_billing_block_title}
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="member-section pull-left">
                    <div className="member-section-title no-margin">
                      {this.state.globalLang &&
                        this.state.globalLang.signup_payment_details}
                    </div>
                    <div className="col-sm-12 form-group">
                      <div className="field-group">
                        <label>
                          {this.state.globalLang &&
                            this.state.globalLang.signup_label_card_number}
                          <span className="required">*</span>
                        </label>
                        <div
                          className={this.state.cardNumberClass}
                          id="stripe-card-number"
                        ></div>
                      </div>
                    </div>
                    <div className="col-sm-12 form-group">
                      <div className="field-group">
                        <label>
                          {this.state.globalLang &&
                            this.state.globalLang.signup_label_security_code}
                          <span className="required">*</span>
                        </label>
                        <div
                          className={this.state.cardCvcClass}
                          id="stripe-card-cvc"
                        ></div>
                      </div>
                    </div>
                    <div className="col-sm-12 form-group">
                      <div className="field-group">
                        <label>
                          {this.state.globalLang &&
                            this.state.globalLang.signup_label_expiration_date}
                          <span className="required">*</span>
                        </label>
                        <div
                          className={this.state.cardExpiryClass}
                          id="stripe-card-expiry"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="member-section pull-left">
                    <div className="member-section-title no-margin">
                      {this.state.globalLang &&
                        this.state.globalLang.signup_billing_details}
                    </div>
                    <div className="col-sm-12 form-group">
                      <TextBox
                        name="address"
                        value={this.state.address}
                        handleInputChange={this.handleInputChange}
                        class={this.state.addressClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang
                            .signup_placeholder_billing_address
                        }
                        type="text"
                        label={
                          this.state.globalLang &&
                          this.state.globalLang.signup_label_billing_address
                        }
                        isRequired={true}
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <TextBox
                        name="city"
                        value={this.state.city}
                        handleInputChange={this.handleInputChange}
                        class={this.state.cityClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang.signup_placeholder_city
                        }
                        type="text"
                        label={
                          this.state.globalLang &&
                          this.state.globalLang.signup_label_city
                        }
                        isRequired={true}
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <TextBox
                        name="state"
                        value={this.state.state}
                        handleInputChange={this.handleInputChange}
                        class={this.state.stateClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang.signup_placeholder_state
                        }
                        type="text"
                        label={
                          this.state.globalLang &&
                          this.state.globalLang.signup_label_state
                        }
                        isRequired={true}
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label>
                        {this.state.globalLang &&
                          this.state.globalLang.signup_label_country}
                        <span className="setting-require">*</span>
                      </label>
                      <select
                        name="country"
                        value={this.state.country}
                        onChange={this.handleInputChange}
                        className={this.state.countryClass}
                        placeholder="Country"
                      >
                        <option value="">
                          {this.state.globalLang &&
                            this.state.globalLang.signup_select_country}
                        </option>
                        {this.state.countryList.map((obj, idx) => {
                          return (
                            <option
                              key={"countryList-" + idx}
                              value={obj.country_code}
                            >
                              {obj.country_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 form-group">
                      <TextBox
                        name="pin_code"
                        value={this.state.pin_code}
                        handleInputChange={this.handleInputChange}
                        class={this.state.pinCodeClass}
                        placeholder={
                          this.state.globalLang &&
                          this.state.globalLang.signup_placeholder_zip_code
                        }
                        type="text"
                        label={
                          this.state.globalLang &&
                          this.state.globalLang.signup_label_zip_code
                        }
                        isRequired={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12">
                  <button
                    type="submit"
                    disabled={this.state.processingLoder}
                    className="billingBtn premium-btn ar-login-btn"
                  >
                    {this.state.processingLoder
                      ? this.state.globalLang &&
                        this.state.globalLang.signup_please_wait + "..."
                      : this.state.globalLang &&
                        this.state.globalLang.signup_button_continue}
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/*  Card And Billing Block END  */}
          {/*  Hippa Policy Block START  */}
          <div className="sign-up-main">
            <div
              className={
                this.state.isShowTerms ? "hippa-block" : "hippa-block hide"
              }
            >
              <TermsOfService
                handleChildChange={this.handleChildChange}
                toggleSignUpForm={this.toggleSignUpForm}
                handleInputChange={this.handleInputChange}
                name="agree_checkbox"
                value={this.state.agree_checkbox}
                nextStep={"isShowTerms"}
                processingLoader={this.state.processingLoder}
                globalLang={this.state.globalLang}
                signUpLabel={
                  this.state.globalLang &&
                  this.state.globalLang.term_of_services
                }
              />
            </div>
          </div>
          {/*  Hippa Policy Block END  */}
          {/*  Bba Policy Block START  */}
          <div className="sign-up-main">
            <div
              className={this.state.isShowBba ? "bba-block" : "bba-block hide"}
            >
              <BbaPolicy
                handleChildChange={this.handleChildChange}
                toggleSignUpForm={this.toggleSignUpForm}
                nextStep={"isShowTerms"}
                globalLang={this.state.globalLang}
                signUpLabel={
                  this.state.globalLang &&
                  this.state.globalLang.term_of_services
                }
              />
            </div>
          </div>
          {/*  Bba Policy Block END  */}
        </div>

        <div className="sign-up-main">
          <center>
            <img
              alt=""
              className="banner-image"
              src="/images/signup-banner1.png"
            />
          </center>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        <FooterTouchMD />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let returnState = {};
  if (state.SignUpReducer.action === "SIGN_UP_TOUCHMD") {
    uiNotification.clear();
    if (state.SignUpReducer.data.status != 201) {
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      if (languageData && languageData.global) {
        uiNotification.error(
          languageData.global[state.SignUpReducer.data.message],
        );
      }
      returnState.processingLoder = true;
      returnState.showLoader = false;
    } else {
      localStorage.setItem("accountSetupFile", state.SignUpReducer.data.data);
      localStorage.setItem("accountSetupStatus", 0);
      returnState.signUpData = state.SignUpReducer.data.data;
    }
  } else if (state.SignUpReducer.action === "UNIQUE_EMAIL") {
    if (state.SignUpReducer.data.status != 200) {
      if (
        state.SignUpReducer.data.data &&
        state.SignUpReducer.data.data.status &&
        state.SignUpReducer.data.data.status == "exists_as_signup"
      ) {
        returnState.existsAsSignup = true;
      }
      returnState.isUniqueEmail = false;
    } else {
      returnState.isUniqueEmail = true;
    }
  } else if (state.SignUpReducer.action === "SIGN_UP_PLANS") {
    uiNotification.clear();
    if (state.SignUpReducer.data.status != 200) {
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      if (languageData && languageData.global) {
        uiNotification.error(
          languageData.global[state.SignUpReducer.data.message],
        );
      }
      returnState.processingLoder = true;
      returnState.showLoader = false;
    } else {
      returnState.planAndCountryData = state.SignUpReducer.data.data;
    }
  }

  return returnState;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signUp: signUp,
      getSignUpPlans: getSignUpPlans,
      signUpPremium: signUpPremium,
      checkUniqueEmail: checkUniqueEmail,
      signUpTouchMD: signUpTouchMD,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CandelaSignup));
