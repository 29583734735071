import { positionFooterCorrectly } from "../../Utils/services.js";
import { tSales } from "../../i18n/useAppTranslation.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

const languageData = JSON.parse(localStorage.getItem("languageData"));

export function getInvoiceDetails(invoiceID, clientID) {
  return async (dispatch) => {
    return http
      .get("sales/invoice_details/" + invoiceID + "/" + clientID, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => {
        dispatch({ type: "GET_INVOICE_DETAILS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_INVOICE_DETAILS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getPaymentHistory(invoiceID) {
  return async (dispatch) => {
    return http
      .get("sales/invoices/payment-history/" + invoiceID)
      .then((response) => {
        dispatch({ type: "GET_PAYMENT_HISTORY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PAYMENT_HISTORY", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function sendAndDownloadInvoice(invoiceID, type) {
  return async (dispatch) => {
    return http
      .get("sales/send_invoice/" + invoiceID + "/" + type)
      .then((response) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_INVOICE",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_INVOICE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function sendAndDownloadRefundReceipt(invoiceID, type) {
  return (dispatch) => {
    http
      .get("sales/send_refund/" + invoiceID + "/" + type)
      .then((response) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_REFUND_RECEIPT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_REFUND_RECEIPT",
          payload: error.response.data,
        });
      });
  };
}

export function getRefundDetails(invoiceID) {
  return async (dispatch) => {
    return http
      .get("sales/issue-refund/" + invoiceID)
      .then((response) => {
        dispatch({ type: "GET_REFUND_DETAILS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_REFUND_DETAILS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function payRefund(formData) {
  return (dispatch) => {
    http
      .post("sales/invoices/pay-refund", formData)
      .then((response) => {
        dispatch({ type: "ISSUE_REFUND", payload: response.data });
        uiNotification.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({ type: "ISSUE_REFUND", payload: error.response.data });
        uiNotification.error(languageData.global[error.response.data.message]);
      });
  };
}

export function changeUserID(formData) {
  return async (dispatch) => {
    return http
      .post("sales/update-invoice-item-employee", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_USER_IN_INVOICE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_USER_IN_INVOICE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function saveAndSendEmail(formData) {
  return async (dispatch) => {
    return http
      .post("sales/save-email", formData)
      .then((response) => {
        dispatch({ type: "SAVE_AND_SEND_EMAIL", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_AND_SEND_EMAIL", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function refundIssue(id) {
  return async (dispatch) => {
    return http
      .post("refund-membership-invoice/" + id)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "MONTHLY_MEMBERSHIP_ISSUE_REFUND",
          payload: response.data,
        });
        if (response.data.status === 200) {
          uiNotification.success(languageData.global[response.data.message]);
        } else {
          uiNotification.error(languageData.global[response.data.message]);
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "MONTHLY_MEMBERSHIP_ISSUE_REFUND",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function voidAndRefund(formData) {
  return (dispatch) => {
    http
      .post("sales/void-refund-invoice", formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "VOID_REFUND_INVOICE", payload: response.data });
        if (response.data.status !== 200) {
          uiNotification.error(
            languageData.global[response.data.message] ||
              tSales(
                `invoicePreview.apiError.voidAndRefund.${response.data.message}`,
              ),
          );
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "VOID_REFUND_INVOICE", payload: error.response.data });
        uiNotification.error(
          languageData.global[error.response.data.message] ||
            tSales(
              `invoicePreview.apiError.voidAndRefund.${error.response.data.message}`,
            ),
        );
      });
  };
}

export const editTipsPerItem = async (invoiceId, formData) => {
  return http.put(`sales/update-invoice-item-tips/${invoiceId}`, formData);
};
