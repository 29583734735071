import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import PropTypes from "prop-types";
import { Box } from "../../../../../../shared/Box/Box";
import classes from "./PatientInfo.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { InfoRow } from "./shared/InfoRow/InfoRow";
import { Header } from "./shared/Header/Header";
import { THEME } from "../../../../../../consts/theme";
import { Skeleton } from "../../../../../../shared/Skeleton/Skeleton";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { showFormattedDate } from "../../../../../../Utils";

export function PatientInfo({ patient, isLoading }) {
  const { tInbox } = useAppTranslation.Inbox();
  const { data: account } = useCurrentUserQuery();

  return isLoading ? (
    <SkeletonTheme baseColor={THEME.colors.white}>
      <Skeleton count={1} height={600} containerClassName={classes.root} />
    </SkeletonTheme>
  ) : (
    <Box noPadding className={classes.root}>
      <Header
        imageUrl={patient.imageUrl}
        fullName={patient.fullName}
        email={patient.email}
        location={patient.location}
      />
      <div className={classes.info}>
        {patient.phone && (
          <InfoRow title={tInbox("leadAr.patientInfo.phone")}>
            {patient.phone}
          </InfoRow>
        )}
        {patient.birthday && (
          <InfoRow title={tInbox("leadAr.patientInfo.birthday")}>
            {showFormattedDate(
              patient.birthday,
              false,
              account?.previewDateFormat,
            )}
          </InfoRow>
        )}
        {patient.emergencyName && (
          <InfoRow title={tInbox("leadAr.patientInfo.emergencyName")}>
            {patient.emergencyName}
          </InfoRow>
        )}
        {patient.emergencyNumber && (
          <InfoRow title={tInbox("leadAr.patientInfo.emergencyNumber")}>
            {patient.emergencyNumber}
          </InfoRow>
        )}
        {patient.lastVisit && (
          <InfoRow title={tInbox("leadAr.patientInfo.lastVisit")}>
            {patient.lastVisit}
          </InfoRow>
        )}
        {patient.membershipProgram && (
          <InfoRow title={tInbox("leadAr.patientInfo.membershipProgram")}>
            {patient.membershipProgram}
          </InfoRow>
        )}
        {patient.totalSaleRelationship && (
          <InfoRow title={tInbox("leadAr.patientInfo.totalSaleRelationship")}>
            {patient.totalSaleRelationship}
          </InfoRow>
        )}
      </div>
    </Box>
  );
}

PatientInfo.propTypes = {
  patient: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
