import * as yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";
import { APPOINTMENT_TYPES, APPT_API_TIME_FORMAT } from "../Event.consts";
import {
  wrapFormDate,
  getCalendarStartDate,
  initFormDate,
} from "../Event.utils";
import { phoneUtil } from "../../../utilities/phone";
import {
  Maybe,
  cond,
  identity,
  pipe,
  positive,
  strDefault,
} from "../../../utilities/fp";
import { isDobForBookingAvailable } from "../../../helpers/featureFlags";

export const getCreateFormInitialDate = (params = {}) => {
  return initFormDate({
    date: params.date,
    boundaryDate: wrapFormDate(new Date()),
  });
};

const initAppointmentCreateForm = ({
  userPaymentSystem,
  params,
  clientById,
  userTimeFormat,
}) => {
  return {
    isEditMode: false,

    userPaymentSystem,

    type: APPOINTMENT_TYPES.inPerson,

    providerId: Maybe.of(params.providerId)
      .map(Number)
      .map((x) => pipe(x, cond(strDefault, [positive, identity])))
      .orElse("")
      .value(),

    clinicId: params.clinicId ? Number(params.clinicId) : "",

    services: [],

    isDoubleBooking: false,

    isOutsideScheduledHours: false,

    isEnterCreditCardDetails: false,

    calendarStartDate: wrapFormDate(getCalendarStartDate(params.date)),

    date: getCreateFormInitialDate(params),

    timeStart: params.time
      ? moment(params.time, APPT_API_TIME_FORMAT).format(userTimeFormat)
      : "",

    clientId: clientById.id || "",

    clientName: clientById.fullName || "",

    clientEmail: clientById.email || "",

    clientPhone: clientById.phone || "",

    clientDob: isDobForBookingAvailable() ? clientById.dateOfBirth || "" : "",

    notes: "",

    clearentZipCode: clientById.pinCode || "",

    clearentEmail: clientById.email || "",

    isAddNewCard: false,

    isPatientToBeCharged: false,

    isCancellationPolicyExcludeConfirmed: false,

    isConvertingWaitlist: false,
  };
};

const initAppointmentEditForm = ({
  userPaymentSystem,
  editEventData,
  params,
  userTimeFormat,
}) => {
  return {
    isEditMode: true,
    userPaymentSystem,
    type: editEventData.type,
    providerId: editEventData.providerId,
    clinicId: params.clinicId
      ? Number(params.clinicId)
      : editEventData.clinicId,
    services: editEventData.services,
    isDoubleBooking: editEventData.isDoubleBooking,
    isOutsideScheduledHours: editEventData.isOutsideScheduledHours,
    calendarStartDate: wrapFormDate(getCalendarStartDate(editEventData.date)),
    date: editEventData.date,
    timeStart: moment(
      params.time || editEventData.timeStart,
      APPT_API_TIME_FORMAT,
    ).format(userTimeFormat),
    clientId: editEventData.clientId,
    clientName: editEventData.clientName,
    clientEmail: editEventData.clientEmail,
    clientPhone: editEventData.clientPhone,
    clientDob: isDobForBookingAvailable() ? editEventData.clientDob || "" : "",
    notes: editEventData.notes,
    clearentZipCode: editEventData.clientPinCode,
    clearentEmail: editEventData.clientEmail,
    isAddNewCard: false,
    isEnterCreditCardDetails: false,
    isPatientToBeCharged: false,
    isCancellationPolicyExcludeConfirmed: false,
    isConvertingWaitlist: false,
  };
};

const initConvertWaitlistForm = ({ history }) => {
  let waitlistFormData = {};

  const {
    id,
    patient,
    appointment_notes,
    appointment_wait_list_clinic,
    appointment_wait_list_services,
  } = history.location.state;

  waitlistFormData = {
    isConvertingWaitlist: true,
    clinicId: appointment_wait_list_clinic,

    clientName: patient.full_name,
    clientId: patient.id,
    clientEmail: patient.email,
    clearentEmail: patient.email,
    clientPhone: patient.phoneNumber,
    clientDob: isDobForBookingAvailable() ? patient.date_of_birth || "" : "",
    notes: appointment_notes,

    services: appointment_wait_list_services.map((serviceDetails) => ({
      name: serviceDetails.name,
      id: serviceDetails.id,
      durationInMin: serviceDetails.duration,
      isFree: serviceDetails.isFree,
      price: serviceDetails.price,
      isSkipPaymentCheck: false,
      isDepositConfirmed: false,
    })),
    appointment_waitlist_id: id,
  };
  return waitlistFormData;
};

export const initAppointmentForm = ({
  userPaymentSystem,
  params,
  clientById,
  editEventData,
  history,
  userTimeFormat,
}) => {
  if (
    history &&
    history.location &&
    history.location.state &&
    "appointment_wait_list_clinic" in history.location.state
  ) {
    return {
      ...initAppointmentCreateForm({
        userPaymentSystem,
        clientById,
        params,
        userTimeFormat,
      }),
      ...initConvertWaitlistForm({ history }),
    };
  }
  if (editEventData) {
    return initAppointmentEditForm({
      userPaymentSystem,
      editEventData,
      params,
      userTimeFormat,
    });
  }
  return initAppointmentCreateForm({
    userPaymentSystem,
    clientById,
    params,
    userTimeFormat,
  });
};

export function useFormAppointment(initialValues) {
  const schema = yup.object({
    isEditMode: yup.bool(),

    userPaymentSystem: yup.string(),

    calendarStartDate: yup.string(),

    type: yup.string().required("Select Appointment Type"),

    providerId: yup.number().required("Select provider"),

    clinicId: yup.number().required("Select clinic"),

    services: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(),
          durationInMin: yup.number().required(),
          isDepositConfirmed: yup.bool().required(),
          isFree: yup.bool().required(),
          isSkipPaymentCheck: yup.bool().required(),
          price: yup.number(),
        }),
      )
      .min(1, "Select at least one service"),

    isDoubleBooking: yup.bool().required(),

    isOutsideScheduledHours: yup.bool().required(),

    isEnterCreditCardDetails: yup.bool().required(),

    date: yup.string().required("Select date"),

    timeStart: yup.string().required("Select start time"),

    clientId: yup.number().test({
      name: "clientIdRequired",
      message: "Seems like you forgot to select client from the search list",
      test: function (value) {
        return !(!value && this.parent.clientName);
      },
    }),

    appointment_waitlist_id: yup.number(),

    clientName: yup.string().required("Select client from the search list"),

    clientEmail: yup.string().email("Provide valid client email"),

    clientDob: isDobForBookingAvailable()
      ? yup.lazy((_, { context }) => {
          if (context.isEditMode) {
            return yup.string().nullable().notRequired();
          }
          return yup.string().required("Provide date of birth");
        })
      : yup.string().nullable().notRequired(),

    clientPhone: yup
      .string()
      .test({
        message: "Please provide phone number",
        test: (value, context) => {
          const isPhoneRequired =
            context.parent.type === APPOINTMENT_TYPES.virtual;
          return !(isPhoneRequired && !value);
        },
      })
      .test({
        message: "Provide valid phone number",
        test: (value) => {
          if (!value) {
            return true;
          }
          return phoneUtil.isPossible(phoneUtil.compose(value));
        },
      }),

    notes: yup.string().nullable(),

    clearentZipCode: yup.string().test({
      name: "clearentZipCodeRequired",
      message: "Provide Zip Code",
      test: function (value) {
        const paymentSystem = this.parent.userPaymentSystem;
        const isEnterCreditCardDetails = this.parent.isEnterCreditCardDetails;
        if (
          paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
          isEnterCreditCardDetails &&
          !value
        ) {
          return false;
        }
        return true;
      },
    }),

    clearentEmail: yup
      .string()
      .test({
        name: "clearentEmailRequired",
        message: "Provide Email for credit card data",
        test: function (value) {
          const paymentSystem = this.parent.userPaymentSystem;
          const isEnterCreditCardDetails = this.parent.isEnterCreditCardDetails;
          if (
            paymentSystem === USER_PAYMENT_SYSTEMS.clearent &&
            isEnterCreditCardDetails &&
            !value
          ) {
            return false;
          }
          return true;
        },
      })
      .email("Provide valid email for credit card data"),

    isAddNewCard: yup.bool().required(""),

    isPatientToBeCharged: yup.bool().required(""),

    isCancellationPolicyExcludeConfirmed: yup.bool(),

    isConvertingWaitlist: yup.bool(),
  });

  const { values, errors, setFieldValue, setFieldError, validateForm } =
    useFormik({
      initialValues,
      validationSchema: schema,
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: () => {},
    });

  const submit = (submitter, partialValues = {}) => {
    const nextValues = { ...values, ...partialValues };
    validateForm(nextValues).then((errors) => {
      if (Object.keys(errors).length === 0) {
        submitter(nextValues, { setFieldError, setFieldValue });
      } else {
        Object.values(errors).forEach((message) => {
          if (message) {
            uiNotification.error(message);
          }
        });
      }
    });
  };

  const hasError = (field) => {
    return Object.keys(errors).includes(field);
  };

  return {
    form: values,
    setFormValue: setFieldValue,
    submit,
    hasError,
  };
}
