import React from "react";
import { AppProvider } from "./shared/AppProvider";
import { store } from "../store/store";
import { queryClient } from "../api/queryClient";
import { history } from "../history";
import ScrollToTop from "../shared/ScrollToTop/ScrollToTop";
import { Router } from "../router/Router";
import { Notifications } from "../widgets/Notifications/Notifications";
import { GlobalPopups } from "../widgets/GlobalPopups/GlobalPopups";
import { Idle } from "../widgets/Idle";
import { isAutoLogoutAvailable } from "../helpers/featureFlags";

export const App = () => {
  return (
    <AppProvider store={store} queryClient={queryClient} history={history}>
      <ScrollToTop />
      <Notifications />
      <GlobalPopups />
      <Router />
      {isAutoLogoutAvailable() && <Idle />}
    </AppProvider>
  );
};
