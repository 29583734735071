import { LOGOUT_REASONS } from "../../consts/auth";
import { LOCAL_STORAGE_KEYS } from "../../consts/localStorage";
import { ROUTES } from "../../consts/routes";
import { logout } from "../../helpers/auth";
import { history } from "../../history";
import { authService } from "../../services/AuthService";

export var ActivityTracking = {
  capture: () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.idle_lastActive, Date.now());
  },

  /**
   * @param {number} timeout
   */
  isExpired: (timeout) => {
    var lastRecord =
      parseInt(localStorage.getItem(LOCAL_STORAGE_KEYS.idle_lastActive)) || 0;
    return !lastRecord || Date.now() - lastRecord >= timeout;
  },

  lock: () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.idle_lockActivityTracking, "true");
  },

  unlock: () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.idle_lockActivityTracking);
  },

  isLocked: () => {
    return (
      localStorage.getItem(LOCAL_STORAGE_KEYS.idle_lockActivityTracking) ===
      "true"
    );
  },
};

export var IdleTabSignature = {
  _getSignature() {
    return String(sessionStorage.getItem("idleTabSignature"));
  },

  _compare(signature) {
    return String(signature) === this._getSignature();
  },

  sign() {
    sessionStorage.setItem("idleTabSignature", Date.now());
  },

  activate() {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.idle_activeTabSignature,
      this._getSignature(),
    );
  },

  deactivate() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.idle_activeTabSignature);
  },

  isActive() {
    var signature = localStorage.getItem(
      LOCAL_STORAGE_KEYS.idle_activeTabSignature,
    );
    return signature === null || this._compare(signature);
  },
};

export var logoutByIdle = (withReason = true) => {
  if (authService.isLoggedIn()) {
    logout(withReason ? { reason: LOGOUT_REASONS.sessionExpire } : {});
  } else if (!window.location.pathname.includes(ROUTES.login())) {
    history.push(ROUTES.login());
  }
};

export function initTabVisibilityListener({ onNotActive, onActive }) {
  var hidden, visibilityChange;

  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }

  var fn = function () {
    if (document[hidden]) {
      onNotActive();
    } else {
      onActive();
    }
  };

  document.addEventListener(visibilityChange, fn, false);

  return {
    destroy: () => {
      document.removeEventListener(visibilityChange, fn, false);
    },
  };
}
