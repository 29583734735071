import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../consts/api";
import { http } from "../../../../services/HttpService";

export const useRunJob = (options = {}) => {
  const res = useMutation(
    async (dto) => http.post(HTTP_ENDPOINTS.runJob(), dto),
    {
      ...options,
    },
  );
  return res;
};
