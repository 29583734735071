import React, { useState, useRef, useEffect } from "react";
import { SketchField, Tools } from "react-sketch";
import { useInView } from "react-intersection-observer";
import { Button } from "../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import styles from "./AgreementContent.module.scss";
import Loader from "../../../../Components/Common/Loader";

export function AgreementContent({ agreement, onSubmit, isLast, isLoading }) {
  const { tCommon } = useAppTranslation.Common();
  const { ref, inView } = useInView();
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isTouched, setIsToched] = useState(false);

  const signRef = useRef();
  const agreementRef = useRef();

  const checkIsTouced = (e) => {
    if (signRef && (e.type === "mouseup" || e.type === "touchend")) {
      setIsToched(true);
    }
  };

  const sumbit = () => {
    const formData = {
      ...agreement,
      signature: signRef.current.toDataURL(),
    };
    onSubmit(formData);
  };

  const clearSignature = () => {
    signRef.current.clear();
    setIsToched(false);
  };

  useEffect(() => {
    if (inView) {
      setIsScrolledToEnd(true);
    }
  }, [inView]);

  return (
    <div>
      <Loader showLoader={isLoading} />
      <div className={styles.termsBlock} ref={agreementRef}>
        <div
          className={styles.agreementInformation}
          dangerouslySetInnerHTML={{ __html: agreement?.text }}
        ></div>
        <div ref={ref}></div>
      </div>

      {isScrolledToEnd && (
        <div>
          <div className={styles.sketchField}>
            <div className={styles.sketchBlock}>
              <SketchField
                width="400px"
                ref={signRef}
                height="200px"
                tool={Tools.Pencil}
                lineColor="black"
                lineWidth={6}
                onChange={checkIsTouced}
              />
            </div>
          </div>
        </div>
      )}

      {isScrolledToEnd && (
        <div className={styles.footerButtonsContainer}>
          <Button
            className={styles.btnText}
            isDisabled={!isTouched || !isScrolledToEnd || isLoading}
            onClick={sumbit}
          >
            {isLast
              ? tCommon("agreementModal.submit")
              : tCommon("agreementModal.continue")}
          </Button>
          <Button onClick={clearSignature} variant="outlined">
            {tCommon("agreementModal.clearSignature")}
          </Button>
        </div>
      )}
    </div>
  );
}
