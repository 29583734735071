import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import moment from "moment";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "../sass/Table.module.scss";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../shared/SortAdornment/SortAdornment";
import { history } from "../../../../../history";
import { RECONCILIATION_REPORTS_ORDER_BY } from "../../../../../api/queries/useReconciliationReportsQuery";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { formatCurrency } from "../../../../../utilities/general";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";

export function Table({ reports, order }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const isEmpty = reports.value.length === 0 && !reports.isSoftLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const goToReport = (report) => {
    history.push(
      window.location.pathname +
      `?${queryString.stringify({
        id: report.id,
      })}`,
    );
  };

  const formatAmount = (fee, currency) => {
    if (fee === 0) {
      return tCommon("label.free");
    }
    if (!fee) {
      return tCommon("symbol.longDash");
    }
    return formatCurrency(fee, currency);
  };

  const getStatus = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const data = reports.value.map((r) => ({
    invoice_number: r.invoice_number || tCommon("symbol.longDash"),
    patient_name: r.patient_name || tCommon("symbol.longDash"),
    payment_date: r.payment_date
      ? moment(r.payment_date).format(user?.dateFormat)
      : tCommon("symbol.longDash"),
    payout_id: r.payout_id || tCommon("symbol.longDash"),
    transaction_type:
      getStatus(r.transaction_type) || tCommon("symbol.longDash"),
    cc_collected_amount:
      formatAmount(r.cc_collected_amount, r.currency) ||
      tCommon("symbol.longDash"),
    payout_amount:
      formatAmount(r.payout_amount, r.currency) || tCommon("symbol.longDash"),

    payout_arrival_date: r.payout_arrival_date
      ? moment(r.payout_arrival_date).format(user?.dateFormat)
      : tCommon("symbol.longDash"),

    transaction_payment_type: r.transaction_payment_type ? getStatus(r.transaction_payment_type) : tCommon("symbol.longDash"),
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <Box>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={39.06} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            headClassName={classes.tableHead}
            bgColor="white"
            keyExtractor={(row) => row.id}
            onRowClick={goToReport}
            rowClassName={classes.row}
            colClassName={classes.col}
            data={data}
            cols={[
              {
                data: renderSortWrap(
                  tCommon("label.invoice_number"),
                  RECONCILIATION_REPORTS_ORDER_BY.invoice_number,
                ),
                accessor: "invoice_number",
                headColClassName: classes.bigColWidth,
              },
              {
                data: renderSortWrap(
                  tCommon("label.patientName"),
                  RECONCILIATION_REPORTS_ORDER_BY.patient_name,
                ),
                accessor: "patient_name",
              },
              {
                data: renderSortWrap(
                  tCommon("label.payment_date"),
                  RECONCILIATION_REPORTS_ORDER_BY.payment_date,
                ),
                accessor: "payment_date",
              },
              {
                data: renderSortWrap(
                  tCommon("label.payout_arrival_date"),
                  RECONCILIATION_REPORTS_ORDER_BY.payout_arrival_date,
                ),
                accessor: "payout_arrival_date",
              },
              {
                data: renderSortWrap(
                  tCommon("label.transaction_type"),
                  RECONCILIATION_REPORTS_ORDER_BY.transaction_type,
                ),
                accessor: "transaction_type",
              },
              {
                data: renderSortWrap(
                  tCommon("label.cc_collected_amount"),
                  RECONCILIATION_REPORTS_ORDER_BY.cc_collected_amount,
                ),
                accessor: "cc_collected_amount",
              },
              {
                data: renderSortWrap(
                  tCommon("label.payout_amount"),
                  RECONCILIATION_REPORTS_ORDER_BY.payout_amount,
                ),
                accessor: "payout_amount",
              },
              {
                data: renderSortWrap(
                  tCommon("label.transaction_payment_type"),
                  RECONCILIATION_REPORTS_ORDER_BY.transaction_payment_type,
                ),
                accessor: "transaction_payment_type",
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}

Table.propTypes = {
  reports: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};
