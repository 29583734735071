import React from "react";
import { logout } from "../../helpers/auth";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    logout();
  }

  render() {
    return null;
  }
}

export default Logout;
