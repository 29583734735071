/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as _ from "lodash";
import { useSpring, a, animated } from "react-spring";
import {
  useMeasure,
  usePrevious,
} from "../../_legacy/Containers/Settings/SidebarHelper";
import { getIsPosEnabled } from "../../Utils/services.js";
import { isShopifyAvailable } from "../../helpers/featureFlags.js";

const toggle = {
  marginTop: 0,
  cursor: "pointer",
  verticalAlign: "top",
  display: "inline-block",
};

const Tree = ({
  children,
  name,
  styleClass,
  styleLinkClass,
  childClass,
  navigateTo,
  activeMenu,
  parentIndex,
}) => {
  const [isOpen, setOpen] = useState(activeMenu);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform, visibility } = useSpring({
    from: {
      height: 0,
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      visibility: "hidden",
    },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
      visibility: isOpen ? "visible" : "hidden",
    },
  });

  return (
    <li className={styleClass}>
      {navigateTo ? (
        navigateTo != "javascript:void(0);" ? (
          <Link className={styleLinkClass} to={navigateTo}>
            {" "}
            {name}
          </Link>
        ) : (
          <a className={styleLinkClass}>{name}</a>
        )
      ) : (
        <a
          className={styleLinkClass}
          style={{ ...toggle, opacity: children ? 1 : 0 }}
          onClick={() => setOpen(!isOpen)}
          data-is-parent-sidebar={1}
          data-is-opened={isOpen ? 1 : 0}
          data-parent-index={parentIndex}
        >
          {name}
          {!navigateTo && isOpen && (
            <span
              style={{ ...toggle }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-up"
                data-is-parent-sidebar={1}
                data-is-opened={isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
          {!navigateTo && !isOpen && (
            <span
              style={{ ...toggle }}
              onClick={() => setOpen(!isOpen)}
              data-is-parent-sidebar={1}
              data-is-opened={!isOpen ? 1 : 0}
              data-parent-index={parentIndex}
            >
              {" "}
              <i
                className="fas fa-angle-down"
                data-is-parent-sidebar={1}
                data-is-opened={!isOpen ? 1 : 0}
                data-parent-index={parentIndex}
              ></i>{" "}
            </span>
          )}
        </a>
      )}

      <animated.div
        className={opacity == 0 ? "no-display frame-content" : "frame-content"}
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
          visibility,
        }}
      >
        <a.ul
          className={childClass}
          style={{ transform }}
          {...bind}
          children={children}
        />
      </animated.div>
    </li>
  );
};

const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
};

const renderSidebar = () => {
  const userPermissions =
    JSON.parse(localStorage.getItem("userData"))?.permissions || [];
  let sideBarPermissions = [...userPermissions];

  let sideBarContent = [];

  let productsSubmenu = [];
  productsSubmenu.push({
    label: "Your Inventory",
    to: "/inventory/products/active",
    match: [
      "/inventory/products/active",
      "/inventory/products/active/:searchTerm?",
      "/inventory/product/add",
      "/inventory/product/edit/:id",
    ],
  });
  if (isShopifyAvailable()) {
    productsSubmenu.push({
      label: "Shopify Products",
      to: "/inventory/shopify-products",
      match: ["/inventory/shopify-products"],
    });
  }
  productsSubmenu.push({
    label: "Price List",
    to: "/inventory/pricelist",
    match: ["/inventory/pricelist"],
  });
  // Adding Charting Packages
  if (checkPermission(sideBarPermissions, "view-charting-package")) {
    productsSubmenu.push({
      label: "Pricing Variation",
      to: "/inventory/standard-packages",
      match: [
        "/inventory/standard-packages",
        "/inventory/standard-packages/create",
        "/inventory/standard-packages/edit/:id",
      ],
    });
  }
  productsSubmenu.push({
    label: "AR Product Directory",
    to: "/inventory/products/inactive",
    match: ["/inventory/products/inactive"],
  });
  productsSubmenu.push({
    label: "Inventory Reconciliation",
    to: "/inventory/reconciliation",
    match: ["/inventory/reconciliation"],
  });
  productsSubmenu.push({
    label: "Stock Transfer",
    to: "/inventory/stock-transfers",
    match: [
      "/inventory/stock-transfers",
      "/inventory/stock-transfers/create",
      "/inventory/stock-transfers/:id/edit",
      "/inventory/stock-transfers/:id/view",
    ],
  });

  if (checkPermission(sideBarPermissions, "receive-stock-alerts")) {
    productsSubmenu.push({
      label: "Stock Alert Emails",
      to: "/inventory/stock-alerts-emails",
      match: ["/inventory/stock-alerts-emails"],
    });
  }

  sideBarContent.push({
    icon: "fas angle-down",
    label: "Products",
    content: productsSubmenu,
  });
  let ordersMenu = [];
  ordersMenu.push({
    label: "Suppliers",
    to: "/inventory/suppliers",
    match: [
      "/inventory/suppliers",
      "/inventory/suppliers/:id/view",
      "/inventory/suppliers/import",
    ],
  });

  ordersMenu.push({
    label: "Purchase Orders",
    to: "/inventory/purchase-order",
    match: [
      "/inventory/purchase-order",
      "/inventory/purchase-order/:id",
      "/inventory/purchase-order/add/:id",
      "/inventory/purchase-order/add",
      "/inventory/purchase-order/view/:id",
    ],
  });

  sideBarContent.push({
    icon: "fas angle-down",
    label: "Orders",
    content: ordersMenu,
  });

  return sideBarContent;
};

class InventorySidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleClassName: "new-setting-tabs-a",
      sideBarContent: renderSidebar(),
      isPosEnabled: getIsPosEnabled(),
      isResetSideBar: true,
      parentSidebarIndex: null,
      isOpened: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleSideBarClick, false);
  }

  static getDerivedStateFromProps(_, prevState) {
    let returnState = {};
    if (getIsPosEnabled() !== prevState.isPosEnabled) {
      returnState.isPosEnabled = getIsPosEnabled();
      returnState.sideBarContent = renderSidebar();
      returnState.isResetSideBar = true;
      returnState.parentSidebarIndex = null;
      returnState.isOpened = 0;
    }
    return returnState;
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleSideBarClick, false);
  }

  handleSideBarClick = (e) => {
    if (e) {
      let dataset = e.target.dataset;
      if (dataset && dataset.isParentSidebar) {
        if (this.state.parentSidebarIndex != dataset.parentIndex) {
          this.setState({ isResetSideBar: false });
          this.setState({
            isResetSideBar: true,
            parentSidebarIndex: dataset.parentIndex,
            isOpened: dataset.isOpened,
          });
        }
      }
    }
  };

  activeParentLink = (obj, idx) => {
    let returnClass = "parent_class new-setting-subtabs-a";
    if (this.state.parentSidebarIndex == null) {
      if (obj !== undefined) {
        obj.map((io) => {
          if (io.content !== undefined) {
            io.content.map((o) => {
              if (o.match.indexOf(this.props.match.path) > -1) {
                returnClass = "parent_class new-setting-subtabs-a active-menu";
              }
            });
          }
        });
      }
    } else {
      if (this.state.parentSidebarIndex == idx && this.state.isOpened == 1) {
        returnClass = "parent_class new-setting-subtabs-a active-menu";
      }
    }
    return { returnClass };
  };

  noChildClass = (obj) => {
    let returnClass = "new-setting-tabs-li";
    if (obj !== undefined) {
      obj.map((io) => {
        if (io.match && io.match !== undefined) {
          if (
            this.props.match.path.slice(9).startsWith(io.to.slice(9)) > -1 &&
            this.props.match.url.startsWith(io.to)
          ) {
            if (io.content) {
              returnClass = "new-setting-tabs-li active-menu has-child";
            } else {
              returnClass = "new-setting-tabs-li active-menu no-child";
            }
          }
        }
      });
    }
    return { returnClass };
  };

  setOpen = () => {};

  renderSideBarContent = () => {
    let nav = [];
    nav = this.state.sideBarContent.map((obj, idx) => {
      let toggleClasss = this.activeParentLink([obj], idx).returnClass;

      let parentListClass = this.noChildClass([obj]).returnClass;
      let activeMenu = toggleClasss.indexOf("active-menu") > -1 ? true : false;

      return (
        <Tree
          name={obj.label}
          styleClass={parentListClass}
          styleLinkClass={toggleClasss}
          key={idx}
          childClass={"setting-submenu child"}
          navigateTo={obj.to}
          activeMenu={activeMenu}
          parentIndex={idx}
        >
          {obj.content !== undefined &&
            obj.content.length > 0 &&
            obj.content.map((innerObj, innerIdx) => {
              let subMenuClass =
                innerObj.match.indexOf(this.props.match.path) > -1
                  ? "new-setting-subtabs-a sel-submenu"
                  : "new-setting-subtabs-a";
              return (
                <Tree
                  name={innerObj.label}
                  styleClass={"new-setting-subtabs-li"}
                  styleLinkClass={subMenuClass}
                  key={innerIdx}
                  navigateTo={innerObj.to}
                />
              );
            })}
        </Tree>
      );
    });
    return nav;
  };

  render() {
    return (
      <div className="setting-left-menu">
        <div className="setting-title">Inventory</div>
        <ul className="new-setting-tabs">
          {this.state.isResetSideBar === true && this.renderSideBarContent()}
        </ul>
      </div>
    );
  }
}

function mapStateToProps() {
  const returnState = {
    isPosEnabled: getIsPosEnabled(),
  };
  return returnState;
}

export default connect(mapStateToProps)(withRouter(InventorySidebar));
