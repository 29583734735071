import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useJoyaReserveValidate = (invoiceId, options = {}) => {
  return useQuery(
    ["joyaReserveValidate"],
    async () => {
      return http.post(HTTP_ENDPOINTS.joyaReserveValidate(invoiceId));
    },
    { cacheTime: 0, ...options },
  );
};
