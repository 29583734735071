import { useMemo } from "react";
import { uiNotification } from "../../../services/UINotificationService";
import { useClientQuery } from "../../../api/queries/useClientQuery";

export function useApiClientById(id) {
  const { data, isFetching } = useClientQuery(
    { clientId: Number(id) },
    {
      staleTime: 0,
      enabled: Boolean(id),
      onError: () => {
        uiNotification.error("Unable to retrieve client data. Try again later");
      },
    },
  );

  const clientById = useMemo(
    () =>
      data
        ? {
            id: data.id,
            fullName: data.fullName,
            email: data.email || "",
            phone: data.phone || "",
            pinCode: data.pinCode || "",
            dateOfBirth: data.dateOfBirth || "",
          }
        : {},
    [data],
  );

  return {
    clientById,
    isClientByIdFetching: isFetching,
  };
}
