export const LOCAL_STORAGE_KEYS = {
  languageData: "languageData",
  userData: "userData",
  userPermissionsAllowed: "userPermissionsAllowed",
  accessToken: "access_token",
  recentlyViewedData: "recentlyViewedData",
  focusedRange: "focusedRange",
  twilioSetup: "twilioSetup",
  currencySymbol: "currencySymbol",
  accountType: "accountType",
  twilioFromNumber: "twilioFromNumber",
  accountStatus: "accountStatus",
  subscriptionType: "subscriptionType",
  subscriptionPlan: "subscriptionPlan",
  currencyLabel: "currencyLabel",
  stripeConnection: "stripeConnection",
  timeFormat: "timeFormat",
  timeClockSetting: "time_clock_setting",
  isMembershipEnabled: "isMembershipEnabled",
  cTerm: "cTerm",
  currentWcFullName: "current_wc_full_name",
  twoFactorData: "twoFactorData",
  showRecaptcha: "showRecaptcha",
  loginAttempts: "login_attempts",
  i18nLang: "i18nextLng",
  wpuId: "wpuId",
  preLoginData: "preLoginData",
  globalSearchUsedPatterns: "globalSearchUsedPatterns",
  dateFormat: "dateFormat",
  clinicTimezone: "clinicTimezone",
  lockAppUntilAccountAddressUpdated: "luau",

  idle_lastActive: "idle_last_active",
  idle_lockActivityTracking: "idle_lock_activity_tracking",
  idle_activeTabSignature: "idle_active_tab_signature",
};

export const LANGUAGE_DATA_KEYS = {
  appointments: "appointments",
};
