import { useMutation, useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useConnectJoya = (options = {}) => {
  const res = useMutation(
    async (dto) => http.get(HTTP_ENDPOINTS.joyaIntegration.getJoyaMember(dto)),
    { ...options },
  );

  return res;
};
