import React, { useEffect, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./Info.module.scss";
import { Select } from "../../../../../../../shared/Select/Select";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { Input } from "../../../../../../../shared/Input/Input";
import { useAppointmentOptions } from "../../../../hooks/useAppointmentOptions";
import { useProviderOptions } from "../../../../hooks/useProviderOptions";
import { useClinicOptions } from "../../../../hooks/useClinicOptions";
import { PROCEDURE_AREA_OPTIONS } from "./Info.consts";
import { DateSelect } from "../../../../shared/DateSelect/DateSelect";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { getCosmeticPathParams } from "../../CosmeticCreateEdit.utils";
import { useServiceOptions } from "../../../../hooks/useServiceOptions";
import { useChartingFilterCategoriesQuery } from "../../../../../../../api/queries/useChartingFilterCategoriesQuery";
import { withGlobalSettings } from "../../../../../../../hocs/withGlobalSettings";

export const Info = withGlobalSettings(
  memo(
    ({
      appointmentId,
      serviceId,
      providerId,
      clinicId,
      procedureArea,
      procedureName,
      procedureDate,
      isLoading,
      type,
    }) => {
      const { tClients } = useAppTranslation.Clients();
      const { clientId, procedureId, isEditMode } = getCosmeticPathParams();

      const appointmentOptions = useAppointmentOptions({
        clientId,
        procedureId,
      });

      const selectedAppointment = appointmentOptions.data.find(
        (a) => a.value === appointmentId.value,
      );

      const serviceOptions = useServiceOptions({
        appointmentServiceIds: selectedAppointment?.serviceIds,
      });

      const providerOptions = useProviderOptions();

      const clinicOptions = useClinicOptions({
        providerId: providerId.value,
        onProviderClinicsFetched: clinicId.validateWithProviderClinics,
      });

      const chartingFilters = useChartingFilterCategoriesQuery();

      const chartingFilterOptions = useMemo(() => {
        return (
          chartingFilters.data?.map((category) => ({
            label: category.name,
            value: category.id,
          })) || []
        );
      }, [chartingFilters.data]);

      useEffect(() => {
        if (!procedureDate.value) {
          procedureDate.onChange(new Date());
        }
      }, []);

      return (
        <div>
          <div className={classes.title}>
            {tClients("createEditCosmeticProcedure.info.label")}
          </div>
          {isLoading ? (
            <div className={classes.selects}>
              {Array.from(Array(7).keys()).map((i) => (
                <Skeleton key={i} count={1} height={50} />
              ))}
            </div>
          ) : (
            <>
              <div className={classes.selects}>
                <div>
                  <InputLabel>
                    {tClients(
                      "createEditCosmeticProcedure.info.selectAppointment",
                    )}
                  </InputLabel>
                  <Select
                    isClearable
                    value={
                      appointmentOptions.data.find(
                        (i) => i.value === appointmentId.value,
                      ) || null
                    }
                    isLoading={appointmentOptions.isLoading}
                    options={appointmentOptions.data}
                    onChange={(option) =>
                      appointmentId.onChange(option, serviceOptions.data)
                    }
                  />
                </div>
                <div>
                  <InputLabel>
                    {tClients("createEditCosmeticProcedure.info.selectService")}
                    {Boolean(appointmentId.value) && (
                      <span className={classes.requiredAsterisk}> *</span>
                    )}
                  </InputLabel>
                  <Select
                    isSearchable
                    isClearable
                    value={
                      serviceOptions.data.find(
                        (i) => i.value === serviceId.value,
                      ) || null
                    }
                    options={serviceOptions.data}
                    onChange={serviceId.onChange}
                    isError={Boolean(serviceId.getError())}
                    isLoading={serviceOptions.isLoading}
                  />
                  {Boolean(serviceId.getError()) && (
                    <InputError>{serviceId.getError()}</InputError>
                  )}
                </div>
                <div>
                  <InputLabel>
                    {tClients(
                      "createEditCosmeticProcedure.info.selectProvider",
                    )}{" "}
                    <span className={classes.requiredAsterisk}>*</span>
                  </InputLabel>
                  <Select
                    isSearchable
                    value={
                      providerOptions.data.find(
                        (i) => i.value === providerId.value,
                      ) || null
                    }
                    isLoading={providerOptions.isLoading}
                    options={providerOptions.data}
                    onChange={providerId.onChange}
                    isError={Boolean(providerId.getError())}
                  />
                  {Boolean(providerId.getError()) && (
                    <InputError>{providerId.getError()}</InputError>
                  )}
                </div>
                <div>
                  <InputLabel>
                    {tClients("createEditCosmeticProcedure.info.selectClinic")}{" "}
                    <span className={classes.requiredAsterisk}>*</span>
                  </InputLabel>
                  <Select
                    isSearchable
                    value={
                      clinicOptions.data.find(
                        (i) => i.value === clinicId.value,
                      ) || null
                    }
                    isLoading={clinicOptions.isLoading}
                    options={clinicOptions.data}
                    onChange={clinicId.onChange}
                    isError={Boolean(clinicId.getError())}
                  />
                  {Boolean(clinicId.getError()) && (
                    <InputError>{clinicId.getError()}</InputError>
                  )}
                </div>
                <div>
                  <InputLabel>
                    {tClients("createEditCosmeticProcedure.info.procedureArea")}{" "}
                    <span className={classes.requiredAsterisk}>*</span>
                  </InputLabel>
                  <Select
                    value={
                      PROCEDURE_AREA_OPTIONS.find(
                        (i) => i.value === procedureArea.value,
                      ) || null
                    }
                    options={PROCEDURE_AREA_OPTIONS}
                    onChange={procedureArea.onChange}
                    isError={Boolean(procedureArea.getError())}
                  />
                  {Boolean(procedureArea.getError()) && (
                    <InputError>{procedureArea.getError()}</InputError>
                  )}
                </div>
                <div>
                  <InputLabel>
                    {tClients("createEditCosmeticProcedure.info.procedureName")}{" "}
                    {isEditMode && (
                      <span className={classes.requiredAsterisk}>*</span>
                    )}
                  </InputLabel>
                  <Input
                    value={procedureName.value}
                    onChange={(e) => procedureName.onChange(e.target.value)}
                    isError={Boolean(procedureName.getError())}
                  />
                  {Boolean(procedureName.getError()) && (
                    <InputError>{procedureName.getError()}</InputError>
                  )}
                </div>
                <div>
                  <InputLabel>
                    {tClients("createEditCosmeticProcedure.info.type")}
                  </InputLabel>
                  <Select
                    value={type.value}
                    onChange={(next) => type.onChange(next)}
                    options={chartingFilterOptions}
                  />
                </div>
                <div>
                  <InputLabel>
                    {tClients("createEditCosmeticProcedure.info.procedureDate")}
                  </InputLabel>
                  <DateSelect
                    value={procedureDate.value}
                    onChange={procedureDate.onChange}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      );
    },
  ),
);

Info.propTypes = {
  appointmentId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }).isRequired,
  serviceId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  providerId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  clinicId: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  procedureArea: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  procedureName: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
    getError: PropTypes.func,
  }).isRequired,
  procedureDate: PropTypes.shape({
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};
