import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../../consts/api";

export const useSmartReaderState = (readerId, options = {}) => {
  return useMutation(
    async () => {
      return http.post(HTTP_ENDPOINTS.getSmartReaderState(readerId));
    },
    { ...options },
  );
};
