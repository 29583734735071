/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { isEqual } from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import ReactTooltip from "react-tooltip";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import BigCalendar, { Views } from "react-big-calendar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import moment from "moment";
import momentTimezone from "moment-timezone";
import AppointmentHeader from "../AppointmentHeader.js";
import {
  getProviderAppointments,
  getAppointment,
  markNoShow,
  updateNotes,
  checkin,
  makePatientCheckin,
  exportEmptyData,
  getAppointmentFees,
  getCancelNotification,
  cancelAppointment,
  checkRescheduleTime,
  rescheduleAppointment,
  sendNotification,
  printAppointment,
  deleteEvent,
  confirmAppointmentStatus,
  updateProvider,
  unCheckAppt,
} from "../../../Actions/Appointment/appointmentAction.js";
import calenLogo from "../../../_legacy/images/calender.svg";
import {
  checkIfPermissionAllowed,
  displayName,
  showFormattedDate,
  formatTime,
  numberFormat,
  positionFooterCorrectly,
  capitalizeFirstLetter,
  getTimeFormat,
  convertTime12to24,
} from "../../../Utils/services.js";
import MembershipBadge from "../../Common/MembershipBadge";
import EditorWithPreview from "../../Common/EditorWithPreview.js";
import CalendarEvent from "./CalendarEvent/CalendarEvent.js";
import { tCommon, tSales } from "../../../i18n/useAppTranslation.js";
import { QualiphyStatusBtn } from "./components/QualiphyStatusBtn.js";
import { ModalCreateQualiphyProcedure } from "../../../widgets/ModalCreateQualiphyProcedure/ModalCreateQualiphyProcedure.js";
import { CLIENTS_ROUTES } from "../../../consts/routes.js";
import { Button } from "../../../shared/Button/Button.js";
import { CalendarCrossIcon } from "../../../assets/Icons/CalendarCrossIcon.js";
import { CreditCardIcon } from "../../../assets/Icons/CreditCardIcon.js";
import { DEFAULT_TIME_FORMAT } from "../../../consts/general.js";
import { Maybe, cond, pipe } from "../../../utilities/fp.js";
import {
  CALENDAR_STEP_OPTIONS,
  CalendarStepSelect,
} from "./components/CalendarStepSelect/CalendarStepSelect.js";
import classes from "./styles/Calendar.module.scss";
import { filterEvents } from "./utilities";
import { uiNotification } from "../../../services/UINotificationService.js";
import { CalendarWrapper } from "./CalendarWrapper";
import { EventWrapper } from "./EventWrapper/EventWrapper";
import { history } from "../../../history.js";
import { dateToApiDateString } from "../../../utilities/api.js";
import { API_DATE_FORMAT } from "../../../consts/api.js";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal.js";

const localizer = BigCalendar.momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const convertDurationToVisibleFormat = (duration) => {
  var hours = Math.floor(duration / 60);
  var minutes = duration % 60;
  return "(" + hours + " hrs " + minutes + " mins)";
};

const showSelectedWeek = (date = moment()) => {
  let showSelectedLabel = "";
  let startOfWeek = moment(date).startOf("week");
  let ednOfWeek = moment(date).endOf("week");
  if (startOfWeek.format("YYYY") != ednOfWeek.format("YYYY")) {
    showSelectedLabel =
      startOfWeek.format("MMM Do, YY - ") + ednOfWeek.format("MMM Do, YY");
  } else if (startOfWeek.format("MM") != ednOfWeek.format("MM")) {
    showSelectedLabel =
      startOfWeek.format("MMM Do - ") + ednOfWeek.format("MMM Do, YYYY");
  } else {
    showSelectedLabel =
      startOfWeek.format("MMM ") +
      startOfWeek.format("Do - ") +
      ednOfWeek.format("Do, YYYY");
  }
  return showSelectedLabel;
};
const makeTitle = (obj) => {
  let titleArr = [],
    title = "";
  if (obj.appointment_services) {
    obj.appointment_services.map((objInner) => {
      if (objInner.service) {
        titleArr.push(objInner.service.name);
      }
    });
  }
  if (titleArr.length) {
    title = titleArr.join(", ");
  }
  return title;
};

const startEndDateOfWeek = (date, format = "MM-DD-YYYY") => {
  return {
    start: moment(date, format).startOf("week"),
    end: moment(date, format).endOf("week"),
  };
};

const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const viewDateFormat = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

class ProviderWeekCalendar extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData")) || {
      global: {},
      appointments: {},
    };
    const timeFormat = localStorage.getItem("timeFormat");
    this.props.exportEmptyData({});
    let user = JSON.parse(localStorage.getItem("userData"));
    var today = new Date();
    var dd = today.getDate() + 1;
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;

    let calendarFilter = JSON.parse(localStorage.getItem("providerWeekFilter"));

    this.state = {
      timeFormat: timeFormat,
      showSelectedDate: calendarFilter
        ? calendarFilter.calendarView == Views.WEEK
          ? showSelectedWeek(calendarFilter.searchPickerDate)
          : viewDateFormat(calendarFilter.searchPickerDate)
        : moment().format("LL"),
      selectedDate: calendarFilter
        ? moment(calendarFilter.searchPickerDate).toDate()
        : moment().toDate(),
      providers: [],
      tmpProviders: [],
      clinics: [],
      services: [],
      calendarView: Views.DAY,
      showLoader: false,
      showProvider: false,
      showService: false,
      showClinics: false,
      selectedProviders: [],
      selectedServices: [],
      selectedClinic: "",
      showAppointmentDetails: false,
      appointmentData: {},
      available_buttons: [],
      editAppointmentId: 0,
      showBookings: false,
      showEditNote: false,
      notificationSent: false,
      checkinData: [],
      showCheckinData: false,
      room: "",
      customer_note: "",
      send_to_provider: true,
      last_canceled_appointment: null,
      markedAsNoShow: true,
      selectedProvidersLength: 0,
      selectedServicesLength: 0,
      globalLang: languageData.global,
      appointmentLang: languageData.appointments,
      userChanged: false,
      forceChanged: false,
      showNotifyPop: false,
      showModal: false,
      showPrintModal: false,
      cancelNotificationMessage: {},
      rescheduledData: {},
      notification_type: "emailAndSms",
      noteError: false,
      selectAllProviders: false,
      selectAllService: false,
      refreshListAfterCancelling: false,
      showRescheduleConfirmModal: false,
      userData: user,
      openAt: "",
      timestamp: "",
      markedTimeStamp: "",
      success_msg: "",
      patient_name: "",
      patient_type: "",
      closesAt: "",
      printTimeStamp: new Date(),
      patientCheckedIdTimestamp: new Date(),
      clinic_timezone: "",
      notesUpdatedTimeStamp: new Date(),
      tempnotes: "",
      isFull: false,
      patient_membership_colors: [],
      activeMenuTag: "",
      providerName: "",
      meeting_url: "",
      appointment_booking_emailClass: "editor_textarea editor-preview",
      showEditProvider: false,
      appointment_provider_class: "simpleSelect",
      appointment_provider: "0",
      patientUnCheckedIdTimestamp: new Date(),
      verticalsList: [],
      selectedVertical: {
        id: 0,
        name: "Default View",
      },
      searchProvider: "",
      verticalsEnabled: user.user.vertical_view,
      needAppointmentsUpdate: true,
      showOutOfOfficeData: false,
      wasViewChanged: false,
      wasProvidersChanged: false,
      isCreateQualiphyProcedureModalOpen: false,
      isUpdatingAfterQualiphySent: false,
      calendarStep: CALENDAR_STEP_OPTIONS[0],
      isConfirmCancelOpen: false,
    };
    window.onscroll = () => {
      return false;
    };
    this.calendarRef = React.createRef();
    this.moveEvent = this.moveEvent.bind(this);
  }

  handleSelect = (event) => {
    const { start, resourceId } = event;

    const eventDate = dateToApiDateString(start);

    const provider = this.state.resourceMap?.find(
      (x) => String(x.resourceId) === String(resourceId),
    );

    const workingHours = provider?.workingHours?.find(
      (x) => x.date === eventDate,
    );

    const schedule = workingHours?.schedule?.find((x) =>
      moment(moment(start).format(`${API_DATE_FORMAT} HH:mm:ss`)).isBetween(
        x.start,
        x.end,
      ),
    );

    const time = moment(start);

    if (schedule && time.isAfter(this.getNow())) {
      history.push(
        `/appointment/create/${resourceId}/${
          this.state.clinic_id
        }/${apiDateFormat(start)}/${time.format("HH:mm:ss")}`,
      );
    }
  };

  navigate = () => {};

  goFull = () => {
    this.setState({ isFull: true });
  };

  fetchAppointmentFees = async (event) => {
    try {
      localStorage.setItem("showLoader", true);
      const feesMode = event.currentTarget.dataset.mode;

      this.setState({
        showLoader: true,
        feesMode,
        notiMode: "cancel",
        appointmentData: {},
      });

      const res = await this.props.getAppointmentFees(
        this.state.editAppointmentId,
      );

      const data = res.data || {};
      const returnState = {};

      returnState.appointmentFees = data.appointmentFees;

      if (data.cancellation_fee) {
        returnState.cancellation_fee = data.cancellation_fee;
        returnState.showLoader = false;
        returnState.showAppointmentDetails = false;
        returnState.showBookings = false;
        returnState.showModal = true;
        returnState.feesTime = data.feesTime;
      } else {
        returnState.cancellation_fee = 0;
        if (feesMode == "cancel") {
          const notification = await this.fetchCancelNotification(
            this.state.editAppointmentId,
          );
          if (!notification.showNotifyPop) {
            returnState.showNotifyPop = false;
            returnState.isConfirmCancelOpen = true;
          }
        } else {
          let formData = {};
          formData.appointment_id = this.state.editAppointmentId;
          formData.charge = false;
          this.props.markNoShow(formData).finally(() => {
            this.setState({ showLoader: false });
          });
        }
      }

      this.setState(returnState);
    } catch (res) {
      uiNotification.error(this.state.globalLang[res.message]);
    } finally {
      localStorage.setItem("showLoader", false);
      this.setState({
        showLoader: false,
      });
    }
  };

  onView = () => {};

  getNow = () => {
    return new Date(
      new Date().getTime() +
        (momentTimezone.tz(new Date(), this.state.clinic_timezone)._offset +
          new Date().getTimezoneOffset()) *
          60000,
    );
  };

  timeRangeFormat = ({ start, end }, culture, localizer) => {
    let s = localizer.format(start, this.state.timeFormat, culture);
    let e = localizer.format(end, this.state.timeFormat, culture);
    return `${s} - ${e}`;
  };

  moveEvent({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) {
    if (event.availabilityGrid) {
      return false;
    }
    if (event.appointmentType == "break_time") {
      return false;
    }

    if (!checkIfPermissionAllowed("update-cancel-reschedule-appointment")) {
      return false;
    }
    if (event.checkedIn) {
      return false;
    }
    if (event.resourceId != resourceId) {
      return;
    }
    if (moment(start).isSame(event.start)) {
      return;
    }

    if (
      event.appointmentType == "outofoffice" &&
      !moment(start).isSame(event.start, "day")
    ) {
      return;
    }

    let outOfOfficeOverlapCheck = false;
    if (event.appointmentType == "outofoffice") {
      if (this.state.events && this.state.events.length) {
        this.state.events.map((obj) => {
          let format = "hh:mm:ss";
          let dropTime = moment(start, format);
          let beforeTime = moment(obj.start, format);
          let afterTime = moment(obj.end, format);
          if (
            ((dropTime.isBetween(beforeTime, afterTime) &&
              moment(start).isSame(moment(obj.end))) ||
              moment(start).isSame(moment(obj.start))) &&
            !obj.availabilityGrid
          ) {
            outOfOfficeOverlapCheck = true;
          }
        });
      }
    }

    if (outOfOfficeOverlapCheck) {
      uiNotification.error("Provider is already booked at this time!");
      return false;
    }

    let checkIfDroppedOutsideAvailableArea = false;
    if (event.appointmentType == "outofoffice") {
      if (this.state.events && this.state.events.length) {
        this.state.events.map((obj) => {
          if (
            obj.availabilityGrid &&
            event.resourceId == obj.resourceId &&
            moment(start).isSame(obj.start, "day")
          ) {
            let format = "hh:mm:ss";
            let dropTime = moment(start, format);
            let beforeTime = moment(obj.start, format);
            let afterTime = moment(obj.end, format);
            if (
              !dropTime.isBetween(beforeTime, afterTime) &&
              !moment(start).isSame(moment(obj.start))
            ) {
              checkIfDroppedOutsideAvailableArea = true;
            }
          }
        });
      }
    }

    if (checkIfDroppedOutsideAvailableArea) {
      uiNotification.error("Provider is not available at this time!");
      return false;
    }

    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    var isAfterOriginalEventDate =
      moment(event.start).isAfter(new Date()) ||
      moment(event.start).isSame(new Date(), "day");

    var isAfter =
      moment(start).isAfter(new Date()) ||
      moment(start).isSame(new Date(), "day");

    let currentDate = moment(new Date());
    let eventOriginalDate = moment(event.start);
    var currentDayCheck = eventOriginalDate.diff(currentDate, "days");

    if (isAfterOriginalEventDate && isAfter && currentDayCheck >= 0) {
      const updatedEvent = { ...event, start, end, resourceId, allDay };

      let formData = {};
      formData.startdate = moment(start).format("YYYY-MM-DD HH:mm:ss");
      localStorage.setItem("rescheduledTime", formData.startdate);
      formData.appointment_id = event.id;
      this.setState({
        updatedEvent: updatedEvent,
        appointment_id: event.id,
        rescheduleFormData: formData,
        showRescheduleConfirmModal: true,
      });
    } else {
      return;
    }
  }

  handleReschedule = (formData) => {
    this.setState({
      showLoader: true,
    });

    this.props
      .rescheduleAppointment(formData)
      .then((data) => {
        let returnState = {};
        const rescheduleData = data.data || {};

        returnState.noti_type = rescheduleData.noti_type;
        returnState.appointment_email_text =
          rescheduleData.appointment_email_text;
        returnState.notification_body = rescheduleData.appointment_email_text;
        returnState.appointment_sms = rescheduleData.appointment_sms;
        returnState.appointment_subject = rescheduleData.appointment_subject;
        returnState.patient_name = rescheduleData.patient_name;
        returnState.success_msg = rescheduleData.success_msg;
        returnState.showAppointmentDetails = false;
        returnState.showBookings = false;
        returnState.showNotifyPop = Boolean(rescheduleData.send_email_status);
        returnState.showLoader = false;
        returnState.notiMode = "reschedule";

        if (!returnState.showNotifyPop) {
          this.refreshAppointments();
          uiNotification.success("Appointment rescheduled successfully");
        }

        this.setState(returnState);
      })
      .catch((res) => {
        uiNotification.error(tSales(`checkoutInvoice.apiError.${res.message}`));
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  rescheduleAppoint = () => {
    localStorage.setItem("showLoader", true);

    this.setState({
      showLoader: true,
      showRescheduleConfirmModal: false,
      appointmentData: {},
    });

    let formData = this.state.rescheduleFormData;

    this.props.checkRescheduleTime(formData).then((data) => {
      const rescheduleData = data.data || {};
      let returnState = {};

      if (rescheduleData.status == "fail") {
        returnState.showLoader = false;
        if (this.state.updatedEvent.appointmentType == "outofoffice") {
          uiNotification.error("Provider is already booked at this time!");
        } else {
          let pushURL = `/appointment/edit/${this.state.updatedEvent.id}/reschedule`;
          if (this.state.clinic_id) {
            pushURL += `/${this.state.clinic_id}`;
          }
          this.props.history.push(pushURL);
        }

        return this.setState(returnState);
      } else {
        if (this.state.updatedEvent.appointmentType == "outofoffice") {
          let time = moment(this.state.updatedEvent.start, "h:mm A").format(
            "HH:mm",
          );
          let timeArr = time.split(":");
          time = timeArr[0] + ":00:00";

          let pushURL = `/appointment/edit/${this.state.updatedEvent.id}/reschedule/${time}`;
          if (this.state.clinic_id) {
            pushURL += `/${this.state.clinic_id}`;
          }
          this.props.history.push(pushURL);
        } else {
          if (rescheduleData.fee_details?.cancellation_fee > 0) {
            returnState.cancellation_fee =
              rescheduleData.fee_details.cancellation_fee;
            returnState.showLoader = false;
            returnState.showAppointmentDetails = false;
            returnState.showBookings = false;
            returnState.showModal = true;
            returnState.notiMode = "reschedule";

            return this.setState(returnState);
          } else {
            returnState.showLoader = true;
            let formData = {};
            formData.appointment_id = this.state.updatedEvent.id;
            formData.appointment_date = moment(
              this.state.updatedEvent.start,
            ).format("YYYY-MM-DD");
            formData.appointment_time = moment(
              this.state.updatedEvent.start,
            ).format("HH:mm:ss");
            this.handleReschedule(formData);

            return this.setState(returnState);
          }
        }
      }
    });
  };

  resizeEvent = (_, { event, start, end }) => {
    const { events } = this.state;

    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    this.setState({
      events: nextEvents,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
    document.body.style.overflow = "";
    uiNotification.clear();
    this.props.exportEmptyData({});
  }

  handleClick = (e) => {
    if (
      (this.state.showProvider ||
        this.state.showClinics ||
        this.state.showService) &&
      e.target.parentElement?.className !== "cal-filter-outer"
    ) {
      if (this.state.showService && !this.refServiceButton.contains(e.target)) {
        this.setState({ showService: false });
      }
      if (
        this.state.showProvider &&
        !this.refProviderButton.contains(e.target)
      ) {
        this.setState({ showProvider: false });
      }
      if (this.state.showClinics && !this.refClinicButton.contains(e.target)) {
        this.setState({ showClinics: false });
      }
    }
  };

  fetchAppointment = async (id, mode, { openApptDetails } = {}) => {
    this.setState({
      showLoader: true,
    });
    return this.props
      .getAppointment(id, mode, null, "appointment-details")
      .then(() => {
        if (openApptDetails) {
          this.setState({
            showAppointmentDetails: true,
          });
        }
      })
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] ||
            tCommon([`event.apiError.${res.message}`, "error.fallback"]),
        );
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  componentDidMount() {
    this.props.exportEmptyData({});
    document.addEventListener("click", this.handleClick, false);
    let formData = {};
    formData.start = this.props.match.params.date
      ? moment(this.props.match.params.date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    formData.end = this.props.match.params.date
      ? moment(this.props.match.params.date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    let returnState = {};
    returnState.calendarView = Views.DAY;
    returnState.showLoader = true;
    returnState.showModal = false;
    returnState.searchPickerDate = this.props.match.params.date
      ? moment(this.props.match.params.date).toDate()
      : moment().toDate();
    returnState.selectedDate = this.props.match.params.date
      ? moment(this.props.match.params.date).toDate()
      : moment().toDate();

    if (+this.props.match.params.clinicId) {
      returnState.clinic_id = this.props.match.params.clinicId;
      formData.clinic_id = this.props.match.params.clinicId;
    } else {
      returnState.clinic_id = "";
      formData.clinic_id = "";
    }

    if (this.props.match.params.appointmentId) {
      returnState.dontShowAppointment = true;
      returnState.editAppointmentId = this.props.match.params.appointmentId;
      this.setState(returnState);
      this.fetchAppointment(this.props.match.params.appointmentId, "view");
    } else if (localStorage.getItem("providerWeekFilter")) {
      let calendarFilter = JSON.parse(
        localStorage.getItem("providerWeekFilter"),
      );
      let date = this.props.match.params.date
        ? moment(this.props.match.params.date).toDate()
        : moment(calendarFilter.searchPickerDate).toDate();
      returnState.searchPickerDate = moment(date).toDate();
      returnState.showSelectedDate = viewDateFormat(date);
      formData.start = formData.end = apiDateFormat(date);
      formData.doctor_ids = calendarFilter.doctor_ids;
      formData.service_ids = calendarFilter.service_ids;
      formData.clinic_id = +this.props.match.params.clinicId
        ? this.props.match.params.clinicId
        : calendarFilter.clinic_id;
      returnState.clinic_id = +this.props.match.params.clinicId
        ? this.props.match.params.clinicId
        : calendarFilter.clinic_id;
      returnState.selectedProviders = calendarFilter.doctor_ids;
      returnState.selectedServices = calendarFilter.service_ids;
      if (returnState.selectedServices) {
        returnState.selectedServices.map((obj) => {
          returnState["service-" + obj] = true;
        });
      }
      this.setState(returnState);
      this.getProviderAppointments(formData, calendarFilter.calendarView);
    } else {
      this.setState(returnState);
      this.getProviderAppointments(formData);
    }
    let windowHeight = parseInt(window.innerHeight);
    let calendarHeight = windowHeight - 267;
    document.getElementById("juvly-section").style.height =
      calendarHeight + "px";
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.deletedOutOfficeTimeStamp != undefined &&
      nextProps.deletedOutOfficeTimeStamp != prevState.deletedOutOfficeTimeStamp
    ) {
      returnState.deletedOutOfficeTimeStamp =
        nextProps.deletedOutOfficeTimeStamp;
      returnState.showLoader = false;
      returnState.showDeleteModal = false;
      return returnState;
    }

    if (
      nextProps.exportCsvData != undefined &&
      prevState.printTimeStamp != nextProps.printTimeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.showPrintModal = false;
        returnState.printTimeStamp = nextProps.printTimeStamp;
        window.open(nextProps.exportCsvData.file);
        return returnState;
      }
    }

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      return returnState;
    }
    if (
      nextProps.notificationSent != undefined &&
      nextProps.notificationSent == true &&
      nextProps.notiTimeStamp != prevState.notiTimeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.showNotifyPop = false;
        returnState.notificationSent = nextProps.notificationSent;
        returnState.notiTimeStamp = nextProps.notiTimeStamp;
        returnState.refreshListAfterCancelling =
          !prevState.refreshListAfterCancelling;
        return returnState;
      }
    }

    if (
      nextProps.notesUpdates != undefined &&
      nextProps.notesUpdates == true &&
      nextProps.notesUpdatedTimeStamp != prevState.notesUpdatedTimeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        uiNotification.success(nextProps.message);
        returnState.showLoader = false;
        returnState.notesUpdatedTimeStamp = nextProps.notesUpdatedTimeStamp;
        returnState.showEditNote = false;
        returnState.showEditProvider = false;
        return returnState;
      }
    }

    if (
      nextProps.patientCheckedIn != undefined &&
      nextProps.patientCheckedIn == true &&
      nextProps.patientCheckedIdTimestamp != prevState.patientCheckedIdTimestamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.showCheckinData = false;
        returnState.room = "";
        returnState.send_to_provider = true;
        returnState.customer_note = "";
        let appointments = prevState.events;
        let appointment_id = prevState.editAppointmentId;
        let index = appointments.findIndex((y) => y.id == appointment_id);
        if (index > -1) {
          appointments[index].appointments_checkin = true;
        }
        returnState.appointments = appointments;
        returnState.patientCheckedIdTimestamp =
          nextProps.patientCheckedIdTimestamp;
        return returnState;
      }
    }

    if (
      nextProps.appointmentCancelled != undefined &&
      nextProps.appointmentCancelled == true
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        returnState.refreshListAfterCancelling = false;
        returnState.showNotifyPop = false;
        returnState.timestamp = nextProps.timestamp;
        return returnState;
      }
    }

    if (
      nextProps.cancelNotificationMessage != undefined &&
      nextProps.cancelNotificationMessage != prevState.cancelNotificationMessage
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.cancelNotificationMessage =
          nextProps.cancelNotificationMessage;
        returnState.noti_type = nextProps.cancelNotificationMessage.noti_type;
        returnState.appointment_email_text =
          nextProps.cancelNotificationMessage.appointment_email_text;
        returnState.notification_body =
          nextProps.cancelNotificationMessage.appointment_email_text;
        returnState.appointment_sms =
          nextProps.cancelNotificationMessage.appointment_sms;
        returnState.appointment_subject =
          nextProps.cancelNotificationMessage.appointment_subject;
        returnState.success_msg =
          nextProps.cancelNotificationMessage.success_msg;
        returnState.showAppointmentDetails = false;
        returnState.showNotifyPop = true;
        returnState.showLoader = false;
        returnState.showModal = false;
        returnState.notiMode = "cancel";
        return returnState;
      }
    }

    if (
      nextProps.appointmentData != undefined &&
      nextProps.appointmentDataTimeStamp !== prevState.appointmentDataTimeStamp
    ) {
      if (!isEmpty(nextProps.appointmentData)) {
        let returnState = {};
        returnState.appointmentDataTimeStamp =
          nextProps.appointmentDataTimeStamp;
        let appointment_details = nextProps.appointmentData.appointment_details;
        if (appointment_details.type == "appointment") {
          returnState.patient_type = nextProps.appointmentData.patient_type;
          returnState.appointment_details =
            nextProps.appointmentData.appointment_details;
          returnState.appointment_user_log =
            nextProps.appointmentData.appointment_user_log;
          returnState.available_buttons =
            nextProps.appointmentData.available_buttons;
          returnState.last_canceled_appointment =
            nextProps.appointmentData.last_canceled_appointment;
          returnState.tempnotes =
            nextProps.appointmentData.appointment_details.appointment_booking.appointment_notes;
          returnState.patient_membership_colors =
            nextProps.appointmentData.patient_memberships || [];
          returnState.show_toggle =
            moment().format("YYYY-MM-DD HH:mm:ss") >
            nextProps.appointmentData.appointment_details.appointment_datetime
              ? false
              : true;
          returnState.show_confirmed_toggle =
            nextProps.appointmentData.appointment_details.is_confirmed == 1
              ? true
              : false;

          if (!prevState.dontShowAppointment) {
            returnState.showLoader = false;

            returnState.meeting_url =
              returnState.appointment_details &&
              returnState.appointment_details.meeting_url
                ? returnState.appointment_details.meeting_url
                : "";
          } else {
            returnState.selectedDate = appointment_details.date;
            returnState.showSelectedDate = viewDateFormat(
              moment(appointment_details.date),
            );
            let formData = {};
            formData.start = apiDateFormat(appointment_details.date);
            formData.end = apiDateFormat(appointment_details.date);
            formData.clinic_id = appointment_details.clinic_id;
            nextProps.getAppointments(formData);
          }
        } else if (appointment_details.type == "outofoffice") {
          //returnState.appointment_details = appointment_details;
          returnState.out_of_office_data = appointment_details.out_of_office;
          returnState.out_of_office_data.appointment_id =
            appointment_details.id;
          returnState.out_of_office_data.main_date = appointment_details.date;
          returnState.showLoader = false;
        }
        return returnState;
      }
    }

    if (
      nextProps.checkinData != undefined &&
      nextProps.checkinData !== prevState.checkinData &&
      nextProps.checkinDataTimeStamp !== prevState.checkinDataTimeStamp
    ) {
      if (nextProps.checkinData.length) {
        nextProps.checkinData.map((obj) => {
          returnState["notes-" + obj.id] = false;
        });
      }
      returnState.checkinDataTimeStamp = nextProps.checkinDataTimeStamp;
      returnState.checkinData = nextProps.checkinData;
      returnState.showLoader = false;
      returnState.showAppointmentDetails = false;
      returnState.showCheckinData = true;
      return returnState;
    }

    if (
      nextProps.markedAsNoShow != undefined &&
      nextProps.markedAsNoShow == true &&
      nextProps.markedTimeStamp != prevState.markedTimeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        //uiNotification.success(nextProps.message);
        returnState.markedAsNoShow = !prevState.markedAsNoShow;
        returnState.showAppointmentDetails = false;
        returnState.showLoader = false;
        returnState.showModal = false;
        returnState.markedTimeStamp = nextProps.markedTimeStamp;
        return returnState;
        //nextProps.history.push(`/appointment/calendar`);
      }
    }

    if (
      nextProps.appointmentsData != undefined &&
      nextProps.appointmentsData !== prevState.appointmentsData &&
      nextProps.appointmentsTimestamp != prevState.appointmentsTimestamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        let resourceMap = [],
          appointments = [];

        if (nextProps.appointmentsData.providers.length) {
          nextProps.appointmentsData.providers.map((obj) => {
            if (
              nextProps.appointmentsData.doctor_ids &&
              nextProps.appointmentsData.doctor_ids.indexOf(obj.id) > -1
            ) {
              returnState["provider-" + obj.id] = true;
            }
          });
          returnState.selectedProvidersLength =
            nextProps.appointmentsData.doctor_ids.length;
          returnState.provider_id = nextProps.appointmentsData.provider_id;
        }
        if (localStorage.getItem("providerWeekFilter")) {
          let calendarFilter = JSON.parse(
            localStorage.getItem("providerWeekFilter"),
          );
          if (
            calendarFilter.clinic_id != nextProps.appointmentsData.clinic_id
          ) {
            returnState.selectedProviders = [];
          } else {
            returnState.selectedProviders =
              nextProps.appointmentsData.doctor_ids;
          }
        } else {
          returnState.selectedProviders = nextProps.appointmentsData.doctor_ids;
        }
        if (prevState.dontShowAppointment) {
          returnState.dontShowAppointment = false;
        }
        if (
          nextProps.appointmentsData.verticals &&
          nextProps.appointmentsData.verticals.length &&
          prevState.calendarView === Views.DAY
        ) {
          returnState.verticalsList = [{ id: 0, name: "Default View" }].concat(
            nextProps.appointmentsData.verticals,
          );
        }
        if (nextProps.appointmentsData.appointments.length) {
          nextProps.appointmentsData.appointments.map((obj) => {
            let dateArr = obj.appointment_date.split("-");
            let startTime = obj.appointment_start_time.split(":");
            let endTime = obj.appointment_end_time.split(":");
            let title = makeTitle(obj);
            let nameWithTitle = "";
            let apptColor = "";
            let gradient = "";
            let confirmed_status = "";

            if (obj.type == "appointment") {
              nameWithTitle = displayName(obj.patient) + " (" + title + ")";
              title = "(" + title + ")";
              apptColor = obj.color;
            } else if (obj.type == "outofoffice") {
              if (obj.appointment_booking) {
                nameWithTitle =
                  "(" +
                  obj.appointment_booking.appointment_notes +
                  " - Out of office)";
              } else {
                nameWithTitle = "(Out of office)";
              }
              title = "(Out of office)";
              apptColor = "#000000";
            } else {
              title = "(Not Available)";
              nameWithTitle = "(Not Available)";
              apptColor = "#9c9c9c";
              gradient =
                "repeating-linear-gradient(to bottom, #cccccc 0px, #cccccc 69px, #9c9c9c 69px, #9c9c9c 70px)";
            }
            if (
              obj.type == "appointment" &&
              obj.appointment_datetime > moment().format("YYYY-MM-DD HH:mm:ss")
            ) {
              confirmed_status =
                obj.is_confirmed == 0 ? "Not Confirmed" : "Confirmed";
            }
            appointments.push({
              id: obj.id ? obj.id : Math.floor(Math.random() * 1000),
              title: title,
              start: new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2],
                startTime[0],
                startTime[1],
                startTime[2],
              ),
              end: new Date(
                dateArr[0],
                dateArr[1] - 1,
                dateArr[2],
                endTime[0],
                endTime[1],
                endTime[2],
              ),
              resourceId: parseInt(obj.user_id),
              color: apptColor,
              gradient: gradient,
              clientName: obj.patient ? displayName(obj.patient) : "",
              clientEmail:
                obj.patient && obj.patient.email ? obj.patient.email : "",
              confirmed_status: confirmed_status,
              clientPhone:
                obj.appointment_booking && obj.appointment_booking.phone
                  ? obj.appointment_booking.phone.replace(/[- )(]/g, "")
                  : "",
              clientNameWithTitle: nameWithTitle,
              appointments_checkin:
                obj.type == "appointment" &&
                obj.appointment_type === "in_person"
                  ? obj.patient_checkedin == 1
                    ? "Checked In"
                    : "Not Checked In"
                  : "",
              checkedIn: obj.patient_checkedin ? obj.patient_checkedin : 0,
              appointmentType: obj.type,
              isReschedule: !!obj.is_reschedule,
            });
          });
        }
        let resourcesSource;
        if (prevState.verticalsEnabled) {
          resourcesSource = nextProps.appointmentsData.providers;
        } else {
          resourcesSource = nextProps.appointmentsData.resources;
        }
        if (resourcesSource.length) {
          resourcesSource.map((obj) => {
            if (
              (prevState.selectedProviders &&
                prevState.selectedProviders.find((p) => p === obj.id)) ||
              (nextProps.appointmentsData.providers &&
                nextProps.appointmentsData.doctor_ids &&
                nextProps.appointmentsData.providers.length ===
                  nextProps.appointmentsData.doctor_ids.length)
            ) {
              resourceMap.push({
                resourceId: obj.id,
                resourceTitle: displayName(obj),
                resourceColor: "#FF1493",
                resourceUrl: `/appointment/provider/${obj.id}/clinic/${
                  nextProps.appointmentsData.clinic_id
                }/${prevState.calendarView}/${moment(
                  prevState.searchPickerDate,
                ).format("YYYY-MM-DD")}`,
                workingHours: obj.working_hours,
              });
            }
            if (obj.working_hours) {
              obj.working_hours.map((time) => {
                if (time.schedule) {
                  time.schedule.map((sc) => {
                    let dateArr = sc.date_scheduled.split("-");
                    let startTime = sc.from_time.split(":");
                    let endTime = sc.to_time.split(":");
                    let formattedFrom = moment(sc.from_time, "HH:mm:ss").format(
                      prevState.timeFormat,
                    );
                    let formattedTo = moment(sc.to_time, "HH:mm:ss").format(
                      prevState.timeFormat,
                    );
                    appointments.unshift({
                      id: obj.id,
                      title:
                        "Available for bookings from " +
                        formattedFrom +
                        " - " +
                        formattedTo,
                      start: new Date(
                        dateArr[0],
                        dateArr[1] - 1,
                        dateArr[2],
                        startTime[0],
                        startTime[1],
                        startTime[2],
                      ),
                      end: new Date(
                        dateArr[0],
                        dateArr[1] - 1,
                        dateArr[2],
                        endTime[0],
                        endTime[1],
                        endTime[2],
                      ),
                      resourceId: obj.id,
                      color: "#dedede",
                      gradient:
                        "repeating-linear-gradient(to bottom, #dedede 0px, #dedede 69px, #cccccc 69px, #cccccc 70px)",
                      clientName: "",
                      availabilityGrid: true,
                      noClickEvents: true,
                    });
                  });
                }
              });
            }
          });
        } else {
          resourceMap.push({
            resourceId: 0,
            resourceTitle: "",
            resourceColor: "#FF1493",
          });
        }

        let clinic = nextProps.appointmentsData.clinics.find(
          (y) => y.id == nextProps.appointmentsData.clinic_id,
        );
        let originalOpens = nextProps.appointmentsData.opensAt.split(":");
        let open = nextProps.appointmentsData.clinicOpensAt.split(":");
        let close = nextProps.appointmentsData.clinicClosesAt.split(":");
        let originalCloses = nextProps.appointmentsData.closesAt.split(":");
        returnState.events = prevState.forceChanged
          ? prevState.events
          : appointments;
        returnState.clinics = nextProps.appointmentsData.clinics;
        returnState.providers = nextProps.appointmentsData.providers;
        returnState.tmpProviders = nextProps.appointmentsData.providers;
        returnState.appointmentsTimestamp = nextProps.appointmentsTimestamp;
        returnState.clinic_timezone =
          nextProps.appointmentsData.clinic_timezone;
        if (nextProps.appointmentsData.providers.length == 0) {
          for (let x in prevState) {
            if (x.startsWith("provider-")) {
              returnState[x] = false;
            }
          }
        }

        if (returnState.provider_id) {
          let provider = returnState.providers.find(
            (e) => e.id == returnState.provider_id,
          );
          returnState.providerName = displayName(provider);
        }

        returnState.resourceMap = resourceMap;
        returnState.clinic_id = nextProps.appointmentsData.clinic_id;
        returnState.selectedClinic = clinic ? clinic.clinic_name : "";
        returnState.showLoader = false;
        returnState.forceChanged = false;
        if (
          prevState.selectedProviders &&
          prevState.selectedProviders.length ==
            returnState.selectedProviders.length
        ) {
          returnState.selectAllProviders = true;
        } else {
          returnState.selectAllProviders = false;
        }
        returnState.openAt = originalOpens[0] + ":" + originalOpens[1];
        returnState.closesAt = originalCloses[0] + ":" + originalCloses[1];
        returnState.min = new Date(2019, 1, 28, open[0], open[1], open[2]);
        returnState.max = new Date(2019, 1, 28, close[0], close[1], close[2]);
      }
      return returnState;
    }

    if (prevState.isFull == false) {
      positionFooterCorrectly();
    }
    if (nextProps.activeMenuTag != undefined) {
      returnState.activeMenuTag = nextProps.activeMenuTag;
      return returnState;
    }

    if (
      nextProps.appointmentConfirmData != undefined &&
      nextProps.appointmentConfirmData == true &&
      nextProps.appointmentConfirmationDataTime !=
        prevState.appointmentConfirmationDataTime
    ) {
      returnState.appointmentConfirmationDataTime =
        nextProps.appointmentConfirmationDataTime;
      let appointments = prevState.events;
      let appointment_id =
        nextProps.appointmentConfirmationData.data.appointment_id;
      let index = appointments.findIndex((y) => y.id == appointment_id);
      if (index > -1) {
        appointments[index].confirmed_status =
          nextProps.appointmentConfirmationData.data.is_confirmed == 1
            ? "Confirmed"
            : "Not Confirmed";
      }
      returnState.events = appointments;
      returnState.showLoader = false;
      return returnState;
    }

    if (
      nextProps.providerUpdates != undefined &&
      nextProps.providerUpdates == true &&
      nextProps.providerUpdatedTimeStamp != prevState.providerUpdatedTimeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        uiNotification.success(nextProps.message);
        returnState.showLoader = false;
        returnState.providerUpdatedTimeStamp =
          nextProps.providerUpdatedTimeStamp;
        returnState.showAppointmentDetails = false;
        returnState.showEditProvider = false;
        returnState.appointment_provider = "0";
        returnState.appointment_provider_class = "simpleSelect";
        return returnState;
      }
    }

    if (
      nextProps.patientUnCheckedIn != undefined &&
      nextProps.patientUnCheckedIn == true &&
      nextProps.patientUnCheckedIdTimestamp !=
        prevState.patientUnCheckedIdTimestamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showAppointmentDetails = false;
        returnState.showLoader = false;
        returnState.patientUnCheckedIdTimestamp =
          nextProps.patientUnCheckedIdTimestamp;
        return returnState;
      }
    }
    return null;
  }

  getProviderAppointments = (formData) => {
    const verticalsIdsArr = [];
    if (this.state.selectedVertical.id !== 0) {
      verticalsIdsArr.push(this.state.selectedVertical.id);
      formData.vertical_ids = verticalsIdsArr;
    }
    if (!this.state.needAppointmentsUpdate) {
      this.setState({
        showLoader: false,
        needAppointmentsUpdate: true,
      });
    } else {
      this.props.getProviderAppointments(formData, this.state.verticalsEnabled);
    }
  };

  showPreviousBookings = (event) => {
    event.preventDefault();
    this.setState({ showBookings: !this.state.showBookings });
  };

  editNote = () => {
    this.setState({ showEditNote: !this.state.showEditNote });
  };

  saveNotes = () => {
    this.setState({ noteError: false });
    if (this.state.notes.trim() == "") {
      this.setState({ noteError: true });
      return false;
    }
    localStorage.setItem("showLoader", true);
    this.setState({ noteError: false, showLoader: true });
    let formData = {};
    formData.appointment_id = this.state.editAppointmentId;
    formData.note = this.state.notes;
    this.props.updateNotes(formData);
  };

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    let name = event.target.name;
    let returnState = {};
    returnState.needAppointmentsUpdate = true;
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }

      case "radio": {
        value = target.value;
        break;
      }
    }

    if (name == "provider_id") {
      returnState.clinic_id = "";
    }

    if (name.startsWith("provider-")) {
      let nameArr = name.split("-");
      let providerId = parseInt(nameArr[1]);
      let selectedProviders = this.state.selectedProviders;
      if (value) {
        selectedProviders.push(providerId);
      } else {
        let index = selectedProviders.indexOf(providerId);
        if (index > -1) {
          selectedProviders.splice(index, 1);
        }
      }
      returnState.selectedProvidersLength = selectedProviders.length;
      returnState.selectedProviders = selectedProviders;
      this.setState({ wasProvidersChanged: true });
    }

    if (name.startsWith("sep-provider-")) {
      let nameArr = name.split("-");
      let providerId = parseInt(nameArr[2]);
      let selectedProviders = this.state.selectedProviders;
      if (providerId === 0) {
        const isAllSelected = this.state.tmpProviders.every(
          (p) => this.state["provider-" + p.id],
        );
        const selectedProviders = [];
        returnState.selectAllProviders = !isAllSelected;
        this.state.providers.map((obj) => {
          returnState["provider-" + obj.id] = !isAllSelected;
          selectedProviders.push(obj.id);
        });
        returnState.selectedProvidersLength = selectedProviders.length;
        returnState.selectedProviders = !isAllSelected ? selectedProviders : [];
        returnState.userChanged = true;
        returnState.needAppointmentsUpdate = !isAllSelected;
      } else {
        if (value) {
          selectedProviders.push(providerId);
        } else {
          let index = selectedProviders.indexOf(providerId);
          if (index > -1) {
            selectedProviders.splice(index, 1);
          }
        }
        returnState["provider-" + providerId] = value;
        returnState.selectedProvidersLength = selectedProviders.length;
        returnState.selectedProviders = selectedProviders;
        returnState.needAppointmentsUpdate = !!selectedProviders.length;
      }
      this.setState({ wasProvidersChanged: true });
    }

    if (name.startsWith("service-")) {
      let nameArr = name.split("-");
      let serviceId = nameArr[1];
      let selectedServices = this.state.selectedServices;
      if (value) {
        selectedServices.push(serviceId);
      } else {
        let index = selectedServices.indexOf(serviceId);
        if (index > -1) {
          selectedServices.splice(index, 1);
        }
      }
      returnState.selectedServices = selectedServices;
      returnState.selectedServicesLength = selectedServices.length;
    }

    if (name == "notification_type" && value) {
      returnState.notification_body =
        value == "sms"
          ? this.state.appointment_sms
          : this.state.appointment_email_text;
    }

    if (name == "searchProvider") {
      let result = this.state.providers.filter((word) => {
        let name = displayName(word).toLowerCase();
        return name.indexOf(value.trim().toLowerCase()) > -1;
      });
      this.setState({ tmpProviders: result });
    }

    returnState[event.target.name] = value;
    returnState.userChanged = true;

    if (
      name != "room" &&
      name != "customer_note" &&
      name != "send_to_provider" &&
      !name.startsWith("notes-") &&
      name != "notification_body" &&
      name != "notification_type" &&
      name != "notes" &&
      !name.startsWith("provider-") &&
      !name.startsWith("service-") &&
      !name.startsWith("searchProvider") &&
      name != "appointment_provider"
    ) {
      localStorage.setItem("showLoader", true);
      returnState.showLoader = true;
      let startEndDateWeek =
        this.state.calendarView == Views.WEEK
          ? startEndDateOfWeek(this.state.selectedDate, "YYYY-MM-DD")
          : { start: this.state.selectedDate, end: this.state.selectedDate };

      this.setState(returnState, () => {
        this.refreshAppointments(
          startEndDateWeek.start,
          startEndDateWeek.end,
          this.state.calendarView,
        );
      });
    } else {
      this.setState(returnState);
    }
  };

  handleNextPrevDate = (navigate) => {
    var selectedDate = moment(this.state.selectedDate);
    let calendarView = this.state.calendarView;
    let returnState = {};
    returnState.userChanged = true;
    switch (calendarView) {
      case Views.WEEK:
        if (navigate == "next") {
          let newSelectedDate = selectedDate.add(1, "weeks").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = showSelectedWeek(newSelectedDate);
          returnState.searchPickerDate = moment(returnState.selectedDate)
            .startOf("week")
            .toDate();
          returnState.isChangeWeek = true;
        } else if (navigate == "prev") {
          let newSelectedDate = selectedDate.subtract(1, "weeks").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = showSelectedWeek(newSelectedDate);
          returnState.searchPickerDate = moment(returnState.selectedDate)
            .startOf("week")
            .toDate();
          returnState.isChangeWeek = true;
        } else {
          returnState.selectedDate = moment();
          selectedDate = moment();
          returnState.showSelectedDate = showSelectedWeek(selectedDate);
          returnState.searchPickerDate = moment(
            returnState.selectedDate,
          ).toDate();
          returnState.isChangeWeek = false;
        }
        break;
      case Views.DAY:
        if (navigate == "next") {
          let newSelectedDate = selectedDate.add(1, "days").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = viewDateFormat(
            moment(newSelectedDate),
          );
        } else if (navigate == "prev") {
          let newSelectedDate = selectedDate.subtract(1, "days").toDate();
          returnState.selectedDate = newSelectedDate;
          returnState.showSelectedDate = viewDateFormat(
            moment(newSelectedDate),
          );
        } else {
          selectedDate = moment();
          returnState.selectedDate = moment().toDate();
          returnState.showSelectedDate = viewDateFormat(moment());
        }
        returnState.searchPickerDate = moment(
          returnState.selectedDate,
        ).toDate();
        break;
    }
    localStorage.setItem("showLoader", true);
    let formattedDate = selectedDate;
    formattedDate = formattedDate.format("YYYY-MM-DD");
    returnState.showLoader = true;

    let startEndDateWeek =
      calendarView == Views.WEEK
        ? startEndDateOfWeek(selectedDate, "YYYY-MM-DD")
        : { start: formattedDate, end: formattedDate };
    this.setState(returnState, () => {
      this.refreshAppointments(
        startEndDateWeek.start,
        startEndDateWeek.end,
        this.state.calendarView,
      );
    });
  };

  markAsNoShow = () => {
    localStorage.setItem("showLoader", true);
    this.setState({ showLoader: true });
    this.props.markNoShow(this.state.editAppointmentId);
  };

  emptyFunction = () => {};

  showEvent = (event) => {
    if (event.appointmentType == "break_time") {
      return false;
    }

    if (event.availabilityGrid) {
      return this.handleSelect(event);
    }

    localStorage.setItem("showLoader", true);
    this.setState({ editAppointmentId: event.id, showLoader: true });
    this.fetchAppointment(event.id, "view", {
      openApptDetails: event.appointmentType !== "outofoffice",
    });
  };

  closePopUp = () => {
    this.setState({
      showAppointmentDetails: false,
      appointmentData: {},
      showBookings: false,
      showEditProvider: false,
      appointment_provider: "0",
      appointment_provider_class: "simpleSelect",
    });
  };

  toggleClinics = () => {
    this.setState({
      showClinics: !this.state.showClinics,
      showService: false,
      showProvider: false,
      userChanged: true,
    });
  };

  toggleService = (event) => {
    event.stopPropagation();
    this.setState({
      showClinics: false,
      showService: true,
      showProvider: false,
      userChanged: true,
    });
  };

  toggleProvider = (event) => {
    event.stopPropagation();
    this.setState({
      showClinics: false,
      showService: false,
      showProvider: true,
      userChanged: true,
    });
  };

  refreshAppointments = async (start, end, view = "") => {
    let formData = {};
    if (view == "") {
      view = this.state.calendarView;
    }
    let startEndDateWeek =
      view == Views.WEEK
        ? startEndDateOfWeek(this.state.selectedDate, "YYYY-MM-DD")
        : { start: this.state.selectedDate, end: this.state.selectedDate };
    formData.start = start
      ? apiDateFormat(start)
      : apiDateFormat(startEndDateWeek.start);
    formData.end = end
      ? apiDateFormat(end)
      : apiDateFormat(startEndDateWeek.end);
    formData.doctor_ids = this.state.providers.length
      ? this.state.selectedProviders
      : [];
    formData.clinic_id = this.state.clinic_id;

    if (this.state.clinic_id) {
      formData.clinic_id = this.state.clinic_id;
    }

    let calendarFilter = JSON.parse(JSON.stringify(formData));

    calendarFilter.searchPickerDate = this.state.selectedDate;
    calendarFilter.calendarView = view;
    localStorage.setItem("providerWeekFilter", JSON.stringify(calendarFilter));
    this.setState({
      showClinics: false,
      showService: false,
      showProvider: false,
      userChanged: true,
      showLoader: true,
    });
    return this.getProviderAppointments(formData, view);
  };

  changeClinic = (event) => {
    localStorage.setItem("showLoader", true);
    let clinicId = parseInt(event.currentTarget.dataset.id);
    let formData = {},
      returnState = {},
      clinic = {};

    let dateToSend = this.getFormDataDate();
    formData.start = apiDateFormat(dateToSend.start);
    formData.end = apiDateFormat(dateToSend.end);
    formData.doctor_ids = [];
    formData.clinic_id = clinicId;
    if (clinicId) {
      clinic = this.state.clinics.find((y) => y.id == clinicId);
      returnState.selectedClinic = clinic.clinic_name;
    } else {
      returnState.clinic_id = clinicId;
      returnState.selectedClinic = "Please Select";
    }

    returnState.showLoader = true;
    returnState.provider_id = [];
    this.setState(returnState);
    let calendarFilter = JSON.stringify(formData);
    localStorage.setItem("providerWeekFilter", calendarFilter);
    this.getProviderAppointments(formData);
  };

  changeVertical = (verticalId) => {
    localStorage.setItem("showLoader", true);
    const selectedVertical = this.state.verticalsList.find(
      (v) => v.id === verticalId,
    );
    const verticalsIdsArr = [];
    let formData = {},
      returnState = {},
      dateToSend = this.getFormDataDate();
    formData.start = apiDateFormat(dateToSend.start);
    formData.end = apiDateFormat(dateToSend.end);
    formData.doctor_ids = this.state.selectedProviders;
    formData.clinic_id = this.state.clinic_id;
    if (verticalId === 0) {
      this.state.verticalsList.map(
        (v) => v.id !== 0 && verticalsIdsArr.push(v.id),
      );
    } else {
      verticalsIdsArr.push(selectedVertical.id);
    }
    formData.vertical_ids = verticalsIdsArr;
    returnState.selectedVertical = selectedVertical;
    returnState.showLoader = true;
    this.setState(returnState, () => {
      let calendarFilter = JSON.stringify(formData);
      localStorage.setItem("providerWeekFilter", calendarFilter);
      this.getProviderAppointments(formData, this.state.calendarView);
    });
  };

  handleCalendarView = (nextCalendarView) => {
    localStorage.setItem("showLoader", true);
    this.setState({ wasViewChanged: true });
    let returnState = {};
    returnState.calendarView = nextCalendarView;
    returnState.userChanged = true;
    returnState.selectedDate = this.state.selectedDate;
    if (nextCalendarView == Views.WEEK) {
      returnState.showSelectedDate = showSelectedWeek(returnState.selectedDate);
    } else if (nextCalendarView == Views.DAY) {
      returnState.isChangeWeek = false;
      if (this.state.returnState) {
        returnState.selectedDate = moment(returnState.selectedDate)
          .startOf("week")
          .toDate();
      }
      returnState.showSelectedDate = viewDateFormat(returnState.selectedDate);
    }
    returnState.searchPickerDate = moment(returnState.selectedDate).toDate();
    returnState.showLoader = true;
    let formattedDate = moment(returnState.selectedDate);
    formattedDate = formattedDate.format("YYYY-MM-DD");
    let startEndDateWeek =
      nextCalendarView == Views.WEEK
        ? startEndDateOfWeek(this.state.selectedDate, "YYYY-MM-DD")
        : { start: formattedDate, end: formattedDate };
    this.setState(returnState);
    this.setState(returnState, () => {
      this.refreshAppointments(
        startEndDateWeek.start,
        startEndDateWeek.end,
        nextCalendarView,
      );
    });
  };

  handleDatePicker = (date) => {
    this.setState({ selectedPickerDate: date, showDatePicker: false });
    this.refDatePicker.setOpen(false);
  };

  resetDatePicker = () => {
    this.setState({ selectedPickerDate: null, showDatePicker: true });
    this.toggleDatePicker();
  };

  blurDatePicker = () => {
    this.refDatePicker.setOpen(true);
    this.setState({ showDatePicker: true });
  };

  focusDatePicker = () => {
    this.setState({ showDatePicker: true });
  };

  toggleDatePicker = () => {
    this.setState({ showDatePicker: true });
    this.refDatePicker.setFocus(true);
    this.refDatePicker.setOpen(true);
  };

  handleRepeatDatePicker = (date) => {
    this.setState({ selectedRepeatPickerDate: date });
  };

  resetRepeatDatePicker = () => {
    this.setState({ selectedRepeatPickerDate: null });
    this.toggleRepeatDatePicker();
  };

  blurRepeatDatePicker = () => {
    this.refRepeatDatePicker.setOpen(true);
  };

  toggleRepeatDatePicker = () => {
    this.refRepeatDatePicker.setFocus(true);
    this.refRepeatDatePicker.setOpen(true);
  };
  getFormDataDate = () => {
    let selDate = moment(this.state.selectedDate);
    selDate = selDate.format("YYYY-MM-DD");
    let startEndDateWeek =
      this.state.calendarView == Views.WEEK
        ? startEndDateOfWeek(this.state.selectedDate, "YYYY-MM-DD")
        : { start: selDate, end: selDate };
    return startEndDateWeek;
  };

  handleSearchDatePicker = (date) => {
    localStorage.setItem("showLoader", true);
    let returnState = {};
    returnState.showLoader = true;
    returnState.searchPickerDate = date;
    returnState.isChangeWeek = false;
    returnState.selectedDate = date;
    returnState.userChanged = true;
    if (this.state.calendarView == Views.WEEK) {
      returnState.showSelectedDate = showSelectedWeek(returnState.selectedDate);
    } else if (this.state.calendarView == Views.DAY) {
      returnState.isChangeWeek = false;
      if (this.state.returnState) {
        returnState.selectedDate = moment(returnState.selectedDate)
          .startOf("week")
          .toDate();
      }
      returnState.showSelectedDate = viewDateFormat(returnState.selectedDate);
    }
    returnState.calendarView = this.state.calendarView;
    let formattedDate = moment(date);
    formattedDate = formattedDate.format("YYYY-MM-DD");
    let startEndDateWeek =
      this.state.calendarView == Views.WEEK
        ? startEndDateOfWeek(date, "YYYY-MM-DD")
        : { start: formattedDate, end: formattedDate };
    this.setState(returnState, () => {
      this.refreshAppointments(startEndDateWeek.start, startEndDateWeek.end);
    });
  };

  patientCheckIn = () => {
    localStorage.setItem("showLoader", true);
    this.setState({ showLoader: true, appointmentData: {} });
    this.props.checkin(this.state.editAppointmentId);
  };

  makeCheckin = () => {
    let formData = {};
    formData.id = this.state.editAppointmentId;
    if (this.state.room.trim() != "") {
      formData.room = this.state.room.trim();
      localStorage.setItem("showLoader", true);
      this.setState({ roomError: false, showLoader: true });
    } else {
      this.setState({ roomError: true });
      return false;
    }

    let arr = [];
    for (let x in this.state) {
      if (x.startsWith("notes-")) {
        if (this.state[x]) {
          arr.push(x.split("-")[1]);
        }
      }
    }

    formData.notes_arr = arr;
    formData.customer_new_note = this.state.customer_note;
    formData.send_to_provider = this.state.send_to_provider;
    this.props.makePatientCheckin(formData);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.markedTimeStamp != undefined &&
      this.props.markedTimeStamp != prevState.markedTimeStamp
    ) {
      this.refreshAppointments();
    }
    if (
      this.props.deletedOutOfficeTimeStamp &&
      this.props.deletedOutOfficeTimeStamp !=
        prevState.deletedOutOfficeTimeStamp
    ) {
      this.refreshAppointments();
    }
    if (
      this.props.timestamp &&
      this.props.timestamp != prevState.timestamp &&
      (this.props.appointmentCancelled || this.props.notificationSent)
    ) {
      this.refreshAppointments();
    }
    if (
      this.props.notiTimeStamp &&
      this.props.notiTimeStamp != prevState.notiTimeStamp
    ) {
      this.refreshAppointments();
    }
    if (
      this.props.patientCheckedIdTimestamp &&
      this.props.patientCheckedIdTimestamp !=
        prevState.patientCheckedIdTimestamp
    ) {
      this.refreshAppointments();
    }

    if (
      this.props.providerUpdatedTimeStamp != undefined &&
      this.props.providerUpdatedTimeStamp != prevState.providerUpdatedTimeStamp
    ) {
      this.refreshAppointments();
    }

    if (
      this.props.patientUnCheckedIdTimestamp &&
      this.props.patientUnCheckedIdTimestamp !=
        prevState.patientUnCheckedIdTimestamp
    ) {
      this.refreshAppointments();
    }

    if (
      this.state.showModal ||
      this.state.showPrintModal ||
      this.state.showRescheduleConfirmModal ||
      this.state.showAppointmentDetails ||
      this.state.showCheckinData ||
      this.state.showNotifyPop
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    ReactTooltip.rebuild();

    if (
      (!prevProps.appointmentDataStatus ||
        prevProps.appointmentDataStatus === "pending") &&
      this.props.appointmentDataStatus === "loading"
    ) {
      this.setState({ showLoader: true });
    }

    if (
      prevProps.appointmentDataStatus === "success" &&
      this.props.appointmentDataStatus === "loading"
    ) {
      this.setState({ showLoader: true });
    }

    if (
      prevProps.appointmentDataStatus === "loading" &&
      this.props.appointmentDataStatus === "success"
    ) {
      this.setState({
        appointmentData: this.props.appointmentData,
        showLoader: false,
      });
    }
    if (
      (isEmpty(prevState.appointmentData) &&
        !isEmpty(this.state.appointmentData)) ||
      (!isEmpty(this.state.appointmentData) &&
        !isEqual(prevState.appointmentData, this.state.appointmentData))
    ) {
      if (
        this.state.appointmentData.appointment_details.type === "appointment"
      ) {
        this.setState({ showAppointmentDetails: true });
      }
      if (
        this.state.appointmentData.appointment_details.type === "outofoffice"
      ) {
        this.setState({ showOutOfOfficeData: true });
      }
    }
    if (
      isEmpty(prevState.appointmentData) &&
      !isEmpty(this.state.appointmentData)
    ) {
      this.setState({
        notes:
          this.state.appointmentData.appointment_details.appointment_booking
            .appointment_notes,
      });
    }
  };

  cancelAppointment = () => {
    localStorage.setItem("showLoader", true);
    this.setState({ showLoader: true });
    let formData = {};
    formData.pat_to_be_charged = this.state.patient_to_charge;
    formData.appointment_id = this.state.editAppointmentId;
    formData.notification_type = this.state.notification_type;
    formData.notification_body = this.state.notification_body;
    formData.appointment_sms = this.state.appointment_sms;
    formData.appointment_subject = this.state.appointment_subject;
    formData.success_msg = this.state.success_msg;
    this.props
      .cancelAppointment(formData)
      .then(() => {
        this.setState({
          appointmentData: {},
        });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  sendNotification = () => {
    let formData = {},
      notiBodyClassError = false;

    notiBodyClassError = this.state.notification_body.trim() ? false : true;

    if (notiBodyClassError) {
      return false;
    }

    localStorage.setItem("showLoader", true);
    this.setState({ showLoader: true });
    formData.notification_type = this.state.notification_type;
    formData.notification_body = this.state.notification_body;
    formData.appointment_sms = this.state.appointment_sms;
    formData.appointment_subject = this.state.appointment_subject;
    formData.appointment_id = this.state.appointment_id;
    formData.success_msg = this.state.success_msg;
    this.props.sendNotification(formData);
  };

  fetchCancelNotification = async (id) => {
    try {
      this.setState({ showLoader: true });

      const res = await this.props.getCancelNotification(id);
      const data = res.data;

      this.setState({
        cancelNotificationMessage: data,
        noti_type: data.noti_type,
        appointment_email_text: data.appointment_email_text,
        notification_body: data.appointment_email_text,
        appointment_sms: data.appointment_sms,
        appointment_subject: data.appointment_subject,
        success_msg: data.success_msg,
        showAppointmentDetails: false,
        showBookings: false,
        showNotifyPop: Boolean(data.send_email_status),
        showLoader: false,
        showModal: false,
        notiMode: "cancel",
      });

      return {
        showNotifyPop: Boolean(data.send_email_status),
      };
    } catch (res) {
      uiNotification.error(this.state.globalLang[res.message]);
    } finally {
      this.setState({ showLoader: false });
    }
  };

  chargeAndFurther = async (event) => {
    let mode = this.state.feesMode;
    let charge = event.currentTarget.dataset.charge;
    this.setState({ patient_to_charge: charge });

    if (mode == "cancel") {
      const notification = await this.fetchCancelNotification(
        this.state.editAppointmentId,
      );
      if (!notification.showNotifyPop) {
        this.setState({ showNotifyPop: false });
        this.cancelAppointment();
      }
    } else {
      localStorage.setItem("showLoader", true);
      this.setState({ showLoader: true });

      let formData = {};
      formData.appointment_id = this.state.editAppointmentId;
      formData.charge = charge;
      this.props.markNoShow(formData).finally(() => {
        this.setState({ showLoader: false });
      });
    }
  };

  dismissModal = () => {
    this.setState({ showModal: !this.state.showModal, appointmentData: {} });
  };
  dismissRescheduleModal = () => {
    this.setState({
      showRescheduleConfirmModal: !this.state.showRescheduleConfirmModal,
      appointmentData: {},
    });
  };
  dismissPrintModal = () => {
    this.setState({
      showPrintModal: !this.state.showPrintModal,
      appointmentData: {},
    });
  };

  toggleAllServices = (event) => {
    event.stopPropagation();
    let allServices = this.state.services;
    let returnState = {};
    let x = this.state.selectAllService ? false : true;
    let selectedServices = [];
    returnState.selectAllService = x;
    allServices.map((obj) => {
      returnState["service-" + obj.id] = x;
      selectedServices.push(obj.id);
    });
    returnState.selectedServices = x ? selectedServices : [];
    returnState.userChanged = true;
    this.setState(returnState);
  };

  toggleAllProviders = (event) => {
    event.stopPropagation();
    let allServices = this.state.providers;
    let returnState = {};
    let x = this.state.selectAllProviders ? false : true;
    let selectedProviders = [];
    returnState.selectAllProviders = x;
    allServices.map((obj) => {
      returnState["provider-" + obj.id] = x;
      selectedProviders.push(obj.id);
    });
    returnState.selectedProviders = x ? selectedProviders : [];
    returnState.userChanged = true;
    this.setState(returnState);
  };

  applyFilter = (event) => {
    event.stopPropagation();
    localStorage.setItem("showLoader", true);
    this.refreshAppointments();
  };
  print = (mode) => {
    if (localStorage.getItem("providerWeekFilter")) {
      localStorage.setItem("showLoader", true);
      this.setState({ showLoader: true });
      let calendarFilter = JSON.parse(
        localStorage.getItem("providerWeekFilter"),
      );
      calendarFilter.print_type = mode;
      this.props.printAppointment(calendarFilter);
    } else {
      let formData = {};
      formData.print_type = mode;
      formData.start = moment().format("YYYY-MM-DD");
      formData.end = moment().format("YYYY-MM-DD");
      this.props.printAppointment(formData);
    }
  };
  closeNotiPop = () => {
    if (this.state.notiMode == "reschedule")
      uiNotification.success(this.state.globalLang[this.state.success_msg]);

    this.setState({
      showNotifyPop: !this.state.showNotifyPop,
      appointmentData: {},
    });
    this.refreshAppointments();
  };

  chargeAndReschedule = (event) => {
    let charge = event.currentTarget.dataset.charge;
    let formData = {};
    formData.appointment_id = this.state.updatedEvent.id;
    formData.patient_to_be_charged = charge == "true" ? 1 : 0;
    formData.appointment_date = moment(this.state.updatedEvent.start).format(
      "YYYY-MM-DD",
    );
    formData.appointment_time = moment(this.state.updatedEvent.start).format(
      "HH:mm:ss",
    );
    localStorage.setItem("showLoader", true);
    this.setState({ showModal: false, showLoader: true, appointmentData: {} });
    this.handleReschedule(formData);
  };

  clickedOutside = () => {
    this.calendarRef.current.setOpen(true);
    return false;
  };

  setTodayDate = () => {
    let returnState = {};
    returnState.searchPickerDate = moment(new Date()).toDate();
    returnState.selectedDate = moment(new Date()).toDate();

    returnState.showSelectedDate =
      this.state.calendarView == Views.WEEK
        ? showSelectedWeek(returnState.searchPickerDate)
        : viewDateFormat(returnState.searchPickerDate);

    this.setState(returnState, () => {
      this.handleCalendarView(this.state.calendarView);
    });
  };

  openToMeetingUrl = () => {
    if (this.state.meeting_url) {
      window.open(this.state.meeting_url, "_blank");
    }
  };

  closeOutPopUp = () => {
    this.setState({ showOutOfOfficeData: false, appointmentData: {} });
  };

  deleteOutOfOffice = () => {
    this.setState({
      showDeleteModal: true,
      showOutOfOfficeData: false,
      appointmentData: {},
    });
  };

  dismissDeleteModal = () => {
    this.setState({ showDeleteModal: false, appointmentData: {} });
  };

  deleteOutEvent = () => {
    let formData = {};
    formData.appointment_id = this.state.out_of_office_data.appointment_id;
    formData.all_following = this.state.checkAllSubsequent ? 1 : 0;
    this.setState({ showLoader: true, appointmentData: {} });
    this.props.deleteEvent(formData);
  };

  handleEditorChange = (childState) => {
    this.setState(childState);
  };

  confirmAppointment = () => {
    this.setState({ showLoader: true });

    let formData = {
      appointment_id: this.state.appointment_details.id,
      is_confirmed: this.state.show_confirmed_toggle == false ? 1 : 0,
    };

    this.props
      .confirmAppointmentStatus(formData)
      .then(() => {
        this.setState({
          show_confirmed_toggle: !this.state.show_confirmed_toggle,
        });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  editProvider = () => {
    this.setState({
      showEditProvider: !this.state.showEditProvider,
      appointment_provider: "0",
      appointment_provider_class: "simpleSelect",
    });
  };

  saveProvider = () => {
    if (
      !this.state.appointment_provider ||
      this.state.appointment_provider == "0"
    ) {
      this.setState({ appointment_provider_class: "simpleSelect field_error" });
    } else {
      this.setState({ appointment_provider_class: "simpleSelect" });
    }

    this.setState({ showLoader: true });

    let formData = {
      appointment_id: this.state.appointment_details.id,
      provider_id: this.state.appointment_provider,
    };

    this.props.updateProvider(formData);
  };

  patientUnCheckIn = () => {
    localStorage.setItem("showLoader", true);
    this.setState({ showLoader: true });
    this.props.unCheckAppt(this.state.editAppointmentId);
  };

  redirectToPatientProfile = () => {
    const id = this.state?.appointment_details?.patient?.id;
    if (id) {
      this.props.history.push(CLIENTS_ROUTES.profile(id));
    }
  };

  switchToCreateExamModal = () => {
    this.setState({
      showAppointmentDetails: false,
      isCreateQualiphyProcedureModalOpen: true,
    });
  };

  switchToApptDetailsModal = () => {
    this.setState({
      showAppointmentDetails: true,
      isCreateQualiphyProcedureModalOpen: false,
    });
  };

  onQualiphyRequestSuccess = async () => {
    const apptId =
      this.props.appointmentData?.appointment_details?.id ||
      this.state?.appointment_details?.id;
    this.switchToApptDetailsModal();
    this.setState({
      isUpdatingAfterQualiphySent: true,
    });
    await this.refreshAppointments();
    await this.fetchAppointment(apptId, "view");
    this.setState({
      isUpdatingAfterQualiphySent: false,
    });
  };

  render() {
    var stringLengt = this.state.selectedClinic;
    let smartBookingUrl = "";
    let curDomain = this.state.userData.domain;
    smartBookingUrl =
      this.state.userData.host_name +
      this.state.userData.account.pportal_subdomain +
      "." +
      curDomain +
      "/book/appointments?user_id=" +
      this.state.userData.user.hash_id;

    let pName =
      this.state.appointment_details && this.state.appointment_details.patient
        ? this.state.appointment_details.patient.firstname
        : this.state.patient_name
        ? this.state.patient_name
        : "";
    let checkinPname =
      this.state.appointment_details && this.state.appointment_details.patient
        ? displayName(this.state.appointment_details.patient)
        : "";

    let selectAllProvidersLabel = this.state.selectAllProviders
      ? "Unselect All"
      : "Select All";

    const preparedEvents = filterEvents(this.state.events);

    return (
      <div id="content">
        <div className="container-fluid content">
          <div className="juvly-section full-width m-b-0">
            <div className="calender-title">
              <div className={classes.header}>
                <div className={classes.left}>
                  <span className={classes.name}>Calendar</span>
                  {this.state.calendarView === Views.DAY ? (
                    <a
                      onClick={this.setTodayDate}
                      className="new-line-btn no-width m-0"
                    >
                      Today
                    </a>
                  ) : (
                    <div className="cal-date-btn-outer calViewCal">
                      <div
                        className="search-bg new-calender"
                        ref={(refSearchDatePickerContainer) =>
                          (this.refSearchDatePickerContainer =
                            refSearchDatePickerContainer)
                        }
                      >
                        <img alt="" src={calenLogo} />
                        <DatePicker
                          value={
                            this.state.searchPickerDate
                              ? viewDateFormat(this.state.searchPickerDate)
                              : null
                          }
                          onChange={this.handleSearchDatePicker}
                          className="setting-search-input search-key"
                          dateFormat="YYYY-MM-dd"
                          minDate={new Date(moment().subtract(10, "years"))}
                          selected={this.state.searchPickerDate}
                          name="searchPickerDate"
                          autoComplete="off"
                          ref={(refSearchDatePicker) =>
                            (this.refSearchDatePicker = refSearchDatePicker)
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="cal-arrow-outer m-0">
                    <a
                      className="cal-arrow pull-left"
                      onClick={this.handleNextPrevDate.bind(this, "prev")}
                    >
                      <img alt="" src="/images/cal-arrow-left.png" />
                    </a>
                    <a
                      className="cal-arrow pull-right"
                      onClick={this.handleNextPrevDate.bind(this, "next")}
                    >
                      <img alt="" src="/images/cal-arrow-right.png" />
                    </a>
                  </div>
                  {this.state.calendarView == Views.DAY && (
                    <div className="calName p-0">
                      {moment(this.state.searchPickerDate).format(
                        "MMMM Do, YYYY",
                      )}
                    </div>
                  )}
                  {this.state.calendarView == Views.DAY &&
                    this.state.verticalsEnabled && (
                      <div className="calName p-0">
                        {!!this.state.selectedVertical &&
                          this.state.selectedVertical.name}
                      </div>
                    )}
                  {this.state.calendarView == Views.WEEK && (
                    <div className="calName p-0">
                      {this.state.showSelectedDate}
                    </div>
                  )}
                  <CalendarStepSelect
                    value={this.state.calendarStep}
                    onChange={(o) => this.setState({ calendarStep: o })}
                  />
                </div>
                <div className={classes.right}>
                  {this.state.calendarView === Views.WEEK && (
                    <button
                      className="calendar-dropdown show cal-provider-filter"
                      onClick={this.toggleProvider}
                      ref={(refProviderButton) =>
                        (this.refProviderButton = refProviderButton)
                      }
                    >
                      {this.state.providers.length == 0
                        ? "No Provider"
                        : this.state.selectedProviders.length == 0
                        ? "All Providers"
                        : this.state.selectedProviders.length > 1
                        ? "(" +
                          this.state.selectedProviders.length +
                          ") Providers"
                        : "(1) Provider"}
                      <a className="cal-arrow pull-right">
                        <i className="fas fa-angle-down"></i>
                      </a>
                      <div
                        className={
                          this.state.showProvider
                            ? "filter-wid-apply-btn"
                            : "filter-wid-apply-btn no-display"
                        }
                      >
                        <div className="cal-filter-outer">
                          <a
                            className={
                              this.state.providers.length == 0
                                ? "line-btn pull-right no-display"
                                : "line-btn pull-right"
                            }
                            onClick={this.applyFilter}
                            disabled={this.state.providers.length}
                          >
                            {this.state.appointmentLang.appointment_apply}
                          </a>
                          <a
                            className={
                              this.state.providers.length == 0
                                ? "line-btn pull-right no-display"
                                : "line-btn pull-right"
                            }
                            onClick={this.toggleAllProviders}
                            disabled={this.state.providers.length}
                          >
                            {selectAllProvidersLabel}
                          </a>
                        </div>
                        <ul
                          className={
                            this.state.providers.length > 0
                              ? "cal-dropdown cal-service-dropdown"
                              : "cal-dropdown cal-service-dropdown no-visible"
                          }
                        >
                          {this.state.providers.length > 0 &&
                            this.state.providers.map((obj, idx) => {
                              return (
                                <li key={"provider-" + idx}>
                                  <a>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        value={this.state["provider-" + obj.id]}
                                        name={"provider-" + obj.id}
                                        onChange={this.handleInputChange}
                                        checked={
                                          this.state["provider-" + obj.id]
                                            ? "checked"
                                            : false
                                        }
                                      />{" "}
                                      {displayName(obj)}
                                      <span
                                        className="filter-provider-span"
                                        style={{
                                          backgroundColor:
                                            obj.appointment_color,
                                        }}
                                      >
                                        {" "}
                                      </span>
                                    </label>
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                        <div
                          className={
                            this.state.providers.length == 0
                              ? "no-record"
                              : "no-display"
                          }
                        >
                          {
                            this.state.appointmentLang
                              .appointment_no_provider_found
                          }
                        </div>
                      </div>
                    </button>
                  )}
                  {!!this.state.verticalsList &&
                    !!this.state.verticalsList.length &&
                    this.state.calendarView === Views.DAY && (
                      <div className="calClinicdropDown">
                        <div className="dropdown pull-left weekDropdowncal">
                          <button
                            className="btn btn-default dropdown-toggle cal-clinic-filter"
                            type="button"
                            id="dropdownMenuVerticals"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {this.state.selectedVertical.name &&
                            this.state.selectedVertical.name.length > 16
                              ? this.state.selectedVertical.name.substring(
                                  0,
                                  16,
                                ) + "..."
                              : this.state.selectedVertical.name}
                            <i className="fas fa-angle-down" />
                          </button>
                          <ul
                            className="dropdown-menu cal-dropdown clinicname-dropdown"
                            aria-labelledby="dropdownMenuVerticals"
                          >
                            <Scrollbars
                              autoHeight
                              autoHeightMin={0}
                              autoHeightMax={306}
                              className="full-width"
                              renderTrackHorizontal={(props) => (
                                <div
                                  {...props}
                                  style={{ display: "none" }}
                                  className="track-horizontal"
                                />
                              )}
                            >
                              {this.state.verticalsList.length > 0 &&
                                this.state.verticalsList.map((obj) => {
                                  return (
                                    <li
                                      key={obj.id}
                                      onClick={() =>
                                        this.changeVertical(obj.id)
                                      }
                                    >
                                      <a>
                                        <label>{obj.name}</label>
                                      </a>
                                    </li>
                                  );
                                })}
                            </Scrollbars>
                          </ul>
                        </div>
                      </div>
                    )}
                  <div className="calClinicdropDown">
                    <div className="dropdown pull-left weekDropdowncal">
                      <button
                        className="btn btn-default dropdown-toggle cal-clinic-filter"
                        type="button"
                        id="dropdownMenuSearch"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {stringLengt.length > 16
                          ? stringLengt.substring(0, 16) + "..."
                          : stringLengt}
                        <i className="fas fa-angle-down"></i>
                      </button>
                      <ul
                        className="dropdown-menu cal-dropdown clinicname-dropdown"
                        aria-labelledby="dropdownMenuSearch"
                      >
                        <Scrollbars
                          autoHeight
                          autoHeightMin={0}
                          autoHeightMax={306}
                          className="full-width"
                          renderTrackHorizontal={(props) => (
                            <div
                              {...props}
                              style={{ display: "none" }}
                              className="track-horizontal"
                            />
                          )}
                        >
                          {this.state.clinics.length > 0 &&
                            this.state.clinics.map((obj, idx) => {
                              return (
                                <li
                                  key={"clinics-" + idx}
                                  data-id={obj.id}
                                  onClick={this.changeClinic}
                                >
                                  <a>
                                    <label>{obj.clinic_name}</label>
                                  </a>
                                </li>
                              );
                            })}
                        </Scrollbars>
                      </ul>
                    </div>
                  </div>
                  <div className="calWeekdropDown calviewDay">
                    <div className="dropdown pull-left weekDropdowncal">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        id="dropdownMenuSearch"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {capitalizeFirstLetter(this.state.calendarView)}
                        <i className="fas fa-angle-down" />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuSearch"
                      >
                        <li>
                          <a
                            onClick={() => this.handleCalendarView(Views.WEEK)}
                            name="expType"
                          >
                            Week
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => this.handleCalendarView(Views.DAY)}
                            name="expoType"
                          >
                            Day
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Link
                    to={`/appointment/index/${moment(
                      this.state.searchPickerDate,
                    ).format("YYYY-MM-DD")}`}
                    className="new-blue-btn m-l-0 switchProvider"
                  >
                    Switch to Provider View
                  </Link>
                  <AppointmentHeader
                    smartBookingUrl={smartBookingUrl}
                    togglePrint={this.dismissPrintModal}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                this.state.calendarView === Views.DAY
                  ? "CalendarOuter ProviderCalendar ProviderCalendarDay"
                  : "CalendarOuter p-l-0 ProviderCalendar"
              }
            >
              {this.state.calendarView === Views.DAY && (
                <div className="calendarLeftSection" id="calendarLeftSection">
                  {checkIfPermissionAllowed("create-appointment") && (
                    <Link
                      id="create_appointment1"
                      className="new-blue-btn CreateAppointmentBtn"
                      data-url="/appointments/add_appointment"
                      to={
                        "/appointment/create/" +
                        pipe(
                          this.state.selectedProviders,
                          cond(() => "0", [(x) => x.length === 1, (x) => x[0]]),
                        )
                      }
                    >
                      {"Create"}
                    </Link>
                  )}
                  <div className="leftCalendar">
                    <DatePicker
                      value={
                        this.state.searchPickerDate
                          ? viewDateFormat(this.state.searchPickerDate)
                          : null
                      }
                      onChange={this.handleSearchDatePicker}
                      className="setting-search-input search-key"
                      dateFormat="YYYY-MM-dd"
                      minDate={new Date(moment().subtract(10, "years"))}
                      selected={this.state.searchPickerDate}
                      openToDate={this.state.searchPickerDate}
                      name="searchPickerDate"
                      autoComplete="off"
                      inline
                    />
                    {!!this.state.verticalsList &&
                      !!this.state.verticalsList.length &&
                      this.state.calendarView === Views.DAY && (
                        <div className="sidebarSectionContainer">
                          <h3 className="sidebarHeading">Verticals</h3>
                          <Scrollbars
                            autoHeight
                            autoHeightMin={0}
                            autoHeightMax={188}
                            className="full-width"
                            renderTrackHorizontal={(props) => (
                              <div
                                {...props}
                                style={{ display: "none" }}
                                className="track-horizontal"
                              />
                            )}
                          >
                            <ul>
                              {this.state.verticalsList.map((vertical) => {
                                return (
                                  !!vertical.id && (
                                    <li
                                      className="verticalsLegendItem"
                                      key={vertical.id}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: vertical.color,
                                        }}
                                        className="verticalsLegendIndicator"
                                      />
                                      <div className="sidebarItemName">
                                        {vertical.name}
                                      </div>
                                    </li>
                                  )
                                );
                              })}
                            </ul>
                          </Scrollbars>
                        </div>
                      )}
                    <div className="sidebarSectionContainer">
                      <h3 className="sidebarHeading">Filter providers</h3>
                      <ul className="cal-dropdown">
                        <li>
                          <a>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                value="all"
                                name="sep-provider-0"
                                onChange={this.handleInputChange}
                                checked={
                                  this.state.providers.every(
                                    (p) => this.state["provider-" + p.id],
                                  )
                                    ? "checked"
                                    : false
                                }
                              />{" "}
                              All providers
                            </label>
                          </a>
                        </li>
                      </ul>
                      <input
                        type="text"
                        className="searchProvider"
                        name="searchProvider"
                        placeholder="Search Provider"
                        onChange={this.handleInputChange}
                        value={this.state.searchProvider}
                      />
                      <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={192}
                        className="full-width"
                        renderTrackHorizontal={(props) => (
                          <div
                            {...props}
                            style={{ display: "none" }}
                            className="track-horizontal"
                          />
                        )}
                      >
                        <ul
                          className={
                            this.state.tmpProviders.length > 0
                              ? "cal-dropdown"
                              : "cal-dropdown no-visible"
                          }
                        >
                          {this.state.tmpProviders.length > 0 &&
                            this.state.tmpProviders.map((obj) => {
                              return (
                                <li key={"provider-" + obj.id}>
                                  <a>
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        value={obj.id}
                                        name={"sep-provider-" + obj.id}
                                        onChange={this.handleInputChange}
                                        checked={
                                          this.state["provider-" + obj.id]
                                            ? "checked"
                                            : false
                                        }
                                      />{" "}
                                      {displayName(obj)}
                                    </label>
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  this.state.calendarView == Views.WEEK
                    ? "calendarrightSection"
                    : "calendarrightSection calendarrightSectionWithSidebar"
                }
              >
                <div
                  className={
                    this.state.calendarView == Views.WEEK
                      ? "juvly-section full-width m-t-0 week-view-calendar calendarScrollContainer"
                      : "juvly-section full-width m-t-0 day-view-calendar calendarScrollContainer"
                  }
                  id="juvly-section"
                >
                  {this.state.events && (
                    <CalendarWrapper>
                      <DragAndDropCalendar
                        selectable
                        formats={{
                          timeGutterFormat: this.state.timeFormat,
                          eventTimeRangeFormat: this.timeRangeFormat,
                          selectRangeFormat: this.timeRangeFormat,
                        }}
                        localizer={localizer}
                        views={[Views.WEEK, Views.DAY]}
                        events={preparedEvents}
                        min={this.state.min}
                        max={this.state.max}
                        resources={this.state.resourceMap}
                        resourceIdAccessor="resourceId"
                        resourceTitleAccessor="resourceTitle"
                        onEventDrop={this.moveEvent}
                        view={this.state.calendarView}
                        defaultDate={new Date(2019, 0, 29)}
                        onSelectSlot={this.handleSelect}
                        onSelectEvent={this.showEvent}
                        step={this.state.calendarStep.value}
                        timeslots={3}
                        showMultiDayTimes
                        toolbar={false}
                        date={this.state.selectedDate}
                        onNavigate={this.navigate}
                        onView={this.onView}
                        getNow={() => this.getNow()}
                        components={{
                          event: CalendarEvent,
                          eventWrapper: EventWrapper,
                        }}
                      />
                    </CalendarWrapper>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.showOutOfOfficeData
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle">
            <div className="loyaltyMembership start-program-main">
              <div className="newPopupTitle">
                Out of office Details
                <Link
                  to={`/appointment/edit/${this.state.editAppointmentId}/${this.state.clinic_id}`}
                  className={
                    this.state.out_of_office_data &&
                    moment(
                      this.state.out_of_office_data.main_date,
                    ).isSameOrAfter(moment(), "day")
                      ? "easy-link edit-event-detail-btn"
                      : "new-green-btn pull-right no-display"
                  }
                >
                  <i className="fa fa-pencil-alt"></i> Edit
                </Link>
                <a className="popupClose" onClick={this.closeOutPopUp}>
                  <img src="/images/close.png" alt="" />
                </a>
              </div>
              <div className="newPopupContent">
                <div className="detail-row">
                  <label>Event Name:</label>
                  <span>
                    {
                      this.props.appointmentData?.appointment_details
                        ?.appointment_booking?.appointment_notes
                    }
                  </span>
                </div>
                <div className="detail-row">
                  <label>Event Date:</label>
                  <span>
                    {this.state.out_of_office_data &&
                      showFormattedDate(
                        this.state.out_of_office_data.main_date,
                      )}
                  </span>
                </div>
                <div className="detail-row">
                  <label>Time:</label>
                  <span>
                    {this.state.out_of_office_data &&
                      formatTime(this.state.out_of_office_data.from_time)}{" "}
                    -{" "}
                    {this.state.out_of_office_data &&
                      formatTime(this.state.out_of_office_data.to_time)}
                  </span>
                </div>
              </div>
              <div className="row eventDetailBtns">
                <div className="col-sm-4 col-xs-12">
                  <a
                    className={"new-red-btn pull-right"}
                    data-mode="delete"
                    onClick={this.deleteOutOfOffice}
                  >
                    {"Delete"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showAppointmentDetails
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle">
            <div className="loyaltyMembership start-program-main">
              <div className="newPopupTitle d-flex flex-col gap-8">
                <div className="d-flex justify-between align-center flex-wrap">
                  <div className="d-flex align-center m-b-8">
                    <div>Appointment Details</div>
                    <Link
                      to={"/appointment/edit/" + this.state.editAppointmentId}
                      className={
                        this.state.appointment_details &&
                        this.state.available_buttons.indexOf(
                          "edit-appointment",
                        ) > -1
                          ? "easy-link edit-event-detail-btn no-padding"
                          : "new-green-btn pull-right no-display no-padding"
                      }
                    >
                      <i className="fa fa-pencil-alt"></i> Edit
                    </Link>
                  </div>
                  <div className="d-flex gap-8 m-r-10">
                    {this.state.appointment_details &&
                      this.state.available_buttons.indexOf("join-meeting") >
                        -1 && (
                        <Button
                          size="small"
                          color="success"
                          onClick={this.openToMeetingUrl}
                        >
                          Join Meeting
                        </Button>
                      )}
                    {!!this.state.appointment_details && (
                      <QualiphyStatusBtn
                        clinicId={this.state.appointment_details.clinic_id}
                        appointmentId={this.state.appointment_details.id}
                        status={this.state.appointmentData?.exam_status}
                        onOpenPatientProfile={this.redirectToPatientProfile}
                        onOpenCreateExamModal={this.switchToCreateExamModal}
                        onRefreshSuccess={this.onQualiphyRequestSuccess}
                        isLoading={this.state.isUpdatingAfterQualiphySent}
                      />
                    )}
                  </div>
                  <a className="popupClose" onClick={this.closePopUp}>
                    <img src="/images/close.png" alt="" />
                  </a>
                </div>
                {(this.state.appointment_details?.cancellation_policy ||
                  this.state.appointment_details?.deposit_charged) && (
                  <div className="d-flex align-items-center gap-8">
                    {this.state.appointment_details?.cancellation_policy && (
                      <div className="text-color-red">
                        <CalendarCrossIcon />
                      </div>
                    )}
                    {this.state.appointment_details?.deposit_charged && (
                      <div className="text-color-green">
                        <CreditCardIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="newPopupContent">
                <div className="event-detail-outer m-b-15">
                  <div className="detail-row relative">
                    <label>Client:</label>
                    <span>
                      <a
                        href={`/clients/profile/${
                          this.state.appointment_details &&
                          this.state.appointment_details.patient.id
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="easy-link pull-left no-padding"
                      >
                        {this.state.appointment_details &&
                          displayName(this.state.appointment_details.patient)}
                      </a>
                    </span>
                    <div className="TypeOfClient d-flex flex-col items-end">
                      <div className="d-flex gap-8 m-b-10 items-center">
                        {this.state.appointment_details &&
                          (this.state.appointment_details.patient
                            .is_monthly_membership ||
                            this.state.appointment_details.patient
                              .member_type == "juvly_member" ||
                            this.state.appointment_details.patient
                              .member_type == "both") &&
                          this.state.patient_membership_colors &&
                          this.state.patient_membership_colors.length > 0 &&
                          this.state.patient_membership_colors.map(
                            (data, index) => {
                              return (
                                <MembershipBadge
                                  key={index}
                                  color={data.color}
                                  onHold={data.on_hold}
                                  displayPage="appointment-details-popup"
                                />
                              );
                            },
                          )}
                      </div>

                      {this.state.patient_type}
                    </div>
                  </div>
                  <div className="detail-row">
                    <label>Date of Booking:</label>
                    <span
                      className="eventBookingDate"
                      onClick={this.showPreviousBookings}
                    >
                      {this.state.appointment_details &&
                        showFormattedDate(
                          this.state.appointment_details.appointment_booking
                            .booking_datetime,
                          true,
                        )}{" "}
                      <i className="fa fa-clock clockIconEvenDetail"></i>
                      <span
                        className={
                          this.state.showBookings
                            ? "appoint-pro-edit"
                            : "appoint-pro-edit no-display"
                        }
                      >
                        <ul className="log-appoint-his">
                          {this.state.appointment_details &&
                            this.state.appointment_user_log.length > 0 &&
                            this.state.appointment_user_log.map((obj, idx) => {
                              let action = "";
                              if (obj.action == "booked") {
                                action = " Booked on ";
                              } else if (obj.action == "marknoshow") {
                                action = " Mark no show on ";
                              } else if (obj.action == "cancel") {
                                action = " Cancelled on ";
                              } else if (
                                (obj.action == "reschedule" ||
                                  obj.action == "edit") &&
                                obj.child != "notes"
                              ) {
                                action = " Rescheduled on ";
                              } else if (
                                obj.action == "edit" &&
                                obj.child == "notes"
                              ) {
                                action = " has Updated Appointment Note on";
                              }
                              return (
                                <li key={"customer_bookings-" + idx}>
                                  {this.state.appointment_user_log &&
                                    displayName(obj.user)}{" "}
                                  {action}{" "}
                                  {showFormattedDate(obj.created, true)}{" "}
                                </li>
                              );
                            })}
                        </ul>
                      </span>
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>Booked by:</label>
                    <span>
                      {this.state.appointment_details &&
                        this.state.appointment_details.appointment_booking
                          .booked_by == "staff" &&
                        displayName(
                          this.state.appointment_details.appointment_booking
                            .user,
                        )}
                      {this.state.appointment_details &&
                        this.state.appointment_details.appointment_booking
                          .booked_by == "patient" &&
                        "Customer"}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>Email:</label>
                    <span>
                      {this.state.appointment_details &&
                        this.state.appointment_details.appointment_booking
                          .email}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>Phone:</label>
                    <span>
                      {this.state.appointment_details &&
                        this.state.appointment_details.appointment_booking
                          .phone}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>Appointment Notes:</label>
                    <span className="editEventCalNote">
                      {checkIfPermissionAllowed(
                        "update-cancel-reschedule-appointment",
                      ) && (
                        <a onClick={this.editNote} className="">
                          <i className="far fa-edit"></i>
                        </a>
                      )}
                      <textarea
                        className={
                          this.state.noteError
                            ? "setting-input-box no-bg auto-height field-error"
                            : "setting-input-box no-bg auto-height"
                        }
                        name="notes"
                        value={this.state.notes}
                        disabled={!this.state.showEditNote ? "disabled" : ""}
                        onChange={this.handleInputChange}
                      >
                        {this.state.appointment_details && this.state.notes}
                      </textarea>
                    </span>
                  </div>

                  <div
                    className={
                      this.state.showEditNote
                        ? "detail-row appointNoteBtn"
                        : "detail-row appointNoteBtn no-display"
                    }
                  >
                    <label>&nbsp;</label>
                    <span>
                      <a
                        className="new-white-btn no-width"
                        onClick={() => {
                          this.setState({
                            showEditNote: false,
                            notes: this.state.tempnotes,
                          });
                        }}
                      >
                        {this.state.appointmentLang.appointment_cancel}
                      </a>
                      <a
                        className="new-blue-btn no-width"
                        onClick={this.saveNotes}
                      >
                        {this.state.appointmentLang.appointment_save}
                      </a>
                    </span>
                  </div>

                  <div className="detail-row">
                    <label>Clinic:</label>
                    <span>
                      {this.state.appointment_details &&
                        this.state.appointment_details.clinic.clinic_name}
                    </span>
                  </div>

                  <div className="detail-row">
                    <label>Medical Provider:</label>
                    <span className="editEventCalNote">
                      {this.state.appointment_details &&
                        !this.state.showEditProvider &&
                        displayName(this.state.appointment_details.provider)}

                      {this.state.appointment_details &&
                        this.state.showEditProvider && (
                          <select
                            className={this.state.appointment_provider_class}
                            name="appointment_provider"
                            onChange={this.handleInputChange}
                            value={this.state.appointment_provider}
                          >
                            <option value="0">Select</option>
                            {this.state.appointmentData &&
                              this.state.appointmentData
                                .providers_for_change_provider &&
                              this.state.appointmentData
                                .providers_for_change_provider.length > 0 &&
                              this.state.appointmentData.providers_for_change_provider.map(
                                (obj, idx) => {
                                  return (
                                    <option key={idx} value={obj.id}>
                                      {displayName(obj)}
                                    </option>
                                  );
                                },
                              )}
                          </select>
                        )}

                      {this.state.appointment_details && (
                        <a
                          onClick={this.editProvider}
                          className={
                            this.state.appointment_details &&
                            this.state.available_buttons.indexOf(
                              "cancel-appointment",
                            ) > -1
                              ? ""
                              : "no-display"
                          }
                        >
                          <i className="far fa-edit"></i>
                        </a>
                      )}
                    </span>
                  </div>

                  {this.state.appointment_details &&
                    this.state.showEditProvider && (
                      <div
                        className={
                          this.state.showEditProvider
                            ? "deatil-row appointNoteBtn"
                            : "deatil-row appointNoteBtn no-display"
                        }
                      >
                        <label>&nbsp;</label>

                        <span>
                          <a
                            className="new-white-btn no-width"
                            onClick={() => {
                              this.setState({
                                showEditProvider: false,
                                appointment_provider: "0",
                                appointment_provider_class: "simpleSelect",
                              });
                            }}
                          >
                            {this.state.appointmentLang.appointment_cancel}
                          </a>
                          <a
                            className="new-blue-btn no-width"
                            onClick={this.saveProvider}
                          >
                            {this.state.appointmentLang.appointment_save}
                          </a>
                        </span>
                      </div>
                    )}

                  <div className="detail-row">
                    <label>
                      {this.state.appointment_details &&
                      this.state.appointment_details.package_id == 0
                        ? this.state.appointmentLang
                            .appointment_service_services
                        : this.state.appointmentLang.appointment_packages}
                      :
                    </label>
                    <span>
                      {this.state.appointment_details &&
                        this.state.appointment_details.package_id == 0 &&
                        this.state.appointment_details.appointment_services.map(
                          (obj, idx) => {
                            return (
                              <div
                                className="services-listdiv service-list"
                                key={"appointment_services-" + idx}
                              >
                                {obj.service ? obj.service.name : ""}
                                <span
                                  data-duration_hours="0"
                                  className="duration_hours"
                                >
                                  {convertDurationToVisibleFormat(obj.duration)}{" "}
                                </span>
                              </div>
                            );
                          },
                        )}
                      {this.state.appointment_details &&
                        this.state.appointment_details.package_id != 0 && (
                          <div className="services-listdiv service-list">
                            {this.state.appointment_details.package.name +
                              " (" +
                              this.state.appointment_details.package.hours +
                              " hrs " +
                              this.state.appointment_details.package.mins +
                              "mins)"}
                          </div>
                        )}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>Date & Time:</label>
                    <span>
                      {this.state.appointment_details &&
                        showFormattedDate(
                          this.state.appointment_details.appointment_datetime,
                          true,
                        )}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>Appointment ends at:</label>
                    <span>
                      {Maybe.of(this.state.appointment_details)
                        .map((d) =>
                          getTimeFormat() !== DEFAULT_TIME_FORMAT
                            ? convertTime12to24(d.appointment_endtime)
                            : d.appointment_endtime,
                        )
                        .value()}
                    </span>
                  </div>

                  {this.state.show_toggle && this.state.show_toggle == true && (
                    <div className="detail-row">
                      <label>Appointment Confirmed:</label>
                      <span>
                        <label
                          className="setting-switch pull-right"
                          htmlFor="show_confirmed_toggle"
                        >
                          {this.state.show_confirmed_toggle ? (
                            <span className="radioTextLabel">Yes</span>
                          ) : (
                            <span className="radioTextLabel">No</span>
                          )}
                          <input
                            type="checkbox"
                            name="show_confirmed_toggle"
                            id="show_confirmed_toggle"
                            checked={
                              this.state.show_confirmed_toggle ? "checked" : 0
                            }
                            onChange={() => this.confirmAppointment()}
                          />
                          <span className="setting-slider width-45"></span>
                        </label>
                      </span>
                    </div>
                  )}
                  <div className="detail-row">
                    <label>Last Cancelled Appointment:</label>
                    <span>
                      {this.state.last_canceled_appointment &&
                        showFormattedDate(
                          this.state.last_canceled_appointment
                            .appointment_datetime,
                          true,
                        )}
                    </span>
                  </div>
                </div>
                <div className="row eventDetailBtns">
                  <div className="col-sm-4 col-xs-12">
                    <a
                      className={
                        this.state.appointment_details &&
                        this.state.available_buttons.indexOf("mark-as-noshow") >
                          -1
                          ? "new-yellow-btn pull-right"
                          : "new-yellow-btn pull-right no-display"
                      }
                      data-mode="markasnoshow"
                      onClick={this.fetchAppointmentFees}
                    >
                      {this.state.appointmentLang.appointment_mark_as_no_show}
                    </a>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <a
                      className={
                        this.state.appointment_details &&
                        this.state.available_buttons.indexOf(
                          "cancel-appointment",
                        ) > -1
                          ? "new-red-btn pull-right m-l-10"
                          : "new-red-btn pull-right m-l-10 no-display"
                      }
                      data-mode="cancel"
                      onClick={this.fetchAppointmentFees}
                    >
                      {this.state.appointmentLang.appointment_cancel_label}
                    </a>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <a
                      className={
                        this.state.appointment_details &&
                        this.state.available_buttons.indexOf(
                          "patient-check-in",
                        ) > -1
                          ? "new-green-btn pull-right no-margin"
                          : "new-green-btn pull-right no-display"
                      }
                      onClick={this.patientCheckIn}
                    >
                      {this.state.appointmentLang.appointment_patient_check_in}
                    </a>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <a
                      className={
                        this.state.appointment_details &&
                        this.state.available_buttons.indexOf(
                          "patient-checked-in",
                        ) > -1
                          ? "new-green-btn pull-right"
                          : "new-green-btn pull-right no-display"
                      }
                      onClick={this.patientUnCheckIn}
                    >
                      {`Uncheck Appointment`}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.showCheckinData
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle">
            <div className="loyaltyMembership start-program-main">
              <div className="newPopupTitle">
                <div className="popup-name">
                  {checkinPname} -{" "}
                  {this.state.appointmentLang.appointment_check_in}
                </div>
                <a
                  className="popupClose"
                  onClick={() => {
                    this.setState({
                      showCheckinData: !this.state.showCheckinData,
                    });
                  }}
                >
                  <img alt="" src="/images/close.png" />
                </a>
              </div>
              <div className="newPopupContent">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="simpleField">
                      <div className="simpleLabel">
                        {this.state.appointmentLang.appointment_room}
                        <span className="required">*</span>
                      </div>

                      <input
                        className={
                          !this.state.roomError
                            ? "simpleInput"
                            : "simpleInput field-error"
                        }
                        type="text"
                        name="room"
                        value={this.state.room}
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="simpleField">
                      <div className="simpleLabel">
                        {this.state.appointmentLang.appointment_customer_note}
                      </div>

                      <input
                        className="simpleInput"
                        type="text"
                        name="customer_note"
                        value={this.state.customer_note}
                        autoComplete="off"
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <p className="p-text col-xs-12">
                    <input
                      type="checkbox"
                      className="new-check"
                      value={this.state.send_to_provider}
                      name="send_to_provider"
                      checked={this.state.send_to_provider ? "checked" : false}
                      autoComplete="off"
                      onChange={() =>
                        this.setState({
                          send_to_provider: !this.state.send_to_provider,
                        })
                      }
                    />
                    {this.state.appointmentLang.appointment_send_this}
                  </p>
                </div>
                <div className="customer-note">
                  <div className="juvly-subtitle m-b-15">
                    {
                      this.state.appointmentLang
                        .appointment_select_customer_notes
                    }
                    :
                  </div>
                  {this.state.checkinData &&
                    this.state.checkinData.map((obj, i) => {
                      return (
                        <div key={i} className="check-note">
                          <input
                            type="checkbox"
                            className="note-check"
                            name={"notes-" + obj.id}
                            checked={
                              this.state["notes-" + obj.id] ? "checked" : false
                            }
                            onChange={this.handleInputChange}
                          />
                          <div className="check-note-text">
                            {obj.added_by}, {obj.created} - {obj.notes}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div>
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.makeCheckin}
                >
                  {this.state.appointmentLang.appointment_complete_check_in}
                </a>
                <a
                  className="new-white-btn pull-right no-width"
                  onClick={() =>
                    this.setState({
                      showCheckinData: !this.state.showCheckinData,
                    })
                  }
                >
                  {this.state.appointmentLang.appointment_cancel}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.showNotifyPop
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle">
            <div className="loyaltyMembership start-program-main">
              <div className="newPopupTitle">
                <div className="popup-name">
                  {this.state.notiMode == "cancel"
                    ? "Cancel Appointment"
                    : "Send Notification"}
                </div>
                <a className="popupClose" onClick={this.closeNotiPop}>
                  <img alt="" src="/images/close.png" />
                </a>
              </div>
              <div className="newPopupContent">
                <div
                  className={
                    this.state.notiMode == "cancel"
                      ? "juvly-subtitle m-b-10"
                      : "no-display"
                  }
                >
                  {this.state.appointmentLang.appointment_r_u_sure_cancel}{" "}
                  {pName}
                  {`'s`} {this.state.appointmentLang.appointment_question_mark}
                  <br />
                  {this.state.appointmentLang.appointment_will_be_removed}{" "}
                  {pName}
                  {`'s`} {this.state.appointmentLang.appointment_history}.
                </div>
                <div
                  className={
                    this.state.notiMode == "reschedule"
                      ? "juvly-subtitle m-b-10"
                      : "no-display"
                  }
                >
                  {
                    this.state.appointmentLang
                      .appointment_rescheduled_successfully
                  }
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="simpleField">
                      <div className="simpleLabel">
                        {pName}{" "}
                        {this.state.appointmentLang.appoinment_will_be_notified}
                        :
                      </div>
                      <select
                        className="simpleSelect"
                        name="notification_type"
                        onChange={this.handleInputChange}
                        value={this.state.notification_type}
                      >
                        <option value="email">
                          {this.state.appointmentLang.appointment_email}
                        </option>
                        <option value="sms">
                          {this.state.appointmentLang.appointment_sms}
                        </option>
                        <option value="emailAndSms">
                          {this.state.appointmentLang.appointment_both}
                        </option>
                        {this.state.notiMode == "cancel" && (
                          <option value="dontsend">
                            {
                              this.state.appointmentLang
                                .appointment_dont_send_notification
                            }
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <p className="p-text">
                  {this.state.appointmentLang.appointment_notification_message}
                  <br />
                  {this.state.appointmentLang.appointment_top_notification}
                </p>

                <div className={"newInputFileldOuter m-b-40"}>
                  <EditorWithPreview
                    name={"notification_body"}
                    placeholder={"Email Body"}
                    value={this.state.notification_body}
                    className={this.state.appointment_booking_emailClass}
                    handleEditorChange={this.handleEditorChange}
                  />
                </div>

                <div className="simpleField no-display">
                  <textarea
                    className="simpleTextarea h-130 scroll-y m-b-10"
                    name="notification_body"
                    onChange={this.handleInputChange}
                    value={this.state.notification_body}
                  >
                    {this.state.notification_body}
                  </textarea>
                </div>
              </div>
              <div>
                <a
                  onClick={this.closeNotiPop}
                  className="new-red-btn pull-left no-width"
                >
                  {this.state.notiMode == "cancel"
                    ? "Back"
                    : this.state.appointmentLang
                        .appointment_dont_send_notification}
                </a>
                <a
                  className={
                    this.state.notiMode == "cancel"
                      ? "new-blue-btn pull-right"
                      : "no-display"
                  }
                  onClick={this.cancelAppointment}
                >
                  {this.state.appointmentLang.appointment_cancel_label}
                </a>
                <a
                  className={
                    this.state.notiMode == "reschedule"
                      ? "new-blue-btn pull-right"
                      : "no-display"
                  }
                  onClick={this.sendNotification}
                >
                  {this.state.appointmentLang.appointment_send_notification}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.showModal ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.showModal ? "modal fade in displayBlock" : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.notiMode == "reschedule"
                    ? "Rescheduling Fee"
                    : "Cancellation Fee"}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {this.state.notiMode == "reschedule"
                  ? "There is a rescheduling fee of "
                  : this.state.appointmentLang
                      .appointment_cancellation_fee_of}{" "}
                {numberFormat(this.state.cancellation_fee, "currency")}.{" "}
                {this.state.appointmentLang.appointment_want_to_do}
              </div>
              <div className="modal-footer">
                {this.state.notiMode == "reschedule" && (
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn btn-success pull-right"
                      data-charge={true}
                      onClick={this.chargeAndReschedule}
                    >
                      {this.state.appointmentLang.appointment_save_charge}
                    </button>
                    <button
                      type="button"
                      className="btn  logout pull-right m-r-10"
                      data-charge={false}
                      onClick={this.chargeAndReschedule}
                    >
                      {
                        this.state.appointmentLang
                          .appointment_save_do_not_charge
                      }
                    </button>
                  </div>
                )}
                {this.state.notiMode != "reschedule" && (
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn btn-success pull-right"
                      data-charge={true}
                      onClick={this.chargeAndFurther}
                    >
                      {this.state.appointmentLang.appointment_cancel_and_charge}
                    </button>
                    <button
                      type="button"
                      className="btn  logout pull-right m-r-10"
                      data-charge={false}
                      onClick={this.chargeAndFurther}
                    >
                      {
                        this.state.appointmentLang
                          .appointment_cancel_donot_charge
                      }
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.state.showPrintModal
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle">
            <div className="loyaltyMembership start-program-main">
              <div className="newPopupTitle">
                <div className="popup-name">
                  {this.state.appointmentLang.appointment_print}
                </div>
                <a
                  className="popupClose"
                  onClick={() =>
                    this.setState({
                      showPrintModal: !this.state.showPrintModal,
                    })
                  }
                >
                  <img alt="" src="/images/close.png" />
                </a>
              </div>
              <div className="newPopupContent">
                <div className="row">
                  <div className="col-xs-12 printable-img-title">
                    {this.state.appointmentLang.appointment_we_offer}.
                  </div>
                  <div className="col-sm-6">
                    <a
                      className="printable-img print_cal_option"
                      onClick={this.print.bind(this, "short")}
                    >
                      <img alt="" src="/images/short.png" />
                      <div className="text-center">
                        {this.state.appointmentLang.appointment_short}
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6">
                    <a
                      className="printable-img print_cal_option"
                      onClick={this.print.bind(this, "extensive")}
                    >
                      <img alt="" src="/images/extensive.png" />
                      <div className="text-center">
                        {this.state.appointmentLang.appointment_extensive}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={this.state.showRescheduleConfirmModal ? "overlay" : ""}
        ></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.showRescheduleConfirmModal
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissRescheduleModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.globalLang.are_you_sure}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {this.state.updatedEvent &&
                this.state.updatedEvent.appointmentType == "outofoffice"
                  ? "Are you sure want to reschedule this out of office event?"
                  : this.state.appointmentLang.reschedule_confirmation_message}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={this.dismissRescheduleModal}
                  >
                    {this.state.appointmentLang.no_option}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.rescheduleAppoint}
                  >
                    {this.state.appointmentLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={this.state.showDeleteModal ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.showDeleteModal
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissDeleteModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.globalLang.are_you_sure}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                Are you sure you want to delete this event ?
                <div className="formInputOuter p20">
                  <div className="basic-checkbox-outer col-xs-12 no-padding m-t-0">
                    <input
                      id="delete-event"
                      type="checkbox"
                      value={this.state.checkAllSubsequent}
                      name={"checkAllSubsequent"}
                      onChange={() => {
                        this.setState({
                          checkAllSubsequent: !this.state.checkAllSubsequent,
                        });
                      }}
                      checked={
                        this.state.checkAllSubsequent ? "checked" : false
                      }
                      className="basic-form-checkbox m-t-0"
                    />
                    <label className="basic-form-text" htmlFor="delete-event">
                      Delete all following events like this ?
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={this.dismissDeleteModal}
                  >
                    {this.state.appointmentLang.no_option}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteOutEvent}
                  >
                    {this.state.appointmentLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock positionFixed"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.appointmentLang.appointment_processing_please_wait}
            </div>
          </div>
        </div>
        <ReactTooltip effect="float" multiline={true} place="left" />
        {this.state.isCreateQualiphyProcedureModalOpen && (
          <ModalCreateQualiphyProcedure
            isOpen
            onClose={this.switchToApptDetailsModal}
            onSuccess={this.onQualiphyRequestSuccess}
            appointmentId={this.state?.appointment_details?.id}
            patientId={this.state?.appointment_details?.patient?.id}
            initialValues={{
              clinicId: this.state?.appointment_details?.clinic_id,
            }}
          />
        )}
        {this.state.isConfirmCancelOpen && (
          <ConfirmModal
            isOpen
            onClose={() => {
              this.setState({
                isConfirmCancelOpen: false,
              });
            }}
            onCancel={() => {
              this.setState({
                isConfirmCancelOpen: false,
              });
            }}
            onConfirm={() => {
              this.setState({
                isConfirmCancelOpen: false,
              });
              this.cancelAppointment();
            }}
          >
            Are you sure you want to cancel this appointment?
          </ConfirmModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData")) || {
    global: {},
  };
  returnState.appointmentDataStatus =
    state.AppointmentReducer.appointmentDataStatus;
  localStorage.setItem("showLoader", false);
  if (state.AppointmentReducer.action === "PROVIDER_WEEK_APPOINTMENTS") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.appointmentsData = state.AppointmentReducer.data.data;
      returnState.appointmentsTimestamp = new Date();
    }
  }
  if (state.AppointmentReducer.action === "APPOINTMENT_GET") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.appointmentData = state.AppointmentReducer.data.data;
      returnState.appointmentDataTimeStamp = new Date();
    }
  }
  if (state.AppointmentReducer.action === "MARKED_NO_SHOW") {
    if (state.AppointmentReducer.data.status == 200) {
      returnState.markedAsNoShow = true;
      returnState.markedTimeStamp =
        state.AppointmentReducer.data.data.timestamp;
    }
  }
  if (state.AppointmentReducer.action === "GET_CHECKIN_DATA") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.checkinData = state.AppointmentReducer.data.data;
      returnState.checkinDataTimeStamp = new Date();
    }
  }
  if (state.AppointmentReducer.action === "POST_CHECKIN_DATA") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.patientCheckedIn = true;
      returnState.patientCheckedIdTimestamp = new Date();
    }
  }
  if (state.AppointmentReducer.action === "EMPTY_DATA") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.appointmentData = {};
    }
  }

  if (state.AppointmentReducer.action === "APPOINTMENT_CANCELLED") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.appointmentCancelled = true;
      returnState.notificationSent = true;
      returnState.timestamp = state.AppointmentReducer.data.data.timestamp;
    }
  }

  if (state.AppointmentReducer.action === "SEND_NOTIFICATION_CALENDAR") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.notificationSent = true;
      returnState.notiTimeStamp = new Date();
    }
  }
  if (state.AppointmentReducer.action === "UPDATE_NOTES") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.notesUpdates = true;
      returnState.notesUpdatedTimeStamp = new Date();
      returnState.message =
        languageData.global[state.AppointmentReducer.data.message] ||
        "Notes Updated Successfully";
    }
  }
  if (state.AppointmentReducer.action === "PRINT_APPOINTMENT") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.exportCsvData = state.AppointmentReducer.data.data;
      returnState.printTimeStamp = new Date();
    }
  }

  if (state.AppointmentReducer.action === "DELETE_EVENT") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.deletedOutOfficeTimeStamp = new Date();
    }
  }

  if (state.AppointmentReducer.action === "CONFIRM_APPOINTMENT") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.appointmentConfirmData = true;
      returnState.appointmentConfirmationData = state.AppointmentReducer.data;
      returnState.appointmentConfirmationDataTime = new Date();
    }
  }

  if (state.AppointmentReducer.action === "UPDATE_PROVIDER") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.providerUpdates = true;
      returnState.providerUpdatedTimeStamp = new Date();
    }
  }

  if (state.AppointmentReducer.action === "GET_UNCHECKIN_DATA") {
    if (state.AppointmentReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.AppointmentReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.patientUnCheckedIn = true;
      returnState.patientUnCheckedIdTimestamp = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProviderAppointments: getProviderAppointments,
      getAppointment,
      markNoShow: markNoShow,
      updateNotes: updateNotes,
      checkin: checkin,
      makePatientCheckin: makePatientCheckin,
      exportEmptyData: exportEmptyData,
      getAppointmentFees,
      getCancelNotification: getCancelNotification,
      cancelAppointment,
      checkRescheduleTime: checkRescheduleTime,
      rescheduleAppointment: rescheduleAppointment,
      sendNotification,
      printAppointment: printAppointment,
      deleteEvent,
      confirmAppointmentStatus,
      updateProvider: updateProvider,
      unCheckAppt: unCheckAppt,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DragDropContext(HTML5Backend)(ProviderWeekCalendar));
